import { useDispatch, useSelector } from 'react-redux';
import { formatImgPath } from '../../utils';
import { useRef, useState } from 'react';
import { Form, Input, InputNumber, Tag, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { updateUser } from '../../features/auth/authSlice';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const url = process.env.REACT_APP_API_BASE_URL;

const beforeUpload = (file) => {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG file!');
	}
	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}
	return isJpgOrPng && isLt2M;
};

export default function Profile() {
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);

	const [form] = Form.useForm();
	const formRef = useRef(null);

    const navigate = useNavigate()

	const [loading, setLoading] = useState(false);
	const [imageUrl, setImageUrl] = useState(
		'https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png',
	);

	const handleProfileChange = (info) => {
		if (info.file.status === 'uploading') {
			setLoading(true);
			return;
		}
		if (info.file.status === 'done') {
			setLoading(false);
			setImageUrl(info?.file?.response?.jsonData);
			// Get this url from response in real world.
			// getBase64(info.file.originFileObj, (url) => {
			// 	setLoading(false);
			// 	setImageUrl(url);
			// });
		}
	};

	const uploadButton = (
		<div>
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div
				style={{
					marginTop: 8,
				}}
			>
				Upload
			</div>
		</div>
	);

	const onFinish = async (values) => {
		values.usrId = user?.usrId;
		values.usrNationalId = user?.usrNationalId;
        if (imageUrl) {
            values.usrProfileImage = imageUrl
        }
		const res = await dispatch(updateUser(values));
        if (res?.payload?.success) {
            toast.success("Personal details updated")
        }else{
            toast("Could not update details. Please try again later")
        }
	};

	function handleGoBack() {
		navigate(-1)
	}

	return (
		<>
			<div className='w-[100%] h-auto bg-white p-10 flex justify-between items-center'>
				<span className='font-bold text-[26px] text-[#141414]'>Profile</span>

				<div className='flex items-center'>
					<button
						type='button'
						onClick={handleGoBack}
						className='bg-transparent flex items-center'
					>
						<div className='flex justify-center items-center rounded-full w-[40px] h-[40px] p-2 bg-[#EDF8FF]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='28'
								height='24'
								viewBox='0 0 28 24'
								fill='none'
							>
								<path
									d='M17.7692 18L11.0469 12L17.7692 6'
									stroke='#147CBC'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
							</svg>
						</div>
						<span className='ml-5 font-bold text-[22px] text-[#222222]'>
							Go Back
						</span>
					</button>
				</div>
			</div>

			<div className='w-[100%] h-auto bg-white my-auto mx-auto p-10 flex flex-col justify-center items-center mt-3'>
				{/* <img
					loading='lazy'
					className={`w-[121px] h-[121px] object-cover`}
					src={
						user?.usrIcon
							? formatImgPath(user?.usrIcon)
							: 'https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png'
					}
					alt='user-profile'
				/> */}

				<Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					onFinish={onFinish}
					style={{
						maxWidth: '100%',
						marginTop: 20,
						width: '700px',
					}}
					form={form}
					initialValues={{
						...user,
					}}
				>
					<div className='flex flex-col items-center mt-5 w-full'>
						<div className='flex justify-center'>
							<Form.Item
								label={<span className='text-blueDark'>Upload photo</span>}
							>
								<Upload
									accept='image/jpeg,image/png'
									name='file'
									listType='picture-circle'
									className='avatar-uploader'
									showUploadList={false}
									action={`${url}/nea/saveFile.action`}
									beforeUpload={beforeUpload}
									onChange={handleProfileChange}
								>
									{imageUrl || user?.usrProfileImage ? (
										<img
											loading='lazy'
											src={imageUrl ? formatImgPath(imageUrl) : formatImgPath(
												user?.usrProfileImage ??
													'https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png',
											)}
											alt='avatar'
											style={{
												width: '100%',
											}}
											className='rounded-full'
										/>
									) : (
										uploadButton
									)}
								</Upload>
							</Form.Item>
						</div>

						<div className='grid grid-cols-1 lg:grid-cols-2 gap-x-10 w-full'>
							<Form.Item name={'usrFirstName'} label='First Name'>
								<Input
									readOnly
									className='input'
								/>
							</Form.Item>

							<Form.Item name={'usrLastName'} label='Other Names'>
								<Input
									readOnly
									className='input'
								/>
							</Form.Item>
						</div>
					</div>

					<div className='grid grid-cols-1 lg:grid-cols-2 gap-x-10'>
						<Form.Item name={'usrNationalId'} label='ID Number'>
							<Input
								readOnly
								className='input'
							/>
						</Form.Item>

						<Form.Item
							name='usrOtherTitle'
							label='KRA Pin'
							rules={[
								{
									required: false,
									message: 'Please add career headline',
								},
							]}
						>
							<Input
								readOnly
								className='input'
							/>
						</Form.Item>

						<Form.Item name={'usrEmail'} label='Email'>
							<Input
								type='email'
								className='input'
							/>
						</Form.Item>

						<Form.Item name={'usrMobileNumber'} label='Phone Number'>
							<InputNumber
								maxLength={10}
								minLength={9}
								className='input'
							/>
						</Form.Item>

						<Form.Item
							label='Role'
							rules={[
								{
									required: false,
									message: 'Please add career headline',
								},
							]}
						>
							<Input
								readOnly
								defaultValue={'Staff'}
								className='input'
							/>
						</Form.Item>
					</div>

					<div className='flex items-center justify-center mt-10 mb-14 w-full'>
						<button
							// disabled={authLoading}
							className='bg-blueDark rounded-[35px] text-white w-[300px] p-3'
							type='submit'
						>
							{/* {authLoading ? <Spin /> : 'Next'} */}
							Upate
						</button>
					</div>
				</Form>
			</div>
		</>
	);
}
