import React from "react";
import frame from "../../assets/Frame.svg";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InsideHeader from "../../components/InsideHeader";

function AdminLanding() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleUsers = () => {
    navigate("/users-list");
  };
  const handleLogo = () => {
    navigate("/admin-logo");
  };
  const handleEmployees = () => {
    navigate("/employees");
  };
  const handleBranches = () => {
    navigate("/branches");
  };

  const handlePanel = () => {
    navigate("/panel-list");
  };

  return (
    <>
     <InsideHeader
        title="Administrator"
        subtitle="Perform all administrative operations"
        back={false}
      />

      <div className="bg-white mr-3 mb-[10px]">
        <div className="ml-[54px] flex flex-col gap-y-[23px] text-center h-[1000px] py-[76px]">
          <div className="flex gap-x-5 ">
            <div
              className="card !w-[524px] h-[173px] !rounded-md border border-[#DBEBF7] p-[30px] cursor-pointer"
              onClick={handleLogo}
            >
              <div className="flex items-center justify-start gap-x-5">
                <img src={frame} alt="frame" />
                <div className="flex flex-col ">
                  <div className="text-black1 text-[22px] font-medium font-dmSans leading-9 flex justify-start">
                    Account
                  </div>
                  <div className="text-black1 text-[20px] font-normal font-dmSans leading-[30px] text-left">
                    Edit your account details
                  </div>
                </div>
              </div>
            </div>

            <div
              className="card !w-[524px] h-[173px] !rounded-md border border-[#DBEBF7] p-[30px] cursor-pointer"
              onClick={handleUsers}
            >
              <div className="flex items-center justify-start gap-x-5">
                <img src={frame} alt="frame" />
                <div className="flex flex-col ">
                  <div className="text-black1 text-[22px] font-medium font-dmSans leading-9 flex justify-start">
                    Users
                  </div>
                  <div className="text-black1 text-[20px] font-normal font-dmSans leading-[30px] text-left">
                    Manage users under your account
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-x-5 ">
            <div
              onClick={handleBranches}
              className="card !w-[524px] h-[173px] !rounded-md border border-[#DBEBF7] p-[30px] cursor-pointer"
            >
              <div className="flex items-center justify-start gap-x-5">
                <img src={frame} alt="frame" />
                <div className="flex flex-col ">
                  <div className="text-black1 text-[22px] font-medium font-dmSans leading-9 flex justify-start">
                    Branches
                  </div>
                  <div className="text-black1 text-[20px] font-normal font-dmSans leading-[30px] text-left">
                    Manage organization branches under your account
                  </div>
                </div>
              </div>
            </div>

            <div
              onClick={handleEmployees}
              className="card !w-[524px] h-[173px] !rounded-md border border-[#DBEBF7] p-[30px] cursor-pointer"
            >
              <div className="flex items-center justify-start gap-x-5">
                <img src={frame} alt="frame" />
                <div className="flex flex-col">
                  <div className="text-black1 text-[22px] font-medium font-dmSans leading-9 flex justify-start">
                    Employees
                  </div>
                  <div className="text-black1 text-[20px] font-normal font-dmSans leading-[30px] text-left">
                    Manage organization branches under your account
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex gap-x-5 ">
            {/* <div className="card !w-[524px] h-[173px] !rounded-md border border-[#DBEBF7] p-[30px] cursor-pointer">
              <div className="flex items-center justify-start gap-x-5">
                <img src={frame} alt="frame" />
                <div className="flex flex-col ">
                  <div className="text-black1 text-[22px] font-medium font-dmSans leading-9 flex justify-start">
                    Social Media Setup
                  </div>
                  <div className="text-black1 text-[20px] font-normal font-dmSans leading-[30px] text-left">
                    Manage links to your organization social media accounts
                  </div>
                </div>
              </div>
            </div> */}

            <div
              className="card !w-[524px] h-[173px] !rounded-md border border-[#DBEBF7] p-[30px] cursor-pointer"
              onClick={handlePanel}
            >
              <div className="flex items-center justify-start gap-x-5">
                <img src={frame} alt="frame" />
                <div className="flex flex-col ">
                  <div className="text-black1 text-[22px] font-medium font-dmSans leading-9 flex justify-start">
                    Interview Panel
                  </div>
                  <div className="text-black1 text-[20px] font-normal font-dmSans leading-[30px]">
                    Edit your panel details
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLanding;
