import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	payLoading: false,
};

export const mpesaPush = createAsyncThunk(
	'paySlice/mpesaPush',
	async (payObj) => {
		const res = await axiosInstance.get(
			`${url}/nea/mpesaPush.action?phone=${payObj?.phone}&amount=${payObj?.amount}&accountref=${payObj?.accountref}`,
		);
		return res.data;
	},
);

export const paySlice = createSlice({
	name: 'pay',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(mpesaPush.pending, (state) => {
				state.payLoading = true;
			})
			.addCase(mpesaPush.fulfilled, (state, action) => {
				state.payLoading = false;
			})
			.addCase(mpesaPush.rejected, (state) => {
				state.payLoading = false;
			});
	},
});

export default paySlice.reducer;
export const {} = paySlice.actions;
