import React, { useEffect, useRef, useState } from 'react';
import Header from './Header';
import { Breadcrumb, Form, Input, InputNumber, Spin, Tabs } from 'antd';
import {
	fetchAgencyDetails,
	saveFile,
} from '../../features/application/applicationSlice';
import toast from 'react-hot-toast';
import uplodSvg from '../../assets/image 194.png';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatImgPath } from '../../utils';
import { fetchAgencyData, saveAgency } from '../../features/agency/agencySlice';
import { createInstitution } from '../../features/auth/authSlice';
import FileUploader from '../../components/FileUploader';
import CompanyRegistration from './agency_details_files/CompanyRegistration';
import BusinessRegistration from './agency_details_files/BusinessRegistration';
import TaxCompliance from './agency_details_files/TaxCompliance';
import CRTwelve from './agency_details_files/CRTwelve';
import LeaseAgreement from './agency_details_files/LeaseAgreement';

const url = process.env.REACT_APP_API_BASE_URL;

export default function AdminDocuments() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [form] = Form.useForm();
	const formRef = useRef(null);

	const { user, authLoading } = useSelector((state) => state.auth);

	const { agencyObj } = useSelector((state) => state.agency);

	const [profile, setProfile] = useState(
		agencyObj?.instIcon ? agencyObj?.instIcon : null,
	);

	const [loadingAttach, setLoadingAttach] = useState(false);

	const handleImageSelect = async (event) => {
		setLoadingAttach(true);
		const formData = new FormData();
		formData.append('file', event.target.files[0]);

		const res = await dispatch(saveFile(formData));

		if (res?.payload?.success) {
			setLoadingAttach(false);
			setProfile(res?.payload?.jsonData ?? '');
		} else {
			setLoadingAttach(false);
			toast.error('Please try uploading your image again');
			return;
		}

		const createAdminUsersRes = await dispatch(
			saveAgency({
				instId: user?.usrInstId,
				instIcon: res?.payload?.jsonData ?? '',
			}),
		);

		if (createAdminUsersRes?.payload?.success) {
			toast.success('Profile updated successfully');
			fetchAgencyDetails();
		} else {
			toast.error('Error updating profile, please try again');
		}
	};

	const [formData, setFormData] = useState({
		agcInstId: user?.usrInstId,
		...agencyObj,
	});

	const handleSuccess = (response) => {
		setFormData((prev) => ({
			...prev,
			[response.fileName]: response.jsonData,
		}));
	};

	const handleError = (response) => {
		// Handle error logic
		console.error('File upload failed:', response);
	};

	async function handleAgencyData() {
		await dispatch(fetchAgencyDetails(user?.usrInstId));
	}

	useEffect(() => {
		handleAgencyData();
	}, []);

	return (
		<>
			<div className='w-full flex flex-col min-h-full h-auto p-14 mb-36'>
				<CompanyRegistration handleAgencyData={handleAgencyData} />

                <CRTwelve handleAgencyData={handleAgencyData} />

				<BusinessRegistration handleAgencyData={handleAgencyData} />

				<TaxCompliance handleAgencyData={handleAgencyData} />

				<LeaseAgreement handleAgencyData={handleAgencyData} />
			</div>
		</>
	);
}
