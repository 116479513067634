import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { cleanMyEmployees, fetchEmployees } from '../../features/myEmployees/myEmployeesSlice';
import EmployeesSection from './employee_files/EmployeesSection';
import NoEmployee from './employee_files/NoEmployee';
import InsideHeader from '../../components/InsideHeader';

export default function Employees() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { employeesData } = useSelector((state) => state.employee);

	const [employees, setemployees] = useState(employeesData);

	function handleGoBack() {
		navigate(-1);
	}

	async function handleFetchEmployees() {
		await dispatch(
			fetchEmployees({
				emplInstId: user?.usrInstId,
			}),
		);
	}

	useEffect(() => {}, [employees]);

	useEffect(() => {
		setemployees(employeesData);
	}, [employeesData]);

	useEffect(() => {
		dispatch(cleanMyEmployees())
		handleFetchEmployees()
	}, []);

	return (
		<>
		<InsideHeader title="Employees" subtitle="Manage employees within your organization" back={false}/>
			 
			<div className='bg-white '>
				{
					employees?.length  ? 
					<EmployeesSection />
					:
					<NoEmployee />
				}
			</div>
		</>
	);
}
