import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Spin,
  message,
} from "antd";
import "../../../../clients/client.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { saveJobApplication } from "../../../../../features/applicantJob/applicantJobSlice";
import { passportData } from "../../../../../Gender";
import Dragger from "antd/es/upload/Dragger";
import { disabledPastDate } from "../../../../../utils";
import { saveNotification } from "../../../../../features/global/globalSlice";

const { TextArea } = Input;
const url = process.env.REACT_APP_API_BASE_URL;

const OfferModal = ({
  isModalOpen,
  setIsModalOpen,
  applicantEdit,
  prodd,
  fetchFunction,
  fetchCount,
}) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const { jobApplicationLoading } = useSelector((state) => state.applicantJob);

  const { agencyObj } = useSelector((state) => state.agency);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formdata, setFormData] = useState();

  const [fileUrl, setfileUrl] = useState("");
  const [fileName, setfileName] = useState("");

  const props = {
    name: "file",
    multiple: false,
    accept: ".pdf",
    action: `${url}/nea/saveFile.action`,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        if (info?.file?.response?.success) {
          setfileUrl(info?.file?.response?.jsonData);
          setfileName(info?.file?.name);
        } else {
          setfileUrl("");
          setfileName("");
        }
        console.log(info.file.response);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const [documentSent, setDocumentSent] = useState();
  const onChange = async (e) => {
    setDocumentSent(e.target.value);
  };

  const onFinish = async (data) => {
    if (!documentSent) {
      toast.error("Select one option");
      return;
    }
    const res = await dispatch(
      saveJobApplication({
        japId: applicantEdit?.japId,
        japProgressStatus: prodd,
        jobUpdatedBy: user?.usrId,
        japOfferLetterFile: fileUrl,
        japValidityStartDate: data?.japValidityStartDate,
      })
    );
    const notObj = {
      ntfnInstId: user?.usrInstId,
      ntfnSenderId: user?.usrId,
      ntfnReceiverId: applicantEdit?.japUsrId,
      ntfnBody: `You have been given an offer by ${agencyObj?.instName}`,
    };
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
      // await dispatch(saveNotification(notObj));
      form.resetFields();
      setfileUrl("");
      fetchFunction();
      fetchCount();
      handleCancel();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer job-vacancy-modal"
        title={
          "Add offer details for candidate" + " " + applicantEdit?.japFullName
        }
        open={isModalOpen}
        onCancel={handleCancel}
        width={850}
      >
        <div className="">
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            className=""
            style={{
              maxWidth: "100%",
            }}
            form={form}
          >
            <div
              className="text-neutral-800 text-[21px] font-medium font-dmSans leading-relaxed
              flex flex-col gap-y-5 mb-10"
            >
              Have you shared offer letter to candidate{" "}
              {applicantEdit?.japFullName}?
              <Radio.Group
                onChange={onChange}
                value={documentSent}
                className="flex flex-col gap-y-5"
              >
                <Radio value={"Yes"}>Shared Offer Letter</Radio>
                <Radio value={"No"}>Not shared Offer Letter</Radio>
              </Radio.Group>
            </div>

            {documentSent == "Yes" ? (
              <Form.Item
                label="Contract Start Date"
                name="japValidityStartDate"
                rules={[
                  {
                    required: documentSent === "Yes" ? true : false,
                    message: "Contract start date is required",
                  },
                ]}
                className="mr-6 w-full"
              >
                <DatePicker
                  style={{
                    width: "100%",
                    height: "52px",
                  }}
                  placeholder="Select Date"
                  className="mr-3 border border-black"
                  format={"DD-MM-YYYY"}
                  disabledDate={disabledPastDate}
                />
              </Form.Item>
            ) : documentSent === "No" ? (
              <Form.Item
                name="attFile"
                label="Upload Offer Document"
                rules={[
                  {
                    required: documentSent === "No" ? true : false,
                    message: "Please upload a file",
                  },
                ]}
              >
                <Dragger {...props}>
                  <div className="flex justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="29"
                      height="29"
                      viewBox="0 0 29 29"
                      fill="none"
                      className="mt-[-8px]"
                    >
                      <path
                        d="M25.375 18.125V22.9583C25.375 23.5993 25.1204 24.214 24.6672 24.6672C24.214 25.1204 23.5993 25.375 22.9583 25.375H6.04167C5.40073 25.375 4.78604 25.1204 4.33283 24.6672C3.87961 24.214 3.625 23.5993 3.625 22.9583V18.125"
                        stroke="#147CBC"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20.5423 9.66667L14.5007 3.625L8.45898 9.66667"
                        stroke="#147CBC"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.5 3.625V18.125"
                        stroke="#147CBC"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <p className="ant-upload-text !ml-2">Upload pdf</p>
                  </div>
                </Dragger>
              </Form.Item>
            ) : null}

            <div className="flex flex-col items-center mt-[100px] gap-y-2 mb-[66px]">
              <button type="submit" key="submit" className="w-[200px] save-btn">
                {jobApplicationLoading ? <Spin /> : "Save"}
              </button>
              <button
                key="back"
                onClick={handleCancel}
                className="w-[200px] cancel-btn border border-blueDark flex items-center justify-center"
              >
                Cancel
              </button>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default OfferModal;
