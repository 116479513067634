import React, { useContext, useEffect, useRef, useState } from 'react';
import Header from './Header';
import { PlusOutlined } from '@ant-design/icons';
import {
	Button,
	Checkbox,
	ColorPicker,
	Dropdown,
	Form,
	Input,
	Popconfirm,
	Select,
	Table,
	Tag,
} from 'antd';
import { stageData } from '../../Gender';
import './editable.css';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteRecruitmentProcess,
	fetchRecruitmentProcessMenus,
	fetchRecruitmentProcesss,
	saveRecruitmentProcessMenu,
} from '../../features/settings/settingSlice';
import toast from 'react-hot-toast';

function Recruitment() {
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const { recruitmentData, recruitmentProcess } = useSelector(
		(state) => state.settings,
	);
	const { user } = useSelector((state) => state.auth);

	const [dataSource, setDataSource] = useState(recruitmentData);
	const [dataList, setdataList] = useState(recruitmentProcess);

	const [addStage, setaddStage] = useState(false);

	function deleteProcess() {
		console.log('YES');
	}

	const columns = [
		{
			title: 'Stage',
			key: 'stage',
			render: (item) => (
				<>
					<div className='flex items-center w-full'>
						{/* <Checkbox onChange={onChange}></Checkbox> */}
						<span>{item?.stageName}</span>
					</div>
				</>
			),
		},
		{
			title: 'Color Code',
			key: 'color',
			render: (item) => (
				<>
					<div className='flex w-full items-center justify-between'>
						<ColorPicker
							open={false}
							className='w-[40px] h-[40px] ml-5'
							value={item?.stageColor}
						/>

						<button className='p-0 m-0 flex pr-10' type='button'>
							<Dropdown
								overlayStyle={{
									width: '250px',
								}}
								trigger={'click'}
								menu={{ items: settingItems }}
								placement='bottom'
							>
								<div className='flex flex-col gap-y-1'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='5'
										height='5'
										viewBox='0 0 5 5'
										fill='none'
									>
										<circle
											cx='2.5'
											cy='2.5'
											r='2.5'
											transform='rotate(-180 2.5 2.5)'
											fill='#333333'
										/>
									</svg>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='5'
										height='5'
										viewBox='0 0 5 5'
										fill='none'
									>
										<circle
											cx='2.5'
											cy='2.5'
											r='2.5'
											transform='rotate(-180 2.5 2.5)'
											fill='#333333'
										/>
									</svg>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='5'
										height='5'
										viewBox='0 0 5 5'
										fill='none'
									>
										<circle
											cx='2.5'
											cy='2.5'
											r='2.5'
											transform='rotate(-180 2.5 2.5)'
											fill='#333333'
										/>
									</svg>
								</div>
							</Dropdown>
						</button>
					</div>
				</>
			),
		},
	];

	const settingItems = [
		{
			key: '1',
			label: (
				<div
					className='font-dmSans text-black font-[400] text-[19px]'
					onClick={deleteProcess}
				>
					Delete
				</div>
			),
			icon: (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
				>
					<path
						d='M2.40039 5.39688H21.6004M8.40039 1.79688H15.6004M9.60039 17.3969V10.1969M14.4004 17.3969V10.1969M16.2004 22.1969H7.80039C6.47491 22.1969 5.40039 21.1224 5.40039 19.7969L4.85247 6.64683C4.82407 5.96508 5.36909 5.39688 6.05143 5.39688H17.9493C18.6317 5.39688 19.1767 5.96508 19.1483 6.64683L18.6004 19.7969C18.6004 21.1224 17.5259 22.1969 16.2004 22.1969Z'
						stroke='black'
						stroke-width='1.5'
						stroke-linecap='round'
						stroke-linejoin='round'
					/>
				</svg>
			),
		},
	];

	const globalProcess = [];

	dataSource?.filter(item => !dataList?.find(itm => itm?.rpmRptId === item?.rtpId))?.forEach((item) => {
		globalProcess.push({
			label: item?.rtpStageName,
			value: item?.rtpId,
		});
	});

	const tagRender = (props) => {
		const { label, value, closable, onClose } = props;
		const onPreventMouseDown = (event) => {
			event.preventDefault();
			event.stopPropagation();
		};
		return (
			<Tag
				color={value}
				onMouseDown={onPreventMouseDown}
				closable={closable}
				onClose={onClose}
				style={{
					marginRight: 3,
				}}
			>
				{label}
			</Tag>
		);
	};

	async function handleFetchGlobalRecruitmentProess() {
		await dispatch(fetchRecruitmentProcesss());
		await dispatch(fetchRecruitmentProcessMenus(user?.usrInstId));
	}

	async function onFinish(values) {
		await Promise.all(
			values?.dataArray.forEach((item) => {
				let dataObj = {
					rpmRptId: item,
					rpmInstId: user?.usrInstId,
					rpm_created_by: user?.usrId,
				};
				dispatch(saveRecruitmentProcessMenu(dataObj));
			}),
		);
		await dispatch(handleFetchGlobalRecruitmentProess());
		await setaddStage(false);
		await form.resetFields();
		await toast('Stage(s) added successfully');
	}

	useEffect(() => {
	}, [dataSource, dataList]);

	useEffect(() => {
		setDataSource(recruitmentData);
	}, [recruitmentData]);

	useEffect(() => {
		setdataList(recruitmentProcess);
	}, [recruitmentProcess]);

	useEffect(() => {
		handleFetchGlobalRecruitmentProess();
	}, []);

	return (
		<>
			<Header title='Recruitment process' />

			<div className='bg-white mr-3 mb-[10px] h-[100vh] p-[40px]'>
				<div class='text-neutral-900 text-[21px] font-medium font-dmSans leading-9 mb-4'>
					This is your current recruitment process. You may customize according
					to your preference.
				</div>

				<div>
					<Table
						dataSource={dataList}
						columns={columns}
						pagination={false}
						className='w-[700px] mt-5'
						locale={{
							emptyText: (
								<>
									<div className='w-full min-h-[200px] flex flex-col justify-center items-center'>
										<span className='text-[#808080] text-[21px] font-normal font-dmSans leading-[29.40px]'>
											No jobs added yet. Click add job button{' '}
										</span>

										<button
											onClick={() => setaddStage(true)}
											className='border-none flex items-center p-1 mt-5'
										>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='24'
												height='24'
												viewBox='0 0 24 24'
												fill='none'
											>
												<path
													d='M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20ZM16 11H13V8C13 7.73478 12.8946 7.48043 12.7071 7.29289C12.5196 7.10536 12.2652 7 12 7C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8V11H8C7.73479 11 7.48043 11.1054 7.2929 11.2929C7.10536 11.4804 7 11.7348 7 12C7 12.2652 7.10536 12.5196 7.2929 12.7071C7.48043 12.8946 7.73479 13 8 13H11V16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16V13H16C16.2652 13 16.5196 12.8946 16.7071 12.7071C16.8946 12.5196 17 12.2652 17 12C17 11.7348 16.8946 11.4804 16.7071 11.2929C16.5196 11.1054 16.2652 11 16 11Z'
													fill='#808080'
												/>
											</svg>
											<span className='ml-1'>Add stage</span>
										</button>
									</div>
								</>
							),
						}}
					/>

					{dataList?.length ? (
						<div className='mt-3 p-3'>
							<button
								onClick={() => setaddStage(true)}
								className='border-none flex items-center'
							>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
								>
									<path
										d='M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20ZM16 11H13V8C13 7.73478 12.8946 7.48043 12.7071 7.29289C12.5196 7.10536 12.2652 7 12 7C11.7348 7 11.4804 7.10536 11.2929 7.29289C11.1054 7.48043 11 7.73478 11 8V11H8C7.73479 11 7.48043 11.1054 7.2929 11.2929C7.10536 11.4804 7 11.7348 7 12C7 12.2652 7.10536 12.5196 7.2929 12.7071C7.48043 12.8946 7.73479 13 8 13H11V16C11 16.2652 11.1054 16.5196 11.2929 16.7071C11.4804 16.8946 11.7348 17 12 17C12.2652 17 12.5196 16.8946 12.7071 16.7071C12.8946 16.5196 13 16.2652 13 16V13H16C16.2652 13 16.5196 12.8946 16.7071 12.7071C16.8946 12.5196 17 12.2652 17 12C17 11.7348 16.8946 11.4804 16.7071 11.2929C16.5196 11.1054 16.2652 11 16 11Z'
										fill='#808080'
									/>
								</svg>
								<span className='ml-1'>Add stage</span>
							</button>
						</div>
					) : null}

					{addStage ? (
						<>
							<Form
								layout='vertical'
								ref={formRef}
								name='control-ref'
								onFinish={onFinish}
								className=' '
								style={{
									maxWidth: '100%',
									width: '700px',
								}}
								form={form}
							>
								<div className='grid grid-cols-3 gap-x-10'>
									<Form.Item
										className='col-span-2'
										name='dataArray'
										label='Select stage(s)'
										rules={[
											{
												required: true,
												message: 'Please select atleast one employee',
											},
										]}
									>
										<Select
											mode='multiple'
											tagRender={tagRender}
											maxTagCount={3}
											style={{
												width: '100%',
												minHeight: '44px',
												height: '44px',
												maxHeight: '100%',
											}}
											className='inbox-select'
											options={globalProcess}
										/>
									</Form.Item>
									{/* 
									<Form.Item
										name='emplArray'
										label='Color code'
										rules={[
											{
												required: true,
												message: 'Please select atleast one employee',
											},
										]}
									>
										<ColorPicker
											className='w-[40px] h-[40px] ml-5'
											defaultValue='#147cbc'
										/>
									</Form.Item> */}
								</div>

								<div className='flex justify-between'>
									<div className='flex justify-start items-center'>
										<button
											onClick={() => setaddStage(false)}
											className='w-[100px] p-1 justify-center items-center rounded-[36px] border-2 border-blueDark flex text-blueDark text-[16px] mr-4'
										>
											Cancel
										</button>

										<button
											key='submit'
											type='submit'
											className='w-[100px] p-1 bg-blueDark rounded-[36px] justify-center items-center text-white text-[16px]'
										>
											Save
										</button>
									</div>
								</div>
							</Form>
						</>
					) : null}
				</div>
			</div>
		</>
	);
}

export default Recruitment;
