import React from 'react'

function Test() {
  return (
    <div className='flex justify-center items-center flex-col'>
       <p className='text-blueDark text-[20px] font-bold mb-[0px] bg-red-500 '>
							Choose account
						</p>
						<p className='text-black2 text-[20px] bg-green-500'>
							Here are the accounts you can switch between
						</p></div>
  )
}

export default Test