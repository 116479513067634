import React, { useEffect, useState } from 'react';
import MaterialIcon from 'material-icons-react';
import { CloudUploadOutlined } from '@ant-design/icons';

import { Divider, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchAgencyDetails,
	saveAgencyInfo,
	saveFile,
} from '../../../features/application/applicationSlice';
import FileUploader from '../../../components/FileUploader';
import moment from 'moment';
import ScrollTo from '../../../ScrollTo';

const url = process.env.REACT_APP_API_BASE_URL;

const AgencyDetails = ({ current, handleLoading }) => {
	const dispatch = useDispatch();
	const { agency } = useSelector((state) => state.application);
	const { user } = useSelector((state) => state.auth);

	const [agencyObj, setagencyObj] = useState(agency);

	const [formData, setFormData] = useState({
		agcInstId: user?.usrInstId,
		...agencyObj,
	});

	const handleSuccess = (response) => {
		setFormData((prev) => ({
			...prev,
			[response.fileName]: response.jsonData,
		}));
	};

	const handleError = (response) => {
		// Handle error logic
		console.error('File upload failed:', response);
	};

	const onChange = (e) => {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	const submitData = async () => {
        await handleLoading(true)
		await dispatch(saveAgencyInfo(formData));
		await handleAgencyData();
        await handleLoading(false)
	};

	async function handleAgencyData() {
		await dispatch(fetchAgencyDetails(user?.usrInstId));
	}

	useEffect(() => {}, [current, agencyObj]);

	useEffect(() => {
		setagencyObj(agency);
	}, [agency]);

	useEffect(() => {
		handleAgencyData();
	}, []);

	return (
		<div className='flex flex-col w-full'>
            <ScrollTo />
			<div>
				<div className='flex flex-row w-full my-10'>
					<MaterialIcon color='#147CBC' icon='drag_indicator' />
					<div className='font-bold'>Company Registration</div>
				</div>
				<div className='grid grid-cols-4 w-full gap-10'>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Company Reg No.</div>
						<Input
							onBlur={submitData}
							className='rounded-[8px] mt-2  h-[44px] w-full border border-black'
							onChange={(e) => onChange(e)}
							name='agcRegNo'
							value={formData?.agcRegNo}
						/>
					</div>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Upload</div>
						{/* <Input onChange={(e) => onChange(e)}
                            name='agcRegFile' suffix={suffix} type='file' className='rounded-[8px] mt-2 h-[44px] w-full border border-black' /> */}
						<FileUploader
							fileName={formData?.agcRegFile?.split('_').pop()}
							name='agcRegFile'
							url={url}
							onSuccess={handleSuccess}
							onError={handleError}
						/>
					</div>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Date of Issue</div>
						<Input
							onBlur={submitData}
							onChange={(e) => onChange(e)}
							name='agcRegIssueDate'
							className='rounded-[8px] mt-2 h-[44px] w-full border border-black'
							type='date'
							value={
								formData?.agcRegIssueDate
									? moment(formData?.agcRegIssueDate).format('YYYY-MM-DD')
									: null
							}
						/>
					</div>
				</div>
				<Divider className='mt-10' />
			</div>
			<div>
				<div className='flex flex-row w-full mb-10'>
					<MaterialIcon color='#147CBC' icon='drag_indicator' />
					<div className='font-bold'>CR 12 Issued within the past 3 months</div>
				</div>
				<div className='grid grid-cols-4 w-full gap-10 mb-4'>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>CR 12 No.</div>
						<Input
							onBlur={submitData}
							onChange={(e) => onChange(e)}
							name='agcCr12No'
							value={formData?.agcCr12No}
							className='rounded-[8px] h-[44px] mt-2 w-full border border-black'
						/>
					</div>
					<div className='flex flex-col w-full '>
						<div className='font-[500]'>Upload</div>
						{/* <Input onChange={(e) => onChange(e)}
                            name='agcCr12File' suffix={suffix} type='file' className='rounded-[8px] h-[44px] mt-2 w-full border border-black' /> */}
						<FileUploader
							fileName={formData?.agcCr12File?.split('_').pop()}
							name='agcCr12File'
							url={url}
							onSuccess={handleSuccess}
							onError={handleError}
						/>
					</div>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Date of Issue</div>
						<Input
							onBlur={submitData}
							value={
								formData?.agcCr12IssueDate
									? moment(formData?.agcCr12IssueDate).format('YYYY-MM-DD')
									: null
							}
							onChange={(e) => onChange(e)}
							name='agcCr12IssueDate'
							className='rounded-[8px] h-[44px] mt-2 w-full border border-black'
							type='date'
						/>
					</div>
				</div>
				<Divider className='mt-10' />
			</div>
			<div>
				<div className='flex flex-row w-full my-10'>
					<MaterialIcon color='#147CBC' icon='drag_indicator' />
					<div className='font-bold'>
						Company Valid Tax Compliance Certificate
					</div>
				</div>
				<div className='grid grid-cols-4 w-full gap-10  mb-4'>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Compliance No.</div>
						<Input
							onBlur={submitData}
							value={formData?.agcTaxComplianceNo}
							onChange={(e) => onChange(e)}
							name='agcTaxComplianceNo'
							className='rounded-[8px] mt-2 h-[44px] w-full border border-black'
						/>
					</div>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Upload</div>
						<FileUploader
							fileName={formData?.agcTaxComplianceFile?.split('_').pop()}
							name='agcTaxComplianceFile'
							url={url}
							onSuccess={handleSuccess}
							onError={handleError}
						/>
					</div>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Date of Issue.</div>
						<Input
							onBlur={submitData}
							value={
								formData?.agcTaxIssueDate
									? moment(formData?.agcTaxIssueDate).format('YYYY-MM-DD')
									: null
							}
							onChange={(e) => onChange(e)}
							name='agcTaxIssueDate'
							className='rounded-[8px] mt-2 h-[44px] w-full border border-black'
							type='date'
						/>
					</div>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Date of Expiry</div>
						<Input
							onBlur={submitData}
							value={
								formData?.agcTaxExpireDate
									? moment(formData?.agcTaxExpireDate).format('YYYY-MM-DD')
									: null
							}
							onChange={(e) => onChange(e)}
							name='agcTaxExpireDate'
							className='rounded-[8px] mt-2 h-[44px] w-full border border-black'
							type='date'
						/>
					</div>
				</div>
				<Divider className='mt-10' />
			</div>
			<div>
				<div className='flex flex-row w-full my-10'>
					<MaterialIcon color='#147CBC' icon='drag_indicator' />
					<div className='font-bold'>
						Valid Business Registration (County Government)
					</div>
				</div>
				<div className='grid grid-cols-4 w-full gap-10  mb-4'>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Business Reg No.</div>
						<Input
							onBlur={submitData}
							value={formData?.agcCountyComplianceNo}
							onChange={(e) => onChange(e)}
							name='agcCountyComplianceNo'
							className='rounded-[8px] mt-2 h-[44px] w-full border border-black'
						/>
					</div>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Upload</div>
						{/* <Input name='agcCountyFile' type="file" className='rounded-[8px] mt-2 h-[44px] w-full border border-black' suffix={suffix} /> */}
						<FileUploader
							fileName={formData?.agcCountyFile?.split('_').pop()}
							name='agcCountyFile'
							url={url}
							onSuccess={handleSuccess}
							onError={handleError}
						/>
					</div>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Date of Issue.</div>
						<Input
							onBlur={submitData}
							value={
								formData?.agcCountyIssueDate
									? moment(formData?.agcCountyIssueDate).format('YYYY-MM-DD')
									: null
							}
							onChange={(e) => onChange(e)}
							name='agcCountyIssueDate'
							className='rounded-[8px] mt-2 h-[44px] w-full border border-black'
							type='date'
						/>
					</div>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Date of Expiry</div>
						<Input
							onBlur={submitData}
							value={
								formData?.agcCountyExpireDate
									? moment(formData?.agcCountyExpireDate).format('YYYY-MM-DD')
									: null
							}
							onChange={(e) => onChange(e)}
							name='agcCountyExpireDate'
							className='rounded-[8px] mt-2 h-[44px] w-full border border-black'
							type='date'
						/>
					</div>
				</div>
				<Divider className='mt-10' />
			</div>
			<div>
				<div className='flex flex-row w-full my-10'>
					<MaterialIcon color='#147CBC' icon='drag_indicator' />
					<div className='font-bold'>Valid Lease Agreement</div>
				</div>
				<div className='grid grid-cols-4 w-full gap-10  mb-4'>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Upload</div>
						<FileUploader
							fileName={formData?.agcLeaseFile?.split('_').pop()}
							name='agcLeaseFile'
							url={url}
							onSuccess={handleSuccess}
							onError={handleError}
						/>
					</div>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Lease Start Date</div>
						<Input
							onBlur={submitData}
							value={
								formData?.agcLeaseIssueDate
									? moment(formData?.agcLeaseIssueDate).format('YYYY-MM-DD')
									: null
							}
							onChange={(e) => onChange(e)}
							name='agcLeaseIssueDate'
							className='rounded-[8px] mt-2 h-[44px] w-full border border-black'
							type='date'
						/>
					</div>
					<div className='flex flex-col w-full'>
						<div className='font-[500]'>Lease end Date</div>
						<Input
							onBlur={submitData}
							value={
								formData?.agcLeaseEndDate
									? moment(formData?.agcLeaseEndDate).format('YYYY-MM-DD')
									: null
							}
							onChange={(e) => onChange(e)}
							name='agcLeaseEndDate'
							className='rounded-[8px]  mt-2 h-[44px] w-full border border-black'
							type='date'
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AgencyDetails;
