import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Modal, Button, Switch, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

const ConfirmationModal = ({
  isModalOpen,
  setIsModalOpen,
  handleSubmit,
  loading,
  content,
  icon,
  subContent,
  btnTitle,
}) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer activity activity-header activity-close"
        title="Confirm Submission"
        open={isModalOpen}
        onCancel={handleCancel}
        width={898}
      >
        {icon && (
          <div className="mt-[51px] flex items-center justify-center">
            <img src={icon} alt="icon" />
          </div>
        )}
        <div className="mt-[18px] text-center justify-center text-black1 font-dmSans text-[20px] font-medium  leading-[24px]">
          {content}
        </div>
        {subContent && (
          <div className="mt-[5px] text-center justify-center text-black1 font-dmSans text-[18px] font-[400]  leading-[24px]">
            {subContent}
          </div>
        )}

        <div className="flex flex-col items-center mt-[100px] gap-y-2 mb-[66px]">
          <button
            className="w-[225px] save-btn"
            type="submit"
            onClick={handleSubmit}
            disabled={loading}
          >
            {btnTitle ? (
              loading ? (
                <Spin />
              ) : (
                btnTitle
              )
            ) : loading ? (
              <Spin />
            ) : (
              "Submit"
            )}
          </button>

          <button
            type="button"
            className="w-[225px] cancel-btn border border-blueDark flex items-center justify-center"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
export default ConfirmationModal;
