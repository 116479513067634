import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import { handleStepCurrent } from "../../../../../features/profile/profileSlice";
import { fetchEducations, saveEducation } from "../../../../../features/edu/eduSlice";
import { Spin, Switch } from "antd";
import moment from "moment";

export default function EducationTab() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { educations, eduLoading } = useSelector((state) => state.edu);

  const [educationList, seteducationList] = useState(educations);

  const { applicantEditId } = useSelector((state) => state.applicant);
  
  const usrObj = {
    usrId: applicantEditId,
  };

  async function handleProfileBuilder() {
    await dispatch(handleStepCurrent(1));
    await navigate("/profile-builder");
  }

  async function handleFetchEducations() {
    const resp = await dispatch(fetchEducations(usrObj));

    if (resp?.payload?.success) {
      await seteducationList(resp?.payload?.data?.result);
    }
  }

  const onChange = async (checked, item) => {
    let objCopy = { ...item, eduPrivacy: !item?.eduPrivacy };
    const res = await dispatch(saveEducation(objCopy));
    if (res?.payload?.success) {
      handleFetchEducations();
    }
  };

  useEffect(() => {}, [educationList]);

  useEffect(() => {
    seteducationList(educations);
  }, [educations]);

  useEffect(() => {
    handleFetchEducations();
  }, []);

  return (
    <>
  
      {eduLoading && <Spin size="large" />}

      {!eduLoading && educationList?.length ? (
        educationList.map((item) => {
          return (
            <div
              key={item?.eduId}
              // style={{
              // 	display: item?.eduPrivacy && !editProfile && user?.usrProfileVisibility === 'SEMI_PUBLIC' ? 'none' : 'block',
              // }}
            >
              <div className="bg-white p-3 flex items-center justify-between w-full">
                <div className="w-full flex flex-col">
                  <h3 className="text-blueDark text-[21px] font-bold">
                    {item?.eduInstName === "N/A"
                      ? "Institution (N/A)"
                      : item?.eduInstName}{" "}
                    -{" "}
                    {item?.eduCourse === "N/A"
                      ? "Course (N.A)"
                      : item?.eduCourse}
                  </h3>
                  <p className="text-black333 font-medium text-[18px] my-1">
                    {item?.eduInstType} - {item?.eduAward}
                  </p>
                  <span className="text-black333 font-normal text-[19px]">
                    {item?.eduStartDate
                      ? moment(item?.eduStartDate).format("MMMM YYYY")
                      : "Start Date"}{" "}
                    -{" "}
                    {item?.endEndDate
                      ? moment(item?.endEndDate).format("MMMM YYYY")
                      : "End Date"}
                  </span>

				  {item?.eduDesc && item?.eduDesc !== "N/A" ? (
                    <>
                      <span className="text-black2 font-normal text-[18px] text-justify mt-5">
                        {item?.eduDesc}
                      </span>
                    </>
                  ) : null}
                </div>
              
              </div>

              <div
                style={{
                  background: "#D8D8D88A",
                  height: "1px",
                  width: "100%",
                }}
                className="my-5"
              ></div>
            </div>
          );
        })
      ) : (
        <div className="flex w-full h-full mb-14">
          <h2 className="text-center text-black3 text-[18px]">
            Your academic qualifications will appear here
          </h2>
        </div>
      )}
    </>
  );
}
