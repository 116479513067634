import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	employeesData: [],
	singleEmployee: {},
	employeeLoading: false,

	editBasic: false,
	viewBAsic: false,
	editJob: false,
	viewJob: false,
	editContract: false,
	viewContract: false,

	employeeEditId: '',
	employeeEditObj: {},
	refetch: false,
	refetchKey: '',
	employeeReturnLoading: false,

	employeeReturnsPage: false,

	employeeCount:0,

	redundantEmployees:[],
	laidOffEmployees:[],

	employeeFolderFilterLength:0,
};

export const saveEmployee = createAsyncThunk(
	'employee/saveEmployee',
	async (data) => {
		const res = await axiosInstance.post(`${url}/nea/saveEmployee.action`, data);
		return res.data;
	},
);

export const saveEmployeeNew = createAsyncThunk(
	'employee/saveEmployeeNew',
	async (data) => {
		const res = await axiosInstance.post(`${url}/nea/saveEmployee.action`, data);
		return res.data;
	},
);

export const fetchEmployees = createAsyncThunk(
	'employee/fetchEmployees',
	async (data) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchEmployees.action?emplInstId=${data?.emplInstId}&emplFldId=${data?.emplFldId ?? ""}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}`,
		);
		return res.data?.data;
	},
);

export const fetchRedundantEmployees = createAsyncThunk(
	'employee/fetchRedundantEmployees',
	async (data) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchEmployees.action?emplInstId=${data?.emplInstId}&emplFldId=${data?.emplFldId ?? ""}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}&emplStatus=${data?.emplStatus ?? ''}`,
		);
		return res.data?.data;
	},
);

export const fetchLaidOffEmployees = createAsyncThunk(
	'employee/fetchLaidOffEmployees',
	async (data) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchEmployees.action?emplInstId=${data?.emplInstId}&emplFldId=${data?.emplFldId ?? ""}&start=${data?.start ?? ''}&limit=${data?.limit ?? ''}&emplStatus=${data?.emplStatus ?? ''}`,
		);
		return res.data?.data;
	},
);

export const saveEmploymentReturn = createAsyncThunk(
	'employee/saveEmploymentReturn',
	async (data) => {
		const res = await axiosInstance.post(
			`${url}/nea/saveEmploymentReturn.action`,
			data,
		);
		return res.data;
	},
);

export const myEmployeesSlice = createSlice({
	name: 'employee',
	initialState,
	reducers: {
		handleSidenavFullWidth: (state) => {
			state.fullWidth = !state.fullWidth;
		},

		setEditBAsic: (state, action) => {
			state.editBasic = action.payload;
		},
		setViewBAsic: (state, action) => {
			state.viewBAsic = action.payload;
		},
		setEditJob: (state, action) => {
			state.editJob = action.payload;
		},
		setViewJob: (state, action) => {
			state.viewJob = action.payload;
		},
		setEditContract: (state, action) => {
			state.editContract = action.payload;
		},
		setViewContract: (state, action) => {
			state.viewContract = action.payload;
		},
		setEmployeeEditId: (state, action) => {
			state.employeeEditId = action.payload;
		},

		setEmployeeEditObj: (state, action) => {
			state.employeeEditObj = action.payload;
		},
		setRefetch: (state) => {
			state.refetch = !state.refetch;
		},
		setRefetchKey: (state, action) => {
			state.refetchKey = action.payload;
		},
		setEmployeeReturnsPage: (state, action) => {
			state.employeeReturnsPage = action.payload;
		},
		setEmployeeFolderFilterLength: (state, action) => {
			state.employeeFolderFilterLength = action.payload;
		},
		
		

		cleanMyEmployees: (state) => {
			state.editBasic = initialState.editBasic;
			state.viewBAsic = initialState.viewBAsic;
			state.editJob = initialState.editJob;
			state.viewJob = initialState.viewJob;
			state.editContract = initialState.editContract;
			state.viewContract = initialState.viewContract;
			state.singleEmployee = initialState.singleEmployee;
			state.employeeEditId = initialState.employeeEditId;
			state.employeeEditObj = {};
			state.refetchKey = initialState.refetchKey;
			state.employeeReturnsPage = initialState.employeeReturnsPage;
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(saveEmployeeNew.pending, (state) => {
				state.employeeLoading = true;
			})
			.addCase(saveEmployeeNew.fulfilled, (state) => {
				state.employeeLoading = false;
			})
			.addCase(saveEmployeeNew.rejected, (state) => {
				state.employeeLoading = false;
			})

			.addCase(saveEmployee.pending, (state) => {
				state.employeeLoading = true;
			})
			.addCase(saveEmployee.fulfilled, (state, action) => {
				state.employeeLoading = false; 
			})
			.addCase(saveEmployee.rejected, (state) => {
				state.employeeLoading = false; 
			})

			.addCase(fetchEmployees.pending, (state) => {
				state.employeeLoading = true;
			})
			.addCase(fetchEmployees.fulfilled, (state, action) => {
				state.employeeLoading = false;
				state.employeesData = action.payload?.result;
				state.employeeCount=  action.payload?.count;
			})
			.addCase(fetchEmployees.rejected, (state) => {
				state.employeeLoading = false;
				state.employeesData=[];
				state.employeeCount =  0;
			})

			.addCase(saveEmploymentReturn.pending, (state) => {
				state.employeeReturnLoading = true;
			})
			.addCase(saveEmploymentReturn.fulfilled, (state, action) => {
				state.employeeReturnLoading = false;
			})
			.addCase(saveEmploymentReturn.rejected, (state) => {
				state.employeeReturnLoading = false;
				state.singleEmployee = {};
			})

			.addCase(fetchRedundantEmployees.pending, (state) => {
				state.employeeLoading = true;
			})
			.addCase(fetchRedundantEmployees.fulfilled, (state, action) => {
				state.employeeLoading = false;
				state.redundantEmployees = action.payload?.result;
				state.employeeCount=  action.payload?.count;
			})
			.addCase(fetchRedundantEmployees.rejected, (state) => {
				state.employeeLoading = false;
				state.redundantEmployees=[];
				state.employeeCount =  0;
			})
			

			.addCase(fetchLaidOffEmployees.pending, (state) => {
				state.employeeLoading = true;
			})
			.addCase(fetchLaidOffEmployees.fulfilled, (state, action) => {
				state.employeeLoading = false;
				state.laidOffEmployees = action.payload?.result;
				state.employeeCount=  action.payload?.count;
			})
			.addCase(fetchLaidOffEmployees.rejected, (state) => {
				state.employeeLoading = false;
				state.laidOffEmployees=[];
				state.employeeCount =  0;
			})

			
	},
});

export default myEmployeesSlice.reducer;
export const {
	handleSidenavFullWidth,
	setEditBAsic,
	setViewBAsic,
	setEditJob,
	setViewJob,
	setEditContract,
	setViewContract,
	setEmployeeEditId,
	setEmployeeEditObj,
	cleanMyEmployees,
	setRefetch,
	setRefetchKey,
	setEmployeeReturnsPage,
	setEmployeeFolderFilterLength,
} = myEmployeesSlice.actions;
