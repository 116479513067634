import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { handleStepCurrent } from '../../../../../features/profile/profileSlice';
 
import { Spin, Switch } from 'antd';
import moment from 'moment/moment';
import { fetchAttachments, saveAttachment } from '../../../../../features/doc/docSlice';
import { formatImgPath } from '../../../../../utils';

const url = process.env.REACT_APP_API_BASE_URL;

export default function CertificateTab() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { docs, docLoading } = useSelector((state) => state.doc);

	const [educationList, seteducationList] = useState(docs);

	const { applicantEditId } = useSelector((state) => state.applicant);
  
	const usrObj = {
	  usrId: applicantEditId,
	};

	async function handleProfileBuilder() {
		await dispatch(handleStepCurrent(4));
		await navigate('/profile-builder');
	}

	async function handleFetchExp() {
		const resp = await dispatch(fetchAttachments(usrObj));

		if (resp?.payload?.success) {
			await seteducationList(resp?.payload?.data?.result);
		}
	}

	const onChange = async (checked, item) => {
		let objCopy = { ...item, attPrivacy: !item?.attPrivacy };
		const res = await dispatch(saveAttachment(objCopy));
		if (res?.payload?.success) {
			handleFetchExp();
		}
	};

	useEffect(() => {}, [educationList]);

	useEffect(() => {
		seteducationList(docs);
	}, [docs]);

	useEffect(() => {
		handleFetchExp();
	}, []);

	return (
		<>
			 
			{docLoading && <Spin size='large' />}

			{!docLoading && educationList?.length ? (
				educationList.map((item) => {
					return (
						<div
							key={item?.attId}
						>
							<div className='bg-white p-3 mb-4 flex items-center justify-between w-full'>
								<div
									 
									className='w-full flex flex-col'
								>
									<h3 className='text-blueDark text-[21px] font-bold'>
										{item?.attCertName}
									</h3>
									<span className='text-black333 font-normal text-[19px]'>
										{moment(item?.attCreatedDate).format('MMMM YYYY')}
									</span>

									<a
										target='_blank'
										rel='noreferrer'
										className='prof-cert-text text-justify mt-7'
										href={formatImgPath(item?.attFile)}
									>
										{item?.attFileName}
									</a>
								</div>
							</div>

							<div
								style={{
									background: '#D8D8D88A',
									height: '1px',
									width: '100%',
								}}
								className='my-5'
							></div>
						</div>
					);
				})
			) : (
				<div className='flex w-full h-full mb-14'>
					<h2 className='text-center text-black3 text-[18px]'>All your statutory documents will appear here</h2>
				</div>
			)}
		</>
	);
}
