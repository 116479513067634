import {  Form, Input, Select, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  capitalizeFirstLetter, 
} from "../../../../utils";
import {
  fetchJobs,
  fetchSingleJob,
  fetchSkillCategorys,
  saveJob,
  setEditJobDetails,
  setJobEditId,
  setViewJobDetails,
} from "../../../../features/jobs/jobSlice";
import citiesData from "../../../../CountriesCities.json";
import { fetchQualifications } from "../../../../features/jobFilters/jobFilterSlice";
import { fetchStudyAreas } from "../../../../features/dropDowns/dropDownSlice";

function JobDetails() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { qualifications } = useSelector((state) => state.jobFilter);

  const {
    singleCreateJob,
    jobEditId,
    jobData,
    jobLoading,
    skillsData,
    editJobDetails,
    singleJob,
  } = useSelector((state) => state.jobs);
  const [data, setdata] = useState({});

  function closeModal() {
    toast.dismiss();
  }

  const [formData, setFormData] = useState(singleJob);

  const {studyAreas} =  useSelector((state) => state.dropDown);


  useEffect(()=>{
    setFormData(singleJob)
  },[singleJob])

  const [occupationLabel, setOccupationLabel] = useState(
    singleJob?.jobOccupation
  );

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  const onChangeNumber = async (e) => {
    if (e.target.value < 0 ) {
      return;
    }
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
  
  function handleSelectOccupation(value) {
    setFormData((prevData) => ({
      ...prevData,
      jobOccupationId: value,
    }));
  }
  function handleSelectStudyArea(value) {
    setFormData((prevData) => ({
      ...prevData,
      jobStudyArea: value,
    }));
  }
  function handleSelectQualification(value) {
    setFormData((prevData) => ({
      ...prevData,
      jobQualification: value,
    }));
  }

 
  const country = citiesData?.find((city) => city.name === "Kenya");
  const [city, setcity] = useState(country?.cities?.map((city) => city.name));
  const [defaultValue, setdefaultValue] = useState(
    formData?.jobCity ? formData?.jobCity : ""
  );

  function selectedCountry(value) {
    const country = citiesData?.find((city) => city.name === value);

    if (country && country?.cities && country?.cities?.length > 0) {
      const defaultCity = "";
      const cityNames = country?.cities?.map((city) => city.name);
      setcity(cityNames);
      setdefaultValue(defaultCity);
      setFormData((prevData) => ({
        ...prevData,
        jobCountry: value,
      }));
    }
  }

  function selectedCity(value) {
    setdefaultValue(value);
    setFormData((prevData) => ({
      ...prevData,
      jobCity: value,
    }));
  }

  const filterOptionCountry = (input, option) =>
    (option?.children?.toLowerCase() ?? "").includes(input.toLowerCase());

  const onSearchCountry = (value) => {};
  const handleCancel = async () => {
    await dispatch(setEditJobDetails(false));
    await dispatch(setViewJobDetails(true)); 
  };

  const onFinish = async () => {
    if (
      !formData?.jobOccupationId ||
      !formData?.jobStudyArea ||
      !formData?.jobQualification ||
      !formData?.jobCountry ||
      !formData?.jobCity
    ) {
      return toast("Please fill all the job details");
    }
    const res = await dispatch(
      saveJob({
        jobId: formData?.jobId,
        jobPosition: capitalizeFirstLetter(formData?.jobPosition),
        jobOccupation: occupationLabel,
        jobOccupationId: formData?.jobOccupationId,
        jobVacancyCount: formData?.jobVacancyCount,
        jobStudyArea: formData?.jobStudyArea,
        jobQualification: formData?.jobQualification,
        jobCountry: formData?.jobCountry,
        jobCity: formData?.jobCity,
        jobInstId: user?.usrInstId,
      })
    );
    if (res?.payload?.success) {
      toast.success("Job details saved successfull");
      form.resetFields();
      await dispatch(fetchSingleJob({
        jobId:jobEditId? jobEditId: singleCreateJob?.jobId
      }))  
      await dispatch(setViewJobDetails(true));
      await dispatch(setEditJobDetails(false));
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const options = [
    {
      label: "Medicine",
      value: "Medicine",
    },
    {
      label: "Computer",
      value: "Computer",
    },
    {
      label: "Agriculture",
      value: "Agriculture",
    },
  ];

   

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSearch = (value) => {};

  async function fetchOccupation() {
    await dispatch(fetchSkillCategorys());
  }

  async function fetchQulificationsData() {
    await dispatch(fetchQualifications());
  }
  async function fetchStudyAreasData() {
    await dispatch(fetchStudyAreas());
  }

  
  useEffect(() => {
    fetchQulificationsData();
    fetchStudyAreasData();
  }, []);

  return (
    <div className="mt-[28px]">
      <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-[30px] mb-[28px]">
        Basic Information
      </div>
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
        }}
        form={form}
      >
        <div className="grid grid-cols-2 lg:gap-4">
          <Form.Item
            label="Position Name"
            rules={[
              {
                required: true,
                message: "Please add position name",
              },
            ]}
          >
            <Input
              required
              name="jobPosition"
              value={formData?.jobPosition}
              onChange={onChange}
              className="input"
            />
          </Form.Item>

          <Form.Item
            label="Occupation"
            rules={[
              {
                required: true,
                message: "Please select occupation",
              },
            ]}
          >
            <Select
              required
              name="jobOccupationId"
              value={formData?.jobOccupationId}
              style={{
                width: "100%",
                height: "44px",
              }}
              showSearch
              optionFilterProp="children"
              onSearch={onSearch}
              filterOption={filterOption}
              options={
                skillsData?.length > 0 &&
                skillsData?.map((item) => ({
                  value: item?.sklcatId,
                  label: item?.sklcatName,
                }))
              }
              onChange={(value, label) => {
                setOccupationLabel(label?.label);
                handleSelectOccupation(value);
              }}
              onDropdownVisibleChange={() => {
                fetchOccupation();
              }}
            />
          </Form.Item>

          <Form.Item
            label="No. of vacancies"
            rules={[
              {
                required: false,
                message: "Please add No. of vacancies",
              },
            ]}
          >
            <Input
              // required
              name="jobVacancyCount"
              value={formData?.jobVacancyCount} 
              onChange={onChangeNumber} 
              min={0}
              type="text"
              className="input"
            />
          </Form.Item>

          <Form.Item
            label="Area of Study"
            rules={[
              {
                required: true,
                message: "Please select area of study",
              },
            ]}
          >
            <Select
              required
              name="jobStudyArea"
              value={formData?.jobStudyArea}
              style={{
                width: "100%",
                height: "44px",
              }}
              showSearch
              optionFilterProp="children"
              onSearch={onSearch}
              filterOption={filterOption}
              options={
                studyAreas?.length > 0 &&
                studyAreas?.map((item) => ({
                  value: item?.stdyName,
                  label: item?.stdyName,
                }))
              }
              onChange={(value) => {
                handleSelectStudyArea(value);
              }}
              onDropdownVisibleChange={() => {
                fetchStudyAreasData()
              }}
            />
          </Form.Item>

          

          <Form.Item
            label="Qualification required"
            rules={[
              {
                required: true,
                message: "Please select qualification required",
              },
            ]}
          >
            <Select
              required
              name="jobQualification"
              value={formData?.jobQualification}
              style={{
                width: "100%",
                height: "44px",
              }}
              showSearch
              optionFilterProp="children"
              onSearch={onSearch}
              filterOption={filterOption}
              options={
                qualifications?.length > 0 &&
                qualifications?.map((item) => ({
                  value: item?.qualName,
                  label: item?.qualName,
                }))
              }
              onChange={(value) => {
                handleSelectQualification(value);
              }}
              onDropdownVisibleChange={() => {
                fetchQulificationsData();
              }}
            />
          </Form.Item>
        </div>
        <div className="grid grid-cols-1 mt-[30px] mb-[2px]">
          <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-[30px] mb-[28px]">
            Location
          </div>
        </div>
        <div className="grid grid-cols-2 lg:gap-4">
          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select a country",
              },
            ]}
          >
            <Select
              required
              name="jobCountry"
              value={formData?.jobCountry}
              showSearch
              style={{
                height: "52px",
              }}
              className="reg-input"
              placeholder="Country"
              onChange={selectedCountry}
              size="large"
              optionFilterProp="children"
              onSearch={onSearchCountry}
              filterOption={filterOptionCountry}
            >
              <Select.Option key="Kenya" value="Kenya">
                Kenya
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: "Please select a city",
              },
            ]}
          >
            <Select
              required
              name="jobCity"
              showSearch
              style={{
                height: "52px",
              }}
              onChange={selectedCity}
              value={defaultValue}
              disabled={city?.length ? false : true}
              placeholder={"City"}
              size="large"
              className="reg-input"
              optionFilterProp="children"
              onSearch={onSearchCountry}
              filterOption={filterOptionCountry}
            >
              {city?.map((city) => {
                return (
                  <Select.Option required key={city} value={city}>
                    {city}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>

        <div className="flex items-center justify-between mt-[35px]">
          <div className="flex items-start"></div>
          <div className="flex gap-5 items-center">
            {editJobDetails && (
              <button
                className="text-blueDark text-[1rem] font-medium font-dmSans leading-normal border-none
              w-[168px] h-12 px-[18px] py-3   justify-center items-center gap-2 inline-flex"
                onClick={handleCancel}
              >
                Cancel
              </button>
            )}
            <button
              className="text-white text-[1rem] font-medium font-dmSans leading-normal bg-blueDark
            w-[168px] h-[46px] px-[18px] py-3 rounded-[46px] border justify-center items-center gap-2 inline-flex"
              type="submit"
              disabled={jobLoading}
            >
              {jobLoading ? <Spin /> : "Save"}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default JobDetails;
