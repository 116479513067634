import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import axios from 'axios';
import axiosInstance from '../../instance';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	recruitmentData: [],
	recruitmentProcess: [],
	recruitmentLoading: false,
};

export const saveRecruitmentProcessMenu = createAsyncThunk(
	'settingSlice/saveRecruitmentProcessMenu',
	async (data) => {
		const res = await axiosInstance.post(
			`${url}/emp/saveRecruitmentProcessMenu.action`,
			data,
		);
		return res.data;
	},
);

export const fetchRecruitmentProcessMenus = createAsyncThunk(
	'settingSlice/fetchRecruitmentProcessMenus',
	async (data) => {
		const res = await axiosInstance.get(
			`${url}/emp/fetchRecruitmentProcessMenus.action?rpmJobId=${data?.rpmJobId}`,
		);
		return res?.data?.data?.result;
	},
);

export const deleteRecruitmentProcessMenus = createAsyncThunk(
	'settingSlice/deleteRecruitmentProcessMenus',
	async (data) => {
		const res = await axiosInstance.get(
			`${url}/emp/deleteRecruitmentProcessMenu.action?rpmId=${data?.rpmId}`,
		);
		return res?.data;
	},
);

export const fetchRecruitmentProcesss = createAsyncThunk(
	'settingSlice/fetchApplicantsDetails',
	async (data) => {
		const res = await axiosInstance.get(`${url}/emp/fetchRecruitmentProcesss.action?rtpLicenseType=${data?.rtpLicenseType}`);
		return res?.data?.data?.result;
	},
);

export const deleteRecruitmentProcess = createAsyncThunk(
	'settingSlice/deleteRecruitmentProcess',
	async (data) => {
		const res = await axiosInstance.get(
			`${url}/emp/deleteRecruitmentProcess.action?rtpId=${data?.rtpId}`,
		);
		return res?.data;
	},
);

export const settingSlice = createSlice({
	name: 'setting',
	initialState,
	reducers: {
		handleSidenavFullWidth: (state) => {
			state.fullWidth = !state.fullWidth;
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(saveRecruitmentProcessMenu.pending, (state) => {
				state.recruitmentLoading = true;
			})
			.addCase(saveRecruitmentProcessMenu.fulfilled, (state, action) => {
				state.recruitmentLoading = false;
			})
			.addCase(saveRecruitmentProcessMenu.rejected, (state) => {
				state.recruitmentLoading = false;
			})

			.addCase(fetchRecruitmentProcesss.pending, (state) => {
				state.recruitmentLoading = true;
			})
			.addCase(fetchRecruitmentProcesss.fulfilled, (state, action) => {
				state.recruitmentLoading = false;
				state.recruitmentData = action.payload;
			})
			.addCase(fetchRecruitmentProcesss.rejected, (state) => {
				state.recruitmentLoading = false;
				state.recruitmentData = [];
			})

			.addCase(fetchRecruitmentProcessMenus.pending, (state) => {
				state.recruitmentLoading = true;
			})
			.addCase(fetchRecruitmentProcessMenus.fulfilled, (state, action) => {
				state.recruitmentLoading = false;
				state.recruitmentProcess = action.payload;
			})
			.addCase(fetchRecruitmentProcessMenus.rejected, (state) => {
				state.recruitmentLoading = false;
				state.recruitmentProcess = [];
			})

			.addCase(deleteRecruitmentProcessMenus.pending, (state) => {
				state.recruitmentLoading = true;
			})
			.addCase(deleteRecruitmentProcessMenus.fulfilled, (state, action) => {
				state.recruitmentLoading = false;
			})
			.addCase(deleteRecruitmentProcessMenus.rejected, (state) => {
				state.recruitmentLoading = false;
			})


			.addCase(deleteRecruitmentProcess.pending, (state) => {
				state.recruitmentLoading = true;
			})
			.addCase(deleteRecruitmentProcess.fulfilled, (state, action) => {
				state.recruitmentLoading = false;
			})
			.addCase(deleteRecruitmentProcess.rejected, (state) => {
				state.recruitmentLoading = false;
			})

			 
			
	},
});

export default settingSlice.reducer;
export const {
	handleSidenavFullWidth,
	setRefetchKey,
	setUserEditId,
	setClientAdminView,
} = settingSlice.actions;
