import { Input } from 'antd';
import toast from 'react-hot-toast';
import { PlusOutlined, CloudUploadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
	fetchManager,
	saveDirectorAndManager,
	saveFile,
} from '../../../features/application/applicationSlice';

import { useDispatch, useSelector } from 'react-redux';
import { formatPath, showToastError, showToastSuccess } from '../../../utils';
import FileUploader from '../../../components/FileUploader';
import ScrollTo from '../../../ScrollTo';

const url = process.env.REACT_APP_API_BASE_URL;

const ManagerDetails = ({ handleLoading }) => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { manager } = useSelector((state) => state.application);

	const [managerObj, setmanagerObj] = useState(manager);

	const [formData, setFormData] = useState({
		secInstId: user?.usrInstId,
		secType: 'MANAGER',
		...managerObj,
	});

	const handleSuccess = (response) => {
		setFormData((prev) => ({
			...prev,
			[response.fileName]: response.jsonData,
		}));
	};

	const handleError = (response) => {
		// Handle error logic
		console.error('File upload failed:', response);
	};

	const onChange = (e) => {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	const submitData = async () => {
		await handleLoading(true);
		await dispatch(saveDirectorAndManager(formData));
		await handleManagerData();
		await handleLoading(false);
	};

	async function handleManagerData() {
		await dispatch(fetchManager(user?.usrInstId));
	}

	const suffix = (
		<CloudUploadOutlined
			style={{
				fontSize: 16,
				color: 'var(--blueDark)',
			}}
		/>
	);

	useEffect(() => {}, [managerObj]);

	useEffect(() => {
		submitData();
	}, [formData]);

	useEffect(() => {
		setmanagerObj(manager);
	}, [manager]);

	useEffect(() => {
		handleManagerData();
	}, []);

	return (
		<div className='flex flex-col w-[100%] mt-14'>
			<ScrollTo />
			<h3 className='appl-head'>Manager</h3>
			<div className='flex flex-row justify-between mt-2'>
				<div>Please fill in the details below</div>
			</div>
			<div className='grid grid-cols-3 gap-10 my-7'>
				<div className='flex flex-col w-[100%]'>
					<div>Name of Manager</div>
					<Input
						onChange={(e) => onChange(e)}
						value={formData?.secFirstName}
						name='secFirstName'
						className='rounded-[8px] mt-1 h-[44px]  w-full border border-black'
					/>
				</div>
				<div className='flex flex-col w-[100%]'>
					<div>Email</div>
					<Input
						onChange={(e) => onChange(e)}
						value={formData?.secEmail}
						name='secEmail'
						className='input'
					/>
				</div>
				<div className='flex flex-col w-full'>
					<div>Phone No.</div>
					<Input
						onChange={(e) => onChange(e)}
						value={formData?.secMobileNumber}
						name='secMobileNumber'
						className='input'
					/>
				</div>
			</div>
			<div className='grid grid-cols-3 gap-10 mb-7'>
				<div className='flex flex-col w-full'>
					<div>ID No.</div>
					<Input
						onChange={(e) => onChange(e)}
						value={formData?.secNeaId}
						name='secNeaId'
						className='rounded-[8px] mt-2 h-[44px]  w-full border border-black'
					/>
				</div>
				<div className='flex flex-col w-full'>
					<div>Upload ID</div>
					<FileUploader
						fileName={formData?.secIdFile?.split('_').pop()}
						name='secIdFile'
						url={url}
						onSuccess={handleSuccess}
						onError={handleError}
					/>
				</div>
			</div>
			<div className='grid grid-cols-3 gap-10 mb-10 '>
				<div className='flex flex-col w-full'>
					<div>Education Cert No.</div>
					<Input
						name='secEduCertNo'
						onChange={(e) => onChange(e)}
						value={formData?.secEduCertNo}
						className='rounded-[8px] mt-2 h-[44px]  w-[100%] border border-black'
					/>
				</div>
				<div className='flex flex-col w-full'>
					<div>Upload Education Certificate</div>
					<FileUploader
						fileName={formData?.secEduCert?.split('_').pop()}
						name='secEduCert'
						url={url}
						onSuccess={handleSuccess}
						onError={handleError}
					/>
				</div>
			</div>
		</div>
	);
};

export default ManagerDetails;
