import { useSelector } from 'react-redux';
import InactiveDash from './InactiveDash';
import ActiveDashBoard from './ActiveDashboard';
import { useEffect } from 'react';
import RejectedDash from './RejectedDash';

export default function DashBoard() {
	const { agencyObj } = useSelector((state) => state.agency);

	useEffect(() => {}, [agencyObj]);

	if (
		agencyObj?.instStatus === 'APPROVE_ACCOUNT_CREATION' ||
		agencyObj?.instStatus === 'DEREGISTRATION_REQUEST'
	) {
		return <ActiveDashBoard />;
	} else if (agencyObj?.instStatus === 'REJECT_ACCOUNT_CREATION') {
		return <RejectedDash />;
	} else {
		return <InactiveDash />;
	}
}
