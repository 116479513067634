import { Steps } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import lic from '../../assets/lic.png';
import InboxTable from './inbox_files/InboxTable';
import SentTable from './inbox_files/SentTable';
import DraftTable from './inbox_files/DraftTable';
import ComposeMessage from './inbox_files/ComposeMessage';

export default function Inbox() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { currentView } = useSelector((state) => state.inbox);

	const [current, setCurrent] = useState(currentView);

	function handleCurrentStep(val) {
		setCurrent(val);
	}

	const steps = [
		{
			content: <InboxTable current={current} />,
		},
		{
			content: <ComposeMessage />,
		},
		{
			content: <SentTable handleCurrentStep={handleCurrentStep} />,
		},
		{
			content: <DraftTable />,
		},
	];

	function handleGoBack() {
		navigate(-1);
	}

	useEffect(() => {}, [current]);

	useEffect(() => {
		setCurrent(currentView);
	}, [currentView]);

	return (
		<>
			<div className='w-[100%] bg-white p-10 flex justify-between items-center'>
				<span className='font-bold text-[26px] text-[#141414]'>Inbox</span>

				<div className='flex items-center'>
					<button
						type='button'
						onClick={handleGoBack}
						className='bg-transparent flex items-center'
					>
						<div className='flex justify-center items-center rounded-full w-[40px] h-[40px] p-2 bg-[#EDF8FF]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='28'
								height='24'
								viewBox='0 0 28 24'
								fill='none'
							>
								<path
									d='M17.7692 18L11.0469 12L17.7692 6'
									stroke='#147CBC'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
							</svg>
						</div>
						<span className='ml-5 font-bold text-[22px] text-[#222222]'>
							Go Back
						</span>
					</button>
				</div>
			</div>

			<div className='mt-3 bg-white py-10 min-h-full h-auto'>
				<div className='grid grid-cols-12'>
					<div className='col-span-2'>
						<div className='flex justify-center'>
							<button
								onClick={() => setCurrent(1)}
								type='button'
								className='border-none text-blueDark text-[21px] font-medium flex items-center'
							>
								<div className='bg-blueDark p-3 flex justify-center items-center rounded-full'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
									>
										<path
											d='M19 11H13V5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H11V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19V13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z'
											fill='#EDF8FF'
										/>
									</svg>
								</div>
								<span className='ml-3'>Compose</span>
							</button>
						</div>

						<div className='mt-5'>
							<div
								onClick={() => setCurrent(0)}
								className={
									current === 0
										? 'border-2 border-blueDark bg-gray-50 w-full mt-10 cursor-pointer'
										: 'mt-10 cursor-pointer'
								}
							>
								<button
									onClick={() => setCurrent(0)}
									type='button'
									className='border-none text-blueDark text-[20px] font-medium p-2 flex items-center my-3 ml-5'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='25'
										height='24'
										viewBox='0 0 25 24'
										fill='none'
									>
										<path
											d='M19.5 4H5.5C4.70435 4 3.94129 4.31607 3.37868 4.87868C2.81607 5.44129 2.5 6.20435 2.5 7V17C2.5 17.7956 2.81607 18.5587 3.37868 19.1213C3.94129 19.6839 4.70435 20 5.5 20H19.5C20.2956 20 21.0587 19.6839 21.6213 19.1213C22.1839 18.5587 22.5 17.7956 22.5 17V7C22.5 6.20435 22.1839 5.44129 21.6213 4.87868C21.0587 4.31607 20.2956 4 19.5 4ZM19.09 6L13.21 11.88C13.117 11.9737 13.0064 12.0481 12.8846 12.0989C12.7627 12.1497 12.632 12.1758 12.5 12.1758C12.368 12.1758 12.2373 12.1497 12.1154 12.0989C11.9936 12.0481 11.883 11.9737 11.79 11.88L5.91 6H19.09ZM20.5 17C20.5 17.2652 20.3946 17.5196 20.2071 17.7071C20.0196 17.8946 19.7652 18 19.5 18H5.5C5.23478 18 4.98043 17.8946 4.79289 17.7071C4.60536 17.5196 4.5 17.2652 4.5 17V7.41L10.38 13.29C10.9425 13.8518 11.705 14.1674 12.5 14.1674C13.295 14.1674 14.0575 13.8518 14.62 13.29L20.5 7.41V17Z'
											fill='#147CBC'
										/>
									</svg>
									<span className='ml-3'>Inbox</span>
								</button>
							</div>

							<div
								onClick={() => setCurrent(2)}
								className={
									current === 2
										? 'border-2 border-blueDark bg-gray-50 w-full cursor-pointer'
										: 'cursor-pointer'
								}
							>
								<button
									onClick={() => setCurrent(2)}
									type='button'
									className='border-none text-blueDark text-[20px] font-medium p-2 flex items-center my-3 ml-5'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='25'
										height='24'
										viewBox='0 0 25 24'
										fill='none'
									>
										<path
											d='M21.4182 10.6531C21.1038 10.4832 18.4075 8.91875 17.3957 8.32989L17.3928 8.32789L5.13793 1.25275C4.92915 1.1253 4.69505 1.04501 4.45199 1.01749C4.20894 0.989977 3.96281 1.0159 3.73082 1.09345C3.70464 1.10285 3.68004 1.11231 3.65459 1.12396C3.5955 1.14322 3.53774 1.16634 3.48168 1.19318C3.24737 1.33323 3.05514 1.53385 2.92522 1.77393C2.79529 2.01401 2.73249 2.28468 2.7434 2.55744V21.5428C2.73597 21.7969 2.79615 22.0484 2.9178 22.2717C3.03945 22.4949 3.21821 22.6818 3.43578 22.8133C3.48596 22.8378 3.53767 22.8591 3.59057 22.877C3.6195 22.8904 3.64708 22.902 3.67693 22.9125C3.82014 22.9645 3.97097 22.9923 4.12328 22.9949C4.41577 22.9918 4.70233 22.912 4.95434 22.7635C5.34301 22.5379 17.3928 15.5809 17.3928 15.5809L21.4133 13.2596C21.6619 13.1351 21.8717 12.945 22.0201 12.7099C22.1684 12.4747 22.2497 12.2035 22.2551 11.9256C22.2429 11.6578 22.1587 11.3983 22.0114 11.1744C21.8641 10.9505 21.6592 10.7704 21.4182 10.6531ZM4.7434 19.8391V4.10199L12.6835 11.9611L4.7434 19.8391ZM9.7614 17.6774L14.1049 13.3679L15.2545 14.5057C13.9898 15.236 11.8531 16.4697 9.7614 17.6775V17.6774ZM14.1032 10.5524L9.70533 6.19946L15.259 9.40576L14.1032 10.5524ZM17.05 13.4691L15.5246 11.9594L17.0523 10.4435C17.7726 10.8618 18.8952 11.5129 19.6682 11.9578L17.05 13.4691Z'
											fill='#147CBC'
										/>
									</svg>
									<span className='ml-3'>Sent</span>
								</button>
							</div>

							<div
								onClick={() => setCurrent(3)}
								className={
									current === 3
										? 'border-2 border-blueDark bg-gray-50 w-full cursor-pointer'
										: 'cursor-pointer'
								}
							>
								<button
									onClick={() => setCurrent(3)}
									type='button'
									className='border-none text-blueDark text-[20px] font-medium p-2 flex items-center my-3 ml-5'
								>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
									>
										<path
											d='M17 4H16C16 3.46957 15.7893 2.96086 15.4142 2.58579C15.0391 2.21071 14.5304 2 14 2H10C9.46957 2 8.96086 2.21071 8.58579 2.58579C8.21071 2.96086 8 3.46957 8 4H7C6.20435 4 5.44129 4.31607 4.87868 4.87868C4.31607 5.44129 4 6.20435 4 7V19C4 19.7956 4.31607 20.5587 4.87868 21.1213C5.44129 21.6839 6.20435 22 7 22H17C17.7956 22 18.5587 21.6839 19.1213 21.1213C19.6839 20.5587 20 19.7956 20 19V7C20 6.20435 19.6839 5.44129 19.1213 4.87868C18.5587 4.31607 17.7956 4 17 4ZM10 4H14V5V6H10V4ZM18 19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V7C6 6.73478 6.10536 6.48043 6.29289 6.29289C6.48043 6.10536 6.73478 6 7 6H8C8 6.53043 8.21071 7.03914 8.58579 7.41421C8.96086 7.78929 9.46957 8 10 8H14C14.5304 8 15.0391 7.78929 15.4142 7.41421C15.7893 7.03914 16 6.53043 16 6H17C17.2652 6 17.5196 6.10536 17.7071 6.29289C17.8946 6.48043 18 6.73478 18 7V19Z'
											fill='#147CBC'
										/>
									</svg>
									<span className='ml-3'>Draft</span>
								</button>
							</div>
						</div>
					</div>

					<div className='col-span-10 w-full h-full ml-5'>
						{true ? (
							<>
								<div className='px-10'>{steps[current].content}</div>
							</>
						) : (
							<>
								<div className='flex flex-col justify-center items-center mt-14'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='252'
										height='142'
										viewBox='0 0 252 142'
										fill='none'
									>
										<path
											d='M198.612 27.9676H69.8398V22.8803C69.8402 21.3908 70.4321 19.9624 71.4853 18.9091C72.5385 17.8559 73.9669 17.264 75.4564 17.2637H196.896C196.689 18.2727 196.585 19.3 196.585 20.33V20.4541H89.9764V24.7335H197.129L197.232 24.7045C197.571 25.8396 198.034 26.9339 198.612 27.9676Z'
											fill='#CADEEC'
										/>
										<path
											d='M139.898 111.377C140.721 111.377 141.525 111.621 142.21 112.078C142.894 112.535 143.428 113.184 143.743 113.945C144.058 114.705 144.14 115.542 143.98 116.349C143.819 117.156 143.423 117.898 142.841 118.48C142.259 119.062 141.518 119.458 140.71 119.618C139.903 119.779 139.066 119.696 138.306 119.381C137.546 119.066 136.896 118.533 136.439 117.848C135.982 117.164 135.738 116.359 135.738 115.536C135.739 114.433 136.177 113.376 136.957 112.596C137.737 111.816 138.795 111.378 139.898 111.377Z'
											fill='#147CBC'
										/>
										<path
											d='M214.95 48.8397V96.7557C213.963 97.1801 213.003 97.6651 212.075 98.2077L209.906 97.4738L209.962 97.3062V39.7344L214.95 48.8397Z'
											fill='#147CBC'
										/>
										<path
											d='M214.949 38.9913V48.8398L209.961 39.7318V37.4746C211.574 38.131 213.244 38.6387 214.949 38.9913Z'
											fill='#147CBC'
										/>
										<path
											d='M205.304 123.123H196.207L200.456 107.952H203.251C202.518 109.771 202.141 111.713 202.139 113.674C202.165 117.08 203.274 120.389 205.304 123.123Z'
											fill='#147CBC'
										/>
										<path
											d='M200.46 107.952L196.211 123.123H72.4284C70.4189 123.123 68.4917 122.325 67.0708 120.904C65.6498 119.483 64.8516 117.556 64.8516 115.546V79.3609L69.8398 72.9893V102.336C69.8402 103.825 70.4321 105.253 71.4853 106.307C72.5385 107.36 73.9669 107.952 75.4564 107.952H200.46ZM144.066 115.537C144.066 114.714 143.823 113.909 143.365 113.224C142.908 112.54 142.259 112.006 141.498 111.691C140.738 111.376 139.901 111.293 139.094 111.453C138.287 111.613 137.545 112.009 136.963 112.591C136.38 113.173 135.984 113.914 135.823 114.721C135.662 115.529 135.744 116.365 136.058 117.126C136.373 117.887 136.906 118.537 137.59 118.995C138.274 119.452 139.078 119.697 139.901 119.697C141.005 119.697 142.063 119.259 142.843 118.478C143.623 117.698 144.062 116.64 144.062 115.537H144.066Z'
											fill='#147CBC'
										/>
										<path
											d='M198.667 42.0534C198.96 42.0462 199.251 42.0977 199.524 42.2049C199.797 42.3121 200.046 42.4728 200.256 42.6775C200.465 42.8823 200.632 43.1269 200.746 43.3971C200.86 43.6672 200.918 43.9574 200.918 44.2505C200.918 44.5437 200.86 44.8339 200.746 45.104C200.632 45.3742 200.465 45.6188 200.256 45.8235C200.046 46.0283 199.797 46.189 199.524 46.2962C199.251 46.4033 198.96 46.4549 198.667 46.4477H148.016C147.581 46.4485 147.156 46.3202 146.794 46.0789C146.432 45.8377 146.15 45.4944 145.984 45.0927C145.817 44.691 145.774 44.2488 145.859 43.8224C145.944 43.396 146.154 43.0045 146.462 42.6976C146.665 42.4924 146.907 42.3297 147.174 42.2191C147.441 42.1086 147.727 42.0522 148.016 42.0534H198.667Z'
											fill='#CADEEC'
										/>
										<path
											d='M198.668 51.4492C199.251 51.4492 199.81 51.6808 200.222 52.0929C200.634 52.5051 200.866 53.0641 200.866 53.647C200.866 54.2299 200.634 54.7889 200.222 55.2011C199.81 55.6133 199.251 55.8448 198.668 55.8448H122.391C121.807 55.8438 121.248 55.6116 120.836 55.1991C120.424 54.7866 120.192 54.2275 120.191 53.6444C120.194 53.0622 120.426 52.5047 120.838 52.0935C121.25 51.6822 121.808 51.4506 122.391 51.4492H198.668Z'
											fill='#147CBC'
										/>
										<path
											d='M198.669 60.8464C198.962 60.8392 199.254 60.8907 199.526 60.9979C199.799 61.1051 200.048 61.2658 200.258 61.4705C200.468 61.6752 200.634 61.9199 200.748 62.19C200.862 62.4602 200.921 62.7503 200.921 63.0435C200.921 63.3367 200.862 63.6268 200.748 63.897C200.634 64.1671 200.468 64.4118 200.258 64.6165C200.048 64.8212 199.799 64.9819 199.526 65.0891C199.254 65.1963 198.962 65.2478 198.669 65.2406H173.644C173.061 65.2403 172.502 65.0085 172.09 64.5962C171.677 64.1838 171.446 63.6247 171.445 63.0415C171.446 62.459 171.679 61.9006 172.091 61.4891C172.503 61.0775 173.062 60.8464 173.644 60.8464H198.669Z'
											fill='#CADEEC'
										/>
										<path
											d='M198.668 79.6387C199.241 79.6527 199.786 79.8904 200.187 80.3009C200.588 80.7114 200.812 81.2623 200.812 81.8358C200.812 82.4094 200.588 82.9602 200.187 83.3707C199.786 83.7812 199.241 84.0189 198.668 84.033H156.56C155.977 84.0326 155.417 83.8008 155.005 83.3885C154.592 82.9762 154.36 82.4171 154.359 81.8338C154.361 81.2513 154.594 80.6931 155.006 80.2817C155.419 79.8702 155.977 79.639 156.56 79.6387H198.668Z'
											fill='#CADEEC'
										/>
										<path
											d='M198.668 89.0348C198.961 89.0277 199.253 89.0792 199.526 89.1864C199.799 89.2935 200.047 89.4542 200.257 89.659C200.467 89.8637 200.634 90.1083 200.747 90.3785C200.861 90.6486 200.92 90.9388 200.92 91.232C200.92 91.5251 200.861 91.8153 200.747 92.0855C200.634 92.3556 200.467 92.6003 200.257 92.805C200.047 93.0097 199.799 93.1704 199.526 93.2776C199.253 93.3848 198.961 93.4363 198.668 93.4291H186.457C185.874 93.4288 185.315 93.197 184.902 92.7846C184.49 92.3723 184.258 91.8131 184.258 91.23C184.26 90.6477 184.492 90.0897 184.904 89.6783C185.316 89.2669 185.875 89.0355 186.457 89.0348H198.668Z'
											fill='#147CBC'
										/>
										<path
											d='M198.669 98.4307C199.252 98.4307 199.811 98.6622 200.223 99.0744C200.636 99.4866 200.867 100.046 200.867 100.628C200.867 101.211 200.636 101.77 200.223 102.183C199.811 102.595 199.252 102.826 198.669 102.826H130.935C130.351 102.826 129.792 102.594 129.38 102.181C128.967 101.768 128.735 101.209 128.734 100.626C128.737 100.043 128.97 99.4857 129.382 99.0744C129.794 98.6631 130.352 98.4317 130.935 98.4307H198.669Z'
											fill='#CADEEC'
										/>
										<path
											d='M198.841 32.6734L196.71 37.0519H130.936C130.353 37.0519 129.794 36.8203 129.382 36.4081C128.97 35.996 128.738 35.4369 128.738 34.8541C128.738 34.2712 128.97 33.7121 129.382 33.3C129.794 32.8878 130.353 32.6562 130.936 32.6562H198.671C198.73 32.6562 198.781 32.6695 198.841 32.6734Z'
											fill='#147CBC'
										/>
										<path
											d='M72.4284 13.624H198.121C197.567 14.783 197.156 16.005 196.896 17.2633H75.4564C73.9669 17.2636 72.5385 17.8555 71.4853 18.9087C70.4321 19.962 69.8402 21.3904 69.8398 22.8799V40.8094C68.2923 39.6628 66.6186 38.6968 64.8516 37.9305V21.2008C64.8516 19.1913 65.6498 17.2641 67.0708 15.8432C68.4917 14.4223 70.4189 13.624 72.4284 13.624Z'
											fill='#147CBC'
										/>
										<path
											d='M197.233 24.7045L197.13 24.7335H89.9766V20.4541H196.59C196.598 21.8945 196.815 23.3261 197.233 24.7045Z'
											fill='#147CBC'
										/>
										<path
											d='M174.324 89.0352C174.898 89.0492 175.443 89.2869 175.844 89.6974C176.244 90.1079 176.468 90.6587 176.468 91.2323C176.468 91.8059 176.244 92.3567 175.844 92.7672C175.443 93.1777 174.898 93.4154 174.324 93.4294H89.5038C88.9207 93.4291 88.3615 93.1973 87.9492 92.7849C87.5368 92.3726 87.305 91.8134 87.3047 91.2303C87.3064 90.648 87.5388 90.09 87.951 89.6786C88.3631 89.2672 88.9215 89.0359 89.5038 89.0352H174.324Z'
											fill='#CADEEC'
										/>
										<path
											d='M161.51 60.8457C162.083 60.8598 162.628 61.0974 163.029 61.5079C163.43 61.9184 163.654 62.4693 163.654 63.0428C163.654 63.6164 163.43 64.1672 163.029 64.5778C162.628 64.9883 162.083 65.2259 161.51 65.24H89.5038C88.9207 65.2396 88.3615 65.0078 87.9492 64.5955C87.5368 64.1832 87.305 63.624 87.3047 63.0409C87.3057 62.4583 87.5379 61.9 87.9502 61.4884C88.3625 61.0769 88.9213 60.8457 89.5038 60.8457H161.51Z'
											fill='#147CBC'
										/>
										<path
											d='M156.262 134.017L158.065 141.965H155.518H125.374H121.738L126.01 123.122L156.262 134.017Z'
											fill='#147CBC'
										/>
										<path
											d='M153.791 123.122L156.26 134.017L126.008 123.122H153.791Z'
											fill='#147CBC'
										/>
										<path
											d='M144.425 79.6387C144.998 79.6527 145.544 79.8904 145.944 80.3009C146.345 80.7114 146.569 81.2623 146.569 81.8358C146.569 82.4094 146.345 82.9602 145.944 83.3707C145.544 83.7812 144.998 84.0189 144.425 84.033H89.5038C88.9207 84.0326 88.3615 83.8008 87.9492 83.3885C87.5368 82.9761 87.305 82.417 87.3047 81.8338C87.3057 81.2513 87.5379 80.6929 87.9502 80.2814C88.3625 79.8698 88.9213 79.6387 89.5038 79.6387H144.425Z'
											fill='#147CBC'
										/>
										<path
											d='M135.885 42.0534C136.178 42.0462 136.47 42.0977 136.743 42.2049C137.015 42.3121 137.264 42.4728 137.474 42.6775C137.684 42.8823 137.85 43.1269 137.964 43.3971C138.078 43.6672 138.137 43.9574 138.137 44.2505C138.137 44.5437 138.078 44.8339 137.964 45.104C137.85 45.3742 137.684 45.6188 137.474 45.8235C137.264 46.0283 137.015 46.189 136.743 46.2962C136.47 46.4033 136.178 46.4549 135.885 46.4477H89.5056C89.2125 46.4549 88.921 46.4033 88.6481 46.2962C88.3753 46.189 88.1266 46.0283 87.9168 45.8235C87.707 45.6188 87.5402 45.3742 87.4264 45.104C87.3126 44.8339 87.2539 44.5437 87.2539 44.2505C87.2539 43.9574 87.3126 43.6672 87.4264 43.3971C87.5402 43.1269 87.707 42.8823 87.9168 42.6775C88.1266 42.4728 88.3753 42.3121 88.6481 42.2049C88.921 42.0977 89.2125 42.0462 89.5056 42.0534H135.885Z'
											fill='#147CBC'
										/>
										<path
											d='M118.799 32.6562C119.381 32.6562 119.94 32.8878 120.353 33.3C120.765 33.7121 120.996 34.2712 120.996 34.8541C120.996 35.4369 120.765 35.996 120.353 36.4081C119.94 36.8203 119.381 37.0519 118.799 37.0519H89.5038C88.9207 37.0515 88.3615 36.8197 87.9492 36.4074C87.5368 35.995 87.305 35.4359 87.3047 34.8527C87.3061 34.2702 87.5383 33.7119 87.9505 33.3002C88.3627 32.8885 88.9212 32.6569 89.5038 32.6562H118.799Z'
											fill='#CADEEC'
										/>
										<path
											d='M118.799 98.4307C119.381 98.4307 119.94 98.6622 120.353 99.0744C120.765 99.4866 120.996 100.046 120.996 100.628C120.996 101.211 120.765 101.77 120.353 102.183C119.94 102.595 119.381 102.826 118.799 102.826H89.5038C88.9206 102.826 88.3614 102.593 87.9491 102.181C87.5368 101.768 87.305 101.209 87.3047 100.626C87.3064 100.043 87.5388 99.4855 87.951 99.0741C88.3631 98.6627 88.9215 98.4314 89.5038 98.4307H118.799Z'
											fill='#147CBC'
										/>
										<path
											d='M110.257 51.4492C110.84 51.4492 111.399 51.6808 111.811 52.0929C112.223 52.5051 112.455 53.0641 112.455 53.647C112.455 54.2299 112.223 54.7889 111.811 55.2011C111.399 55.6133 110.84 55.8448 110.257 55.8448H89.5038C88.9206 55.8441 88.3614 55.612 87.9491 55.1995C87.5368 54.7869 87.305 54.2276 87.3047 53.6444C87.3064 53.062 87.5388 52.5041 87.951 52.0927C88.3631 51.6813 88.9215 51.4499 89.5038 51.4492H110.257Z'
											fill='#CADEEC'
										/>
										<path
											d='M105.985 70.2383C106.568 70.2383 107.127 70.4698 107.539 70.882C107.952 71.2942 108.183 71.8532 108.183 72.4361C108.183 73.019 107.952 73.578 107.539 73.9902C107.127 74.4023 106.568 74.6339 105.985 74.6339H89.5038C88.9206 74.6332 88.3614 74.4011 87.9491 73.9885C87.5368 73.576 87.305 73.0167 87.3047 72.4334C87.3064 71.8511 87.5388 71.2932 87.951 70.8818C88.3631 70.4704 88.9215 70.239 89.5038 70.2383H105.985Z'
											fill='#CADEEC'
										/>
										<path
											d='M77.2812 52.125C77.5708 52.2331 77.8235 52.4216 78.0096 52.6684C78.1957 52.9153 78.3073 53.2101 78.3315 53.5183C78.3556 53.8264 78.2912 54.1351 78.1458 54.4079C78.0004 54.6806 77.7802 54.9062 77.5109 55.058V54.7808C77.5109 54.563 77.5109 54.3452 77.4938 54.1274V54.076C77.4657 53.4217 77.3947 52.77 77.2812 52.125Z'
											fill='#147CBC'
										/>
										<path
											d='M76.7018 33.2158C77.0258 33.2148 77.3428 33.3099 77.6126 33.4891C77.8825 33.6683 78.0931 33.9236 78.2178 34.2226C78.3425 34.5216 78.3757 34.8509 78.3131 35.1687C78.2505 35.4866 78.0951 35.7787 77.8664 36.0081C77.6377 36.2376 77.346 36.394 77.0284 36.4576C76.7107 36.5212 76.3814 36.4891 76.082 36.3653C75.7826 36.2416 75.5266 36.0318 75.3465 35.7625C75.1664 35.4932 75.0703 35.1766 75.0703 34.8526C75.0703 34.4194 75.242 34.0039 75.5478 33.6971C75.8537 33.3903 76.2686 33.2172 76.7018 33.2158Z'
											fill='#147CBC'
										/>
										<path
											d='M76.7018 42.6133C77.0255 42.6125 77.3421 42.7077 77.6115 42.887C77.881 43.0662 78.0912 43.3214 78.2156 43.6201C78.34 43.9189 78.373 44.2479 78.3103 44.5654C78.2477 44.8829 78.0922 45.1747 77.8637 45.4038C77.6351 45.6329 77.3437 45.7891 77.0263 45.8525C76.709 45.9159 76.3799 45.8838 76.0808 45.7601C75.7818 45.6365 75.5261 45.4268 75.3462 45.1578C75.1663 44.8888 75.0703 44.5724 75.0703 44.2488C75.0703 43.8157 75.2421 43.4003 75.5479 43.0937C75.8538 42.7871 76.2688 42.6143 76.7018 42.6133Z'
											fill='#147CBC'
										/>
										<path
											d='M76.7011 61.4055C77.1349 61.4055 77.5509 61.5778 77.8576 61.8846C78.1643 62.1913 78.3366 62.6073 78.3366 63.041C78.3366 63.4748 78.1643 63.8908 77.8576 64.1975C77.5509 64.5042 77.1349 64.6765 76.7011 64.6765C76.3525 64.6683 76.0153 64.5503 75.7375 64.3394C75.4598 64.1285 75.2556 63.8353 75.1541 63.5017L74.9297 63.3605C75.3152 62.7617 75.6618 62.1387 75.9672 61.4953L76.0398 61.5283C76.1758 61.3937 76.5889 61.4055 76.7011 61.4055Z'
											fill='#147CBC'
										/>
										<path
											d='M76.7018 80.1973C77.0258 80.1962 77.3428 80.2913 77.6126 80.4706C77.8825 80.6498 78.0931 80.905 78.2178 81.204C78.3425 81.503 78.3757 81.8323 78.3131 82.1501C78.2505 82.468 78.0951 82.7601 77.8664 82.9896C77.6377 83.219 77.346 83.3754 77.0284 83.439C76.7107 83.5026 76.3814 83.4705 76.082 83.3468C75.7826 83.223 75.5266 83.0133 75.3465 82.744C75.1664 82.4747 75.0703 82.158 75.0703 81.8341C75.0703 81.4009 75.242 80.9853 75.5478 80.6785C75.8537 80.3717 76.2686 80.1987 76.7018 80.1973Z'
											fill='#147CBC'
										/>
										<path
											d='M76.7018 89.5947C77.0255 89.5939 77.3421 89.6892 77.6115 89.8684C77.881 90.0477 78.0912 90.3028 78.2156 90.6016C78.34 90.9004 78.373 91.2293 78.3103 91.5468C78.2477 91.8644 78.0922 92.1561 77.8637 92.3853C77.6351 92.6144 77.3437 92.7705 77.0263 92.834C76.709 92.8974 76.3799 92.8652 76.0808 92.7416C75.7818 92.6179 75.5261 92.4083 75.3462 92.1392C75.1663 91.8702 75.0703 91.5538 75.0703 91.2302C75.0703 90.7971 75.2421 90.3817 75.5479 90.0751C75.8538 89.7686 76.2688 89.5958 76.7018 89.5947Z'
											fill='#147CBC'
										/>
										<path
											d='M76.7018 98.9902C77.0258 98.9892 77.3429 99.0843 77.6128 99.2636C77.8827 99.4429 78.0933 99.6982 78.2179 99.9973C78.3426 100.296 78.3757 100.626 78.313 100.944C78.2503 101.261 78.0947 101.554 77.8659 101.783C77.6371 102.012 77.3453 102.169 77.0276 102.232C76.7098 102.296 76.3804 102.263 76.081 102.139C75.7817 102.015 75.5258 101.805 75.3459 101.536C75.166 101.267 75.0701 100.95 75.0703 100.626C75.0703 100.193 75.2421 99.7773 75.5479 99.4707C75.8538 99.1641 76.2688 98.9913 76.7018 98.9902Z'
											fill='#147CBC'
										/>
										<path
											d='M67.5801 70.2461L69.9561 71.3694L69.8359 71.6255V72.989L64.8477 79.3607V71.6255C65.7859 71.2222 66.6984 70.7615 67.5801 70.2461Z'
											fill='#147CBC'
										/>
										<path
											d='M59.4509 73.3809L59.4971 73.5907C57.2901 78.5962 54.4811 83.5633 51.1904 86.3274C50.1221 87.2241 49.0942 88.1323 48.1069 89.0519C47.7634 83.8951 47.3775 78.7412 46.9492 73.5907C48.842 73.9353 50.7621 74.108 52.6859 74.1069C54.9606 74.1102 57.2288 73.8668 59.4509 73.3809Z'
											fill='#147CBC'
										/>
										<path
											d='M43.9736 98.4014C44.7856 96.4995 46.0374 94.8175 47.6261 93.4936C47.8689 93.3062 48.1118 93.1174 48.36 92.9379C52.2793 90.1812 56.5501 87.9615 61.0584 86.3379C60.9383 86.594 56.3513 96.3105 51.9504 100.022C50.93 100.88 49.9083 101.65 48.9434 102.398C47.9642 103.143 47.038 103.955 46.1714 104.828C45.2149 105.77 44.4583 106.895 43.9468 108.137C43.4354 109.378 43.1796 110.709 43.1948 112.051V112.269H41.3125V112.051C41.3364 109.75 41.551 107.454 41.954 105.187C42.338 102.849 43.0166 100.569 43.9736 98.4014Z'
											fill='#CADEEC'
										/>
										<path
											d='M49.4138 112.052V112.269H47.125C47.0136 109.767 46.6935 107.278 46.168 104.829C47.0345 103.955 47.9607 103.143 48.94 102.398C49.2343 107.354 49.4138 111.06 49.4138 112.052Z'
											fill='#147CBC'
										/>
										<path
											d='M48.363 92.9341C48.1148 93.1176 47.872 93.3063 47.6291 93.4938C46.0404 94.8177 44.7886 96.4997 43.9766 98.4015C43.4684 97.6095 42.8401 96.7317 42.1141 95.7536C40.4271 93.4727 38.2412 90.6967 35.9986 87.6119C33.298 83.995 30.8872 80.1705 28.7887 76.1741C28.7887 76.1741 28.7755 76.1529 28.7544 76.1147C28.4412 75.4931 28.1411 74.8651 27.8542 74.231C18.5125 53.6271 24.5647 33.084 24.5647 33.084C27.7673 36.0052 30.7721 39.1361 33.5592 42.456C31.8083 44.005 30.3974 45.9 29.4155 48.0215C28.4336 50.143 27.902 52.4449 27.8542 54.7821C27.8542 63.9179 35.9986 71.5765 46.9493 73.5921C47.3334 78.0722 47.7347 83.5951 48.1069 89.0533C48.1927 90.3548 48.2825 91.6524 48.363 92.9341Z'
											fill='#CADEEC'
										/>
										<path
											d='M41.035 97.376C41.035 97.376 41.7359 101.548 41.959 105.184C41.556 107.45 41.3414 109.746 41.3175 112.048V112.266H39.4417V112.048C40.1017 107.631 39.4246 102.66 39.4417 100.31C39.9174 99.3031 40.4493 98.3235 41.035 97.376Z'
											fill='#147CBC'
										/>
										<path
											d='M42.1136 95.7534C41.7387 96.2814 41.3797 96.8253 41.0378 97.3757C40.4521 98.3232 39.9202 99.3029 39.4445 100.31C37.7133 104.052 36.8033 108.121 36.7755 112.244V112.265H36.0231C35.9776 107.682 35.5043 103.113 34.6094 98.6178C35.041 96.358 35.2694 93.7959 35.5453 91.181C35.6693 90.0022 35.8013 88.8115 35.9637 87.6367L35.998 87.6104C38.2407 90.6978 40.4266 93.4698 42.1136 95.7534Z'
											fill='#CADEEC'
										/>
										<path
											d='M36.0222 112.27H32.6958C32.478 100.951 24.8247 97.376 17.1621 90.9186C9.24208 84.2513 1 66.6504 1 66.6504C9.2385 69.6175 17.0238 73.7172 24.1317 78.8313C24.3531 79.019 24.584 79.1952 24.8234 79.3593C30.3674 83.7074 33.1908 91.5667 34.6085 98.6181C35.5037 103.115 35.977 107.685 36.0222 112.27Z'
											fill='#CADEEC'
										/>
										<path
											d='M35.9976 87.6122L35.9633 87.6386C35.8009 88.8134 35.6689 90.004 35.5448 91.1828C34.7845 86.1391 30.744 83.4568 24.8251 79.3609C24.5941 79.1813 24.3671 79.0018 24.1321 78.8329C18.7637 74.3317 17.0859 63.3506 17.0859 63.3506C23.2319 66.9634 28.2927 75.3388 28.7534 76.1163L28.7877 76.1757C30.8863 80.1717 33.2971 83.9957 35.9976 87.6122Z'
											fill='#147CBC'
										/>
										<path
											d='M64.8516 71.6249C65.7884 71.2214 66.6996 70.7607 67.58 70.2455L69.956 71.3688L81.572 76.8362L74.048 64.6342C74.3635 64.2197 74.6631 63.7973 74.9364 63.3603C75.3222 62.7613 75.6687 62.1378 75.9739 61.4939C76.9454 59.4848 77.4696 57.2888 77.5104 55.0575V54.7803C77.5104 54.5625 77.5104 54.3448 77.4932 54.127'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M77.4981 54.0759C77.4682 53.4215 77.3955 52.7697 77.2803 52.1249C76.3405 47.5395 73.6772 43.4895 69.8394 40.8098C68.2919 39.6632 66.6182 38.6973 64.8512 37.9309C61.0099 36.2736 56.8669 35.4304 52.6834 35.4546C45.656 35.3049 38.8289 37.802 33.5566 42.4506C31.8057 43.9996 30.3948 45.8946 29.4129 48.0161C28.431 50.1376 27.8994 52.4395 27.8516 54.7768C27.8516 63.9125 35.996 71.5711 46.9467 73.5868C48.8395 73.9313 50.7595 74.1041 52.6834 74.1029C54.958 74.1063 57.2263 73.8628 59.4484 73.3769C61.3022 72.9714 63.1118 72.3851 64.8512 71.6266'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M198.844 32.6734L200.057 30.1799C199.519 29.4791 199.034 28.7397 198.605 27.9676C198.028 26.9337 197.568 25.8394 197.231 24.7046C196.813 23.3261 196.597 21.8945 196.589 20.4542V20.3301C196.588 19.3002 196.692 18.2728 196.898 17.2637C197.157 16.0056 197.567 14.7836 198.12 13.6245C201.622 6.2536 210.732 1 221.418 1C235.128 1 246.246 9.6526 246.246 20.3301C246.246 31.0076 235.128 39.6575 221.418 39.6575C219.245 39.6611 217.077 39.4364 214.95 38.987C213.245 38.6375 211.575 38.1311 209.962 37.4742C208.779 36.9975 207.632 36.435 206.53 35.7912L194.914 40.7465L196.717 37.0505L198.852 32.6721'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M209.906 97.4751L202.479 94.9592L205.61 103.822C204.638 105.084 203.845 106.473 203.252 107.952C202.519 109.771 202.142 111.713 202.141 113.674C202.167 117.08 203.277 120.389 205.309 123.123C209.558 129.017 217.669 133.002 226.975 133.002C240.686 133.002 251.803 124.348 251.803 113.674C251.803 103.001 240.686 94.3481 226.975 94.3481C222.845 94.3269 218.754 95.1479 214.953 96.761C213.965 97.1854 213.005 97.6703 212.078 98.213L209.906 97.4751Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M64.8516 37.9305V21.2008C64.8516 19.1913 65.6498 17.2641 67.0708 15.8432C68.4917 14.4223 70.4189 13.624 72.4284 13.624H198.121'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M126.012 123.122H72.4284C70.4189 123.122 68.4917 122.324 67.0708 120.903C65.6498 119.482 64.8516 117.555 64.8516 115.545V71.625'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M205.306 123.122H196.209H153.793'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M214.949 38.9912V48.8397V96.761'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M69.8398 40.8151V22.8803C69.8402 21.3908 70.4321 19.9624 71.4853 18.9091C72.5385 17.8559 73.9669 17.264 75.4564 17.2637H196.896'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M203.251 107.951H75.4564C73.9669 107.951 72.5385 107.359 71.4853 106.306C70.4321 105.253 69.8402 103.824 69.8398 102.335V71.625'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M209.961 37.4795V39.7341V97.3072'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M69.8398 27.9678H198.607'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M197.133 24.7335H89.9805V20.4541H196.556'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M144.062 115.536C144.063 116.359 143.819 117.164 143.362 117.849C142.905 118.534 142.256 119.068 141.495 119.383C140.735 119.699 139.898 119.781 139.091 119.621C138.283 119.461 137.542 119.065 136.959 118.484C136.377 117.902 135.98 117.161 135.819 116.353C135.658 115.546 135.74 114.709 136.054 113.949C136.369 113.188 136.902 112.538 137.586 112.08C138.27 111.622 139.074 111.377 139.898 111.377C141.001 111.377 142.06 111.815 142.841 112.594C143.622 113.374 144.061 114.433 144.062 115.536Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M121.738 141.965L126.01 123.122H153.793L156.262 134.017L158.065 141.965'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M155.52 141.966H125.375'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M126.008 123.122L156.26 134.017'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M110.246 141.966H116.833'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M118.789 141.966H121.739H125.375'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M16.2812 141.966H22.868'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M48.5977 141.966H65.5795'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M24.8242 141.966H33.7329'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M155.52 141.966H158.066H162.105'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M164.059 141.966H170.645'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M81.7305 25.2296L86.0997 22.7097L81.7305 20.1846'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M78.9083 20.1846L74.5352 22.7097L78.9083 25.2296'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M209.961 39.7344L214.949 48.8397'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M200.456 107.952L196.207 123.123'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M69.8398 72.9893L64.8516 79.3609'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M48.5961 141.966H33.7329C31.3702 141.966 29.1042 141.027 27.4335 139.356C25.7628 137.686 24.8242 135.42 24.8242 133.057V112.27H57.5048V133.057C57.5044 135.419 56.5657 137.685 54.8951 139.356C53.2245 141.027 50.9587 141.965 48.5961 141.966Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M36.0222 112.27C35.977 107.685 35.5037 103.115 34.6085 98.6181C33.1908 91.5667 30.3674 83.7021 24.8247 79.3593C24.5937 79.1798 24.3666 79.0003 24.1317 78.8313C17.0238 73.7172 9.2385 69.6175 1 66.6504C1 66.6504 9.2434 84.2513 17.1621 90.9186C24.8247 97.376 32.4741 100.951 32.6958 112.27'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M39.4425 100.314C37.7113 104.056 36.8012 108.126 36.7734 112.249'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M42.1144 95.7539C41.7395 96.2819 41.3804 96.8258 41.0386 97.3762C40.4529 98.3237 39.921 99.3034 39.4453 100.311'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M59.4958 73.5908C57.2887 78.5963 54.4798 83.5634 51.189 86.3275C50.1207 87.2242 49.0928 88.1324 48.1055 89.052'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M34.6094 98.6178C35.041 96.358 35.2694 93.7958 35.5453 91.1809C35.6693 90.0022 35.8013 88.8115 35.9637 87.6367'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M61.0673 86.3271C60.9432 86.5911 56.3562 96.3037 51.9593 100.016C50.939 100.874 49.9173 101.643 48.9524 102.392C47.9731 103.136 47.0469 103.948 46.1804 104.822C45.2239 105.763 44.4672 106.889 43.9558 108.13C43.4443 109.371 43.1885 110.702 43.2038 112.045'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M48.3633 92.934C52.2826 90.1773 56.5534 87.9576 61.0617 86.334'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M41.3125 112.052C41.3364 109.75 41.551 107.454 41.954 105.188C42.3384 102.851 43.017 100.572 43.9736 98.4059C44.7856 96.504 46.0374 94.822 47.6261 93.4981C47.8689 93.3107 48.1118 93.1219 48.36 92.9424'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M46.168 104.828C46.6935 107.277 47.0136 109.766 47.125 112.269'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M28.7891 76.1777C30.8876 80.1742 33.2983 83.9986 35.9989 87.6155C38.2429 90.7004 40.4275 93.4763 42.1145 95.7573C42.8405 96.7354 43.4688 97.6185 43.977 98.4052'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M33.5592 42.455C30.7721 39.1351 27.7673 36.0042 24.5647 33.083C24.5647 33.083 18.5125 53.6262 27.8542 74.23C28.1393 74.8623 28.4389 75.4854 28.7544 76.1137'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M48.3629 92.942C48.2824 91.6563 48.1927 90.3587 48.1069 89.0559C47.7634 83.8991 47.3775 78.7452 46.9492 73.5947'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M49.4113 112.052C49.4113 111.06 49.2318 107.354 48.9414 102.398'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M28.7877 76.1777L28.7534 76.1183C28.2927 75.3408 23.2319 66.9654 17.0859 63.3525C17.0859 63.3525 18.7637 74.3336 24.1321 78.8348C24.3538 79.0227 24.5851 79.199 24.8251 79.3628C30.744 83.4548 34.7845 86.141 35.5448 91.1847'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M39.4417 100.311C39.4246 102.66 40.1017 107.631 39.4417 112.048'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M41.0352 97.376C41.0352 97.376 41.7361 101.548 41.9592 105.184'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M83.9141 31.0205V103.252'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M118.799 37.0519H89.5038C88.9207 37.0515 88.3615 36.8197 87.9492 36.4074C87.5368 35.995 87.305 35.4359 87.3047 34.8527C87.3061 34.2702 87.5383 33.7119 87.9505 33.3002C88.3627 32.8885 88.9212 32.6569 89.5038 32.6562H118.799C119.381 32.6562 119.94 32.8878 120.353 33.3C120.765 33.7121 120.996 34.2712 120.996 34.8541C120.996 35.4369 120.765 35.996 120.353 36.4081C119.94 36.8203 119.381 37.0519 118.799 37.0519Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M198.845 32.6734C198.781 32.6734 198.73 32.6562 198.671 32.6562H130.936C130.353 32.6562 129.794 32.8878 129.382 33.3C128.97 33.7121 128.738 34.2712 128.738 34.8541C128.738 35.4369 128.97 35.996 129.382 36.4081C129.794 36.8203 130.353 37.0519 130.936 37.0519H196.71L198.845 32.6734Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M78.3347 34.8526C78.3342 35.1757 78.238 35.4913 78.0581 35.7597C77.8783 36.028 77.623 36.2371 77.3244 36.3604C77.0258 36.4837 76.6974 36.5158 76.3806 36.4525C76.0638 36.3893 75.7729 36.2336 75.5446 36.005C75.3162 35.7765 75.1608 35.4855 75.0978 35.1686C75.0348 34.8518 75.0671 34.5234 75.1907 34.2249C75.3142 33.9265 75.5235 33.6713 75.792 33.4917C76.0605 33.3121 76.3762 33.2161 76.6992 33.2158C76.9142 33.2155 77.1272 33.2576 77.3258 33.3397C77.5245 33.4219 77.705 33.5425 77.8569 33.6945C78.0089 33.8466 78.1293 34.0272 78.2113 34.2259C78.2933 34.4247 78.3352 34.6376 78.3347 34.8526Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M135.885 46.4477H89.5056C89.2125 46.4549 88.921 46.4033 88.6481 46.2962C88.3753 46.189 88.1266 46.0283 87.9168 45.8235C87.707 45.6188 87.5402 45.3742 87.4264 45.104C87.3126 44.8339 87.2539 44.5437 87.2539 44.2505C87.2539 43.9574 87.3126 43.6672 87.4264 43.3971C87.5402 43.1269 87.707 42.8823 87.9168 42.6775C88.1266 42.4728 88.3753 42.3121 88.6481 42.2049C88.921 42.0977 89.2125 42.0462 89.5056 42.0534H135.885C136.178 42.0462 136.47 42.0977 136.743 42.2049C137.015 42.3121 137.264 42.4728 137.474 42.6775C137.684 42.8823 137.85 43.1269 137.964 43.3971C138.078 43.6672 138.137 43.9574 138.137 44.2505C138.137 44.5437 138.078 44.8339 137.964 45.104C137.85 45.3742 137.684 45.6188 137.474 45.8235C137.264 46.0283 137.015 46.189 136.743 46.2962C136.47 46.4033 136.178 46.4549 135.885 46.4477Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M198.667 46.4477H148.016C147.581 46.4485 147.156 46.3202 146.794 46.0789C146.432 45.8377 146.15 45.4944 145.984 45.0927C145.817 44.691 145.774 44.2488 145.859 43.8224C145.944 43.396 146.154 43.0045 146.462 42.6976C146.665 42.4924 146.907 42.3297 147.174 42.2191C147.441 42.1086 147.727 42.0522 148.016 42.0534H198.667C198.96 42.0462 199.251 42.0977 199.524 42.2049C199.797 42.3121 200.046 42.4728 200.256 42.6775C200.465 42.8823 200.632 43.1269 200.746 43.3971C200.86 43.6672 200.918 43.9574 200.918 44.2505C200.918 44.5437 200.86 44.8339 200.746 45.104C200.632 45.3742 200.465 45.6188 200.256 45.8235C200.046 46.0283 199.797 46.189 199.524 46.2962C199.251 46.4033 198.96 46.4549 198.667 46.4477Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M78.3347 44.2488C78.3345 44.5719 78.2384 44.8876 78.0588 45.1562C77.8791 45.4247 77.6239 45.6339 77.3253 45.7575C77.0268 45.881 76.6983 45.9132 76.3814 45.8501C76.0646 45.787 75.7735 45.6314 75.545 45.403C75.3166 45.1745 75.161 44.8834 75.0979 44.5666C75.0348 44.2497 75.067 43.9212 75.1905 43.6227C75.3141 43.3241 75.5233 43.0689 75.7919 42.8892C76.0604 42.7096 76.3762 42.6135 76.6992 42.6133C76.9141 42.6129 77.1269 42.655 77.3255 42.7371C77.5241 42.8191 77.7045 42.9396 77.8565 43.0915C78.0084 43.2435 78.1289 43.4239 78.2109 43.6225C78.293 43.8211 78.3351 44.0339 78.3347 44.2488Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M110.257 55.8497H89.5038C88.9206 55.849 88.3614 55.6169 87.9491 55.2044C87.5368 54.7918 87.305 54.2325 87.3047 53.6493C87.3064 53.0669 87.5388 52.509 87.951 52.0976C88.3631 51.6862 88.9215 51.4548 89.5038 51.4541H110.257C110.84 51.4541 111.399 51.6856 111.811 52.0978C112.223 52.51 112.455 53.069 112.455 53.6519C112.455 54.2348 112.223 54.7938 111.811 55.206C111.399 55.6181 110.84 55.8497 110.257 55.8497Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M198.668 55.8497H122.391C121.807 55.8487 121.248 55.6164 120.836 55.204C120.424 54.7915 120.192 54.2324 120.191 53.6493C120.194 53.0671 120.426 52.5096 120.838 52.0983C121.25 51.6871 121.808 51.4555 122.391 51.4541H198.668C199.251 51.4541 199.81 51.6856 200.222 52.0978C200.634 52.51 200.866 53.069 200.866 53.6519C200.866 54.2348 200.634 54.7938 200.222 55.206C199.81 55.6181 199.251 55.8497 198.668 55.8497Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M77.5177 54.7808C77.5177 54.8746 77.5177 54.9683 77.5085 55.058C77.7777 54.9062 77.998 54.6806 78.1433 54.4079C78.2887 54.1351 78.3531 53.8264 78.329 53.5183C78.3048 53.2101 78.1932 52.9153 78.0071 52.6684C77.8211 52.4216 77.5684 52.2331 77.2788 52.125H77.2695C77.3864 52.7716 77.4622 53.425 77.4966 54.0812V54.134C77.5124 54.3452 77.5177 54.563 77.5177 54.7808Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M198.669 65.2406H173.644C173.061 65.2403 172.502 65.0085 172.09 64.5962C171.677 64.1838 171.446 63.6247 171.445 63.0415C171.446 62.459 171.679 61.9006 172.091 61.4891C172.503 61.0775 173.062 60.8464 173.644 60.8464H198.669C198.962 60.8392 199.254 60.8907 199.526 60.9979C199.799 61.1051 200.048 61.2658 200.258 61.4705C200.468 61.6752 200.634 61.9199 200.748 62.19C200.862 62.4602 200.921 62.7503 200.921 63.0435C200.921 63.3367 200.862 63.6268 200.748 63.897C200.634 64.1671 200.468 64.4118 200.258 64.6165C200.048 64.8212 199.799 64.9819 199.526 65.0891C199.254 65.1963 198.962 65.2478 198.669 65.2406Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M76.0433 61.5262C76.1753 61.3942 76.5898 61.4035 76.7033 61.4035C77.137 61.4035 77.553 61.5758 77.8598 61.8825C78.1665 62.1892 78.3388 62.6052 78.3388 63.0389C78.3388 63.4727 78.1665 63.8887 77.8598 64.1954C77.553 64.5021 77.137 64.6744 76.7033 64.6744C76.3546 64.6662 76.0174 64.5482 75.7397 64.3373C75.4619 64.1264 75.2578 63.8333 75.1562 63.4996'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M105.985 74.6378H89.5038C88.9206 74.6371 88.3614 74.405 87.9491 73.9924C87.5368 73.5799 87.305 73.0206 87.3047 72.4373C87.3064 71.855 87.5388 71.2971 87.951 70.8857C88.3631 70.4743 88.9215 70.2429 89.5038 70.2422H105.985C106.568 70.2422 107.127 70.4737 107.539 70.8859C107.952 71.2981 108.183 71.8571 108.183 72.44C108.183 73.0229 107.952 73.5819 107.539 73.9941C107.127 74.4062 106.568 74.6378 105.985 74.6378Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M198.668 84.033H156.56C155.977 84.0326 155.417 83.8008 155.005 83.3885C154.592 82.9762 154.36 82.4171 154.359 81.8338C154.361 81.2513 154.594 80.6931 155.006 80.2817C155.419 79.8702 155.977 79.639 156.56 79.6387H198.668C199.241 79.6527 199.786 79.8904 200.187 80.3009C200.588 80.7114 200.812 81.2623 200.812 81.8358C200.812 82.4094 200.588 82.9602 200.187 83.3707C199.786 83.7812 199.241 84.0189 198.668 84.033Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M78.3347 81.8341C78.3342 82.1571 78.238 82.4727 78.0581 82.7411C77.8783 83.0095 77.623 83.2185 77.3244 83.3418C77.0258 83.4651 76.6974 83.4972 76.3806 83.434C76.0638 83.3707 75.7729 83.215 75.5446 82.9865C75.3162 82.758 75.1608 82.4669 75.0978 82.1501C75.0348 81.8332 75.0671 81.5048 75.1907 81.2064C75.3142 80.9079 75.5235 80.6527 75.792 80.4731C76.0605 80.2935 76.3762 80.1975 76.6992 80.1973C76.9142 80.1969 77.1272 80.239 77.3258 80.3212C77.5245 80.4033 77.705 80.5239 77.8569 80.676C78.0089 80.8281 78.1293 81.0086 78.2113 81.2074C78.2933 81.4061 78.3352 81.6191 78.3347 81.8341Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M198.668 93.4291H186.457C185.874 93.4288 185.315 93.197 184.902 92.7846C184.49 92.3723 184.258 91.8131 184.258 91.23C184.26 90.6477 184.492 90.0897 184.904 89.6783C185.316 89.2669 185.875 89.0355 186.457 89.0348H198.668C198.961 89.0277 199.253 89.0792 199.526 89.1864C199.799 89.2935 200.047 89.4542 200.257 89.659C200.467 89.8637 200.634 90.1083 200.747 90.3785C200.861 90.6486 200.92 90.9388 200.92 91.232C200.92 91.5251 200.861 91.8153 200.747 92.0855C200.634 92.3556 200.467 92.6003 200.257 92.805C200.047 93.0097 199.799 93.1704 199.526 93.2776C199.253 93.3848 198.961 93.4363 198.668 93.4291Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M78.3347 91.2263C78.3345 91.5494 78.2384 91.8652 78.0588 92.1337C77.8791 92.4022 77.6239 92.6115 77.3253 92.735C77.0268 92.8585 76.6983 92.8908 76.3814 92.8277C76.0646 92.7646 75.7735 92.609 75.545 92.3805C75.3166 92.152 75.161 91.861 75.0979 91.5441C75.0348 91.2272 75.067 90.8988 75.1905 90.6002C75.3141 90.3017 75.5233 90.0464 75.7919 89.8668C76.0604 89.6871 76.3762 89.5911 76.6992 89.5908C76.9141 89.5905 77.1269 89.6325 77.3255 89.7146C77.5241 89.7967 77.7045 89.9171 77.8565 90.0691C78.0084 90.221 78.1289 90.4014 78.2109 90.6C78.293 90.7986 78.3351 91.0114 78.3347 91.2263Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M118.799 102.826H89.5038C88.9206 102.826 88.3614 102.593 87.9491 102.181C87.5368 101.768 87.305 101.209 87.3047 100.626C87.3064 100.043 87.5388 99.4855 87.951 99.0741C88.3631 98.6627 88.9215 98.4314 89.5038 98.4307H118.799C119.381 98.4307 119.94 98.6622 120.353 99.0744C120.765 99.4866 120.996 100.046 120.996 100.628C120.996 101.211 120.765 101.77 120.353 102.183C119.94 102.595 119.381 102.826 118.799 102.826Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M198.669 102.826H130.935C130.351 102.826 129.792 102.594 129.38 102.181C128.967 101.768 128.735 101.209 128.734 100.626C128.737 100.043 128.97 99.4857 129.382 99.0744C129.794 98.6631 130.352 98.4317 130.935 98.4307H198.669C199.252 98.4307 199.811 98.6622 200.223 99.0744C200.636 99.4866 200.867 100.046 200.867 100.628C200.867 101.211 200.636 101.77 200.223 102.183C199.811 102.595 199.252 102.826 198.669 102.826Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
										<path
											d='M78.3347 100.626C78.3345 100.949 78.2384 101.265 78.0588 101.533C77.8791 101.802 77.6239 102.011 77.3253 102.134C77.0268 102.258 76.6983 102.29 76.3814 102.227C76.0646 102.164 75.7735 102.008 75.545 101.78C75.3166 101.551 75.161 101.26 75.0979 100.944C75.0348 100.627 75.067 100.298 75.1905 99.9996C75.3141 99.7011 75.5233 99.4458 75.7919 99.2662C76.0604 99.0865 76.3762 98.9905 76.6992 98.9902C76.9141 98.9901 77.1268 99.0322 77.3253 99.1144C77.5238 99.1965 77.7042 99.317 77.8561 99.4689C78.008 99.6208 78.1285 99.8011 78.2106 99.9996C78.2927 100.198 78.3349 100.411 78.3347 100.626Z'
											stroke='#147CBC'
											stroke-width='0.07764'
											stroke-linecap='round'
											stroke-linejoin='round'
										/>
									</svg>

									<span className='no-email-black my-5'>No Conversations</span>

									<span className='no-email-gray w-[500px]'>
										All your conversations, alerts and notifications will appear
										here
									</span>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
