import { Breadcrumb, Dropdown, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGraduateFolders,
  fetchGraduates,
  saveGraduateHeader,
  setGradUpload,
  setGraduateListObj,
} from "../../../features/graduates/GraduateSlice";
import { useNavigate } from "react-router-dom";
import AddGraduateListModal from "./modals/AddGraduateListModal";
import InsideHeader from "../../../components/InsideHeader";
import container from "../../../assets/Container.png";
import HowToAddGraduateModal from "./modals/HowToAddGraduateModal";
import GraduatesListView from "./GraduatesListView";

export default function GraduatesFolderView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { folderObj } = useSelector((state) => state.folder);
  const { gradLoading, graduatesHeaders, graduates } = useSelector(
    (state) => state.graduate
  );

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasSelected, sethasSelected] = useState(false);
  const [open, setopen] = useState(false);
  const [openGraduate, setopenGraduate] = useState(false);
  const [activeGradListObj, setactiveGradListObj] = useState({});

  function handleCancel() {
    setopen(false);
  }

  function handleCancelGraduate() {
    setopenGraduate(false);
  }

  function handleAddNew() {
    setopen(true);
    setopenGraduate(false);
  }

  const handleGraduateOption = () => {
    setopenGraduate(true);
  };

  async function handleUploadFile() {
    await dispatch(setGradUpload(false));
    await navigate("/graduates/add/file-upload");
  }

  async function handleGradListObj(item) {
    await dispatch(setGraduateListObj(item));
    await setactiveGradListObj(item);
    // await navigate(`/graduates/folders/list/${item?.gdcTitle}`)
  }

  async function handleSubmitToNea() {
    await selectedRowKeys?.forEach((item) => {
      let updObj = {
        gdcId: item,
        gdcStatus: "SUBMITTED",
      };
      dispatch(saveGraduateHeader(updObj));
    });
    await handleFetchData();
    await setSelectedRowKeys([]);
    await sethasSelected(false);
  }

  async function handleFetchData() {
    await dispatch(fetchGraduateFolders(folderObj?.fldId));
  }

  
  const handleGoBack = ()=>{
    navigate('/graduates')
  }


  const settingItems = [
    {
      key: "0",
      label: (
        <button
          onClick={() =>
            navigate(`/graduates/folders/list/${activeGradListObj?.gdcTitle}`)
          }
          className="font-dmSans text-black font-[400] text-[19px] !mt-[5%]"
        >
          View list
        </button>
      ),
    },

    {
      key: "1",
      label: (
        <div
          onClick={handleUploadFile}
          className="font-dmSans text-black font-[400] text-[19px] !mt-[5%]"
        >
          Upload .csv
        </div>
      ),
    },

    {
      key: "2",
      label: (
        <div
          onClick={
            activeGradListObj?.gdcStatus === "SUBMITTED"
              ? null
              : handleSubmitToNea
          }
          className="font-dmSans text-black font-[400] text-[19px] !mt-[5%]"
        >
          {activeGradListObj?.gdcStatus === "SUBMITTED"
            ? "Submitted"
            : "Submit to NEA"}
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: "Name of List",
      dataIndex: "gdcTitle",
    },
    {
      title: "Updated By",
      dataIndex: "gdcUpdatedBy",
    },
    {
      title: "Date Created",
      dataIndex: "gdcCreatedDate",

      render: (item) => <span>{moment(item).format("Do MMMM YYYY")}</span>,
    },
    {
      title: "Status",
      dataIndex: "gdcStatus",
    },
    {
      title: "",
      render: (item) => (
        <>
          {/* <button onClick={() =>handleGradListObj(item)}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='27'
							height='24'
							viewBox='0 0 27 24'
							fill='none'>
							<path
								d='M23.9436 11.6C21.8099 6.91 17.7959 4 13.4651 4C9.13428 4 5.12035 6.91 2.98662 11.6C2.92846 11.7262 2.89844 11.8623 2.89844 12C2.89844 12.1377 2.92846 12.2738 2.98662 12.4C5.12035 17.09 9.13428 20 13.4651 20C17.7959 20 21.8099 17.09 23.9436 12.4C24.0018 12.2738 24.0318 12.1377 24.0318 12C24.0318 11.8623 24.0018 11.7262 23.9436 11.6ZM13.4651 18C10.1061 18 6.94774 15.71 5.12035 12C6.94774 8.29 10.1061 6 13.4651 6C16.8241 6 19.9825 8.29 21.8099 12C19.9825 15.71 16.8241 18 13.4651 18ZM13.4651 8C12.6294 8 11.8125 8.2346 11.1177 8.67412C10.4229 9.11365 9.88133 9.73836 9.56153 10.4693C9.24174 11.2002 9.15807 12.0044 9.3211 12.7804C9.48413 13.5563 9.88654 14.269 10.4774 14.8284C11.0683 15.3878 11.8212 15.7688 12.6408 15.9231C13.4604 16.0775 14.31 15.9983 15.082 15.6955C15.8541 15.3928 16.514 14.8801 16.9782 14.2223C17.4425 13.5645 17.6903 12.7911 17.6903 12C17.6903 10.9391 17.2451 9.92172 16.4528 9.17157C15.6604 8.42143 14.5857 8 13.4651 8ZM13.4651 14C13.0473 14 12.6388 13.8827 12.2914 13.6629C11.944 13.4432 11.6732 13.1308 11.5133 12.7654C11.3534 12.3999 11.3116 11.9978 11.3931 11.6098C11.4746 11.2219 11.6758 10.8655 11.9713 10.5858C12.2667 10.3061 12.6432 10.1156 13.053 10.0384C13.4628 9.96126 13.8875 10.0009 14.2736 10.1522C14.6596 10.3036 14.9895 10.56 15.2217 10.8889C15.4538 11.2178 15.5777 11.6044 15.5777 12C15.5777 12.5304 15.3551 13.0391 14.9589 13.4142C14.5627 13.7893 14.0254 14 13.4651 14Z'
								fill='black'
							/>
						</svg>
					</button> */}

          {item?.gdcStatus === "SUBMITTED" ? null : (
            <>
              <Dropdown
                onOpenChange={() => handleGradListObj(item)}
                overlayStyle={{
                  width: "250px",
                }}
                trigger={"click"}
                menu={{ items: settingItems }}
                placement="bottom"
              >
                <button>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 7C12.3956 7 12.7822 6.8827 13.1111 6.66294C13.44 6.44318 13.6964 6.13082 13.8478 5.76537C13.9991 5.39992 14.0387 4.99778 13.9616 4.60982C13.8844 4.22186 13.6939 3.86549 13.4142 3.58579C13.1345 3.30608 12.7781 3.1156 12.3902 3.03843C12.0022 2.96126 11.6001 3.00087 11.2346 3.15224C10.8692 3.30362 10.5568 3.55996 10.3371 3.88886C10.1173 4.21776 10 4.60444 10 5C10 5.53043 10.2107 6.03914 10.5858 6.41421C10.9609 6.78929 11.4696 7 12 7ZM12 17C11.6044 17 11.2178 17.1173 10.8889 17.3371C10.56 17.5568 10.3036 17.8692 10.1522 18.2346C10.0009 18.6001 9.96126 19.0022 10.0384 19.3902C10.1156 19.7781 10.3061 20.1345 10.5858 20.4142C10.8655 20.6939 11.2219 20.8844 11.6098 20.9616C11.9978 21.0387 12.3999 20.9991 12.7654 20.8478C13.1308 20.6964 13.4432 20.44 13.6629 20.1111C13.8827 19.7822 14 19.3956 14 19C14 18.4696 13.7893 17.9609 13.4142 17.5858C13.0391 17.2107 12.5304 17 12 17ZM12 10C11.6044 10 11.2178 10.1173 10.8889 10.3371C10.56 10.5568 10.3036 10.8692 10.1522 11.2346C10.0009 11.6001 9.96126 12.0022 10.0384 12.3902C10.1156 12.7781 10.3061 13.1345 10.5858 13.4142C10.8655 13.6939 11.2219 13.8844 11.6098 13.9616C11.9978 14.0387 12.3999 13.9991 12.7654 13.8478C13.1308 13.6964 13.4432 13.44 13.6629 13.1111C13.8827 12.7822 14 12.3956 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10Z"
                      fill="black"
                    />
                  </svg>
                </button>
              </Dropdown>
            </>
          )}
        </>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    newSelectedRowKeys.length > 0
      ? sethasSelected(true)
      : sethasSelected(false);
  };

  const tableColumns = columns.map((item) => ({
    ...item,
  }));
 
 

  async function handleFetchGraduatesData() {
    await dispatch(fetchGraduates(folderObj?.fldId));
  }

  useEffect(() => {
    handleFetchGraduatesData();
  }, [folderObj]);

  useEffect(() => {
    handleFetchData();
  }, [folderObj]);

  useEffect(() => {}, [graduatesHeaders]);
  useEffect(() => {}, [graduates]);

  return (
    <>
      <InsideHeader
        title="Graduates"
        subtitle="Manage graduates within your institution"
        back={false} 
      />

<div className="mt-[2.5rem] px-10 font-dmSans text-[18px]">
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer "
                  onClick={() => navigate("/graduates")}
                >
                  Graduates
                </span>
              ),
            }, 
            {
              title: (
                <span className="text-blueDark font-dmSans">
                 {folderObj?.fldName}
                </span>
              ),
            },
          ]}
        />
      </div>

      <div className="dash-inner-page mt-[51px] max-w-full w-full overflow-x-auto  px-10 h-full">
        <div className="flex items-center justify-between w-full">
          <div>
            {/* <div className='flex mb-1'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='29'
								height='29'
								viewBox='0 0 24 24'
								fill='none'>
								<path
									d='M14.29 10.79L11 14.09L9.71 12.79C9.5217 12.6017 9.2663 12.4959 9 12.4959C8.7337 12.4959 8.4783 12.6017 8.29 12.79C8.1017 12.9783 7.99591 13.2337 7.99591 13.5C7.99591 13.7663 8.1017 14.0217 8.29 14.21L10.29 16.21C10.383 16.3037 10.4936 16.3781 10.6154 16.4289C10.7373 16.4797 10.868 16.5058 11 16.5058C11.132 16.5058 11.2627 16.4797 11.3846 16.4289C11.5064 16.3781 11.617 16.3037 11.71 16.21L15.71 12.21C15.8983 12.0217 16.0041 11.7663 16.0041 11.5C16.0041 11.2337 15.8983 10.9783 15.71 10.79C15.5217 10.6017 15.2663 10.4959 15 10.4959C14.7337 10.4959 14.4783 10.6017 14.29 10.79ZM19 5.50002H12.72L12.4 4.50002C12.1926 3.91325 11.8077 3.40553 11.2989 3.04718C10.7901 2.68884 10.1824 2.49762 9.56 2.50002H5C4.20435 2.50002 3.44129 2.81609 2.87868 3.3787C2.31607 3.94131 2 4.70437 2 5.50002V18.5C2 19.2957 2.31607 20.0587 2.87868 20.6213C3.44129 21.184 4.20435 21.5 5 21.5H19C19.7956 21.5 20.5587 21.184 21.1213 20.6213C21.6839 20.0587 22 19.2957 22 18.5V8.50002C22 7.70437 21.6839 6.94131 21.1213 6.3787C20.5587 5.81609 19.7956 5.50002 19 5.50002ZM20 18.5C20 18.7652 19.8946 19.0196 19.7071 19.2071C19.5196 19.3947 19.2652 19.5 19 19.5H5C4.73478 19.5 4.48043 19.3947 4.29289 19.2071C4.10536 19.0196 4 18.7652 4 18.5V5.50002C4 5.23481 4.10536 4.98045 4.29289 4.79292C4.48043 4.60538 4.73478 4.50002 5 4.50002H9.56C9.76964 4.49948 9.97416 4.56484 10.1446 4.68686C10.3151 4.80889 10.4429 4.9814 10.51 5.18002L11.05 6.82002C11.1171 7.01864 11.2449 7.19116 11.4154 7.31318C11.5858 7.4352 11.7904 7.50056 12 7.50002H19C19.2652 7.50002 19.5196 7.60538 19.7071 7.79292C19.8946 7.98045 20 8.23481 20 8.50002V18.5Z'
									fill='black'
								/>
							</svg>
							<h3 className='table-head mb-1 -mt-1 ml-2'>
								/ {folderObj?.fldName}
							</h3>
						</div> */}
            <h3 className="dash-title font-[700] text-[24px] text-black1">
              {folderObj?.fldName}
            </h3>
            {/* <h5 className="table-sub-head">List of graduates in this folder</h5> */}
          </div>

          {hasSelected ? (
            <>
              <button
                onClick={handleSubmitToNea}
                className="bg-blueDark w-[211px] p-2 text-white font-medium text-[18px] rounded-[56px]"
              >
                Submit to NEA
              </button>
            </>
          ) : (
            <>
              {/* <div className='flex items-center'>
								<button
									onClick={handleUploadFile}
									className='border border-blueDark w-fit py-2 px-5 text-blueDark font-medium text-[18px] flex items-center justify-center rounded-[56px]'>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='25'
										height='24'
										viewBox='0 0 25 24'
										fill='none'>
										<path
											d='M9.21 7.70892L11.5 5.40892V14.9989C11.5 15.2641 11.6054 15.5185 11.7929 15.706C11.9804 15.8936 12.2348 15.9989 12.5 15.9989C12.7652 15.9989 13.0196 15.8936 13.2071 15.706C13.3946 15.5185 13.5 15.2641 13.5 14.9989V5.40892L15.79 7.70892C15.883 7.80265 15.9936 7.87704 16.1154 7.92781C16.2373 7.97858 16.368 8.00472 16.5 8.00472C16.632 8.00472 16.7627 7.97858 16.8846 7.92781C17.0064 7.87704 17.117 7.80265 17.21 7.70892C17.3037 7.61596 17.3781 7.50536 17.4289 7.3835C17.4797 7.26164 17.5058 7.13093 17.5058 6.99892C17.5058 6.86691 17.4797 6.7362 17.4289 6.61434C17.3781 6.49248 17.3037 6.38188 17.21 6.28892L13.21 2.28892C13.1149 2.19788 13.0028 2.12651 12.88 2.07892C12.6365 1.9789 12.3635 1.9789 12.12 2.07892C11.9972 2.12651 11.8851 2.19788 11.79 2.28892L7.79 6.28892C7.69676 6.38216 7.6228 6.49285 7.57234 6.61467C7.52188 6.73649 7.49591 6.86706 7.49591 6.99892C7.49591 7.13078 7.52188 7.26135 7.57234 7.38317C7.6228 7.50499 7.69676 7.61568 7.79 7.70892C7.88324 7.80216 7.99393 7.87612 8.11575 7.92658C8.23757 7.97704 8.36814 8.00301 8.5 8.00301C8.63186 8.00301 8.76243 7.97704 8.88425 7.92658C9.00607 7.87612 9.11676 7.80216 9.21 7.70892ZM21.5 11.9989C21.2348 11.9989 20.9804 12.1043 20.7929 12.2918C20.6054 12.4793 20.5 12.7337 20.5 12.9989V18.9989C20.5 19.2641 20.3946 19.5185 20.2071 19.706C20.0196 19.8936 19.7652 19.9989 19.5 19.9989H5.5C5.23478 19.9989 4.98043 19.8936 4.79289 19.706C4.60536 19.5185 4.5 19.2641 4.5 18.9989V12.9989C4.5 12.7337 4.39464 12.4793 4.20711 12.2918C4.01957 12.1043 3.76522 11.9989 3.5 11.9989C3.23478 11.9989 2.98043 12.1043 2.79289 12.2918C2.60536 12.4793 2.5 12.7337 2.5 12.9989V18.9989C2.5 19.7946 2.81607 20.5576 3.37868 21.1202C3.94129 21.6828 4.70435 21.9989 5.5 21.9989H19.5C20.2956 21.9989 21.0587 21.6828 21.6213 21.1202C22.1839 20.5576 22.5 19.7946 22.5 18.9989V12.9989C22.5 12.7337 22.3946 12.4793 22.2071 12.2918C22.0196 12.1043 21.7652 11.9989 21.5 11.9989Z'
											fill='#147CBC'
										/>
									</svg>
									<span className='ml-1'>Upload Excel Instead</span>
								</button>
								<button
									onClick={handleAddNew}
									className='bg-blueDark w-[211px] p-2 text-white font-medium text-[18px] rounded-[56px] ml-3'>
									Add new list
								</button>
							</div> */}
            </>
          )}
        </div>

        {graduates && graduates?.length > 0 ? (
          <GraduatesListView handleGraduateOption={handleGraduateOption} />
        ) : (
          <div
            className="mt-[31px] bg-white w-full h-[471.33px] rounded-[15px] border border-solid
           border-[#F5F7FF] py-[60px] flex flex-col justify-center items-center cursor-pointer"
            onClick={handleGraduateOption}
          >
            <div>
              <img
                src={container}
                alt="group-pic"
                className="object-contain h-[291.33px] w-[292px]"
              />
               <div className="empty-desc flex items-center justify-center">Add graduates to this group</div>
            </div>

            <div className="mt-[2.63rem]">
              <button
                className="w-[250px] h-[50px] px-[24px] py-3 bg-blueDark rounded-[28px] justify-center items-center gap-1 inline-flex
            text-white text-[18px] leading-normal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M19.5 11H13.5V5C13.5 4.73478 13.3946 4.48043 13.2071 4.29289C13.0196 4.10536 12.7652 4 12.5 4C12.2348 4 11.9804 4.10536 11.7929 4.29289C11.6054 4.48043 11.5 4.73478 11.5 5V11H5.5C5.23478 11 4.98043 11.1054 4.79289 11.2929C4.60536 11.4804 4.5 11.7348 4.5 12C4.5 12.2652 4.60536 12.5196 4.79289 12.7071C4.98043 12.8946 5.23478 13 5.5 13H11.5V19C11.5 19.2652 11.6054 19.5196 11.7929 19.7071C11.9804 19.8946 12.2348 20 12.5 20C12.7652 20 13.0196 19.8946 13.2071 19.7071C13.3946 19.5196 13.5 19.2652 13.5 19V13H19.5C19.7652 13 20.0196 12.8946 20.2071 12.7071C20.3946 12.5196 20.5 12.2652 20.5 12C20.5 11.7348 20.3946 11.4804 20.2071 11.2929C20.0196 11.1054 19.7652 11 19.5 11Z"
                    fill="#EDF8FF"
                  />
                </svg>
                Add graduate
              </button>
            </div>
          </div>
        )}

        {/* <section>
					<Table
						rowKey={'gdcId'}
						rowSelection={{
							onChange: onSelectChange,
							selectedRowKeys: selectedRowKeys,
						}}
						loading={gradLoading}
						className='no-header-bg-table'
						size={'middle'}
						bordered={false}
						columns={tableColumns}
						dataSource={graduatesHeaders}
						scroll={{
							x: 700,
						}}
						pagination={{
							defaultPageSize: 5,
							hideOnSinglePage: true,
							pageSizeOptions: [10, 20, 50, 100],
						}}
					/>
				</section> */}
      </div>
      <AddGraduateListModal
        open={open}
        handleFetchData={handleFetchData}
        handleCancel={handleCancel}
      />
      <HowToAddGraduateModal
        open={openGraduate}
        handleCancel={handleCancelGraduate}
        uploadGraduateFile={handleUploadFile}
      />
    </>
  );
}
