import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	agnLoading: false,
	agency: {},
	agencyObj: {},
	branches: [],
	branchObj: {},
};

export const notifyNewAdmin = createAsyncThunk(
	'agencySlice/notifyNewAdmin',
	async (data) => {
		const res = await axiosInstance.post(`${url}/nea/notifyNewAdmin.action`, data);
		return res.data;
	},
);

export const saveBranchAgency = createAsyncThunk(
	'agencySlice/saveBranchAgency',
	async (data) => {
		const res = await axiosInstance.post(`${url}/nea/saveBranch.action`, data);
		return res.data;
	},
);

export const saveAgency = createAsyncThunk(
	'agencySlice/saveAgency',
	async (data) => {
		const res = await axiosInstance.post(`${url}/nea/saveInstitution.action`, data);
		return res.data;
	},
);

export const fetchAgencyData = createAsyncThunk(
	'agencySlice/fetchAgencyData',
	async (instId) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchInstitutions.action?instId=${instId}`,
		);
		return res.data.data.result;
	},
);

export const fetchAgencyBranches = createAsyncThunk(
	'agencySlice/fetchAgencyBranches',
	async (instMainId) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchInstitutions.action?instMainId=${instMainId}`,
		);
		return res.data.data.result;
	},
);

export const agencySlice = createSlice({
	name: 'agency',
	initialState,
	reducers: {
		setBranchObj: (state, action) => {
			state.branchObj = action.payload;
		},
		clearAgency: (state) => {
			state.branchObj = {};
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(saveBranchAgency.pending, (state) => {
				state.agnLoading = true;
			})
			.addCase(saveBranchAgency.fulfilled, (state, action) => {
				state.agnLoading = false;
			})
			.addCase(saveBranchAgency.rejected, (state) => {
				state.agnLoading = false;
			})

			.addCase(saveAgency.pending, (state) => {
				state.agnLoading = true;
			})
			.addCase(saveAgency.fulfilled, (state, action) => {
				state.agnLoading = false;
				state.agency = action?.payload;
			})
			.addCase(saveAgency.rejected, (state) => {
				state.agnLoading = false;
			})

			.addCase(fetchAgencyBranches.pending, (state) => {
				state.agnLoading = true;
			})
			.addCase(fetchAgencyBranches.fulfilled, (state, action) => {
				state.agnLoading = false;
				state.branches = action?.payload;
			})
			.addCase(fetchAgencyBranches.rejected, (state) => {
				state.agnLoading = false;
				state.branches = [];
			})

			.addCase(fetchAgencyData.pending, (state) => {
				state.agnLoading = true;
			})
			.addCase(fetchAgencyData.fulfilled, (state, action) => {
				state.agnLoading = false;
				state.agencyObj = action?.payload[0];
			})
			.addCase(fetchAgencyData.rejected, (state) => {
				state.agnLoading = false;
				// state.agencyObj = {};
			});
	},
});

export default agencySlice.reducer;
export const { setBranchObj, clearAgency } = agencySlice.actions;
