import React, { useRef } from "react";
import { Button, Form, Input, Modal, Select, Spin, Tag } from "antd";
import "../../../clients/client.css";
import { useDispatch, useSelector } from "react-redux";
import { Await, useNavigate } from "react-router-dom";
import {
  saveFolder,
  setFolderObj,
} from "../../../../features/folder/folderSlice";
import { customToast } from "../../../../notifications";

const { TextArea } = Input;

const AddFolderModal = ({ open, handleCancel, handleFetchData }) => {
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fldLoading } = useSelector((state) => state.folder);
  const { user } = useSelector((state) => state.auth);

  const onFinish = async (values) => {
    values.fldInstId = user?.usrInstId;
    values.fldCreatedBy = user?.usrId;
    values.fldType = "GRADUATES";
    const res = await dispatch(saveFolder(values));
    if (res?.payload?.success) {
      await customToast({
        content: "Folder created",
        header: "Success",
        bdColor: "Success",
      });
      await handleFetchData();
      await handleCancel();
      await form.resetFields();
      await dispatch(setFolderObj(res?.payload?.jsonData));
      await navigate(`/graduates/folder/${values?.fldName}`);
    } else {
      await customToast({
        content: res?.payload?.messages?.message,
        header: "Error",
        bdColor: "error",
      });
    }
  };

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title="Add graduate group"
        open={open}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <Form.Item
            name="fldName"
            label="Group name (e.g June 2024)"
            rules={[
              {
                required: true,
                message: "Enter name of group",
              },
            ]}
          >
            <Input className="input" />
          </Form.Item>
          <div className="w-full">
            <Form.Item
              name="fldDesc"
              label="Description"
              rules={[
                {
                  required: true,
                  message: "Enter description of this group",
                },
              ]}
              extra={"Write a brief description of this group"}
            >
              <TextArea
                rows={4}
                placeholder="Enter description"
                className="input-textarea"
              />
            </Form.Item>
          </div>
          <div className="flex justify-between mt-[156px] mb-[48px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center gap-x-2">
              <Button
                onClick={handleCancel}
                className="w-[149px] h-[50px] p-2 justify-center items-center rounded-[36px] border-2 border-none flex !text-blueDark text-[18px]"
              >
                Cancel
              </Button>

              <button
                key="submit"
                type="submit"
                className="w-[132px] h-[50px] px-10 py-3 bg-blueDark rounded-[28px] justify-center items-center text-white text-[18px]"
                disabled={fldLoading}
              >
                {fldLoading ? <Spin /> : "Save"}
              </button>
            </div>
          </div>
          {/* <div className='flex justify-between mt-10'>
						<div className='justify-start'></div>
						<div className='justify-end flex items-center'>
							<button
								onClick={handleCancel}
								className='w-[200px] p-2 justify-center items-center rounded-[36px] border-2 border-blueDark flex text-blueDark text-[18px] mr-4'>
								Cancel
							</button>

							<button
								key='submit'
								type='submit'
								className='w-[200px] p-2 bg-blueDark rounded-[36px] justify-center items-center text-white text-[18px]'
								disabled={fldLoading}>
								{fldLoading ? <Spin /> : 'Save'}
							</button>
						</div>
					</div> */}
        </Form>
      </Modal>
    </>
  );
};
export default AddFolderModal;
