import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Modal, Button, Switch, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import groupSvg from "../assets/Group.svg";
import UsersAddModal from "../pages/admin/UsersAddModal";
import { useNavigate } from "react-router-dom";

const AddPanelUserModal = ({
  isModalOpen,
  setIsModalOpen,
  setIsModalOpenReopen,
}) => {
  const handleCancel = () => {
    setIsModalOpen(false);
    if (setIsModalOpenReopen) {
      setIsModalOpenReopen(true);
    }
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const { usersData } = useSelector((state) => state.admin);
  const { panelDetails } = useSelector((state) => state.panel);
  const navigate = useNavigate();

  const [isModalOpenUser, setIsModalOpenUser] = useState(false);
  const showModalUser = async () => {
    await setIsModalOpenUser(true);
    await setIsModalOpen(false);
  };

  const handlePanel = () => {
    navigate("/panel-list");
  };

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer activity activity-header activity-close"
        title="Confirmation"
        open={isModalOpen}
        onCancel={handleCancel}
        width={898}
      >
        <div className="flex flex-col items-center justify-center mt-[80px]">
          <img src={groupSvg} alt="groupSvg" />
          <div className="confirm-h mt-[1rem]">Add Activity</div>
          <div className="confirm-p mt-[.19rem]">
            You should have users and panelist to proceed to activity
          </div>
        </div>

        <div
          className={`flex flex-col
         justify-center
           items-center mt-[80px] gap-y-3 mb-[66px]`}
        >
          {usersData?.length === 0 && (
            <button
              className="w-[200px] save-btn"
              type="submit"
              onClick={showModalUser}
            >
              Add User
            </button>
          )}

          {(panelDetails?.length === 0 ||
            panelDetails?.every((item) => item?.children === null) ||
            usersData?.length === 0) && (
            <button
              type="submit"
              className="w-[200px] cancel-btn flex items-center justify-center border border-blueDark"
              onClick={handlePanel}
            >
              Add Panel
            </button>
          )}
        </div>
      </Modal>

      <UsersAddModal
        isModalOpen={isModalOpenUser}
        setIsModalOpen={setIsModalOpenUser}
      />
    </>
  );
};
export default AddPanelUserModal;
