import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../instance";
 

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  jobFilterLoading: false,
  qualifications: [],
  jobTypes: [],
  jobStyles: [],
  jobExperienceLevels: [],
  categorys: [],
};

export const fetchJobExperienceLevels = createAsyncThunk(
  "jobFilterSlice/fetchJobExperienceLevels",
  async () => {
    const res = await axiosInstance.get(`${url}/setup/fetchJobExperienceLevels.action`);
    return res.data.data.result;
  }
);

export const fetchJobTypes = createAsyncThunk(
  "jobFilterSlice/fetchJobTypes",
  async () => {
    const res = await axiosInstance.get(`${url}/setup/fetchJobTypes.action`);
    return res.data.data.result;
  }
);

export const fetchJobWorkStyles = createAsyncThunk(
  "jobFilterSlice/fetchJobWorkStyles",
  async () => {
    const res = await axiosInstance.get(`${url}/setup/fetchJobWorkStyles.action`);
    return res.data.data.result;
  }
);

export const fetchQualifications = createAsyncThunk(
  "jobFilterSlice/fetchQualifications",
  async () => {
    const res = await axiosInstance.get(`${url}/emp/fetchQualifications.action`);
    return res.data.data.result;
  }
);

export const fetchJobCategorys = createAsyncThunk(
  "jobFilterSlice/fetchJobCategorys",
  async () => {
    const res = await axiosInstance.get(`${url}/setup/fetchJobCategorys.action`);
    return res.data.data.result;
  }
);

export const jobFilterSlice = createSlice({
  name: "jobFilter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchJobExperienceLevels.pending, (state) => {
        state.jobFilterLoading = true;
      })
      .addCase(fetchJobExperienceLevels.fulfilled, (state, action) => {
        state.jobFilterLoading = false;
        state.jobExperienceLevels = action?.payload;
      })
      .addCase(fetchJobExperienceLevels.rejected, (state) => {
        state.jobFilterLoading = false;
        state.jobExperienceLevels = [];
      })

      .addCase(fetchJobTypes.pending, (state) => {
        state.jobFilterLoading = true;
      })
      .addCase(fetchJobTypes.fulfilled, (state, action) => {
        state.jobFilterLoading = false;
        state.jobTypes = action?.payload;
      })
      .addCase(fetchJobTypes.rejected, (state) => {
        state.jobFilterLoading = false;
        state.jobTypes = [];
      })

      .addCase(fetchJobWorkStyles.pending, (state) => {
        state.jobFilterLoading = true;
      })
      .addCase(fetchJobWorkStyles.fulfilled, (state, action) => {
        state.jobFilterLoading = false;
        state.jobStyles = action?.payload;
      })
      .addCase(fetchJobWorkStyles.rejected, (state) => {
        state.jobFilterLoading = false;
        state.jobStyles = [];
      })

      .addCase(fetchQualifications.pending, (state) => {
        state.jobFilterLoading = true;
      })
      .addCase(fetchQualifications.fulfilled, (state, action) => {
        state.jobFilterLoading = false;
        state.qualifications = action?.payload;
      })
      .addCase(fetchQualifications.rejected, (state) => {
        state.jobFilterLoading = false;
        state.qualifications = [];
      })

      .addCase(fetchJobCategorys.pending, (state) => {
        state.jobFilterLoading = true;
      })
      .addCase(fetchJobCategorys.fulfilled, (state, action) => {
        state.jobFilterLoading = false;
        state.categorys = action?.payload;
      })
      .addCase(fetchJobCategorys.rejected, (state) => {
        state.jobFilterLoading = false;
        state.categorys = [];
      });
  },
});

export default jobFilterSlice.reducer;
export const {} = jobFilterSlice.actions;
