import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  applicants: [],
  applicantsById: {},
  singleCreateApplicant: {},
  applicantLoading: false,
  viewApplicantDetails: false,
  editApplicantDetails: false,
  viewJobSeekerInformation: false,
  editJobSeekerInformation: false,
  viewContact: false,
  editContact: false,

  viewSkills: false,
  editSkills: false,

  eduLoading:false,
  viewEducation: false,
  editEducation: false,

  applicantEditId: "",
  skillsData: [],

  applicantLoading: false, 
  viewModeTable: false,
  refetch: false,
  disability:{},
  disabilityLoading: false,

  jobSeekerInfo:[],
  seekerLoading: false,
  singleCreateSeekerInfo: {},
  seekerInfoEditId: "",

  singleCreateEducation: {},
  educationEditId: "",
  educations:[],

  viewDisability: false,
  editDisability: false,

  tabsActiveKey:"1",
 
};

export const createApplicantsDetails = createAsyncThunk(
  "applicantSlice/createApplicantDetails",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveUser.action`, data);
    return res.data.jsonData;
  }
);

export const fetchApplicantsDetails = createAsyncThunk(
  "applicantSlice/fetchApplicantsDetails",
  async (data) => {
    const res = await axiosInstance.get(`${url}/nea/fetchUsers.action?usrInstId=${data?.usrInstId}`);
    return res?.data?.data?.result;
  }
);
export const fetchApplicantsById = createAsyncThunk(
  "applicantSlice/fetchApplicantsById",
  async (data) => {
    const res = await axiosInstance.get(`${url}/nea/fetchUsers.action?usrId=${data?.usrId}`);
    return res?.data?.data?.result;
  }
);

export const fetchUserDisabilitys = createAsyncThunk(
  "applicantSlice/fetchUserDisabilitys",
  async (data) => {
    const res = await axiosInstance.get(`${url}/nea/fetchUserDisabilitys.action?disUsrId=${data?.disUsrId}`);
    return res.data?.data?.result;
  }
);

export const saveUserDisability = createAsyncThunk(
  "applicantSlice/saveUserDisability",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveUserDisability.action`, data);
    return res.data?.jsonData;
  }
);

export const saveJobSeekerInfo = createAsyncThunk(
	'jobSlice/saveJobSeekerInfo',
	async (jobObj) => {
		const res = await axiosInstance.post(`${url}/nea/saveJobSeekerInfo.action`, jobObj);
		return res.data?.jsonData;
	},
);

export const fetchJobSeekerInfos = createAsyncThunk(
	'jobSlice/fetchJobSeekerInfos',
	async (usrObj) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchJobSeekerInfos.action?jsiUsrId=${usrObj?.jsiUsrId}`,
		);
		return res.data?.data?.result;
	},
);

export const fetchEducations = createAsyncThunk(
	'eduSlice/fetchEducations',
	async (usrObj) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchEducations.action?eduUsrId=${usrObj?.eduUsrId}`,
		);
    return res.data?.data?.result;
	},
);

export const saveEducation = createAsyncThunk(
	'eduSlice/saveEducation',
	async (eduObj) => {
		const res = await axiosInstance.post(`${url}/nea/saveEducation.action`, eduObj);
		return res.data?.jsonData;
	},
);



export const applicantSlice = createSlice({
  name: "applicants",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },
    setViewApplicantDetails: (state, action) => {
      state.viewApplicantDetails = action.payload;
    },
    setEditpplicantDetails: (state, action) => {
      state.editApplicantDetails = action.payload;
    },
    setEditJobDetails: (state, action) => {
      state.editJobDetails = action.payload;
    },
    setViewJobSeekerInformation: (state, action) => {
      state.viewJobSeekerInformation = action.payload;
    },
    setEditJobSeekerInformation: (state, action) => {
      state.editJobSeekerInformation = action.payload;
    },
    setApplicantEditId: (state, action) => {
      state.applicantEditId = action.payload;
    },
    setSeekerInfoEditId: (state, action) => {
      state.seekerInfoEditId = action.payload;
    },
    

    setviewContact: (state, action) => {
      state.viewContact = action.payload;
    },
    seteditContact: (state, action) => {
      state.editContact = action.payload;
    },
    setViewModeTable: (state, action) => {
      state.viewModeTable = action.payload;
    },
    setRefetch: (state) => {
      state.refetch = !state.refetch;
    },
    setViewSkills: (state, action) => {
      state.viewSkills = action.payload;
    },
    setEditSkills: (state, action) => {
      state.editSkills = action.payload;
    },

    setViewEducation: (state, action) => {
      state.viewEducation = action.payload;
    },
    setEditEducation: (state, action) => {
      state.editEducation = action.payload;
    },
    setEditApplicantDetails: (state, action) => {
      state.editApplicantDetails = action.payload;
    },
    setEducationEditId: (state, action) => {
      state.educationEditId = action.payload;
    },
    setViewDisability: (state, action) => {
      state.viewDisability = action.payload;
    },
    setEditDisability: (state, action) => {
      state.editDisability = action.payload;
    },

    setEditDisability: (state, action) => {
      state.editDisability = action.payload;
    },

    setTabsActiveKey:(state,action)=>{
      state.tabsActiveKey = action?.payload;
    },
    
 
   
    cleanupApplicant: (state) => {
      state.viewApplicantDetails = initialState.viewApplicantDetails;
      state.editApplicantDetails = initialState.editApplicantDetails;
      state.viewJobSeekerInformation = initialState.viewJobSeekerInformation;
      state.editJobSeekerInformation = initialState.editJobSeekerInformation;
      state.viewContact = initialState.viewContact;
      state.editContact = initialState.editContact;
      state.viewSkills = initialState.viewSkills;
      state.editSkills = initialState.editSkills;
      state.viewEducation = initialState.viewEducation;
      state.editEducation = initialState.editEducation;
      state.editApplicantDetails = initialState.editApplicantDetails;

      state.singleCreateApplicant = initialState.singleCreateApplicant;

      state.viewDisability = initialState.viewDisability;
      state.editDisability = initialState.editDisability;

      state.tabsActiveKey = initialState.tabsActiveKey;
    },
    cleanUpapplicantEditId: (state) => {
      state.applicantEditId = initialState.applicantEditId; 
    },

    // to be used in cleaning up applicant view only
    cleanUpapplicantSingleCreateApplicant: (state) => {
      state.singleCreateApplicant = initialState.singleCreateApplicant; 
    },
    
  },
  extraReducers: (builder) => {
    builder

      .addCase(createApplicantsDetails.pending, (state) => {
        state.applicantLoading = true;
      })
      .addCase(createApplicantsDetails.fulfilled, (state, action) => {
        state.applicantLoading = false; 
        state.singleCreateApplicant =  action?.payload
      })
      .addCase(createApplicantsDetails.rejected, (state, action) => {
        state.applicantLoading = false;
      })

      .addCase(fetchApplicantsDetails.pending, (state) => {
        state.applicantLoading = true;
      })
      .addCase(fetchApplicantsDetails.fulfilled, (state, action) => {
        state.applicantLoading = false;
        state.applicants = action.payload;
      })
      .addCase(fetchApplicantsDetails.rejected, (state, action) => {
        state.applicantLoading = false;
        state.applicants = [];
      })

      .addCase(fetchApplicantsById.pending, (state) => {
        state.applicantLoading = true;
      })
      .addCase(fetchApplicantsById.fulfilled, (state, action) => {
        state.applicantLoading = false;
        if(action?.payload && action?.payload?.length > 0){
          state.applicantsById = action.payload[0];
        } else { 
          console.error("Payload is undefined or empty");
        } 
      })
      .addCase(fetchApplicantsById.rejected, (state, action) => {
        state.applicantLoading = false;
        state.applicants = [];
      })

      .addCase(fetchUserDisabilitys.pending, (state) => {
        state.disabilityLoading = true;
      })
      .addCase(fetchUserDisabilitys.fulfilled, (state, action) => {
        state.disabilityLoading = false;
        if(action?.payload && action?.payload?.length > 0){
          state.disability =  action?.payload[0]
        }else{ 
        }
       
      })
      .addCase(fetchUserDisabilitys.rejected, (state) => {
        state.disabilityLoading = true;
      })

      .addCase(saveUserDisability.pending, (state) => {
        state.disabilityLoading = true;
      })
      .addCase(saveUserDisability.fulfilled, (state) => {
        state.disabilityLoading = false;
      })
      .addCase(saveUserDisability.rejected, (state) => {
        state.disabilityLoading = false;
      })


      .addCase(saveJobSeekerInfo.pending, (state) => {
        state.seekerLoading = true;
      })
      .addCase(saveJobSeekerInfo.fulfilled, (state,action) => {
        state.seekerLoading = false; 
        state.singleCreateSeekerInfo =  action?.payload
      })
      .addCase(saveJobSeekerInfo.rejected, (state) => {
        state.seekerLoading = false;
      })

      .addCase(fetchJobSeekerInfos.pending, (state) => {
        state.seekerLoading = true;
      })
      .addCase(fetchJobSeekerInfos.fulfilled, (state,action) => {
        state.seekerLoading = false; 
        if(action?.payload && action?.payload?.length > 0){
          state.jobSeekerInfo =  action?.payload[0]
        }else{}
        
      })
      .addCase(fetchJobSeekerInfos.rejected, (state) => {
        state.seekerLoading = false;
      })


      .addCase(saveEducation.pending, (state) => {
				state.eduLoading = true;
			})
			.addCase(saveEducation.fulfilled, (state,action) => {
				state.eduLoading = false;
        state.singleCreateEducation =  action?.payload
			})
			.addCase(saveEducation.rejected, (state) => {
				state.eduLoading = false;
			})

			.addCase(fetchEducations.pending, (state) => {
				state.eduLoading = true;
			})
			.addCase(fetchEducations.fulfilled, (state, action) => {
				state.eduLoading = false; 
        if(action?.payload && action?.payload?.length > 0){
          state.educations =  action?.payload[0]
        }else{}
       
			})
			.addCase(fetchEducations.rejected, (state) => {
				state.eduLoading = false;
			});

      
 
  },
});

export default applicantSlice.reducer;
export const {
  handleSidenavFullWidth,
  setViewApplicantDetails,
  setEditApplicantDetails,
  setViewJobSeekerInformation,
  setEditJobSeekerInformation,
  setApplicantEditId,
  setviewContact,
  seteditContact,
  setViewModeTable,
  setRefetch,
  setViewSkills,
  setEditSkills,
  setEditEducation,
  cleanupApplicant,
  cleanUpapplicantEditId,
  setSeekerInfoEditId,
  setEducationEditId,
  setViewEducation,
  setViewDisability,
  setEditDisability,
  setTabsActiveKey,
  cleanUpapplicantSingleCreateApplicant
} = applicantSlice.actions;
 