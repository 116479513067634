import Header from './components/signup/Header';

export default function Loader() {
	return (
		<>
			<div className='sticky top-0 w-full z-[1000]'>
				<Header />
			</div>

			<div className='mt-10 px-10 bg-white'>
				<div class='loading'>Loading&#8230;</div>
			</div>
		</>
	);
}
