import React, { useRef, useState } from 'react';
import { Button, Form, Input, Modal, Spin } from 'antd';
import '../clients/client.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import FileUploader from '../../components/FileUploader';
import { saveEmailTemplate } from '../../features/emailTemp/emailTempSlice';
import toast from 'react-hot-toast';

var modules = {
	toolbar: [
		[{ size: ['small', false, 'large', 'huge'] }],
		['bold', 'italic', 'underline', 'strike', 'blockquote'],
		[{ list: 'ordered' }, { list: 'bullet' }],
		['link', 'image'],
		[
			{ list: 'ordered' },
			{ list: 'bullet' },
			{ indent: '-1' },
			{ indent: '+1' },
			{ align: [] },
		],
		[
			{
				color: [
					'#000000',
					'#e60000',
					'#ff9900',
					'#ffff00',
					'#008a00',
					'#0066cc',
					'#9933ff',
					'#ffffff',
					'#facccc',
					'#ffebcc',
					'#ffffcc',
					'#cce8cc',
					'#cce0f5',
					'#ebd6ff',
					'#bbbbbb',
					'#f06666',
					'#ffc266',
					'#ffff66',
					'#66b966',
					'#66a3e0',
					'#c285ff',
					'#888888',
					'#a10000',
					'#b26b00',
					'#b2b200',
					'#006100',
					'#0047b2',
					'#6b24b2',
					'#444444',
					'#5c0000',
					'#663d00',
					'#666600',
					'#003700',
					'#002966',
					'#3d1466',
					'custom-color',
				],
			},
		],
	],
};

var formats = [
	'header',
	'height',
	'bold',
	'italic',
	'underline',
	'strike',
	'blockquote',
	'list',
	'color',
	'bullet',
	'indent',
	'link',
	'image',
	'align',
	'size',
];

const url = process.env.REACT_APP_API_BASE_URL;

const AddEmailtempModal = ({ open, handleCancel, handleFetchListData }) => {
	const [form] = Form.useForm();
	const formRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { user } = useSelector((state) => state.auth);
	const { emailTempLoading } = useSelector((state) => state.emailTemp);

	const [formData, setFormData] = useState({});

	const handleSuccess = (response) => {
		setFormData((prev) => ({
			...prev,
			[response.fileName]: response.jsonData,
		}));
	};

	const handleError = (response) => {
		// Handle error logic
		console.error('File upload failed:', response);
	};

	async function handleCloseModal() {
		await handleCancel();
		await form.resetFields();
	}

	const onFinish = async (values) => {
		// values.emtBody = formData?.emtBody
		values.emtInstId = user?.usrInstId;
		values.emtUsrId = user?.usrId;
		values.emtCreatedBy = user?.usrId;

		const res = await dispatch(saveEmailTemplate(values));
		if (res?.payload?.success) {
			await handleFetchListData();
			await handleCancel();
            await handleCloseModal()
            await toast.success("Email template saved successfull")
		}else{
            toast("We cannot process your request at the moment. Please try again later")
        }
	};

	return (
		<>
			<Modal
				className='modal-btn mod-height mod-footer'
				title='Add Template'
				open={open}
				onOk={onFinish}
				onCancel={handleCloseModal}
				width={850}
			>
				<Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					onFinish={onFinish}
					className=' '
					style={{
						maxWidth: '100%',
					}}
					form={form}
				>
					<Form.Item
						className='w-full'
						name='emtName'
						label='Name'
						rules={[
							{
								required: true,
								message: 'Subject of message is required',
							},
						]}
					>
						<Input className='input' />
					</Form.Item>
					<Form.Item
						className='w-full'
						name='emtSubject'
						label='Subject'
						rules={[
							{
								required: true,
								message: 'Subject of message is required',
							},
						]}
					>
						<Input defaultValue={'{{ Subject }} - '} className='input' />
					</Form.Item>
					<Form.Item
						className='w-full'
						name='emtBody'
						label='Body'
						rules={[
							{
								required: true,
								message: 'Please add body of message',
							},
						]}
					>
						<ReactQuill
							modules={modules}
							formats={formats}
							theme='snow'
							style={{ height: '220px', marginBottom: '5%' }}
						/>
					</Form.Item>

					{/* <FileUploader
							// fileName={formData?.agcCr12File?.split('_').pop()}
							name='emtFile'
							url={url}
							onSuccess={handleSuccess}
							onError={handleError}
						/> */}

					<div className='flex justify-between mt-10'>
						<div className='justify-start'></div>
						<div className='justify-end flex items-center'>
							<button
								type='button'
								onClick={handleCloseModal}
								className='w-[200px] p-2 justify-center items-center rounded-[36px] border-2 border-blueDark flex text-blueDark text-[18px] mr-4'
							>
								Cancel
							</button>

							<button
								type='submit'
								className='w-[200px] p-2 bg-blueDark rounded-[36px] justify-center items-center text-white text-[18px]'
								disabled={emailTempLoading}
							>
								{emailTempLoading ? <Spin /> : 'Save'}
							</button>
						</div>
					</div>
				</Form>
			</Modal>
		</>
	);
};
export default AddEmailtempModal;
