import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"; 
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  notesData: [],
  noteLoading: false, 
};

export const saveApplicantCv = createAsyncThunk(
  "cv/saveApplicantCv",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveApplicantCv.action`, data);
    return res.data?.jsonData;
  }
);

export const fetchApplicantCvs = createAsyncThunk(
  "cv/fetchApplicantCvs",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchApplicantCvs.action?cvUsrId=${data?.cvUsrId}`
    );
    return res.data?.data?.result;
  }
);

export const noteSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },
    
    
    cleanupcv: (state) => {
    
    },
    setCvEditId:(state,action)=>{
      state.cvEditId = action.payload;
    },
    setRefetch:(state)=>{
      state.refetch = !state.refetch;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(saveApplicantCv.pending, (state) => {
        state.noteLoading = true;
      })
      .addCase(saveApplicantCv.fulfilled, (state, action) => {
        state.noteLoading = false;
      })
      .addCase(saveApplicantCv.rejected, (state) => {
        state.noteLoading = false;
      })

      .addCase(fetchApplicantCvs.pending, (state) => {
        state.noteLoading = true;
      })
      .addCase(fetchApplicantCvs.fulfilled, (state, action) => {
        state.noteLoading = false;
        state.notesData = action.payload;
      })
      .addCase(fetchApplicantCvs.rejected, (state) => {
        state.noteLoading = false;
      });
  },
});

export default noteSlice.reducer;
export const {
  handleSidenavFullWidth, 
} = noteSlice.actions;
