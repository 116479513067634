import { Button, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import MaterialIcon from "material-icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setEditContact,
  setViewContact,
} from "../../../../features/client/clientSlice";
import {
  cleanUpapplicantEditId,
  cleanUpapplicantSingleCreateApplicant,
  cleanupApplicant,
  setApplicantEditId,
  setEditApplicantDetails,
  setEditDisability,
  setEditEducation,
  setEditJobSeekerInformation,
  setEditSkills,
  setViewApplicantDetails,
  setViewDisability,
  setViewEducation,
  setViewJobSeekerInformation,
  setViewSkills,
} from "../../../../features/applicant/applicantSlice";
import ApplicantDrawer from "../../../applicants/applicant-tabs/ApplicantDrawer";
import { fetchJobMatching, saveReferal } from "../../../../features/jobs/jobSlice";
import { convertToPercentage } from "../../../../utils";
import toast from "react-hot-toast";

function RecommedationTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = async () => {
    await dispatch(cleanupApplicant());
    await dispatch(cleanUpapplicantEditId());
    setIsModalOpen(true);
  };

  const { applicantRecommendedData } = useSelector(
    (state) => state.recommedation
  );

  const { user } = useSelector((state) => state.auth);
  const {
    jobEditId,
    refetch,
    recommedationCount,
    jobLoading,
    jobMatchingData,
  } = useSelector((state) => state.jobs);
  const { refetchKey } = useSelector((state) => state.cv);

  const [searchText, setSearchText] = useState("");

  const [open, setOpen] = useState(false);
  const [prodd, setProdd] = useState("");

  const [applicantsList, setApplicantsList] = useState(
    applicantRecommendedData
  );

  useEffect(() => {
    setApplicantsList(applicantRecommendedData);
  }, [applicantRecommendedData]);

  const showApplicant = async (id) => {
    await dispatch(setViewApplicantDetails(true));
    await dispatch(setEditApplicantDetails(false));
    await dispatch(setViewJobSeekerInformation(true));
    await dispatch(setEditJobSeekerInformation(false));
    await dispatch(setViewContact(true));
    await dispatch(setEditContact(false));
    await dispatch(setViewSkills(true));
    await dispatch(setEditSkills(false));
    await dispatch(setViewEducation(true));
    await dispatch(setEditEducation(false));
    await dispatch(setViewDisability(true));
    await dispatch(setEditDisability(false));
    await dispatch(cleanUpapplicantSingleCreateApplicant());

    await dispatch(setApplicantEditId(id));
    setOpen(true);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [rowId, setRowId] = useState([]);

  const handleEmployeeToReturns = async (selectedRows) => {
    setRowId(selectedRows);
  };

  const onSelectChange = (keys, rows) => {
    setSelectedRowKeys(keys);
    handleEmployeeToReturns(rows);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchRecommedations(page, size) {
    const res = await dispatch(
      fetchJobMatching({
        jbrJobId: jobEditId,
        limit: size ?? pageSize,
        start: page ?? pageIndex,
      })
    );
  }

  useEffect(() => {
    if (refetchKey == 3) {
      fetchRecommedations();
    }
  }, [refetch, refetchKey]);

  const columns = [
    {
      title: "Full name",
      render: (item) => (
        <>
          <div className="grid grid-cols-2">
            <div className="flex items-center">
              <span className="table-name mx-3">{item?.jbrFullName}</span>
            </div>
            {/* <div className="flex items-center">
              <button className="p-0 mx-4 flex items-end" type="button">
                <MaterialIcon
                  color="#000"
                  icon="visibility"
                  onClick={() => showApplicant(item?.jbrUsrId)}
                />
              </button>
            </div> */}
          </div>
        </>
      ),
    },
    {
      title: "Job Match",
      render: (item) => {
        return (
          <div className="w-[81px] py-[4px] px-[12px] bg-[#02A548] text-white rounded-[3px] flex items-center justify-center">
            {item?.jbrPercentage ? convertToPercentage(item?.jbrPercentage) : 0}{" "}
            %
          </div>
        );
      },
    },
  ];
 
  const filteredData = searchText
    ? jobMatchingData?.filter((item) =>
        item?.jbrFullName?.toLowerCase().includes(searchText.toLowerCase())
      )
    : jobMatchingData;

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

 
  const handleRefer = async () => {
    if (!rowId?.length > 0) {
      toast.error("Select atleast one candidate");
      return;
    }
    await rowId?.forEach((item) => {
      const obj = {
            jbfJobId:item?.jbrJobId,
            jbfUsrId:item?.jbrUsrId, 
      };
      const res = dispatch(saveReferal(obj))
    });

  };
  return (
    <>
      <div className="min-h-[895px] bg-white mt-[1%]">
        <div className="p-[50px] py-[10px]">
          <div className="text-blackest text-[22px] font-bold font-dmSans leading-[33px]">
            Recommended candidates
          </div>
          <div className="w-[618px] text-blackest text-[1rem] font-normal font-dmSans leading-[30px]  mb-[41px]">
            Search candidates who may be suitable for this job
          </div>
          <div className="flex justify-between items-center w-full mt-[31px]">
            <div className="flex items-center w-full ">
              <Input
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search candidate name or keyword"
                className="text-[16px] font-[400] flex-row-reverse"
                prefix={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.2508 3.75C7.50609 3.75 4.47041 6.93997 4.47041 10.875C4.47041 14.81 7.50609 18 11.2508 18C14.9955 18 18.0312 14.81 18.0312 10.875C18.0312 6.93997 14.9955 3.75 11.2508 3.75ZM3.04297 10.875C3.04297 6.11154 6.71773 2.25 11.2508 2.25C15.7838 2.25 19.4586 6.11154 19.4586 10.875C19.4586 15.6385 15.7838 19.5 11.2508 19.5C6.71773 19.5 3.04297 15.6385 3.04297 10.875Z"
                      fill="#333333"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.045 15.913C16.3237 15.6201 16.7756 15.6201 17.0543 15.913L21.3902 20.4693C21.6689 20.7622 21.6689 21.237 21.3902 21.5299C21.1115 21.8228 20.6596 21.8228 20.3809 21.5299L16.045 16.9737C15.7663 16.6808 15.7663 16.2059 16.045 15.913Z"
                      fill="#333333"
                    />
                  </svg>
                }
              />
            </div>
            {/* {rowId && rowId?.length > 0 && (
              <button onClick={handleRefer} className="w-[200px] save-btn">
                Refer
              </button>
            )} */}
          </div>
        </div>

        <div className="mt-[6px] ml-[45px] mr-[10px]">
          {jobMatchingData && jobMatchingData?.length > 0 ? (
            <Table
              rowSelection={rowSelection}
              size={"middle"}
              bordered={true}
              pagination={{
                position: ["bottomCenter"],
                current: pageIndex + 1,
                total: recommedationCount,
                pageSize: pageSize,
                onChange: (page, size) => {
                  setPageIndex(page - 1);
                  setPageSize(size);
                  fetchRecommedations(page - 1, size);
                },
                showSizeChanger: false,
                hideOnSinglePage: true,
              }}
              rowKey={(record) => record?.jbrId}
              columns={tableColumns}
              dataSource={filteredData}
              scroll={{
                x: 1200,
              }}
              loading={jobLoading}
            />
          ) : (
            <div className="justify-center flex flex-col mt-[90px]">
              <div className="flex justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="247"
                  height="211"
                  viewBox="0 0 247 211"
                  fill="none"
                >
                  <g opacity="0.63">
                    <path
                      d="M22.7168 104.5C22.7168 130.344 32.9833 155.129 51.2577 173.404C69.5322 191.678 94.3177 201.945 120.162 201.945C146.006 201.945 170.791 191.678 189.066 173.404C207.34 155.129 217.607 130.344 217.607 104.5C217.607 78.6556 207.34 53.8701 189.066 35.5956C170.791 17.3212 146.006 7.05469 120.162 7.05469C94.3177 7.05469 69.5322 17.3212 51.2577 35.5956C32.9833 53.8701 22.7168 78.6556 22.7168 104.5Z"
                      fill="#D9EDFF"
                    />
                    <path
                      d="M59.6596 47.9375L26.0801 123.677"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M90.4644 173.684L26.0801 123.68"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M190.925 98.7969L157.555 157.352"
                      stroke="#00AED9"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M59.6582 47.9387L146.996 29.3438"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M190.924 98.7952L146.996 29.3438"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M190.925 98.7969L119.462 114.778L26.0801 123.679"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M59.6582 47.9375L90.463 173.68"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M146.998 29.3438L119.463 114.776"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M59.6582 47.9375L157.554 157.349"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M157.552 157.352L90.4609 173.683"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M26.0801 123.678L146.997 29.3438"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2 119.171C2 122.697 2.69452 126.189 4.04391 129.447C5.39329 132.704 7.37112 135.664 9.86446 138.158C12.3578 140.651 15.3178 142.629 18.5755 143.978C21.8332 145.328 25.3248 146.022 28.8509 146.022C32.3771 146.022 35.8686 145.328 39.1264 143.978C42.3841 142.629 45.3441 140.651 47.8374 138.158C50.3308 135.664 52.3086 132.704 53.658 129.447C55.0074 126.189 55.7019 122.697 55.7019 119.171C55.7019 115.645 55.0074 112.154 53.658 108.896C52.3086 105.638 50.3308 102.678 47.8374 100.185C45.3441 97.6914 42.3841 95.7136 39.1264 94.3642C35.8686 93.0148 32.3771 92.3203 28.8509 92.3203C25.3248 92.3203 21.8332 93.0148 18.5755 94.3642C15.3178 95.7136 12.3578 97.6914 9.86446 100.185C7.37112 102.678 5.39329 105.638 4.04391 108.896C2.69452 112.154 2 115.645 2 119.171Z"
                      fill="white"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M43.1888 126.006C43.1835 125.422 42.9766 124.859 42.6032 124.411C42.2297 123.963 41.7127 123.658 41.14 123.548V121.908C41.1629 120.279 40.8618 118.661 40.2541 117.149C39.6465 115.637 38.7444 114.261 37.6003 113.101C36.4562 111.94 35.0929 111.019 33.5897 110.39C32.0864 109.761 30.4731 109.438 28.8436 109.438C27.2141 109.438 25.6009 109.761 24.0976 110.39C22.5944 111.019 21.2311 111.94 20.087 113.101C18.9429 114.261 18.0408 115.637 17.4331 117.149C16.8255 118.661 16.5244 120.279 16.5473 121.908V123.548C15.9499 123.66 15.4135 123.984 15.0376 124.462C14.6616 124.94 14.4718 125.537 14.5034 126.144C14.5349 126.751 14.7857 127.326 15.2091 127.762C15.6325 128.198 16.1998 128.466 16.8055 128.515C17.3828 131.28 18.8947 133.763 21.0871 135.545C23.2794 137.327 26.0184 138.3 28.8436 138.3C31.6688 138.3 34.4079 137.327 36.6002 135.545C38.7926 133.763 40.3045 131.28 40.8817 128.515C41.5095 128.459 42.0937 128.17 42.5203 127.706C42.9469 127.242 43.1852 126.636 43.1888 126.006Z"
                      fill="white"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.6562 127.545C20.6562 127.953 20.8182 128.344 21.1065 128.632C21.3948 128.92 21.7858 129.082 22.1934 129.082C22.6011 129.082 22.9921 128.92 23.2804 128.632C23.5687 128.344 23.7306 127.953 23.7306 127.545C23.7306 127.137 23.5687 126.746 23.2804 126.458C22.9921 126.17 22.6011 126.008 22.1934 126.008C21.7858 126.008 21.3948 126.17 21.1065 126.458C20.8182 126.746 20.6563 127.137 20.6562 127.545Z"
                      fill="#B0D9FF"
                    />
                    <path
                      d="M33.9746 127.545C33.9746 127.747 34.0144 127.947 34.0916 128.133C34.1689 128.32 34.2821 128.489 34.4248 128.632C34.5676 128.775 34.737 128.888 34.9235 128.965C35.11 129.042 35.3099 129.082 35.5118 129.082C35.7137 129.082 35.9136 129.042 36.1001 128.965C36.2866 128.888 36.456 128.775 36.5988 128.632C36.7415 128.489 36.8547 128.32 36.932 128.133C37.0092 127.947 37.049 127.747 37.049 127.545C37.049 127.343 37.0092 127.143 36.932 126.957C36.8547 126.77 36.7415 126.601 36.5988 126.458C36.456 126.315 36.2866 126.202 36.1001 126.125C35.9136 126.048 35.7137 126.008 35.5118 126.008C35.3099 126.008 35.11 126.048 34.9235 126.125C34.737 126.202 34.5676 126.315 34.4248 126.458C34.2821 126.601 34.1689 126.77 34.0916 126.957C34.0144 127.143 33.9746 127.343 33.9746 127.545Z"
                      fill="#B0D9FF"
                    />
                    <path
                      d="M25.7812 131.227C26.691 131.834 27.7604 132.159 28.8544 132.159C29.9484 132.159 31.0179 131.834 31.9276 131.227"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M42.1715 118.837C42.1953 117.072 41.8684 115.321 41.2098 113.684C40.5511 112.047 39.5738 110.557 38.3346 109.301C37.0954 108.045 35.619 107.048 33.9912 106.367C32.3633 105.686 30.6164 105.336 28.852 105.336C27.0875 105.336 25.3406 105.686 23.7128 106.367C22.0849 107.048 20.6085 108.045 19.3693 109.301C18.1301 110.557 17.1528 112.047 16.4942 113.684C15.8355 115.321 15.5086 117.072 15.5325 118.837V121.911C15.5325 122.183 15.6403 122.443 15.8321 122.635C16.024 122.827 16.2843 122.934 16.5556 122.934H18.6069C18.8782 122.934 19.1385 122.827 19.3303 122.635C19.5222 122.443 19.63 122.183 19.63 121.911C19.631 120.691 19.8771 119.484 20.3538 118.36C20.8304 117.237 21.5279 116.221 22.4048 115.373C22.6288 115.153 22.9005 114.987 23.1987 114.889C23.497 114.791 23.8138 114.763 24.1247 114.807C25.4176 114.969 26.6189 115.56 27.5352 116.486C28.7461 117.734 30.3427 118.538 32.0668 118.767C33.791 118.995 35.5418 118.635 37.0361 117.745C37.5504 118.744 37.8805 119.826 38.0106 120.942C38.0399 121.208 38.1663 121.455 38.3657 121.635C38.5652 121.815 38.8238 121.915 39.0922 121.916H41.141C41.4126 121.915 41.6728 121.807 41.8646 121.615C42.0564 121.423 42.1642 121.162 42.1642 120.89L42.1715 118.837Z"
                      fill="white"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M42.1715 118.837C42.1953 117.072 41.8684 115.321 41.2098 113.684C40.5511 112.047 39.5738 110.557 38.3346 109.301C37.0954 108.045 35.619 107.048 33.9912 106.367C32.3633 105.686 30.6164 105.336 28.852 105.336C27.0875 105.336 25.3406 105.686 23.7128 106.367C22.0849 107.048 20.6085 108.045 19.3693 109.301C18.1301 110.557 17.1528 112.047 16.4942 113.684C15.8355 115.321 15.5086 117.072 15.5325 118.837V121.911C15.5325 122.183 15.6403 122.443 15.8321 122.635C16.024 122.827 16.2843 122.934 16.5556 122.934H18.6069C18.8782 122.934 19.1385 122.827 19.3303 122.635C19.5222 122.443 19.63 122.183 19.63 121.911C19.631 120.691 19.8771 119.484 20.3538 118.36C20.8304 117.237 21.5279 116.221 22.4048 115.373C22.6288 115.153 22.9005 114.987 23.1987 114.889C23.497 114.791 23.8138 114.763 24.1247 114.807C25.4176 114.969 26.6189 115.56 27.5352 116.486C28.7461 117.734 30.3427 118.538 32.0668 118.767C33.791 118.995 35.5418 118.635 37.0361 117.745C37.5504 118.744 37.8805 119.826 38.0106 120.942C38.0399 121.208 38.1663 121.455 38.3657 121.635C38.5652 121.815 38.8238 121.915 39.0922 121.916H41.141C41.4126 121.915 41.6728 121.807 41.8646 121.615C42.0564 121.423 42.1642 121.162 42.1642 120.89L42.1715 118.837Z"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M37.0356 117.743C37.1439 117.679 37.2308 117.584 37.2856 117.471C37.3405 117.357 37.3607 117.23 37.3438 117.106C37.3269 116.981 37.2736 116.864 37.1907 116.769C37.1077 116.675 36.9987 116.606 36.8772 116.573C35.2691 116.12 33.8773 115.104 32.9551 113.711"
                      fill="#FFDF3D"
                    />
                    <path
                      d="M37.0356 117.743C37.1439 117.679 37.2308 117.584 37.2856 117.471C37.3405 117.357 37.3607 117.23 37.3438 117.106C37.3269 116.981 37.2736 116.864 37.1907 116.769C37.1077 116.675 36.9987 116.606 36.8772 116.573C35.2691 116.12 33.8773 115.104 32.9551 113.711"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M22.7031 123.443C22.7031 123.645 22.7429 123.845 22.8201 124.032C22.8974 124.218 23.0106 124.388 23.1534 124.53C23.2961 124.673 23.4656 124.786 23.6521 124.864C23.8386 124.941 24.0385 124.981 24.2403 124.981C24.4422 124.981 24.6421 124.941 24.8286 124.864C25.0151 124.786 25.1845 124.673 25.3273 124.53C25.47 124.388 25.5832 124.218 25.6605 124.032C25.7378 123.845 25.7775 123.645 25.7775 123.443C25.7775 123.242 25.7378 123.042 25.6605 122.855C25.5832 122.669 25.47 122.499 25.3273 122.356C25.1845 122.214 25.0151 122.101 24.8286 122.023C24.6421 121.946 24.4422 121.906 24.2403 121.906C24.0385 121.906 23.8386 121.946 23.6521 122.023C23.4656 122.101 23.2961 122.214 23.1534 122.356C23.0106 122.499 22.8974 122.669 22.8201 122.855C22.7429 123.042 22.7031 123.242 22.7031 123.443Z"
                      fill="#020064"
                    />
                    <path
                      d="M31.9238 123.443C31.9238 123.851 32.0858 124.242 32.3741 124.53C32.6623 124.819 33.0533 124.981 33.461 124.981C33.8687 124.981 34.2597 124.819 34.548 124.53C34.8363 124.242 34.9982 123.851 34.9982 123.443C34.9982 123.036 34.8363 122.645 34.548 122.356C34.2597 122.068 33.8687 121.906 33.461 121.906C33.0533 121.906 32.6623 122.068 32.3741 122.356C32.0858 122.645 31.9238 123.036 31.9238 123.443Z"
                      fill="#020064"
                    />
                    <path
                      d="M28.8509 105.513C30.5958 105.51 32.3242 105.851 33.9372 106.517C35.5503 107.182 37.0164 108.159 38.2519 109.391C38.7966 108.537 39.0896 107.547 39.0972 106.534C39.0972 102.573 34.51 99.3594 28.8509 99.3594C23.1917 99.3594 18.6191 102.573 18.6191 106.534C18.6231 107.546 18.9118 108.536 19.4523 109.391C20.6873 108.159 22.1531 107.183 23.7657 106.517C25.3783 105.852 27.1063 105.51 28.8509 105.513Z"
                      fill="white"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M118.801 28.8509C118.801 35.9723 121.63 42.8019 126.665 47.8374C131.701 52.873 138.53 55.7019 145.652 55.7019C152.773 55.7019 159.603 52.873 164.638 47.8374C169.674 42.8019 172.503 35.9723 172.503 28.8509C172.503 21.7296 169.674 14.9 164.638 9.86446C159.603 4.82893 152.773 2 145.652 2C138.53 2 131.701 4.82893 126.665 9.86446C121.63 14.9 118.801 21.7296 118.801 28.8509Z"
                      fill="white"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M163.068 35.8176C163.062 35.1565 162.827 34.5179 162.403 34.0103C161.98 33.5027 161.393 33.1575 160.744 33.0331V31.1744C160.744 27.4793 159.276 23.9356 156.663 21.3228C154.051 18.71 150.507 17.2422 146.812 17.2422C143.117 17.2422 139.573 18.71 136.96 21.3228C134.348 23.9356 132.88 27.4793 132.88 31.1744V33.0331C132.201 33.1583 131.592 33.526 131.164 34.0674C130.737 34.6089 130.521 35.2874 130.557 35.9763C130.592 36.6652 130.877 37.3176 131.359 37.812C131.84 38.3063 132.484 38.6087 133.172 38.663C133.825 41.7985 135.537 44.6135 138.022 46.6343C140.507 48.6551 143.612 49.7583 146.814 49.7583C150.017 49.7583 153.122 48.6551 155.607 46.6343C158.091 44.6135 159.804 41.7985 160.457 38.663C161.167 38.5977 161.829 38.2702 162.311 37.7442C162.794 37.2183 163.064 36.5315 163.068 35.8176Z"
                      fill="white"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M163.068 35.8176C163.062 35.1565 162.827 34.5179 162.403 34.0103C161.98 33.5027 161.393 33.1575 160.744 33.0331V31.1744C160.744 27.4793 159.276 23.9356 156.663 21.3228C154.051 18.71 150.507 17.2422 146.812 17.2422C143.117 17.2422 139.573 18.71 136.96 21.3228C134.348 23.9356 132.88 27.4793 132.88 31.1744V33.0331C132.201 33.1583 131.592 33.526 131.164 34.0674C130.737 34.6089 130.521 35.2874 130.557 35.9763C130.592 36.6652 130.877 37.3176 131.359 37.812C131.84 38.3063 132.484 38.6087 133.172 38.663C133.825 41.7985 135.537 44.6135 138.022 46.6343C140.507 48.6551 143.612 49.7583 146.814 49.7583C150.017 49.7583 153.122 48.6551 155.607 46.6343C158.091 44.6135 159.804 41.7985 160.457 38.663C161.167 38.5977 161.829 38.2702 162.311 37.7442C162.794 37.2183 163.064 36.5315 163.068 35.8176V35.8176Z"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M139.85 32.9137C139.85 33.3757 140.033 33.8187 140.36 34.1454C140.686 34.472 141.129 34.6555 141.591 34.6555C142.053 34.6555 142.496 34.472 142.823 34.1454C143.15 33.8187 143.333 33.3757 143.333 32.9137C143.333 32.4517 143.15 32.0087 142.823 31.682C142.496 31.3554 142.053 31.1719 141.591 31.1719C141.129 31.1719 140.686 31.3554 140.36 31.682C140.033 32.0087 139.85 32.4517 139.85 32.9137Z"
                      fill="#020064"
                    />
                    <path
                      d="M150.295 32.9137C150.295 33.3757 150.478 33.8187 150.805 34.1454C151.132 34.472 151.575 34.6555 152.037 34.6555C152.499 34.6555 152.942 34.472 153.268 34.1454C153.595 33.8187 153.779 33.3757 153.779 32.9137C153.779 32.4517 153.595 32.0087 153.268 31.682C152.942 31.3554 152.499 31.1719 152.037 31.1719C151.575 31.1719 151.132 31.3554 150.805 31.682C150.478 32.0087 150.295 32.4517 150.295 32.9137Z"
                      fill="#020064"
                    />
                    <path
                      d="M137.529 37.5543C137.529 38.0163 137.713 38.4593 138.039 38.786C138.366 39.1126 138.809 39.2962 139.271 39.2962C139.733 39.2962 140.176 39.1126 140.503 38.786C140.829 38.4593 141.013 38.0163 141.013 37.5543C141.013 37.0924 140.829 36.6493 140.503 36.3227C140.176 35.996 139.733 35.8125 139.271 35.8125C138.809 35.8125 138.366 35.996 138.039 36.3227C137.713 36.6493 137.529 37.0924 137.529 37.5543Z"
                      fill="#B0D9FF"
                    />
                    <path
                      d="M152.615 37.5543C152.615 38.0163 152.799 38.4593 153.125 38.786C153.452 39.1126 153.895 39.2962 154.357 39.2962C154.819 39.2962 155.262 39.1126 155.589 38.786C155.915 38.4593 156.099 38.0163 156.099 37.5543C156.099 37.0924 155.915 36.6493 155.589 36.3227C155.262 35.996 154.819 35.8125 154.357 35.8125C153.895 35.8125 153.452 35.996 153.125 36.3227C152.799 36.6493 152.615 37.0924 152.615 37.5543Z"
                      fill="#B0D9FF"
                    />
                    <path
                      d="M143.334 41.7266C144.365 42.4141 145.577 42.781 146.816 42.781C148.056 42.781 149.268 42.4141 150.299 41.7266"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M131.719 20.7188V31.1673C131.719 31.475 131.841 31.7702 132.058 31.9881C132.276 32.206 132.571 32.3287 132.878 32.3293H134.053L138.681 20.7188H131.719Z"
                      fill="white"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M161.909 21.8775C161.908 19.4144 160.929 17.0523 159.187 15.3108C157.445 13.5694 155.083 12.591 152.62 12.591H142.171C141.728 12.5911 141.293 12.464 140.92 12.2248C140.547 11.9856 140.249 11.6443 140.064 11.2414C139.942 10.9714 139.75 10.7388 139.508 10.5675C139.266 10.3963 138.983 10.2925 138.688 10.267H136.714C134.936 10.2326 133.208 10.8573 131.863 12.0209C130.517 13.1844 129.65 14.8045 129.428 16.5692C129.254 18.391 129.805 20.208 130.96 21.6275C132.115 23.0469 133.782 23.955 135.601 24.1553C135.93 25.3878 136.62 26.4934 137.584 27.3291C138.548 28.1647 139.74 28.6919 141.007 28.8424H143.331C143.423 28.8416 143.511 28.8109 143.584 28.7549C143.657 28.699 143.709 28.6208 143.733 28.5324C143.758 28.444 143.752 28.35 143.718 28.2648C143.685 28.1797 143.624 28.1079 143.545 28.0604C142.49 27.4305 141.722 26.4142 141.404 25.2272C141.368 25.1078 141.36 24.9816 141.381 24.8587C141.403 24.7358 141.453 24.6197 141.527 24.5195C141.601 24.4193 141.698 24.338 141.81 24.2819C141.921 24.2259 142.044 24.1967 142.169 24.1967H151.655C151.96 24.1967 152.262 24.2569 152.544 24.374C152.826 24.4911 153.082 24.6626 153.297 24.8788L159.585 31.164H160.744C160.897 31.1644 161.048 31.1345 161.189 31.0762C161.33 31.018 161.459 30.9324 161.567 30.8244C161.675 30.7164 161.76 30.5882 161.818 30.4471C161.877 30.3059 161.907 30.1547 161.906 30.002L161.909 21.8775Z"
                      fill="white"
                    />
                    <path
                      d="M161.909 21.8775C161.908 19.4144 160.929 17.0523 159.187 15.3108C157.445 13.5694 155.083 12.591 152.62 12.591H142.171C141.728 12.5911 141.293 12.464 140.92 12.2248C140.547 11.9856 140.249 11.6443 140.064 11.2414C139.942 10.9714 139.75 10.7388 139.508 10.5675C139.266 10.3963 138.983 10.2925 138.688 10.267H136.714C134.936 10.2326 133.208 10.8573 131.863 12.0209C130.517 13.1844 129.65 14.8045 129.428 16.5692C129.254 18.391 129.805 20.208 130.96 21.6275C132.115 23.0469 133.782 23.955 135.601 24.1553C135.93 25.3878 136.62 26.4934 137.584 27.3291C138.548 28.1647 139.74 28.6919 141.007 28.8424H143.331C143.423 28.8416 143.511 28.8109 143.584 28.7549C143.657 28.699 143.709 28.6208 143.733 28.5324C143.758 28.444 143.752 28.35 143.718 28.2648C143.685 28.1797 143.624 28.1079 143.545 28.0604C142.49 27.4305 141.722 26.4142 141.404 25.2272C141.368 25.1078 141.36 24.9816 141.381 24.8587C141.403 24.7358 141.453 24.6197 141.527 24.5195C141.601 24.4193 141.698 24.338 141.81 24.2819C141.921 24.2259 142.044 24.1967 142.169 24.1967H151.655C151.96 24.1967 152.262 24.2569 152.544 24.374C152.826 24.4911 153.082 24.6626 153.297 24.8788L159.585 31.164H160.744C160.897 31.1644 161.048 31.1345 161.189 31.0762C161.33 31.018 161.459 30.9324 161.567 30.8244C161.675 30.7164 161.76 30.5882 161.818 30.4471C161.877 30.3059 161.907 30.1547 161.906 30.002L161.909 21.8775Z"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M181.297 155.867L191.045 146.119L208.104 163.178L198.355 172.926L181.297 155.867Z"
                      fill="white"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M241.242 205.613L240.777 206.079C238.894 207.947 236.349 208.995 233.696 208.995C231.044 208.995 228.499 207.947 226.616 206.079L195.835 175.286C195.213 174.658 194.863 173.81 194.863 172.925C194.863 172.041 195.213 171.193 195.835 170.565L205.741 160.659C206.368 160.037 207.216 159.688 208.1 159.688C208.984 159.688 209.832 160.037 210.459 160.659L241.242 191.44C242.177 192.368 242.919 193.472 243.425 194.689C243.931 195.905 244.192 197.209 244.192 198.527C244.192 199.844 243.931 201.148 243.425 202.365C242.919 203.581 242.177 204.685 241.242 205.613Z"
                      fill="#B0D9FF"
                    />
                    <path
                      d="M241.242 205.613L240.777 206.079C238.894 207.947 236.349 208.995 233.696 208.995C231.044 208.995 228.499 207.947 226.616 206.079L195.835 175.286C195.213 174.658 194.863 173.81 194.863 172.925C194.863 172.041 195.213 171.193 195.835 170.565L205.741 160.659C206.368 160.037 207.216 159.688 208.1 159.688C208.984 159.688 209.832 160.037 210.459 160.659L241.242 191.44C242.177 192.368 242.919 193.472 243.425 194.689C243.931 195.905 244.192 197.209 244.192 198.527C244.192 199.844 243.931 201.148 243.425 202.365C242.919 203.581 242.177 204.685 241.242 205.613Z"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M107.954 146.301C115.111 157.997 126.621 166.37 139.951 169.579C153.282 172.789 167.342 170.571 179.037 163.414C190.733 156.257 199.106 144.747 202.315 131.416C205.524 118.086 203.307 104.026 196.15 92.3308C188.993 80.6353 177.483 72.2618 164.152 69.0526C150.822 65.8433 136.762 68.0611 125.067 75.218C113.371 82.3749 104.998 93.8847 101.789 107.215C98.5792 120.546 100.797 134.606 107.954 146.301Z"
                      fill="white"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M181.297 90.0677C187.081 95.8519 191.019 103.221 192.615 111.244C194.211 119.266 193.391 127.582 190.261 135.139C187.131 142.696 181.83 149.155 175.028 153.699C168.227 158.244 160.231 160.669 152.051 160.669C143.872 160.669 135.876 158.244 129.074 153.699C122.273 149.155 116.972 142.696 113.842 135.139C110.711 127.582 109.892 119.266 111.488 111.244C113.083 103.221 117.022 95.8519 122.806 90.0677C126.646 86.2269 131.206 83.1802 136.224 81.1016C141.242 79.023 146.62 77.9531 152.051 77.9531C157.483 77.9531 162.861 79.023 167.879 81.1016C172.897 83.1802 177.457 86.2269 181.297 90.0677Z"
                      fill="#B0D9FF"
                    />
                    <path
                      d="M151.054 139.133C146.302 139.16 141.637 140.414 137.512 142.775C133.387 145.136 129.942 148.523 127.512 152.607C134.381 157.665 142.645 160.477 151.174 160.658C159.703 160.839 168.079 158.381 175.157 153.62C172.815 149.261 169.339 145.614 165.097 143.064C160.855 140.514 156.004 139.156 151.054 139.133Z"
                      fill="white"
                    />
                    <path
                      d="M151.054 139.133C146.302 139.16 141.637 140.414 137.512 142.775C133.387 145.136 129.942 148.523 127.512 152.607C134.381 157.665 142.645 160.477 151.174 160.658C159.703 160.839 168.079 158.381 175.157 153.62C172.815 149.261 169.339 145.614 165.097 143.064C160.855 140.514 156.004 139.156 151.054 139.133V139.133Z"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M159.226 140.463C158.787 140.323 158.345 140.198 157.898 140.087C157.382 139.957 156.924 139.658 156.598 139.238C156.271 138.818 156.094 138.3 156.095 137.768V129.539H146.407V137.676C146.407 138.211 146.227 138.73 145.896 139.151C145.565 139.572 145.102 139.869 144.582 139.995C144.022 140.131 143.464 140.282 142.913 140.455L142.857 140.528C143.001 142.607 143.928 144.554 145.451 145.976C146.975 147.398 148.981 148.189 151.065 148.189C153.149 148.189 155.155 147.398 156.678 145.976C158.202 144.554 159.129 142.607 159.272 140.528L159.226 140.463Z"
                      fill="white"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M172.872 109.145C172.872 114.96 170.562 120.536 166.45 124.648C162.338 128.76 156.761 131.07 150.947 131.07C145.132 131.07 139.555 128.76 135.443 124.648C131.331 120.536 129.021 114.96 129.021 109.145C129.021 97.0226 135.209 87.512 141.504 85.7044C142.029 85.5536 142.584 85.5467 143.112 85.6842C143.64 85.8218 144.121 86.0989 144.505 86.4864C151.322 93.3416 172.872 84.7981 172.872 109.145Z"
                      fill="white"
                    />
                    <path
                      d="M172.872 109.145C172.872 114.96 170.562 120.536 166.45 124.648C162.338 128.76 156.761 131.07 150.947 131.07C145.132 131.07 139.555 128.76 135.443 124.648C131.331 120.536 129.021 114.96 129.021 109.145C129.021 97.0226 135.209 87.512 141.504 85.7044C142.029 85.5536 142.584 85.5467 143.112 85.6842C143.64 85.8218 144.121 86.0989 144.505 86.4864C151.322 93.3416 172.872 84.7981 172.872 109.145Z"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M169.235 114.628L165.554 116.455L162.899 107.163C162.651 106.271 162.07 105.507 161.277 105.029C160.484 104.551 159.538 104.394 158.633 104.591C153.574 105.796 148.303 105.796 143.244 104.591C142.339 104.394 141.393 104.552 140.6 105.03C139.807 105.508 139.227 106.271 138.978 107.163L136.323 116.455L132.698 114.628C127.482 114.628 127.826 123.763 133.609 123.763H133.626C134.771 127.468 137.072 130.709 140.193 133.011C143.313 135.313 147.089 136.555 150.967 136.555C154.844 136.555 158.62 135.313 161.741 133.011C164.861 130.709 167.162 127.468 168.307 123.763C174.098 123.763 174.448 114.628 169.235 114.628Z"
                      fill="white"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M143.119 115.088C143.119 115.661 143.347 116.21 143.751 116.614C144.156 117.019 144.705 117.247 145.278 117.247C145.85 117.247 146.399 117.019 146.804 116.614C147.209 116.21 147.436 115.661 147.436 115.088C147.436 114.516 147.209 113.967 146.804 113.562C146.399 113.157 145.85 112.93 145.278 112.93C144.705 112.93 144.156 113.157 143.751 113.562C143.347 113.967 143.119 114.516 143.119 115.088Z"
                      fill="#020064"
                    />
                    <path
                      d="M154.529 115.088C154.529 115.661 154.757 116.21 155.161 116.614C155.566 117.019 156.115 117.247 156.688 117.247C157.26 117.247 157.809 117.019 158.214 116.614C158.619 116.21 158.846 115.661 158.846 115.088C158.846 114.516 158.619 113.967 158.214 113.562C157.809 113.157 157.26 112.93 156.688 112.93C156.115 112.93 155.566 113.157 155.161 113.562C154.757 113.967 154.529 114.516 154.529 115.088Z"
                      fill="#020064"
                    />
                    <path
                      d="M141.849 120.108C141.849 120.47 141.742 120.823 141.541 121.124C141.341 121.424 141.055 121.658 140.721 121.797C140.387 121.935 140.02 121.971 139.665 121.9C139.311 121.829 138.985 121.655 138.73 121.399C138.474 121.144 138.3 120.818 138.23 120.463C138.16 120.109 138.196 119.741 138.335 119.407C138.474 119.074 138.708 118.788 139.009 118.588C139.31 118.388 139.663 118.281 140.025 118.281C140.265 118.281 140.502 118.329 140.724 118.42C140.945 118.512 141.146 118.647 141.316 118.817C141.485 118.986 141.62 119.188 141.711 119.409C141.803 119.631 141.85 119.869 141.849 120.108Z"
                      fill="#B0D9FF"
                    />
                    <path
                      d="M163.773 120.108C163.773 120.47 163.666 120.823 163.465 121.123C163.265 121.424 162.979 121.658 162.645 121.796C162.312 121.935 161.944 121.971 161.59 121.9C161.235 121.83 160.91 121.656 160.654 121.4C160.399 121.145 160.225 120.819 160.154 120.465C160.084 120.11 160.12 119.743 160.258 119.409C160.397 119.075 160.631 118.79 160.931 118.589C161.232 118.388 161.585 118.281 161.946 118.281C162.431 118.281 162.896 118.474 163.238 118.816C163.581 119.159 163.773 119.624 163.773 120.108Z"
                      fill="#B0D9FF"
                    />
                    <path
                      d="M146.422 126.039C147.775 126.94 149.363 127.42 150.988 127.42C152.613 127.42 154.202 126.94 155.555 126.039"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M181.297 90.0677C187.081 95.8519 191.019 103.221 192.615 111.244C194.211 119.266 193.391 127.582 190.261 135.139C187.131 142.696 181.83 149.155 175.028 153.699C168.227 158.244 160.231 160.669 152.051 160.669C143.872 160.669 135.876 158.244 129.074 153.699C122.273 149.155 116.972 142.696 113.842 135.139C110.711 127.582 109.892 119.266 111.488 111.244C113.083 103.221 117.022 95.8519 122.806 90.0677C126.646 86.2269 131.206 83.1802 136.224 81.1016C141.242 79.023 146.62 77.9531 152.051 77.9531C157.483 77.9531 162.861 79.023 167.879 81.1016C172.897 83.1802 177.457 86.2269 181.297 90.0677V90.0677Z"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M72.5957 169.66C72.5957 174.191 74.3954 178.536 77.5989 181.739C80.8024 184.943 85.1473 186.742 89.6778 186.742C94.2083 186.742 98.5531 184.943 101.757 181.739C104.96 178.536 106.76 174.191 106.76 169.66C106.76 165.13 104.96 160.785 101.757 157.581C98.5531 154.378 94.2083 152.578 89.6778 152.578C85.1473 152.578 80.8024 154.378 77.5989 157.581C74.3954 160.785 72.5957 165.13 72.5957 169.66Z"
                      fill="white"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M84.1816 169.259C84.1816 170.717 84.7607 172.115 85.7913 173.146C86.822 174.176 88.2199 174.755 89.6775 174.755C91.1351 174.755 92.533 174.176 93.5637 173.146C94.5944 172.115 95.1734 170.717 95.1734 169.259V166.512C95.1734 165.054 94.5944 163.656 93.5637 162.625C92.533 161.595 91.1351 161.016 89.6775 161.016C88.2199 161.016 86.822 161.595 85.7913 162.625C84.7607 163.656 84.1816 165.054 84.1816 166.512V169.259Z"
                      fill="white"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M101.099 182.359C97.9639 185.176 93.898 186.734 89.6832 186.734C85.4685 186.734 81.4026 185.176 78.2676 182.359C79.2056 180.107 80.7887 178.183 82.8175 176.829C84.8464 175.476 87.2304 174.752 89.6694 174.75C92.1085 174.748 94.4937 175.467 96.5249 176.818C98.5561 178.168 100.142 180.089 101.084 182.339"
                      fill="#B0D9FF"
                    />
                    <path
                      d="M101.099 182.359C97.9639 185.176 93.898 186.734 89.6832 186.734C85.4685 186.734 81.4026 185.176 78.2676 182.359C79.2056 180.107 80.7887 178.183 82.8175 176.829C84.8464 175.476 87.2304 174.752 89.6694 174.75C92.1085 174.748 94.4937 175.467 96.5249 176.818C98.5561 178.168 100.142 180.089 101.084 182.339"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M84.2285 165.802C84.3728 164.694 84.8515 163.656 85.6008 162.827C86.3501 161.998 87.3344 161.416 88.4225 161.161C89.5105 160.906 90.6505 160.988 91.6905 161.397C92.7305 161.806 93.6211 162.523 94.2434 163.451C92.9429 164.712 91.3246 165.597 89.561 166.011C87.7973 166.425 85.9543 166.353 84.2285 165.802Z"
                      fill="#B0D9FF"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M43.248 46.4258C43.248 50.9563 45.0478 55.3012 48.2513 58.5047C51.4548 61.7082 55.7997 63.5079 60.3301 63.5079C64.8606 63.5079 69.2055 61.7082 72.409 58.5047C75.6125 55.3012 77.4122 50.9563 77.4122 46.4258C77.4122 41.8954 75.6125 37.5505 72.409 34.347C69.2055 31.1435 64.8606 29.3438 60.3301 29.3438C55.7997 29.3438 51.4548 31.1435 48.2513 34.347C45.0478 37.5505 43.248 41.8954 43.248 46.4258Z"
                      fill="white"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M54.834 46.029C54.8643 47.4669 55.4567 48.8357 56.4843 49.8419C57.5119 50.8481 58.8929 51.4116 60.3311 51.4116C61.7693 51.4116 63.1503 50.8481 64.1779 49.8419C65.2055 48.8357 65.7979 47.4669 65.8282 46.029V43.281C65.7979 41.8431 65.2055 40.4744 64.1779 39.4682C63.1503 38.462 61.7693 37.8984 60.3311 37.8984C58.8929 37.8984 57.5119 38.462 56.4843 39.4682C55.4567 40.4744 54.8643 41.8431 54.834 43.281V46.029Z"
                      fill="white"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M71.7513 59.132C68.6155 61.9475 64.5499 63.5049 60.3356 63.5049C56.1213 63.5049 52.0557 61.9475 48.9199 59.132C49.858 56.8805 51.441 54.9567 53.4699 53.6029C55.4987 52.249 57.8827 51.5255 60.3218 51.5234C62.7609 51.5214 65.1461 52.2408 67.1772 53.5912C69.2084 54.9416 70.7948 56.8626 71.7366 59.1125"
                      fill="#B0D9FF"
                    />
                    <path
                      d="M71.7513 59.132C68.6155 61.9475 64.5499 63.5049 60.3356 63.5049C56.1213 63.5049 52.0557 61.9475 48.9199 59.132C49.858 56.8805 51.441 54.9567 53.4699 53.6029C55.4987 52.249 57.8827 51.5255 60.3218 51.5234C62.7609 51.5214 65.1461 52.2408 67.1773 53.5912C69.2084 54.9416 70.7948 56.8626 71.7366 59.1125"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M54.8809 42.5777C55.0243 41.4689 55.5027 40.4302 56.2521 39.6005C57.0015 38.7707 57.9862 38.1894 59.0748 37.9341C60.1633 37.6789 61.3038 37.7619 62.344 38.172C63.3841 38.5821 64.2743 39.2998 64.8958 40.2293C63.5949 41.4899 61.9765 42.374 60.2129 42.7876C58.4493 43.2011 56.6065 43.1286 54.8809 42.5777Z"
                      fill="#B0D9FF"
                      stroke="#020064"
                      stroke-width="4"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </div>
              <div className="text-[#ACACAC] text-[1rem] font-normal font-dmSans leading-[30px] flex justify-center mt-[58px]">
                Matching candidates will appear here.
              </div>
            </div>
          )}
        </div>
      </div>

      <ApplicantDrawer open={open} setOpen={setOpen} />
    </>
  );
}

export default RecommedationTable;
