import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Input, InputNumber, Modal, Select, Spin } from "antd";
import "../../../../clients/client.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { saveJobApplication } from "../../../../../features/applicantJob/applicantJobSlice";
import { passportData } from "../../../../../Gender";

const { TextArea } = Input;
const InterviewScoreModal = ({
  isModalOpen,
  setIsModalOpen,
  applicantEdit,
  prodd,
  fetchFunction,
  fetchCount 
}) => {
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  const { jobApplicationLoading } = useSelector((state) => state.applicantJob);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formdata, setFormData] = useState(applicantEdit);


  const onChange = async (e) => {
    if (e.target.value < 0 || e.target.value > 100 ) {
      return;
    }
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onChangeOther = (value, fieldName) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

 

  const onFinish = async () => { 
    const res = await dispatch(
      saveJobApplication({
        japId: applicantEdit?.japId,
        japProgressStatus:prodd,
        jobUpdatedBy: user?.usrId,
        japInterviewScore: formdata?.japInterviewScore,
        japInterviewRemarks: formdata?.japInterviewRemarks,
      })
    );
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
      form.resetFields();
      setFormData('');
      fetchFunction();
      fetchCount();
      handleCancel();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  useEffect(() => {
    setFormData(applicantEdit);
  }, [applicantEdit]);

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer"
        title={
          "Add Interview Score for candidate" + " " + applicantEdit?.japFullName
        }
        open={isModalOpen}
        onCancel={handleCancel}
        width={850}
      >
        <div className="">
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            className=""
            style={{
              maxWidth: "100%",
            }}
            form={form}
          >
            <Form.Item
              label="Score (%)"
              rules={[
                {
                  required: true,
                  message: "Please add score",
                },
              ]}
            >
              <InputNumber
                required
                name="japInterviewScore"
                value={formdata?.japInterviewScore}
                min={0}
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value?.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) => onChangeOther(value, "japInterviewScore")}
                className="input"
              />
              {/* <Input
                required
                name="japInterviewScore"
                onChange={onChange}
                value={formdata?.japInterviewScore} 
                max={100}
                min={0} 
                className="rounded-[4px] h-[52px] w-full border border-black"
              /> */}
            </Form.Item>

            <Form.Item
              label="Interview Comment"
              rules={[
                {
                  required: true,
                  message: "Please add interview comments",
                },
              ]}
            >
              <TextArea
                required
                name="japInterviewRemarks"
                onChange={onChange}
                value={formdata?.japInterviewRemarks}
                rows={6}
                className="rounded-[4px] h-[52px] w-full border border-black"
              />
            </Form.Item>

       

            <div className="flex justify-between py-[50px]">
              <div className="justify-start"></div>
              <div className="justify-end flex items-center">
                <Button
                  key="back"
                  onClick={handleCancel}
                  className="w-[138px] h-12 px-7 py-4 rounded-[56px] border-none justify-center items-center gap-2.5 inline-flex
                     text-blueDark text-[1rem] font-medium font-dmSans leading-normal"
                >
                  Cancel
                </Button>

                <button
                  type="submit"
                  key="submit"
                  className="w-[239px] h-12 px-7 py-4 bg-blueDark rounded-[56px] shadow justify-center items-center gap-2.5 inline-flex
                 text-blue-50 text-[1rem] font-medium font-dmSans leading-normal"
                >
                  {jobApplicationLoading ? <Spin /> : "Save"}
                </button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};
export default InterviewScoreModal;
