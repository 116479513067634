import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Form, Input, Modal, Select, Spin, message } from "antd";
import "../../clients/demand-modal/demand-modal.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  fetchJobs,
  saveJob,
  setRefetchKey,
} from "../../../features/jobs/jobSlice";

const AddJobModal = ({ isModalOpen, setIsModalOpen }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobLoading } = useSelector((state) => state.jobs);

   
  const [data, setdata] = useState({});

  const onFinish = async (data) => {
    const res = await dispatch(
      saveJob({
        jobName: data?.jobName,
        jobInstId: user?.usrInstId,
        jobInstType:'EMPLOYER',
        jobCreatedBy:user?.usrId
      })
    );
    if (res?.payload?.success) {
      toast.success("Job name added successfully,proceed to fill the other details");
      await dispatch(
        fetchJobs({
          jobInstId: user?.usrInstId,
          jobCntId: "",
        })
      );
      // navigate("/job-tabs");
      navigate("/job-view");
      form.resetFields();
      handleOk();
      dispatch(setRefetchKey("1"));
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  return (
    <>
      <Modal
        className="mod mod-height"
        title="Add Job"
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <div className="text-black1 text-[22px] font-[400] font-dmSans leading-9 mb-[35px]">
          Fill in the following details to add a job vacancy
        </div>

        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="grid grid-cols-1">
            <Form.Item
              className="job-vacancy-modal"
              name="jobName"
              label="Job Vacancy Name "
              rules={[
                {
                  required: true,
                  message: "Please add job vacancy name ",
                },
              ]}
            >
              <Input className="input" />
            </Form.Item>
          </div>

           

          <div className="flex justify-between mt-[10rem] mb-[40px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center">
              <Button
                key="back"
                onClick={handleCancel}
                className="w-[149px] h-[50px] p-2 justify-center items-center rounded-[36px] border-2 border-none 
                flex !text-blueDark text-[18px] font-dmSans"
              >
                Cancel
              </Button>
              ,
              <button
                key="submit"
                type="submit"
                className="w-[190px] h-[50px] px-10 py-3 bg-blueDark rounded-[28px] justify-center items-center
                 text-white text-[18px] font-dmSans"
              >
                {jobLoading ? <Spin /> : "Continue"}
              </button>
              ,
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default AddJobModal;
