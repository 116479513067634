import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  message,
} from "antd";
import "../../clients/demand-modal/demand-modal.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import {
  fetchDemandLetters,
  saveDemandLetter,
} from "../../../features/demand/demandLetterSlice";
import { disabledDate, disabledPastDate, formatPath } from "../../../utils";
import {
  fetchJobs,
  fetchSingleJob,
  saveJob,
} from "../../../features/jobs/jobSlice";

const CreateAdvertModal = ({ isModalOpen, setIsModalOpen }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { jobLoading, jobEditId, singleCreateJob, singleJob } = useSelector(
    (state) => state.jobs
  );
  const [receivedId, setReceivedId] = useState(
    jobEditId ? jobEditId : singleCreateJob?.jobId
  );

  useEffect(() => {
    setReceivedId(jobEditId ? jobEditId : singleCreateJob?.jobId);
  }, [jobEditId, singleCreateJob?.jobId]);

  function closeModal() {
    toast.dismiss();
  }
  const [data, setdata] = useState({});

  const onFinish = async (data) => {
    const requiredColumns = [
      "jobPosition",
      "jobCntName",
      "jobStartPeriod",
      "jobQualification",
      "jobCity",
      "jobCountry",
      "jobEmploymentTerms",
      "jobWorkStyle",
      "jobGrossPay",
      "jobCategory",
    ];

    const hasEmptyFields = requiredColumns?.some(
      (column) => !singleJob[column]
    );

    if (hasEmptyFields) {
      toast.error("Please fill all required fields before creating an advert");
      return;
    }

    const res = await dispatch(
      saveJob({
        jobId: receivedId,
        jobAdvertStartDate: data?.jobAdvertStartDate,
        jobAdvertEndDate: data?.jobAdvertEndDate,
        jobAdvertStatus: "APPROVED",
        jobInstId: user?.usrInstId,
      })
    );
    if (res?.payload?.success) {
      await toast.success("Job advert created successfully");
      await dispatch(
        fetchSingleJob({
          jobId: receivedId,
        })
      );
      handleOk();
    } else {
      toast.error("Error creating job advert, please try again");
    }
  };

  useEffect(() => {
    dispatch(
      fetchSingleJob({
        jobId: receivedId,
      })
    );
  }, []);

  return (
    <>
      <Modal
        className="modal-btn mod-height-demand mod-footer demand-body close-con-demand demand-title"
        title="Create advert "
        open={isModalOpen}
        onOk={onFinish}
        onCancel={handleCancel}
        width={850}
      >
        <div className="w-[716px] text-black1 text-2xl font-normal font-dmSans leading-9">
          Create an advert to announce this job vacancy. The advert will go live
          once you post it.
        </div>
        <div className="text-black1 mt-[58px] text-[21px] font-normal font-dmSans leading-loose">
          How long would you like to run the ad?
        </div>

        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={onFinish}
          className=" "
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <div className="grid grid-cols-2 gap-x-5">
            <Form.Item
              label="From"
              name="jobAdvertStartDate"
              rules={[
                {
                  required: true,
                  message: "From date is required",
                },
              ]}
              className="mr-6 w-full"
            >
              <DatePicker
                style={{
                  width: "100%",
                  height: "44px",
                }}
                placeholder="Select Date"
                className="mr-3"
                format={"DD-MM-YYYY"}
                disabledDate={disabledPastDate}
              />
            </Form.Item>

            <Form.Item
              label="To"
              name="jobAdvertEndDate"
              rules={[
                {
                  required: true,
                  message: "To date is required",
                },
              ]}
              className="mr-6 w-full"
            >
              <DatePicker
                style={{
                  width: "100%",
                  height: "44px",
                }}
                placeholder="Select Date"
                className="mr-3"
                format={"DD-MM-YYYY"}
                disabledDate={disabledPastDate}
              />
            </Form.Item>
          </div>
          <div className="flex justify-between mt-[10rem] mb-[40px]">
            <div className="justify-start"></div>
            <div className="justify-end flex items-center">
              <Button
                key="back"
                onClick={handleCancel}
                className="w-[138px] h-12 px-7 py-4 rounded-[56px] border-none justify-center items-center gap-2.5 inline-flex
                        text-blueDark text-[1rem] font-medium font-dmSans leading-normal"
              >
                Cancel
              </Button>
              ,
              <button
                key="submit"
                type="submit"
                className="w-[239px] h-12 px-[16px] py-[28px] bg-blueDark rounded-[56px] shadow justify-center items-center gap-2.5 inline-flex
                           text-blue-50 text-[1rem] font-medium font-dmSans leading-normal"
              >
                {jobLoading ? <Spin /> : "Create advert"}
              </button>
              ,
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default CreateAdvertModal;
