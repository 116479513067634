import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	fetchReceivedInboxs,
	fetchSentInboxThreads,
	saveInbox,
	setCurrentView,
	setInboxObj,
} from '../../../features/inbox/inboxSlice';
import { formatImgPath } from '../../../utils';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import toast from 'react-hot-toast';

export default function SentMessages() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { inboxObj, inbLoading, sentInboxThreads } = useSelector(
		(state) => state.inbox,
	);
	const { user } = useSelector((state) => state.auth);
	const { agencyObj } = useSelector((state) => state.agency);

	const [dataList, setdataList] = useState(sentInboxThreads);

	async function handleFetchListData() {
		await dispatch(fetchSentInboxThreads(inboxObj?.inbId));
	}

	async function handleArchive() {
		const objCopy = {
			...inboxObj,
			inbArchived: true,
			inbArchivedStatus: 'ARCHIVED',
			inbStatus: 'ARCHIVED',
		};
		delete objCopy.inbUpdatedTime;

		const res = await dispatch(saveInbox(objCopy));
		if (res?.payload?.success) {
			toast.success('Message archived');
			handleGoBack();
		} else {
			toast(res?.payload?.messages?.message);
		}
	}

	async function handleDelete() {
		const objCopy = {
			...inboxObj,
			inbStatus: 'DELETED',
		};
		delete objCopy.inbUpdatedTime;

		const res = await dispatch(saveInbox(objCopy));
		if (res?.payload?.success) {
			toast.success('Message deleted');
			handleGoBack();
		} else {
			toast(res?.payload?.messages?.message);
		}
	}

	async function handleStarred() {
		const objCopy = {
			...inboxObj,
			inbStarred: !inboxObj?.inbStarred,
			inbStarredStatus: 'STARRED',
		};
		delete objCopy.inbUpdatedTime;

		const res = await dispatch(saveInbox(objCopy));
		if (res?.payload?.success) {
			const resp = await dispatch(fetchReceivedInboxs(user?.usrId));
			if (resp?.payload?.length) {
				const updObj = resp?.payload?.find(
					(item) => item?.inbId === objCopy?.inbId,
				);
				await dispatch(setInboxObj(updObj));
			}
		} else {
			toast(res?.payload?.messages?.message);
		}
	}

	useEffect(() => {}, [dataList, inboxObj]);

	useEffect(() => {
		setdataList(sentInboxThreads);
	}, [sentInboxThreads]);

	useEffect(() => {
		handleFetchListData();
	}, []);

	async function handleGoBack() {
		await dispatch(setCurrentView(2));
		await navigate(-1);
	}

	return (
		<>
			<div className='w-[100%] h-auto bg-white p-10 flex justify-between items-center'>
				<span className='font-bold text-[26px] text-[#141414]'>
					{inboxObj?.inbSubject}
				</span>

				<div className='flex items-center'>
					<button
						type='button'
						onClick={handleGoBack}
						className='bg-transparent flex items-center'
					>
						<div className='flex justify-center items-center rounded-full w-[40px] h-[40px] p-2 bg-[#EDF8FF]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='28'
								height='24'
								viewBox='0 0 28 24'
								fill='none'
							>
								<path
									d='M17.7692 18L11.0469 12L17.7692 6'
									stroke='#147CBC'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
							</svg>
						</div>
						<span className='ml-5 font-bold text-[22px] text-[#222222]'>
							Go Back
						</span>
					</button>
				</div>
			</div>

			<div className='w-full mt-3 bg-white h-full rounded-[8px] pb-[200px]'>
				<div className='bg-gray-100 text-blueDark h-[60px] flex items-center justify-between'>
					<div className='flex items-center px-3'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='40'
							height='40'
							viewBox='0 0 24 24'
							fill='none'
							onClick={handleGoBack}
							className='inb-view-icon'
						>
							<path
								d='M8.29 11.29C8.19896 11.3851 8.12759 11.4972 8.08 11.62C7.97998 11.8635 7.97998 12.1365 8.08 12.38C8.12759 12.5028 8.19896 12.6149 8.29 12.71L11.29 15.71C11.4783 15.8983 11.7337 16.0041 12 16.0041C12.2663 16.0041 12.5217 15.8983 12.71 15.71C12.8983 15.5217 13.0041 15.2663 13.0041 15C13.0041 14.7337 12.8983 14.4783 12.71 14.29L11.41 13H15C15.2652 13 15.5196 12.8946 15.7071 12.7071C15.8946 12.5196 16 12.2652 16 12C16 11.7348 15.8946 11.4804 15.7071 11.2929C15.5196 11.1054 15.2652 11 15 11H11.41L12.71 9.71C12.8037 9.61704 12.8781 9.50644 12.9289 9.38458C12.9797 9.26272 13.0058 9.13201 13.0058 9C13.0058 8.86799 12.9797 8.73728 12.9289 8.61542C12.8781 8.49356 12.8037 8.38296 12.71 8.29C12.617 8.19627 12.5064 8.12188 12.3846 8.07111C12.2627 8.02034 12.132 7.9942 12 7.9942C11.868 7.9942 11.7373 8.02034 11.6154 8.07111C11.4936 8.12188 11.383 8.19627 11.29 8.29L8.29 11.29ZM2 12C2 13.9778 2.58649 15.9112 3.6853 17.5557C4.78412 19.2002 6.3459 20.4819 8.17317 21.2388C10.0004 21.9957 12.0111 22.1937 13.9509 21.8079C15.8907 21.422 17.6725 20.4696 19.0711 19.0711C20.4696 17.6725 21.422 15.8907 21.8079 13.9509C22.1937 12.0111 21.9957 10.0004 21.2388 8.17317C20.4819 6.3459 19.2002 4.78412 17.5557 3.6853C15.9112 2.58649 13.9778 2 12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12ZM20 12C20 13.5823 19.5308 15.129 18.6518 16.4446C17.7727 17.7602 16.5233 18.7855 15.0615 19.391C13.5997 19.9965 11.9911 20.155 10.4393 19.8463C8.88743 19.5376 7.46197 18.7757 6.34315 17.6569C5.22433 16.538 4.4624 15.1126 4.15372 13.5607C3.84504 12.0089 4.00346 10.4003 4.60896 8.93853C5.21447 7.47672 6.23984 6.22729 7.55544 5.34824C8.87103 4.46919 10.4177 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12Z'
								fill='#0873b9'
							/>
						</svg>

						<div className='px-10 flex items-center ml-10'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='32'
								height='32'
								viewBox='0 0 24 24'
								fill='none'
								onClick={handleDelete}
								className='inb-view-icon'
							>
								<path
									d='M10.0002 13H14.0002C14.2654 13 14.5197 12.8946 14.7073 12.7071C14.8948 12.5196 15.0002 12.2652 15.0002 12C15.0002 11.7348 14.8948 11.4804 14.7073 11.2929C14.5197 11.1054 14.2654 11 14.0002 11H10.0002C9.73495 11 9.4806 11.1054 9.29306 11.2929C9.10553 11.4804 9.00017 11.7348 9.00017 12C9.00017 12.2652 9.10553 12.5196 9.29306 12.7071C9.4806 12.8946 9.73495 13 10.0002 13ZM19.0002 3H5.00017C4.29663 3.00546 3.61738 3.25801 3.08118 3.71352C2.54497 4.16902 2.18591 4.79849 2.06677 5.49189C1.94763 6.18529 2.07598 6.89851 2.4294 7.50687C2.78281 8.11523 3.3388 8.58002 4.00017 8.82V18C4.00017 18.7956 4.31624 19.5587 4.87885 20.1213C5.44146 20.6839 6.20452 21 7.00017 21H17.0002C17.7958 21 18.5589 20.6839 19.1215 20.1213C19.6841 19.5587 20.0002 18.7956 20.0002 18V8.82C20.6615 8.58002 21.2175 8.11523 21.5709 7.50687C21.9244 6.89851 22.0527 6.18529 21.9336 5.49189C21.8144 4.79849 21.4554 4.16902 20.9192 3.71352C20.383 3.25801 19.7037 3.00546 19.0002 3ZM18.0002 18C18.0002 18.2652 17.8948 18.5196 17.7073 18.7071C17.5197 18.8946 17.2654 19 17.0002 19H7.00017C6.73495 19 6.4806 18.8946 6.29306 18.7071C6.10553 18.5196 6.00017 18.2652 6.00017 18V9H18.0002V18ZM19.0002 7H5.00017C4.73495 7 4.4806 6.89464 4.29306 6.70711C4.10553 6.51957 4.00017 6.26522 4.00017 6C4.00017 5.73478 4.10553 5.48043 4.29306 5.29289C4.4806 5.10536 4.73495 5 5.00017 5H19.0002C19.2654 5 19.5197 5.10536 19.7073 5.29289C19.8948 5.48043 20.0002 5.73478 20.0002 6C20.0002 6.26522 19.8948 6.51957 19.7073 6.70711C19.5197 6.89464 19.2654 7 19.0002 7Z'
									fill='#0873b9'
								/>
							</svg>

							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='32'
								height='32'
								viewBox='0 0 24 24'
								fill='none'
								onClick={handleArchive}
								className='inb-view-icon mx-5'
							>
								<path
									d='M10 18C10.2652 18 10.5196 17.8946 10.7071 17.7071C10.8946 17.5196 11 17.2652 11 17V11C11 10.7348 10.8946 10.4804 10.7071 10.2929C10.5196 10.1054 10.2652 10 10 10C9.73478 10 9.48043 10.1054 9.29289 10.2929C9.10536 10.4804 9 10.7348 9 11V17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM20 6H16V5C16 4.20435 15.6839 3.44129 15.1213 2.87868C14.5587 2.31607 13.7956 2 13 2H11C10.2044 2 9.44129 2.31607 8.87868 2.87868C8.31607 3.44129 8 4.20435 8 5V6H4C3.73478 6 3.48043 6.10536 3.29289 6.29289C3.10536 6.48043 3 6.73478 3 7C3 7.26522 3.10536 7.51957 3.29289 7.70711C3.48043 7.89464 3.73478 8 4 8H5V19C5 19.7956 5.31607 20.5587 5.87868 21.1213C6.44129 21.6839 7.20435 22 8 22H16C16.7956 22 17.5587 21.6839 18.1213 21.1213C18.6839 20.5587 19 19.7956 19 19V8H20C20.2652 8 20.5196 7.89464 20.7071 7.70711C20.8946 7.51957 21 7.26522 21 7C21 6.73478 20.8946 6.48043 20.7071 6.29289C20.5196 6.10536 20.2652 6 20 6ZM10 5C10 4.73478 10.1054 4.48043 10.2929 4.29289C10.4804 4.10536 10.7348 4 11 4H13C13.2652 4 13.5196 4.10536 13.7071 4.29289C13.8946 4.48043 14 4.73478 14 5V6H10V5ZM17 19C17 19.2652 16.8946 19.5196 16.7071 19.7071C16.5196 19.8946 16.2652 20 16 20H8C7.73478 20 7.48043 19.8946 7.29289 19.7071C7.10536 19.5196 7 19.2652 7 19V8H17V19ZM14 18C14.2652 18 14.5196 17.8946 14.7071 17.7071C14.8946 17.5196 15 17.2652 15 17V11C15 10.7348 14.8946 10.4804 14.7071 10.2929C14.5196 10.1054 14.2652 10 14 10C13.7348 10 13.4804 10.1054 13.2929 10.2929C13.1054 10.4804 13 10.7348 13 11V17C13 17.2652 13.1054 17.5196 13.2929 17.7071C13.4804 17.8946 13.7348 18 14 18Z'
									fill='#0873b9'
								/>
							</svg>

							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='32'
								height='32'
								viewBox='0 0 24 24'
								fill='none'
								onClick={handleStarred}
								className='inb-view-icon'
							>
								<path
									d='M22.0014 9.67161C21.9383 9.4887 21.8234 9.32804 21.6708 9.20911C21.5181 9.09019 21.3342 9.01811 21.1414 9.00161L15.4514 8.17161L12.9014 3.00161C12.8196 2.83254 12.6917 2.68995 12.5325 2.59018C12.3734 2.49041 12.1893 2.4375 12.0014 2.4375C11.8136 2.4375 11.6295 2.49041 11.4704 2.59018C11.3112 2.68995 11.1833 2.83254 11.1014 3.00161L8.55144 8.16161L2.86144 9.00161C2.67636 9.02792 2.50237 9.10557 2.35919 9.22577C2.21602 9.34596 2.1094 9.50388 2.05144 9.68161C1.99839 9.85528 1.99363 10.0401 2.03767 10.2163C2.08171 10.3925 2.1729 10.5533 2.30144 10.6816L6.43144 14.6816L5.43144 20.3616C5.39574 20.5491 5.41444 20.7429 5.48531 20.9201C5.55619 21.0973 5.6763 21.2505 5.83144 21.3616C5.98266 21.4697 6.16102 21.5335 6.34649 21.5459C6.53196 21.5583 6.71721 21.5187 6.88144 21.4316L12.0014 18.7616L17.1014 21.4416C17.2418 21.5208 17.4003 21.5621 17.5614 21.5616C17.7733 21.5624 17.9799 21.4958 18.1514 21.3716C18.3066 21.2605 18.4267 21.1073 18.4976 20.9301C18.5684 20.7529 18.5871 20.5591 18.5514 20.3716L17.5514 14.6916L21.6814 10.6916C21.8258 10.5693 21.9325 10.4085 21.9892 10.228C22.0458 10.0474 22.0501 9.85448 22.0014 9.67161ZM15.8514 13.6716C15.7342 13.785 15.6464 13.9255 15.5959 14.0806C15.5453 14.2357 15.5335 14.4009 15.5614 14.5616L16.2814 18.7516L12.5214 16.7516C12.3768 16.6746 12.2154 16.6343 12.0514 16.6343C11.8875 16.6343 11.7261 16.6746 11.5814 16.7516L7.82144 18.7516L8.54144 14.5616C8.56938 14.4009 8.55756 14.2357 8.50701 14.0806C8.45646 13.9255 8.36872 13.785 8.25144 13.6716L5.25144 10.6716L9.46144 10.0616C9.62344 10.0391 9.77744 9.97715 9.90994 9.88126C10.0424 9.78537 10.1494 9.65845 10.2214 9.51161L12.0014 5.70161L13.8814 9.52161C13.9535 9.66845 14.0604 9.79537 14.1929 9.89126C14.3254 9.98715 14.4794 10.0491 14.6414 10.0716L18.8514 10.6816L15.8514 13.6716Z'
									fill='#0873b9'
								/>
							</svg>
						</div>
					</div>

					{/* <div className='px-10'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='40'
							height='40'
							viewBox='0 0 24 24'
							fill='none'
							className='inb-view-icon'
						>
							<path
								d='M17.0028 9.49628H7.41283L8.71283 8.20628C8.90113 8.01798 9.00692 7.76258 9.00692 7.49628C9.00692 7.22998 8.90113 6.97458 8.71283 6.78628C8.52452 6.59798 8.26913 6.49219 8.00283 6.49219C7.73652 6.49219 7.48113 6.59798 7.29283 6.78628L4.29283 9.78628C4.20179 9.88138 4.13042 9.99353 4.08283 10.1163C3.98281 10.3597 3.98281 10.6328 4.08283 10.8763C4.13042 10.999 4.20179 11.1112 4.29283 11.2063L7.29283 14.2063C7.38579 14.3 7.49639 14.3744 7.61825 14.4252C7.74011 14.4759 7.87081 14.5021 8.00283 14.5021C8.13484 14.5021 8.26554 14.4759 8.3874 14.4252C8.50926 14.3744 8.61986 14.3 8.71283 14.2063C8.80655 14.1133 8.88095 14.0027 8.93172 13.8809C8.98249 13.759 9.00862 13.6283 9.00862 13.4963C9.00862 13.3643 8.98249 13.2336 8.93172 13.1117C8.88095 12.9898 8.80655 12.8792 8.71283 12.7863L7.41283 11.4963H17.0028C17.268 11.4963 17.5224 11.6016 17.7099 11.7892C17.8975 11.9767 18.0028 12.2311 18.0028 12.4963V16.4963C18.0028 16.7615 18.1082 17.0159 18.2957 17.2034C18.4833 17.3909 18.7376 17.4963 19.0028 17.4963C19.268 17.4963 19.5224 17.3909 19.7099 17.2034C19.8975 17.0159 20.0028 16.7615 20.0028 16.4963V12.4963C20.0028 11.7006 19.6868 10.9376 19.1241 10.375C18.5615 9.81235 17.7985 9.49628 17.0028 9.49628Z'
								fill='#0873b9'
							/>
						</svg>
					</div> */}
				</div>

				<div className='p-10 h-full'>
					<div className='w-full flex flex-col border-b-2 border-[#ECECEC] pb-5 mb-5'>
						<div className='flex justify-start'>
							{inboxObj?.inbInstIcon || inboxObj?.inbSenderIcon ? (
								<>
									<img
										className='read-img'
										src={
											inboxObj?.inbInstIcon
												? formatImgPath(inboxObj?.inbInstIcon)
												: formatImgPath(inboxObj?.inbSenderIcon)
										}
										alt='Avatar'
									/>
								</>
							) : (
								<UserOutlined className='read-icon' />
							)}

							<div className='flex flex-col ml-3 w-full'>
								{inboxObj?.institutionName ? (
									<>
										<div className='w-full'>
											<span className='text-[21px] text-blackest font-bold w-full'>
												{inboxObj?.institutionName}
											</span>
											{' <>'}
											<span className='read w-full !text-[16px] ml-1'>
												{inboxObj?.senderName}
											</span>
										</div>
									</>
								) : (
									<>
										<span className='read w-full'>
											{inboxObj?.institutionName ?? inboxObj?.senderName}
										</span>
									</>
								)}
								<span className='text-[13px] font-bold text-[#9D9797]'>
									{moment(inboxObj?.inbUpdatedDate).format('LL')}{' '}
									{moment(inboxObj?.inbUpdatedTime).format('hh:mm A')}
								</span>
							</div>
						</div>

						{
							<div
								className='mt-3 ml-[4%] text-black1'
								dangerouslySetInnerHTML={{
									__html: inboxObj?.inbBody,
								}}
							/>
						}
					</div>

					{dataList?.length ? (
						<div className='flex items-center mb-5'>
							<span className='text-blueDark font-thin text-[1rem] mr-2'>
								Threads
							</span>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
							>
								<path
									d='M17.0036 9.1736C16.8162 8.98735 16.5628 8.88281 16.2986 8.88281C16.0344 8.88281 15.781 8.98735 15.5936 9.1736L12.0036 12.7136L8.46361 9.1736C8.27625 8.98735 8.0228 8.88281 7.75861 8.88281C7.49442 8.88281 7.24097 8.98735 7.05361 9.1736C6.95988 9.26657 6.88549 9.37717 6.83472 9.49903C6.78395 9.62089 6.75781 9.75159 6.75781 9.88361C6.75781 10.0156 6.78395 10.1463 6.83472 10.2682C6.88549 10.39 6.95988 10.5006 7.05361 10.5936L11.2936 14.8336C11.3866 14.9273 11.4972 15.0017 11.619 15.0525C11.7409 15.1033 11.8716 15.1294 12.0036 15.1294C12.1356 15.1294 12.2663 15.1033 12.3882 15.0525C12.51 15.0017 12.6206 14.9273 12.7136 14.8336L17.0036 10.5936C17.0973 10.5006 17.1717 10.39 17.2225 10.2682C17.2733 10.1463 17.2994 10.0156 17.2994 9.88361C17.2994 9.75159 17.2733 9.62089 17.2225 9.49903C17.1717 9.37717 17.0973 9.26657 17.0036 9.1736Z'
									fill='#147cbc'
								/>
							</svg>
						</div>
					) : null}

					<div className='ml-[5%]'>
						{dataList?.length
							? dataList?.map((item) => {
									return (
										<>
											<div className='w-full flex flex-col border-b-2 border-[#ECECEC] pb-5 mb-5'>
												<div className='flex justify-start w-full'>
													{item?.inbInstIcon || item?.inbSenderIcon ? (
														<>
															<img
																className='read-img'
																src={
																	item?.inbInstIcon
																		? formatImgPath(item?.inbInstIcon)
																		: formatImgPath(item?.inbSenderIcon)
																}
																alt='Avatar'
															/>
														</>
													) : (
														<UserOutlined className='read-icon' />
													)}

													<div className='flex flex-col ml-3 w-full'>
														{item?.institutionName ? (
															<>
																<div className='w-full'>
																	<span className='text-[21px] text-blackest font-bold w-full'>
																		{item?.institutionName}
																	</span>
																	{' <>'}
																	<span className='read w-full !text-[16px] ml-1'>
																		{item?.senderName}
																	</span>
																</div>
															</>
														) : (
															<>
																<span className='read w-full'>
																	{item?.institutionName ?? item?.senderName}
																</span>
															</>
														)}
														<span className='text-[13px] font-bold text-[#9D9797]'>
															{moment(item?.inbUpdatedDate).format('LL')}{' '}
															{moment(item?.inbUpdatedTime).format('hh:mm A')}
														</span>
													</div>
												</div>

												{
													<div
														className='mt-3 ml-[4%] text-black1'
														dangerouslySetInnerHTML={{
															__html: item?.inbBody,
														}}
													/>
												}
											</div>
										</>
									);
							  })
							: null}
					</div>
				</div>
			</div>
		</>
	);
}
