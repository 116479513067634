import React, { useEffect, useRef, useState } from "react";
import backArrow from "../../../assets/Back.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Breadcrumb,
  Collapse,
  DatePicker,
  Form,
  Input,
  Select,
  Spin,
  theme,
} from "antd";
import toast from "react-hot-toast";
import {
  fetchEmployees,
  saveEmployee,
} from "../../../features/myEmployees/myEmployeesSlice";
import dayjs from "dayjs";
import EmployeeTable from "../employee_files/EmployeeTable";
import AddEmployeeToFolderModal from "./modals/AddEmployeeToFolderModal";
import InsideHeader from "../../../components/InsideHeader";
import container from "../../../assets/Container-employees.png";

const { TextArea } = Input;

function FolderEmployeesAdd() {
  const { token } = theme.useToken();
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { employeeLoading } = useSelector((state) => state.employee);
  const { folderObj, fldLoading } = useSelector((state) => state.folder);
  const { user } = useSelector((state) => state.auth);

  const [data, setdata] = useState(folderObj);
  const [open, setopen] = useState(false);

  function handleCancel() {
    navigate(-1);
  }

  function handleOpen() {
    setopen(true);
  }

  function handleGoBack() {
    navigate(-1);
  }

  const panelStyle = {
    marginBottom: 24,
    background: "#fff",
    borderRadius: token.borderRadiusLG,
    border: "1px solid #147CBC",
  };

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <div className="flex flex-row w-[100%] gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <circle cx="12.5" cy="12.5" r="12" fill="white" stroke="#147CBC" />
            <circle cx="12.7904" cy="12.7943" r="2.32558" fill="#147CBC" />
          </svg>

          <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
            Folder Info
          </div>
        </div>
      ),
      children: (
        <div className="p-10">
          <span className="accordion-sub-title">Update Folder Details</span>
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            style={{
              maxWidth: "100%",
              marginTop: "30px",
            }}
            form={form}
            initialValues={{
              ...data,
              fldCreatedTime: dayjs(data?.fldCreatedTime),
            }}
          >
            <div className="grid grid-cols-2 gap-x-10 w-full mb-5">
              <Form.Item name="fldName" label="Folder Name">
                <Input className="input" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="fldCreatedTime"
                label="Created Date"
              >
                <DatePicker disabled className="input" />
              </Form.Item>
            </div>

            <div className="w-full">
              <Form.Item name="fldDesc" label="Description">
                <TextArea
                  rows={4}
                  placeholder="Brief description about this folder"
                  className="input-textarea"
                />
              </Form.Item>
            </div>

            <div className="flex items-center justify-end mt-14 mb-14 w-full">
              <button
                disabled={fldLoading}
                className="w-[132px] h-[50px] px-10 py-3 bg-blueDark rounded-[28px] justify-center items-center text-white text-[18px]"
                type="submit"
              >
                {fldLoading ? <Spin /> : "Save"}
              </button>
            </div>
          </Form>
        </div>
      ),
      showArrow: true,
      style: panelStyle,
    },
    // {
    // 	key: '2',
    // 	label: (
    // 		<div className='flex flex-row w-[100%] gap-2'>
    // 			<svg
    // 				xmlns='http://www.w3.org/2000/svg'
    // 				width='25'
    // 				height='25'
    // 				viewBox='0 0 25 25'
    // 				fill='none'
    // 			>
    // 				<circle cx='12.5' cy='12.5' r='12' fill='white' stroke='#147CBC' />
    // 				<circle cx='12.7904' cy='12.7943' r='2.32558' fill='#147CBC' />
    // 			</svg>

    // 			<div className='text-blueDark text-[1rem] font-medium font-dmSans leading-tight'>
    // 				Employees
    // 			</div>
    // 		</div>
    // 	),
    // 	children: (
    // 		<div className=''>
    // 			<EmployeeTable hide={true} fldId={data?.fldId} />
    // 		</div>
    // 	),
    // 	showArrow: true,
    // 	style: panelStyle,
    // },
  ];

  const onFinish = async (values) => {
    values.emplContractCommencement = values.emplContractCommencement.$d;
    const res = await dispatch(
      saveEmployee({
        ...data,
        ...values,
      })
    );
    if (res?.payload?.emplId) {
      toast.success("Employee details saved successful");
      form.resetFields();
      navigate("/employees");
    } else {
      toast.error(
        "We cannot process your request at the moment please try again later"
      );
    }
  };

  async function handleFetchListData() {
    await dispatch(
      fetchEmployees({
        emplInstId: user?.usrInstId,
      })
    );
  }

  useEffect(() => {}, [data]);

  useEffect(() => {
    setdata(folderObj);
  }, [folderObj]);

  useEffect(() => {
    handleFetchListData();
  }, []);

  return (
    <>
      <InsideHeader
        title="Employees"
        subtitle="Manage employees within your organization"
        back={false}
      />

      <div className="mt-[2.5rem] px-10 font-dmSans text-[18px]">
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer"
                  onClick={() => navigate("/employees")}
                >
                  Employees
                </span>
              ),
            },
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer"
                  onClick={() => navigate(`/employees/folder/${data?.fldName}`)}
                >
                  {folderObj?.fldName}
                </span>
              ),
            },

            {
              title: (
                <span
                  className="text-blueDark font-dmSans"
                  onClick={() => navigate(`/employees/folder/${data?.fldName}`)}
                >
                  Add employees
                </span>
              ),
            },
          ]}
        />
      </div>

      <div className="px-10 mt-[51px]">
        <h3 className="dash-title font-[700] text-[24px] text-black1 leading-[32.4px]">
          {folderObj?.fldName}
        </h3>
        <div className="mt-[15px] text-black2 text-[24px] font-[700] dash-title  leading-[32.4px]">
          Add employees
        </div>

        <div className="mt-[15px] text-black2 text-[20px] font-[500]   leading-[24px]">
          Choose employees to add to this group
        </div>
      </div>

      <div className="  mt-[13px] mr-3">
        <EmployeeTable hide={true} showBtn={true} data={data} />
      </div>
    </>
  );
}

export default FolderEmployeesAdd;
