import { Form, Input, Radio, Select, Tag } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJobs } from '../../../features/jobs/jobSlice';
import { fetchShortlistedCandidates } from '../../../features/applicantJob/applicantJobSlice';

export default function SelectReciepient({
	handlegetCandidates,
	handleCurrentView,
	handleDontSendToNea,
	handleSendToNea,
}) {
	const dispatch = useDispatch();

	const { jobData, jobLoading } = useSelector((state) => state.jobs);
	const { user } = useSelector((state) => state.auth);
	const { agencyObj } = useSelector((state) => state.agency);

	const [candidates, setcandidates] = useState([]);
	const [value, setValue] = useState(1);
	const [btnDisabled, setbtnDisabled] = useState(true);

	async function handleFetchJobs() {
		await dispatch(
			fetchJobs({
				jobInstId: user?.usrInstId,
				jobCntId: '',
			}),
		);
	}

	async function handleJobChange(val) {
		const res = await dispatch(fetchShortlistedCandidates(val));
		if (res?.payload?.success) {
			setcandidates(res?.payload?.data?.result);
		} else {
			setcandidates([]);
		}
	}

	function handleCandidatesChange(val) {
		handlegetCandidates(val);
		setbtnDisabled(false);
	}

	function handleNext() {
		handleCurrentView(1);
	}

	const onChange = (e) => {
		console.log('radio checked', e.target.value);
		setValue(e.target.value);
		if (e.target.value === 1) {
			handleDontSendToNea()
		}else{
			handleSendToNea()
		}
	};

	const selectList = [];
	const subSelectList = [];

	const filterOption = (input, option) =>
		(option?.label ?? '').toLowerCase().includes(input.toLowerCase());

	jobData.forEach((item) => {
		selectList.push({
			label: item?.jobName,
			value: item?.jobId,
		});
	});

	candidates.forEach((item) => {
		subSelectList.push({
			label: item?.japFullName,
			value: item?.japUsrId,
		});
	});

	const tagRender = (props) => {
		const { label, value, closable, onClose } = props;
		const onPreventMouseDown = (event) => {
			event.preventDefault();
			event.stopPropagation();
		};
		return (
			<Tag
				color={value}
				onMouseDown={onPreventMouseDown}
				closable={closable}
				onClose={onClose}
				style={{
					marginRight: 3,
				}}
			>
				{label}
			</Tag>
		);
	};

	useEffect(() => {}, [candidates, jobData]);

	useEffect(() => {
		handleFetchJobs();
	}, []);

	console.log(agencyObj);

	return (
		<>
			<div className='flex justify-start flex-col mt-10'>
				<span className='tb-title mb-10'>Selct your receipient(s)</span>

				<Radio.Group
					className='flex justify-start items-center w-full'
					onChange={onChange}
					value={value}
				>
					<Radio value={1}>
						<span>Shortlisted Candidates</span>
					</Radio>
					<Radio className='ml-10' value={2}>
						National Employment Authority Office (NEA)
					</Radio>
				</Radio.Group>

				{value === 1 ? (
					<>
						<div className='mt-10 w-full'>
							<div className='grid grid-cols-2 gap-5'>
								<Form.Item label='Jobs'>
									<Select
										placeholder='Choose job to get shortlisted candidates'
										loading={jobLoading}
										style={{
											width: '100%',
											minHeight: '44px',
											height: '44px',
											maxHeight: '100%',
										}}
										onChange={handleJobChange}
										options={selectList}
									/>
								</Form.Item>

								<Form.Item label='Candidates'>
									<Select
										className='inbox-select'
										placeholder='Choose candidate(s)'
										loading={jobLoading}
										mode='multiple'
										tagRender={tagRender}
										filterOption={filterOption}
										maxTagCount={1}
										style={{
											width: '100%',
											minHeight: '44px',
											height: '44px',
											maxHeight: '100%',
										}}
										onChange={handleCandidatesChange}
										options={subSelectList}
									/>
								</Form.Item>
							</div>

							<div className='flex justify-end mt-20'>
								<button
									disabled={btnDisabled}
									onClick={handleNext}
									className='bg-blueDark rounded-[35px] text-white w-[300px] p-3'
									type='button'
								>
									Next
								</button>
							</div>
						</div>
					</>
				) : null}

				{value === 2 ? (
					<>
						<div className='mt-10 w-full'>
							<Form.Item label='Nea Office'>
								<Input
									value={agencyObj?.instNearNeaOffice}
									readOnly
									className='input'
								/>
							</Form.Item>

							<div className='flex justify-end mt-20'>
								<button
									onClick={handleNext}
									className='bg-blueDark rounded-[35px] text-white w-[300px] p-3'
									type='button'
								>
									Next
								</button>
							</div>
						</div>
					</>
				) : null}
			</div>
		</>
	);
}
