import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveAgencyInfo } from '../../../features/application/applicationSlice';
import FileUploader from '../../../components/FileUploader';

const url = process.env.REACT_APP_API_BASE_URL;

const CRTwelve = ({ handleAgencyData }) => {
	const dispatch = useDispatch();
	const { crTwelve } = useSelector((state) => state.application);
	const { user } = useSelector((state) => state.auth);

	const [formData, setFormData] = useState({
		agcInstId: user?.usrInstId,
		agcType: 'CR_TWELVE',
		...crTwelve,
	});

	const handleSuccess = (response) => {
		setFormData((prev) => ({
			...prev,
			[response.fileName]: response.jsonData,
		}));
	};

	const handleError = (response) => {
		// Handle error logic
		console.error('File upload failed:', response);
	};

	const onChange = (e) => {
		setFormData((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}));
	};

	const submitData = async () => {
		await dispatch(saveAgencyInfo(formData));
		await handleAgencyData();
	};

	useEffect(() => {
		setFormData(crTwelve);
	}, [crTwelve]);

	useEffect(() => {
		submitData();
	}, [formData?.agcFile]);

	return (
		<div className='border-2 border-[#ECECEC] w-full p-5 flex items-center justify-between mb-5'>
			<span className='adm-doc-name'>CR 12</span>
			{crTwelve?.agcFile ? (
				<>
					<a
						href={crTwelve?.agcFile}
						target='_blank'
						rel='noreferrer'
						className='text-blueDark text-[18px] font-bold'
						type='button'
					>
						View
					</a>
				</>
			) : (
				<FileUploader
					fileName={formData?.agcFile?.split('_').pop()}
					name='agcFile'
					url={url}
					blueBtn={true}
					onSuccess={handleSuccess}
					onError={handleError}
				/>
			)}
		</div>
	);
};

export default CRTwelve;
