import React, { useEffect, useState } from "react";
import emptyContainer from "../../assets/Container.png";
import { useSelector } from "react-redux";
import { Spin } from "antd";

const url = process.env.REACT_APP_API_BASE_URL;
function Certificate() {
  const { agencyObj } = useSelector((state) => state.agency);
  const { user } = useSelector((state) => state.auth);
  const { refetchKey } = useSelector((state) => state.employee);

  const [loading, setloading] = useState(true);

  const [iframeSrc, setIframeSrc] = useState(""); 

  useEffect(() => { 
    if (refetchKey == 4){ 
    setIframeSrc(
      `https://backoffice.kazi254.ke/nea/directReport.action?&INST_ID=${agencyObj?.instId}&usrId=${user?.usrId}&reportName=CERTIFICATE_OF_COMPLIANCE&PDF_FORMAT=inline&P_FORMAT=PDF`
    );
  }
  }, [refetchKey]);
 
  return (
    <>
      <iframe
        onLoad={() => setloading(false)}
        title="Generated Returns Certificate"
        width={"100%"}
        height={"700px"} 
        src={iframeSrc}
      ></iframe>

      {loading ? (
        <>
          <button
            className="bg-blueDark text-white w-[300px] p-3 flex justify-center mt-10"
            disabled
            type="button"
          >
            <Spin />
            <span className="ml-2">
              Loading returns certificate... Please wait
            </span>
          </button>
        </>
      ) : null}
    </>
  );
}

export default Certificate;
