import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	expLoading: false,
	experiences: [],
	expCV: [],
	expData: [],
	activeExp: {},
};

export const fetchExperiences = createAsyncThunk(
	'expSlice/fetchExperiences',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/nea/fetchExperiences.action?expUsrId=${usrObj?.usrId}`,
		);
		return res.data;
	},
);

export const fetchCVExperiences = createAsyncThunk(
	'expSlice/fetchCVExperiences',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/nea/fetchExperiences.action?expUsrId=${usrObj?.usrId}&expCvlId=${usrObj?.expCvlId}`,
		);
		return res.data;
	},
);

export const saveExperience = createAsyncThunk(
	'expSlice/saveExperience',
	async (eduObj) => {
		const res = await axios.post(`${url}/nea/saveExperience.action`, eduObj);
		return res.data;
	},
);

export const expSlice = createSlice({
	name: 'exp',
	initialState,
	reducers: {
		setExpData: (state, action) => {
			state.expData = action.payload;
		},
		setActiveExp: (state, action) => {
			state.activeExp = action.payload;
		},
		clearExpData: (state) => {
			state.expData = {};
		},
		clearActiveExp: (state) => {
			state.activeExp = {};
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(saveExperience.pending, (state) => {
				state.expLoading = true;
			})
			.addCase(saveExperience.fulfilled, (state) => {
				state.expLoading = false;
			})
			.addCase(saveExperience.rejected, (state) => {
				state.expLoading = false;
			})

			.addCase(fetchExperiences.pending, (state) => {
				state.expLoading = true;
			})
			.addCase(fetchExperiences.fulfilled, (state, action) => {
				state.expLoading = false;
				if (action?.payload?.success) {
					state.experiences = action?.payload?.data?.result;
				} else {
					state.experiences = [];
				}
			})
			.addCase(fetchExperiences.rejected, (state) => {
				state.expLoading = false;
			})

			.addCase(fetchCVExperiences.pending, (state) => {
				state.expLoading = true;
			})
			.addCase(fetchCVExperiences.fulfilled, (state, action) => {
				state.expLoading = false;
				if (action?.payload?.success) {
					state.expCV = action?.payload?.data?.result;
				} else {
					state.expCV = [];
				}
			})
			.addCase(fetchCVExperiences.rejected, (state) => {
				state.expLoading = false;
			});
	},
});

export default expSlice.reducer;
export const { setExpData, setActiveExp, clearActiveExp, clearExpData } =
	expSlice.actions;
