import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  clients: {},
  clientData:[],
  clientLoading: false,
  viewBasic: false,
  editBasic: false,

  viewLocation: false,
  editLocation: false,

  viewContact: false,
  editContact: false,

  contactDetails:[],
  contactPersons:[],
  contactLoading: false,
  contactId:'',

  clientEditId:'',

  contactAdding:false,

  tabsActiveKey:"1",
  refetch: false,
  refetchKey:'',
};

export const saveClient = createAsyncThunk(
  "client/saveClient",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveClient.action`, data);
    return res.data?.jsonData;
  }
);

export const fetchClients = createAsyncThunk(
  "client/fetchClients",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchClients.action?cntInstId=${data?.cntInstId}`
    );
    return res.data?.data?.result;
  }
);


export const saveContact = createAsyncThunk(
  "client/saveContact",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveContact.action`, data);
    return res.data?.jsonData;
  }
);

export const fetchContacts = createAsyncThunk(
  "client/fetchContacts",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchContacts.action?conUsrId=${data?.conUsrId}&conType=CLIENT`
    );
    return res.data?.data?.result;
  }
);


export const saveContactPerson = createAsyncThunk(
  "client/saveContactPerson",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveContactPerson.action`, data);
    return res.data?.jsonData;
  }
);

export const fetchContactPersons = createAsyncThunk(
  "client/fetchContactPersons",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchContactPersons.action?conUsrId=${data?.conUsrId}`
    );
    return res.data?.data?.result;
  }
);


 
export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },
    setViewBasic: (state, action) => {
      state.viewBasic = action.payload;
    },
    setEditBasic: (state, action) => {
      state.editBasic = action.payload;
    },

    setViewLocation: (state, action) => {
      state.viewLocation = action.payload;
    },
    setEditLocation: (state, action) => {
      state.editLocation = action.payload;
    },

    setViewContact: (state, action) => {
      state.viewContact = action.payload;
    },
    setEditContact: (state, action) => {
      state.editContact = action.payload;
    },
    setContactId:(state, action) => {
      state.contactId = action.payload;
    },
    setClientEditId:(state, action) => {
      state.clientEditId = action.payload;
    },
    setContactAdding:(state, action) => {
      state.contactAdding = action.payload;
    },

    setRefetch: (state) => {
      state.refetch = !state.refetch;
    },
    setClientTabActiveKey: (state,action) => {
      state.tabsActiveKey = action.payload;
    }, 
    setRefetchKey: (state, action) => {
      state.refetchKey = action.payload;
    },

    
    

  
    cleanUpEditBasic: (state) => {
      state.viewBasic = initialState.viewBasic;
      state.editBasic = initialState.editBasic;

      state.viewLocation = initialState.viewLocation;
      state.editLocation = initialState.editLocation;

      state.viewContact = initialState.viewContact;
      state.editContact = initialState.editContact;
      state.contactId = initialState.contactId;

      state.contactAdding = initialState.contactAdding;

     
    },

    cleanUpClients:(state)=>{
      state.clients= initialState.clients;
    },
    cleanUpEditId:(state)=>{
      state.clientEditId = initialState.clientEditId;
    },

    
  },
  extraReducers: (builder) => {
    builder

      .addCase(saveClient.pending, (state) => {
        state.clientLoading = true;
      })
      .addCase(saveClient.fulfilled, (state, action) => {
        state.clientLoading = false;
        state.clients = action.payload;
      })
      .addCase(saveClient.rejected, (state) => {
        state.clientLoading = false;
      })

      .addCase(fetchClients.pending, (state) => {
        state.clientLoading = true;
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.clientLoading = false;
        state.clientData = action.payload;
      })
      .addCase(fetchClients.rejected, (state) => {
        state.clientLoading = false;
      })


      .addCase(saveContact.pending, (state) => {
        state.contactLoading = true;
      })
      .addCase(saveContact.fulfilled, (state, action) => {
        state.contactLoading = false;
      })
      .addCase(saveContact.rejected, (state) => {
        state.contactLoading = false;
      })

      .addCase(fetchContacts.pending, (state) => {
        state.contactLoading = true;
      })
      .addCase(fetchContacts.fulfilled, (state, action) => {
        state.contactLoading = false;
        state.contactDetails = action.payload;
      })
      .addCase(fetchContacts.rejected, (state) => {
        state.contactLoading = false;
      })


      .addCase(saveContactPerson.pending, (state) => {
        state.contactLoading = true;
      })
      .addCase(saveContactPerson.fulfilled, (state, action) => {
        state.contactLoading = false;
      })
      .addCase(saveContactPerson.rejected, (state) => {
        state.contactLoading = false;
      })

      .addCase(fetchContactPersons.pending, (state) => {
        state.contactLoading = true;
      })
      .addCase(fetchContactPersons.fulfilled, (state, action) => {
        state.contactLoading = false;
        state.contactPersons = action.payload;
      })
      .addCase(fetchContactPersons.rejected, (state) => {
        state.contactLoading = false;
      });
  },
});

export default clientSlice.reducer;
export const {
  handleSidenavFullWidth,
  setViewBasic,
  setEditBasic,
  cleanUpEditBasic,
  setViewLocation,
  setEditLocation,
  setViewContact,
  setEditContact,
  setContactId,
  setClientEditId,
  cleanUpClients,
  cleanUpEditId,
  setContactAdding,
  setRefetch,
  setClientTabActiveKey,
  setRefetchKey

} = clientSlice.actions;
