import React from 'react';
import Header from './Header';
import frame from '../../assets/Frame.svg';
import { useNavigate } from 'react-router-dom';

function SettingsLanding() {
	const navigate = useNavigate();
	return (
		<>
			<Header title='Settings' />

			<div className='bg-white mr-3 mb-[10px]'>
				<div className='ml-[54px] flex flex-col gap-y-[23px] text-center h-[1000px] py-[76px]'>
					<div className='flex gap-x-[5rem] '>
					  
						<div
							className='card !w-[524px] h-[173px] !rounded-md border border-[#DBEBF7] p-[30px] cursor-pointer'
							onClick={() => navigate('/email-template')}
						>
							<div className='flex items-center justify-start gap-x-5'>
								<img src={frame} alt='frame' />
								<div className='flex flex-col '>
									<div className='text-black1 text-[22px] font-medium font-dmSans leading-9 flex justify-start'>
										Email Templates
									</div>
									<div className='text-black1 text-[20px] font-normal font-dmSans leading-[30px]'>
										Manage your email templates.
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SettingsLanding;
