import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialIcon from 'material-icons-react';
import { Link, useNavigate } from 'react-router-dom';
import { handleStepCurrent } from '../../../../../features/profile/profileSlice';
import { fetchSkills } from '../../../../../features/skills/skillSlice';
 
export default function TrainingTab() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { mySkills, skillLoading } = useSelector((state) => state.skill);

	const [skillsList, setskillsList] = useState(mySkills);

	const { applicantEditId } = useSelector((state) => state.applicant);
	const usrObj = {
	  usrId: applicantEditId,
	};

	async function handleProfileBuilder() {
		await dispatch(handleStepCurrent(3));
		await navigate('/profile-builder');
	}

	async function handleFetchSkills() {
		const resp = await dispatch(fetchSkills(usrObj));

		if (resp?.payload?.success) {
			await setskillsList(resp?.payload?.data?.result);
		}
	}

	useEffect(() => {}, [skillsList]);

	useEffect(() => {
		setskillsList(mySkills);
	}, [mySkills]);

	useEffect(() => {
		handleFetchSkills();
	}, []);

	return (
		<>
		 
			<div className='flex items-center flex-wrap mt-5'>
				{skillsList?.length ? (
					skillsList?.map((item) => {
						return (
							<>
								<div
									key={item?.skillId}
									className='bg-lightPurple rounded-[63px] px-5 py-1 mr-2 mb-3'
								>
									<span className='prof-card-badge'>{item?.skillName}</span>
								</div>
							</>
						);
					})
				) : (
					<>
						<div className='flex w-full h-full mb-14'>
							<h2 className='text-center text-black3 text-[18px]'>
								You have not added any skill
							</h2>
						</div>
					</>
				)}
			</div>

			<div
				style={{
					background: '#D8D8D88A',
					height: '1px',
					width: '100%',
				}}
				className='my-10'
			></div>
		</>
	);
}
