import { Button, Input, Upload, message, Form } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import toast from 'react-hot-toast';
import AttachImage from '../../../../assets/uploadPic.png';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchDirectorAndManager,
	saveDirectorAndManager,
	saveFile,
} from '../../../../features/application/applicationSlice';
import {
	formatImgPath,
	showToastError,
	showToastSuccess,
} from '../../../../utils';
import { toFormData } from 'axios';
import Dragger from 'antd/es/upload/Dragger';
import FileUploader from '../../../../components/FileUploader';

const AddDirector = ({ handleCancelAdd, editItem, handleLoading }) => {
	const { editId, directors } = useSelector((state) => state.application);
	const { user } = useSelector((state) => state.auth);

	const formRef = useRef(null);

	const url = process.env.REACT_APP_API_BASE_URL;

	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [form] = Form.useForm();
	const values = Form.useWatch([], form);
	const [fileName, setFileName] = useState('');
	const [profile, setProfile] = useState(null);
	const [formData, setFormData] = useState({
		secInstId: user?.usrInstId,
		secType: 'DIRECTOR',
	});

	const editableForm = () => {
		if (Object.keys(editItem).length > 0) {
			setProfile(formatImgPath(editItem.secIcon ?? ''));
			setFormData({
				secFirstName: editItem?.secFirstName,
				secEmail: editItem?.secEmail,
				secMobileNumber: editItem?.secMobileNumber,
				secNeaId: editItem?.secNeaId,
				secIdFile: editItem?.secIdFile,
				secGoodConduct: editItem?.secGoodConduct,
				secEduCertNo: editItem?.secEduCertNo,
				secEduCert: editItem?.secEduCert,
				secId: editItem?.secId,
				file: editItem?.secIdFile?.split('_').pop(),
				file2: editItem?.secGoodConduct?.split('_').pop(),
				file3: editItem?.secEduCert?.split('_').pop(),
			});
		}
	};

	useEffect(() => {
		editableForm();
	}, [editId]);

	const onSubmit = async () => {
		setIsLoading(true);
		await handleLoading(true)
		const res = await dispatch(saveDirectorAndManager(formData));
		await dispatch(fetchDirectorAndManager(user?.usrInstId));
		if (res?.payload?.success) {
			message.info('Director details saved');
			handleCancelAdd();
			setIsLoading(false);
			await handleLoading(false)
		} else {
			message.error(res?.payload?.messages?.message);
			setIsLoading(false);
			await handleLoading(false)
		}
	};

	const handleSuccess = (response, name) => {
		// Handle success logic
		setFormData((prev) => ({
			...prev,
			[response.fileName]: response.jsonData,
		}));
	};

	const handleError = (response) => {
		// Handle error logic
		console.error('File upload failed:', response);
	};

	const onChange = async (e) => {
		if (
			e.target.name === 'secGoodConduct' ||
			e.target.name === 'secEduCert' ||
			e.target.name === 'secIdFile' ||
			e.target.name === 'secIcon'
		) {
			const formData = new FormData();
			formData.append('file', e.target.files[0]);
			const res = await dispatch(saveFile(formData));

			setFormData((prev) => ({
				...prev,
				[e.target.name]: res.payload.jsonData,
			}));
			if (e.target.name === 'secIcon') {
				setProfile(formatImgPath(res.payload.jsonData));
			}
		} else {
			setFormData((prev) => ({
				...prev,
				[e.target.name]: e.target.value,
			}));
		}
	};

	return (
		<Form
			onFinish={onSubmit}
			disabled={isLoading}
			form={form}
			ref={formRef}
			className='flex flex-col w-[100%]'
		>
			<div className='flex flex-row justify-between my-2'>
				<div>Please fill in the details below</div>
			</div>
			{profile === null ? (
				<label className='flex flex-row items-center justify-start gap-2  w-[30%]'>
					<input
						name='secIcon'
						type='file'
						accept='image/*'
						style={{ display: 'none' }}
						onChange={onChange}
					/>
					<img
						className='z-10 w-[100px] h-[100px]'
						alt='tab1'
						src={AttachImage}
						onClick={() => document.querySelector('input[type="file"]').click()}
						style={{ cursor: 'pointer' }}
					/>
					<div className='text-blueDark cursor-pointer '>
						Upload passport photo
					</div>
				</label>
			) : (
				<label className='flex flex-row items-center justify-start gap-2  w-[30%]'>
					<input
						name='secIcon'
						type='file'
						accept='image/*'
						style={{ display: 'none' }}
						onChange={onChange}
					/>
					<div className='rounded-full  overflow-hidden border-4 border-blueDark'>
						<img
							src={profile}
							alt='director'
							className='w-28 h-28 cursor-pointer object-cover'
						/>
					</div>
				</label>
			)}
			<div>
				<div className='flex flex-row w-[100wh] gap-10 my-7'>
					<Form.Item
						rules={[
							{
								required: true,
								message: 'Field is required',
							},
						]}
						className='flex flex-col w-[100%]'
					>
						<div>Name of Director</div>
						<Input
							required
							value={formData.secFirstName}
							onChange={(e) => onChange(e)}
							name='secFirstName'
							className='rounded-[8px] mt-1 h-[44px]  w-full border border-black'
						/>
					</Form.Item>
					<Form.Item className='flex flex-col w-[100%]'>
						<div>Email</div>
						<Input
							required
							value={formData.secEmail}
							onChange={(e) => onChange(e)}
							name='secEmail'
							className='input'
						/>
					</Form.Item>
					<Form.Item className='flex flex-col w-[100%]'>
						<div>Phone No.</div>
						<Input
							required
							value={formData.secMobileNumber}
							onChange={(e) => onChange(e)}
							name='secMobileNumber'
							className='input'
						/>
					</Form.Item>
				</div>
				<div className='grid grid-cols-3 gap-10 my-7'>
					<Form.Item className='flex flex-col'>
						<div>ID No.</div>
						<Input
							required
							value={formData.secNeaId}
							onChange={(e) => onChange(e)}
							name='secNeaId'
							className='rounded-[8px] mt-2 h-[44px] border border-black'
						/>
					</Form.Item>
					<Form.Item className='flex flex-col'>
						<div className='mb-2'>Upload ID</div>
						<FileUploader
							fileName={formData?.file}
							setFileName={setFileName}
							name='secIdFile'
							url={url}
							onSuccess={handleSuccess}
							onError={handleError}
						/>
					</Form.Item>

					<Form.Item className='flex flex-col'>
						<div className='mb-2'>Good Conduct Certificate</div>
						<FileUploader
							fileName={formData?.file2}
							setFileName={setFileName}
							name='secGoodConduct'
							url={url}
							onSuccess={handleSuccess}
							onError={handleError}
						/>
					</Form.Item>
				</div>
				<div className='grid grid-cols-3 gap-10 mb-5 '>
					<Form.Item className='flex flex-col w-[100%]'>
						<div>Education Cert No</div>
						<Input
							required
							value={formData.secEduCertNo}
							onChange={(e) => onChange(e)}
							name='secEduCertNo'
							className='input'
						/>
					</Form.Item>
					<Form.Item className='flex flex-col w-[100%]'>
						<div className='mb-2'>Upload Education Certificate</div>
						<FileUploader
							fileName={formData?.file3}
							setFileName={setFileName}
							name='secEduCert'
							url={url}
							onSuccess={handleSuccess}
							onError={handleError}
						/>
					</Form.Item>
				</div>
			</div>

			<div className='flex flex-col gap-4 my-10 w-[100%] items-center'>
				<button
					type='submit'
					// onClick={onSubmit}
					className=' bg-blueDark rounded-[35px] text-white w-[200px] p-3 ml-4'
				>
					Save
				</button>
				<Button
					onClick={() => handleCancelAdd()}
					className='flex justify-center items-center border-2 border-blueDark rounded-[35px] text-blueDark font-medium w-[200px] p-5 ml-4'
				>
					Cancel
				</Button>
			</div>
		</Form>
	);
};

export default AddDirector;
