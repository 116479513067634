import React, { useState } from "react";
import Candidates from "./Candidates";

function CandidatesList() {
  const [rowSelected, setRowSelected] = useState([]);
  return (
      <div className="bg-white mr-1 min-h-[100vh] px-[41px] py-[61px]"> 
      <div className="text-black text-[21px] font-bold font-dmSans leading-[33px]">
        Candidate Details
      </div>
      <div className="text-black text-[21px] font-bold font-dmSans leading-[33px] mt-[35px]">
        List of Candidates
      </div>
      <div className="text-black1 text-[1rem] font-bold font-dmSans leading-tight mt-[30px]">
        Choose stage to view
      </div>
      
      <Candidates
        rowSelected={rowSelected}
        setRowSelected={setRowSelected}
        intervalTime = "Block"
       />
    </div>
  );
}

export default CandidatesList;
