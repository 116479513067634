import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  japLoading: false,
  singleJap: {},
  candidateJapId: {},
};

export const fetchSingleJap = createAsyncThunk(
  "candidate/fetchSingleJap",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchJobApplications.action?japId=${data?.japId}`
    );
    return res.data?.data?.result;
  }
);

export const candidateSlice = createSlice({
  name: "candidate",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },
    setCandidateJapId: (state, action) => {
      state.candidateJapId = action.payload;
    },
    cleanCandidate: (state) => {
      state.candidateJapId = initialState.candidateJapId;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchSingleJap.pending, (state) => {
        state.japLoading = true;
      })
      .addCase(fetchSingleJap.fulfilled, (state, action) => {
        state.japLoading = false;
        state.singleJap = action.payload[0];
      })
      .addCase(fetchSingleJap.rejected, (state) => {
        state.japLoading = false;
        state.singleJap = {};
      });
  },
});

export default candidateSlice.reducer;
export const { setCandidateJapId, cleanCandidate } = candidateSlice.actions;
