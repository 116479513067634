import Stepper from './Stepper';

export default function Application() {
	return (
		<div className='w-[100%] min-h-[100%] bg-white p-10'>
			<div className='flex w-full h-[47px] relative'>
				<div className='triangleStyle w-[45%] flex justify-center items-center'>
					Application Stage
				</div>
				<div className='triangleStyle2 w-[55%] flex justify-center items-center'>
					Purchase License
				</div>
			</div>
			<Stepper />
		</div>
	);
}
