import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	globalLoading: false,
	fullWidth: true,
	notifications: [],
	signUpDetails:{}
};

export const saveNotification = createAsyncThunk(
	'globalSlice/saveNotification',
	async (obj) => {
		const res = await axios.post(`${url}/not/saveNotification.action`, obj);
		return res.data;
	}
);

export const fetchNotifications = createAsyncThunk(
	'globalSlice/fetchNotifications',
	async (usrObj) => {
		const res = await axios.get(
			`${url}/not/fetchNotifications.action?ntfnInstId=${usrObj?.usrInstId}&ntfnReceiverId=${usrObj?.usrId}`
		);
		return res.data.data.result;
	}
);

export const deleteNotification = createAsyncThunk(
	'deleteSlice/deleteNotification',
	async (id) => {
		const res = await axios.get(
			`${url}/not/deleteNotification.action?ntfnId=${id}`
		);
		return res.data;
	}
);



export const globalSlice = createSlice({
	name: 'global',
	initialState,
	reducers: {
		handleSidenavFullWidth: (state) => {
			state.fullWidth = !state.fullWidth;
		},

		setSignupDetails:(state,action)=>{
			state.signUpDetails = action.payload
		},

		cleanSignupDetails:(state,action)=>{
			state.signUpDetails = initialState.signUpDetails
		}
	},
	extraReducers: (builder) => {
		builder
		
		.addCase(deleteNotification.pending, (state) => {
			state.globalLoading = true;
		  })
		  .addCase(deleteNotification.fulfilled, (state) => {
			state.globalLoading = false;
		  })
		  .addCase(deleteNotification.rejected, (state) => {
			state.globalLoading = false;
		  })
	  
		  .addCase(saveNotification.pending, (state) => {
			state.globalLoading = true;
		  })
		  .addCase(saveNotification.fulfilled, (state) => {
			state.globalLoading = false;
		  })
		  .addCase(saveNotification.rejected, (state) => {
			state.globalLoading = false;
		  })
	  
		  .addCase(fetchNotifications.pending, (state) => {
			state.globalLoading = true;
		  })
		  .addCase(fetchNotifications.fulfilled, (state, action) => {
			state.globalLoading = false;
			state.notifications = action.payload;
		  })
		  .addCase(fetchNotifications.rejected, (state) => {
			state.globalLoading = false;
		  })
	},
});

export default globalSlice.reducer;
export const { handleSidenavFullWidth,setSignupDetails,cleanSignupDetails } = globalSlice.actions;
