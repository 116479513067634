import { useState } from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const url = process.env.REACT_APP_API_BASE_URL;

export default function MainLicensePage() {
	const navigate = useNavigate();

	const { agencyObj } = useSelector((state) => state.agency);
	const { user } = useSelector((state) => state.auth);

	const [loading, setloading] = useState(true);

	function handleGoBack() {
		navigate(-1);
	}

	return (
		<>
			<div className='w-[100%] h-auto bg-white p-10 flex justify-between items-center'>
				<span className='font-bold text-[26px] text-[#141414]'>License</span>

				<div className='flex items-center'>
					<button
						type='button'
						onClick={handleGoBack}
						className='bg-transparent flex items-center'>
						<div className='flex justify-center items-center rounded-full w-[40px] h-[40px] p-2 bg-[#EDF8FF]'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='28'
								height='24'
								viewBox='0 0 28 24'
								fill='none'>
								<path
									d='M17.7692 18L11.0469 12L17.7692 6'
									stroke='#147CBC'
									stroke-width='2'
									stroke-linecap='round'
									stroke-linejoin='round'
								/>
							</svg>
						</div>
						<span className='ml-5 font-bold text-[22px] text-[#222222]'>
							Go Back
						</span>
					</button>
				</div>
			</div>

			<div className='mt-3 bg-white p-10'>
				<iframe
					onLoad={() => setloading(false)}
					title='Generated invoice'
					width={'100%'}
					height={'700px'}
					src={`${url}/nea/directReport.action?&INST_ID=${agencyObj?.instId}&usrId=${user?.usrId}&reportName=certificate&PDF_FORMAT=inline&P_FORMAT=PDF`}></iframe>

				{loading ? (
					<>
						<button
							className='bg-blueDark text-white w-[300px] p-3 flex justify-center mt-10'
							disabled
							type='button'>
							<Spin />
							<span className='ml-2'>Loading license... Please wait</span>
						</button>
					</>
				) : null}
			</div>
		</>
	);
}
