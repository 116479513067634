import { Button, Form, Input, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchApplicantsDetails,
  fetchJobSeekerInfos,
} from "../../../../../features/applicant/applicantSlice";
import { dateForHumans } from "../../../../../utils";

function SeekerDetailsView() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setdata] = useState({});
  const onFinish = async (data) => {};

  const { user } = useSelector((state) => state.auth);
  const { refetch ,refetchKey} = useSelector((state) => state.cv);
  const { singleCreateSeekerInfo, applicantEditId, applicants,jobSeekerInfo ,singleCreateApplicant} = useSelector(
    (state) => state.applicant
  );

  const [applicantsList, setApplicantsList] = useState(jobSeekerInfo);
  const [receivedId, setReceivedId] = useState(
    applicantEditId ? applicantEditId : singleCreateSeekerInfo?.jsiId
  );
  const [singleApplicant, setSingleApplicant] = useState("");
 

  useEffect(() => {
    setReceivedId(
      applicantEditId ? applicantEditId : singleCreateSeekerInfo?.jsiId
    );
  }, [applicantEditId, singleCreateSeekerInfo?.jsiId]);
 

  useEffect(() => {
  }, [singleApplicant]);

  useEffect(() => {
    if(refetchKey == 1){
    dispatch(
      fetchJobSeekerInfos({
        jsiUsrId: singleCreateApplicant?.usrId ? singleCreateApplicant?.usrId : applicantEditId ,
      })
    );
    }
  }, [refetch,refetchKey,applicantEditId]);
 
  return (
    <div className="mt-[38px]">
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
        }}
        form={form}
      >
        <div className="grid grid-cols-2">
          <div className="grid grid-cols-2">
            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Job seeking method
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {jobSeekerInfo?.jsiSeekingMethod ?? 'N/A'}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Means of maintenance
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {jobSeekerInfo?.jsiMaintenance ?? 'N/A'}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Duration of unemployment
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {jobSeekerInfo?.jsiUnempDuration ?? 'N/A'}
            </div>

            <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose mb-[30px]">
              Reason for unemployment
            </div>
            <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose mb-[30px]">
              {jobSeekerInfo?.jsiUnempReason ?? 'N/A'}
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default SeekerDetailsView;
