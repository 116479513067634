import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Modal, Button, Switch,Spin } from "antd"; 
import { useDispatch, useSelector } from "react-redux"; 
import toast from "react-hot-toast"

const EndRecruitmentConfirmationModal = ({
  isModalOpen,
  setIsModalOpen,
  handleSubmit, 
  loading, 
  content,
}) => {
 
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);  
  };
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);
  
  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer activity activity-header activity-close"
        title="Confirm Submission"
        open={isModalOpen}
        onCancel={handleCancel}
        width={898}
      >
        
        <div className="mt-[18px] text-center text-black1 text-[21px] font-normal font-dmSans leading-loose">
         {content}
        </div>

        <div className="flex flex-col items-center mt-[150px] gap-y-5 mb-[66px]">
          <Button
            className="text-white text-[1rem] font-medium font-dmSansleading-normal bg-blueDark
          w-[239px] h-[46px] px-[18px] py-3  rounded-[56px] justify-center items-center gap-2.5 inline-flex"
            type="submit" 
            onClick={handleSubmit}
            disabled={loading}
          >
           {loading ? <Spin/> : 'Submit' }   
          </Button>

          <button
            className="text-blueDark text-[1rem] font-medium font-dmSansleading-normal
          w-[239px] h-[46px] px-[18px] py-3  rounded-[56px] border border-blueDark justify-center items-center gap-2.5 inline-flex"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
export default EndRecruitmentConfirmationModal;
