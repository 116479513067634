import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	inbLoading: false,
	sentInbox: [],
	receivedInbox: [],
    receivedInboxThreads: [],
    sentInboxThreads: [],
    draftInbox: [],
	inboxObj: {},
    currentView: 0,
	draftObj: {},
};

export const saveInbox = createAsyncThunk(
	'inboxSlice/saveInbox',
	async (data) => {
		const res = await axiosInstance.post(`${url}/nea/saveInbox.action`, data);
		return res.data;
	},
);

export const fetchSentInboxs = createAsyncThunk(
	'inboxSlice/fetchSentInboxs',
	async (inbSenderId) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchInboxs.action?inbSenderId=${inbSenderId}&inbStatus=ACTIVE`,
		);
		return res.data.data.result;
	},
);

export const fetchSentInboxThreads = createAsyncThunk(
	'inboxSlice/fetchSentInboxThreads',
	async (inbSenderId) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchInboxs.action?inbThreadId=${inbSenderId}`,
		);
		return res.data.data.result;
	},
);

export const fetchReceivedInboxs = createAsyncThunk(
	'inboxSlice/fetchReceivedInboxs',
	async (inbReceiverId) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchInboxs.action?inbReceiverId=${inbReceiverId}&mainThread=TRUE&inbStatus=ACTIVE`,
		);
		return res.data.data.result;
	},
);

export const fetchReceivedInboxThreads = createAsyncThunk(
	'inboxSlice/fetchReceivedInboxThreads',
	async (inbReceiverId) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchInboxs.action?inbThreadId=${inbReceiverId}`,
		);
		return res.data.data.result;
	},
);

export const fetchDraftInbox = createAsyncThunk(
	'inboxSlice/fetchDraftInbox',
	async (inbReceiverId) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchInboxs.action?inbSenderId=${inbReceiverId}&inbStatus=DRAFT`,
		);
		return res.data.data.result;
	},
);

export const inboxSlice = createSlice({
	name: 'inbox',
	initialState,
	reducers: {
		setInboxObj: (state, action) => {
			state.inboxObj = action.payload;
		},

        setCurrentView: (state, action) => {
			state.currentView = action.payload;
		},

		setDraftObj: (state, action) => {
			state.draftObj = action.payload;
		},

		inboxCleanUp: (state) => {
			state.inboxObj = {};
			state.draftObj = {}
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(saveInbox.pending, (state) => {
				state.inbLoading = true;
			})
			.addCase(saveInbox.fulfilled, (state) => {
				state.inbLoading = false;
			})
			.addCase(saveInbox.rejected, (state) => {
				state.inbLoading = false;
			})

			.addCase(fetchSentInboxs.pending, (state) => {
				state.inbLoading = true;
			})
			.addCase(fetchSentInboxs.fulfilled, (state, action) => {
				state.inbLoading = false;
				state.sentInbox = action?.payload;
			})
			.addCase(fetchSentInboxs.rejected, (state) => {
				state.inbLoading = false;
				state.sentInbox = [];
			})

            .addCase(fetchSentInboxThreads.pending, (state) => {
				state.inbLoading = true;
			})
			.addCase(fetchSentInboxThreads.fulfilled, (state, action) => {
				state.inbLoading = false;
				state.sentInboxThreads = action?.payload;
			})
			.addCase(fetchSentInboxThreads.rejected, (state) => {
				state.inbLoading = false;
				state.sentInboxThreads = [];
			})

			.addCase(fetchReceivedInboxs.pending, (state) => {
				state.inbLoading = true;
			})
			.addCase(fetchReceivedInboxs.fulfilled, (state, action) => {
				state.inbLoading = false;
				state.receivedInbox = action?.payload;
			})
			.addCase(fetchReceivedInboxs.rejected, (state) => {
				state.inbLoading = false;
				state.receivedInbox = [];
			})

            .addCase(fetchReceivedInboxThreads.pending, (state) => {
				state.inbLoading = true;
			})
			.addCase(fetchReceivedInboxThreads.fulfilled, (state, action) => {
				state.inbLoading = false;
				state.receivedInboxThreads = action?.payload;
			})
			.addCase(fetchReceivedInboxThreads.rejected, (state) => {
				state.inbLoading = false;
				state.receivedInboxThreads = [];
			})

            .addCase(fetchDraftInbox.pending, (state) => {
				state.inbLoading = true;
			})
			.addCase(fetchDraftInbox.fulfilled, (state, action) => {
				state.inbLoading = false;
				state.draftInbox = action?.payload;
			})
			.addCase(fetchDraftInbox.rejected, (state) => {
				state.inbLoading = false;
				state.draftInbox = [];
			});
	},
});

export default inboxSlice.reducer;
export const { setInboxObj, inboxCleanUp, setCurrentView, setDraftObj } = inboxSlice.actions;
