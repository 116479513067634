import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  eduLoading: false,
  educations: [],
  eduCV: [],
  eduData: [],
  activeEdu: {},
  professionalQualifications: [],
  membershipBoards: [],
};

export const fetchEducations = createAsyncThunk(
  "eduSlice/fetchEducations",
  async (usrObj) => {
    const res = await axios.get(
      `${url}/nea/fetchEducations.action?eduUsrId=${usrObj?.usrId}`
    );
    return res.data;
  }
);

export const fetchCVEducations = createAsyncThunk(
  "eduSlice/fetchCVEducations",
  async (usrObj) => {
    const res = await axios.get(
      `${url}/nea/fetchEducations.action?eduUsrId=${usrObj?.usrId}&eduCvlId=${usrObj?.eduCvlId}`
    );
    return res.data;
  }
);

export const fetchProfessionalQualifications = createAsyncThunk(
  "eduSlice/fetchProfessionalQualifications",
  async (usrObj) => {
    const res = await axios.get(
      `${url}/setup/fetchProfessionalQualifications.action?pqlUsrId=${usrObj?.usrId}`
    );
    return res.data.data.result;
  }
);

export const fetchMembershipBoards = createAsyncThunk(
	"eduSlice/fetchMembershipBoards",
	async (usrObj) => {
	  const res = await axios.get(
		`${url}/setup/fetchMembershipBoards.action?mebUsrId=${usrObj?.usrId}`
	  );
	  return res.data.data.result;
	}
  );

export const saveEducation = createAsyncThunk(
  "eduSlice/saveEducation",
  async (eduObj) => {
    const res = await axios.post(`${url}/nea/saveEducation.action`, eduObj);
    return res.data;
  }
);

export const saveProfessionalQualification = createAsyncThunk(
  "eduSlice/saveProfessionalQualification",
  async (eduObj) => {
    const res = await axios.post(
      `${url}/setup/saveProfessionalQualification.action`,
      eduObj
    );
    return res.data;
  }
);

export const saveMembershipBoard = createAsyncThunk(
	"eduSlice/saveMembershipBoard",
	async (eduObj) => {
	  const res = await axios.post(
		`${url}/setup/saveMembershipBoard.action`,
		eduObj
	  );
	  return res.data;
	}
  );

export const eduSlice = createSlice({
  name: "edu",
  initialState,
  reducers: {
    setEduData: (state, action) => {
      state.eduData = action.payload;
    },
    setActiveEdu: (state, action) => {
      state.activeEdu = action.payload;
    },
    clearEduData: (state) => {
      state.eduData = {};
    },
    clearActiveEdu: (state) => {
      state.activeEdu = {};
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(saveMembershipBoard.pending, (state) => {
        state.eduLoading = true;
      })
      .addCase(saveMembershipBoard.fulfilled, (state) => {
        state.eduLoading = false;
      })
      .addCase(saveMembershipBoard.rejected, (state) => {
        state.eduLoading = false;
      })

	  .addCase(saveProfessionalQualification.pending, (state) => {
        state.eduLoading = true;
      })
      .addCase(saveProfessionalQualification.fulfilled, (state) => {
        state.eduLoading = false;
      })
      .addCase(saveProfessionalQualification.rejected, (state) => {
        state.eduLoading = false;
      })

      .addCase(saveEducation.pending, (state) => {
        state.eduLoading = true;
      })
      .addCase(saveEducation.fulfilled, (state) => {
        state.eduLoading = false;
      })
      .addCase(saveEducation.rejected, (state) => {
        state.eduLoading = false;
      })

      .addCase(fetchMembershipBoards.pending, (state) => {
        state.eduLoading = true;
      })
      .addCase(fetchMembershipBoards.fulfilled, (state, action) => {
        state.eduLoading = false;
        state.membershipBoards = action?.payload;
      })
      .addCase(fetchMembershipBoards.rejected, (state) => {
        state.eduLoading = false;
        state.membershipBoards = [];
      })

	  .addCase(fetchProfessionalQualifications.pending, (state) => {
        state.eduLoading = true;
      })
      .addCase(fetchProfessionalQualifications.fulfilled, (state, action) => {
        state.eduLoading = false;
        state.professionalQualifications = action?.payload;
      })
      .addCase(fetchProfessionalQualifications.rejected, (state) => {
        state.eduLoading = false;
        state.professionalQualifications = [];
      })

      .addCase(fetchEducations.pending, (state) => {
        state.eduLoading = true;
      })
      .addCase(fetchEducations.fulfilled, (state, action) => {
        state.eduLoading = false;
        if (action?.payload?.success) {
          state.educations = action?.payload?.data?.result;
        } else {
          state.educations = [];
        }
      })
      .addCase(fetchEducations.rejected, (state) => {
        state.eduLoading = false;
      })

      .addCase(fetchCVEducations.pending, (state) => {
        state.eduLoading = true;
      })
      .addCase(fetchCVEducations.fulfilled, (state, action) => {
        state.eduLoading = false;
        if (action?.payload?.success) {
          state.eduCV = action?.payload?.data?.result;
        } else {
          state.eduCV = [];
        }
      })
      .addCase(fetchCVEducations.rejected, (state) => {
        state.eduLoading = false;
      });
  },
});

export default eduSlice.reducer;
export const { setActiveEdu, setEduData, clearEduData, clearActiveEdu } =
  eduSlice.actions;
