import React, { useEffect, useState } from 'react';
import AddDirector from './AddDirector';
import DirectorDetails from './DirectorDetails';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteDirectorAndManager,
	fetchDirectorAndManager,
	setEditId,
} from '../../../../features/application/applicationSlice';
import { Button, Table } from 'antd';
import FileButton from '../../../../components/FileButton';
import { formatImgPath } from '../../../../utils';
// import DirectorCard from './DirectorCard';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import ScrollTo from '../../../../ScrollTo';

const DirectorStep = ({ handleHideNext, handleShowNext, handleLoading }) => {
	const dispatch = useDispatch();
	const { directors } = useSelector((state) => state.application);
	const { user } = useSelector((state) => state.auth);

	const [directorsList, setdirectorsList] = useState(directors);
	const [addDirector, setaddDirector] = useState(true);
	const [isDeleted, setIsDeleted] = useState(false);

	const [hideList, sethideList] = useState(
		directorsList?.length ? false : true,
	);
	const [editItem, seteditItem] = useState({});

	const onAdd = () => {
		dispatch(setEditId(-1));
		setaddDirector(true);
		handleHideNext();
		sethideList(true);
	};

	function handleEdit(item) {
		setaddDirector(true);
		handleHideNext();
		sethideList(true);
		seteditItem(item);
	}

	function handleCancelAdd() {
		setaddDirector(false);
		handleShowNext();
		sethideList(false);
		seteditItem({})
	}

	const onDelete = async (item) => {
		setIsDeleted(true);
		await dispatch(deleteDirectorAndManager(item?.secId));
		await fetchDirectorInfo();
		setIsDeleted(false);
	};

	const columns = [
		{
			title: 'Name',
			render: (item) => (
				<>
					<div className='flex items-center w-full'>
						<img
							loading='lazy'
							className='w-12 h-12 mr-4 rounded-full'
							src={
								item.secIcon
									? formatImgPath(item.secIcon)
									: 'https://cdn-icons-png.flaticon.com/512/3607/3607444.png'
							}
							alt='Avatar'
						/>
						<div className='flex flex-col'>
							<span className='table-name font-semibold'>{item?.secFirstName}</span>
							<span className='table-name text-[17px]'>{item?.secMobileNumber}</span>
						</div>
					</div>
				</>
			),
		},
		{
			title: 'Email',
			dataIndex: 'secEmail',
			key: 'secEmail',
		},
		{
			title: 'ID No.',
			render: (item) => (
				<>
					<div className='flex flex-col'>
						<span className='table-name'>{item?.secNeaId}</span>
						<FileButton
							title={formatImgPath(item?.secIdFile)}
							titlePreview={item?.secIdFile}
							viable='true'
						/>
					</div>
				</>
			),
		},
		{
			title: 'Education Certificate',
			render: (item) => (
				<div className='flex flex-col'>
					<span className='table-name'>{item?.secEduCertNo}</span>
					<FileButton
						title={formatImgPath(item?.secEduCert)}
						titlePreview={item?.secEduCert}
						viable='true'
					/>
				</div>
			),
		},
		{
			title: 'Good Conduct',
			render: (item) => (
				<FileButton
					title={formatImgPath(item?.secGoodConduct)}
					titlePreview={item?.secGoodConduct}
					viable='true'
				/>
			),
		},
		{
			title: 'Action',
			render: (item) => (
				<div className='flex flex-row gap-2 justify-start'>
					<Button
						disabled={isDeleted}
						danger
						icon={<DeleteOutlined />}
						loading={isDeleted}
						onClick={() => onDelete(item)}
					/>
					<Button
						disabled={isDeleted}
						icon={<EditOutlined />}
						onClick={() => handleEdit(item)}
					/>
				</div>
			),
		},
	];

	const fetchDirectorInfo = async () => {
		await dispatch(fetchDirectorAndManager(user?.usrInstId));
	};

	useEffect(() => {
		fetchDirectorInfo();
		seteditItem({})
	}, []);

	useEffect(() => {}, [directorsList]);

	useEffect(() => {
		setdirectorsList(directors);
	}, [directors]);

	return (
		<>
		<ScrollTo />
			<div className='flex justify-between items-center mt-14'>
				<h3 className='appl-head'>Director Details</h3>

				{directorsList?.length ? (
					<Button
						className='border-2 border-blueDark text-blueDark flex justify-center items-center font-medium'
						onClick={onAdd}
						shape='round'
						icon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
							>
								<path
									d='M19 11H13V5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H11V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19V13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z'
									fill='#147CBC'
								/>
							</svg>
						}
						size={'large'}
					>
						Add another director
					</Button>
				) : null}
			</div>

			{hideList ? null : (
				<>
					{directorsList?.length ? (
						<div className='dash-inner-page mt-4 max-w-full w-full overflow-x-auto'>
							<section>
								<Table
									locale={{
										emptyText: (
											<>
												<div className='w-full min-h-[200px] flex flex-col justify-center items-center'>
													<span className='text-gray-400 text-lg font-semibold mb-3'>
														Education data will appear here
													</span>
													<Button
														className='border-2 border-blueDark text-blueDark flex justify-center items-center font-medium'
														onClick={onAdd}
														shape='round'
														icon={
															<svg
																xmlns='http://www.w3.org/2000/svg'
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'
															>
																<path
																	d='M19 11H13V5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H11V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19V13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z'
																	fill='#147CBC'
																/>
															</svg>
														}
														size={'large'}
													>
														Add director
													</Button>
												</div>
											</>
										),
									}}
									className='no-header-bg-table'
									size={'middle'}
									bordered={false}
									columns={columns}
									dataSource={directorsList}
									scroll={{
										x: 700,
									}}
									pagination={{
										defaultPageSize: 5,
										hideOnSinglePage: true,
										pageSizeOptions: [10, 20, 50, 100],
									}}
								/>
							</section>
						</div>
					) : // directorsList?.map((item, index) => {
					// 		return <DirectorCard handleEdit={handleEdit} key={index} data={item} />;
					//   })
					null}
				</>
			)}

			{addDirector && hideList ? (
				<AddDirector handleLoading={handleLoading} handleCancelAdd={handleCancelAdd} editItem={editItem} />
			) : null}

			{directorsList?.length === 0 && !addDirector ? (
				<div className='flex justify-center mt-20'>
					<Button
						className='border-2 border-blueDark text-blueDark flex justify-center items-center font-medium'
						onClick={onAdd}
						shape='round'
						icon={
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
							>
								<path
									d='M19 11H13V5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H11V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19V13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z'
									fill='#147CBC'
								/>
							</svg>
						}
						size={'large'}
					>
						Add director
					</Button>
				</div>
			) : null}
		</>
	);
};

export default DirectorStep;
