import React from 'react';
import { Button, Modal } from 'antd';
import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookMessengerIcon,
	FacebookMessengerShareButton,
	FacebookShareButton,
	LinkedinIcon,
	LinkedinShareButton,
	TelegramIcon,
	TelegramShareButton,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton,
	XIcon,
} from 'react-share';
import { useEffect } from 'react';

export default function SocialShareModal({ open, handleCancel, activeJob }) {
	let shareUrl = `https://kazi254.ke/#/shared-job/${activeJob?.jobId}/${activeJob?.jobPosition}`;
	let title = activeJob?.jobPosition;

	useEffect(() => {}, [activeJob]);

	return (
		<>
			<Modal 
				className='modal-btn mod-height mod-footer'
				title={`${activeJob?.jobPosition}`}
				open={open}
				onCancel={handleCancel}
				width={850}
				footer={false}>
				<p className='text-black3 text-[24px] text-center w-full font-medium p-7'>
					Share this job
				</p>

				<div className='flex justify-evenly max-w-full w-[500px]'>
					<FacebookShareButton url={shareUrl}>
						<FacebookIcon size={50} round />
					</FacebookShareButton>

					<FacebookMessengerShareButton url={shareUrl} appId='521270401588372'>
						<FacebookMessengerIcon size={50} round />
					</FacebookMessengerShareButton>

					<TwitterShareButton url={shareUrl}>
						<XIcon size={50} round />
					</TwitterShareButton>

					<TelegramShareButton url={shareUrl}>
						<TelegramIcon size={50} round />
					</TelegramShareButton>

					<WhatsappShareButton url={shareUrl}>
						<WhatsappIcon size={50} round />
					</WhatsappShareButton>

					<LinkedinShareButton url={shareUrl}>
						<LinkedinIcon size={50} round />
					</LinkedinShareButton>

					<EmailShareButton url={shareUrl} subject={title} body='body'>
						<EmailIcon size={50} round />
					</EmailShareButton>
				</div>

				<div className='flex justify-end items-center mt-10'> 
                    <Button
                  key="back"
                  onClick={handleCancel}
                  className="w-[138px] h-12 px-7 py-4 rounded-[56px] border-none justify-center items-center gap-2.5 
                  inline-flex text-blueDark text-[1rem] font-medium font-dmSans leading-normal"
                >
                  Cancel
                </Button>
				</div>
			</Modal>
		</>
	);
}
