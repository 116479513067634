import React from 'react';
import { Collapse, Descriptions, theme } from 'antd';
import MaterialIcon from 'material-icons-react';
import { useDispatch, useSelector } from 'react-redux';
import FileButton from '../../../components/FileButton';
import { formatImgPath } from '../../../utils';
import LicenseCard from './LicenseCard';

const Summary = () => {
	const { token } = theme.useToken();
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { agencyObj } = useSelector((state) => state.agency);
	const { agentLicence } = useSelector((state) => state.license);
	const { agency } = useSelector((state) => state.application);

	const bgColorClass = (bgColor) => {
		return {
			backgroundColor: bgColor,
		};
	};

	const license = agentLicence?.find(
		(item) => item?.licId === agencyObj?.instLicId,
	);

	const panelStyle = {
		marginBottom: 24,
		background: token.colorFillAlter,
		borderRadius: token.borderRadiusLG,
		border: '1px solid #147CBC',
	};

	const items = [
		{
			key: '1',
			label: (
				<span className='profile-basic-info font-[500] w-[200px] my-3'>
					{' '}
					Name
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] w-full truncate my-3'>
					{agencyObj?.instName}
				</span>
			),
		},
		{
			key: '2',
			label: (
				<span className='profile-basic-info font-[500] w-[200px] my-3'>
					Economic Activity
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] text-start w-full truncate '>
					{agencyObj?.instEconomic}
				</span>
			),
		},
		{
			key: '3',
			label: (
				<span className='profile-basic-info font-[500] w-[200px] mb-3'>
					KRA PIN
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] text-start w-full truncate '>
					{agencyObj?.instKraPin}
				</span>
			),
		},
		{
			key: '4',
			label: (
				<span className='profile-basic-info font-[500] w-[200px] mb-3'>
					Postal Address
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] text-start w-full truncate'>
					{agencyObj?.instPostalAddress}
				</span>
			),
		},
		{
			key: '5',
			label: (
				<span className='profile-basic-info font-[500] w-[200px] mb-3'>
					Postal Code
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] text-start w-full truncate '>
					{agencyObj?.instPostalCode}
				</span>
			),
		},
		{
			key: '6',
			label: (
				<span className='profile-basic-info font-[500]  w-[200px] mb-3'>
					Official Location
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] text-start w-full truncate'>
					{agencyObj?.instPhysicalAddress}
				</span>
			),
		},
		{
			key: '7',
			label: (
				<span className='profile-basic-info font-[500] w-[200px] mb-3'>
					Nearest NEA Office
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] text-start w-[300px] truncate '>
					{agencyObj?.instNearNeaOffice}
				</span>
			),
		},
		{
			key: '8',
			label: (
				<span className='profile-basic-info font-[500] w-[200px] mb-3'>
					Official Office No.
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] text-start w-full truncate '>
					{agencyObj?.instWorkTel}
				</span>
			),
		},
		{
			key: '9',
			label: (
				<span className='profile-basic-info font-[500] w-[200px]'>
					Official Email Address
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] w-[200px] mb-3 '>
					{agencyObj?.instEmail}
				</span>
			),
		},
		{
			key: '10',
			label: (
				<span className='profile-basic-info font-[500] w-[200px] mb-3'>
					Official Website
				</span>
			),
			children: (
				<span className='profile-basic-info font-[400] w-[200px] mb-3  '>
					{agencyObj?.instPhysicalAddress}
				</span>
			),
		},
	];

	const docItems = [
		{
			key: '1',
			label: (
				<span className='profile-basic-info font-[500] w-[300px] mt-3'>
					{' '}
					Company Reg. Certificate
				</span>
			),
			children: (
				<div className='mt-3'>
					<FileButton
						show={true}
						title={formatImgPath(agency?.agcRegFile)}
						titlePreview={agency?.agcRegFile}
						viable='true'
					/>
				</div>
			),
		},
		{
			key: '2',
			label: (
				<span className='profile-basic-info font-[500] w-[300px] my-3'>
					CR 12 Certificate
				</span>
			),
			children: (
				<div className='my-3'>
					<FileButton
						show={true}
						title={formatImgPath(agency?.agcCr12File)}
						titlePreview={agency?.agcCr12File}
						viable='true'
					/>
				</div>
			),
		},
		{
			key: '3',
			label: (
				<span className='profile-basic-info font-[500] w-[300px] mb-3'>
					Tax Compliance Certificate
				</span>
			),
			children: (
				<div className=''>
					<FileButton
						show={true}
						title={formatImgPath(agency?.agcTaxComplianceFile)}
						titlePreview={agency?.agcTaxComplianceFile}
						viable='true'
					/>
				</div>
			),
		},
		{
			key: '4',
			label: (
				<span className='profile-basic-info font-[500] w-[300px] mb-3'>
					Business Reg. Certificate
				</span>
			),
			children: (
				<div className=''>
					<FileButton
						show={true}
						title={formatImgPath(agency?.agcCountyFile)}
						titlePreview={agency?.agcCountyFile}
						viable='true'
					/>
				</div>
			),
		},
		{
			key: '5',
			label: (
				<span className='profile-basic-info font-[500] w-[300px] mb-3'>
					Lease Agreement
				</span>
			),
			children: (
				<div className=''>
					<FileButton
						show={true}
						title={formatImgPath(agency?.agcLeaseFile)}
						titlePreview={agency?.agcLeaseFile}
						viable='true'
					/>
				</div>
			),
		},
	];

	const LicenView = (
		<LicenseCard
			hide={true}
			style={bgColorClass(license?.licDesc)}
			licenseType={license?.licType}
			price={license?.licAmount}
			selectedButton={license?.licId}
		/>
	);
	const getItems = (panelStyle) => [
		{
			key: '1',
			label: (
				<div className='flex items-center w-[100%] gap-2'>
					<MaterialIcon color='#147CBC' icon='drag_indicator' />
					<span className='font-[500] text-black2 text-[18px]'>
						Account Details
					</span>
				</div>
			),
			children: (
				<Descriptions
					colon={false}
					size={'large'}
					column={2}
					layout='horizontal'
					items={items}
					style={{
						marginLeft: '20px',
						marginTop: '10px',
					}}
				/>
			),
			style: panelStyle,
		},
		{
			key: '2',
			label: (
				<div className='flex items-center w-[100%] gap-2'>
					<MaterialIcon color='#147CBC' icon='drag_indicator' />
					<span className='font-[500] text-black2 text-[18px]'>Documents</span>
				</div>
			),
			children: (
				<Descriptions
					colon={false}
					size={'large'}
					column={1}
					layout='horizontal'
					items={docItems}
					style={{
						marginLeft: '20px',
						marginTop: '10px',
					}}
				/>
			),
			style: panelStyle,
		},
		{
			key: '3',
			label: (
				<div className='flex items-center w-[100%] gap-2'>
					<MaterialIcon color='#147CBC' icon='drag_indicator' />
					<span className='font-[500] text-black2 text-[18px]'>License</span>
				</div>
			),
			children: (
				<div
					style={{
						marginLeft: '20px',
						marginTop: '20px',
					}}
					className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-10'
				>
					<div>{LicenView}</div>
				</div>
			),
			style: panelStyle,
		},
	];
	return (
		<div className='flex flex-col w-[100%] my-14'>
			<div className='appl-head'>Application summary</div>
			<div className='flex flex-row justify-between mt-2 mb-10'>
				<div className=''>Here’s a summary of your application</div>
			</div>

			<Collapse
				bordered={false}
				expandIconPosition='end'
				defaultActiveKey={['1']}
				// expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
				style={{
					background: token.colorBgContainer,
				}}
				items={getItems(panelStyle)}
			/>
		</div>
	);
};

export default Summary;
