import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import { handleStepCurrent } from "../../../../../features/profile/profileSlice";
import {
  fetchExperiences,
  saveExperience,
} from "../../../../../features/exp/expSlice";
import { Spin, Switch } from "antd";
import moment from "moment";

export default function ExperienceTab() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { experiences, expLoading } = useSelector((state) => state.exp);

  const [educationList, seteducationList] = useState(experiences);

  const { applicantEditId } = useSelector((state) => state.applicant);

  const usrObj = {
    usrId: applicantEditId,
  };

  async function handleProfileBuilder() {
    await dispatch(handleStepCurrent(2));
    await navigate("/profile-builder");
  }

  async function handleFetchExp() {
    const resp = await dispatch(fetchExperiences(usrObj));

    if (resp?.payload?.success) {
      await seteducationList(resp?.payload?.data?.result);
    }
  }

  useEffect(() => {}, [educationList]);

  useEffect(() => {
    seteducationList(experiences);
  }, [experiences]);

  useEffect(() => {
    handleFetchExp();
  }, []);

  return (
    <>
    
      {expLoading && <Spin size="large" />}

      {!expLoading && educationList?.length ? (
        educationList.map((item) => {
          return (
            <div
              key={item?.expId}
              // style={{
              // 	display: item?.expPrivacy && !editProfile && user?.usrProfileVisibility === 'SEMI_PUBLIC' ? 'none' : 'block',
              // }}
            >
              <div className="bg-white p-3 flex items-center justify-between w-full">
                <div
                   
                  className="w-full flex flex-col"
                >
                  <h3 className="text-blueDark text-[21px] font-bold">
                    {item?.expEmployer === "None"
                      ? "Employer - N/A"
                      : item?.expEmployer}
                  </h3>
                  <p className="text-black333 font-medium text-[18px] my-1">
                    {item?.expType === "N/A"
                      ? "Experience - N/A"
                      : item?.expType}
                  </p>
                  <span className="text-black333 font-normal text-[19px]">
                    {item?.expStartDate
                      ? moment(item?.expStartDate).format("MMMM YYYY")
                      : "Start Date"}{" "}
                    -{" "}
                    {item?.expCurrent
                      ? "Current"
                      : item?.endEndDate
                      ? moment(item?.endEndDate).format("MMMM YYYY")
                      : "End Date"}
                  </span>

                  {item?.expDesc && item?.expDesc !== "N/A" ? (
                    <>
                      <span className="text-black2 font-normal text-[18px] text-justify mt-5">
                        {item?.expDesc}
                      </span>
                    </>
                  ) : null}
                </div>
              </div>

              <div
                style={{
                  background: "#D8D8D88A",
                  height: "1px",
                  width: "100%",
                }}
                className="my-5"
              ></div>
            </div>
          );
        })
      ) : (
        <div className="flex w-full h-full mb-14">
          <h2 className="text-center text-black3 text-[18px]">
            All your experiences will appear here
          </h2>
        </div>
      )}
    </>
  );
}
