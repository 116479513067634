import React, { useEffect, useState } from 'react';
import Header from './Header';
import MaterialIcon from 'material-icons-react';
import { Skeleton, Table } from 'antd';
import { formatImgPath } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import AddEmailtempModal from './AddEmailTempModal';
import { fetchEmailTemplates } from '../../features/emailTemp/emailTempSlice';
import moment from 'moment';

function EmailTemplate() {
	const dispatch = useDispatch();

	const { emailTemplates } = useSelector((state) => state.emailTemp);
	const { user } = useSelector((state) => state.auth);

	const [dataList, setdataList] = useState(emailTemplates);
	const [open, setopen] = useState(false);

	function handleOpen() {
		setopen(true);
	}

	function handleCancel() {
		setopen(false);
	}

	async function handleFetchListData() {
		await dispatch(fetchEmailTemplates(user?.usrInstId));
	}

	const columns = [
		{
			title: 'Name',
      dataIndex: 'emtName',
		},
		{
			title: 'Subject',
			dataIndex: 'emtSubject',
		},
		{
			title: 'Creator',
			dataIndex: 'cntCounty',
		},
		{
			title: 'Date Created',
			dataIndex: 'emtCreatedDate',
      render: item => (
        <>
        <span>{moment(item).format("Do MMM YYYY")}</span>
        </>
      )
		},
	];

	const tableColumns = columns?.map((item) => ({
		...item,
	}));

	useEffect(() => {}, [dataList]);

	useEffect(() => {
		setdataList(emailTemplates);
	}, [emailTemplates]);

	useEffect(() => {
		handleFetchListData();
	}, []);

	return (
		<>
			<Header title='Email Templates' />

			<div className='bg-white mr-3 mb-[10px] h-[100vh] p-[62px]'>
				<div className='flex items-center justify-between'>
					<div class='text-neutral-900 text-2xl font-bold font-dmSans leading-9 flex justify-start'>
						Your email templates
					</div>
					<button
						onClick={handleOpen}
						type='button'
						className='flex items-center gap-x-5'
					>
						<div className='w-[24px] h-[24px] bg-blueDark rounded-full gap-x-5 flex items-center justify-center'>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'
							>
								<path
									d='M19 11H13V5C13 4.73478 12.8946 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4C11.7348 4 11.4804 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12C4 12.2652 4.10536 12.5196 4.29289 12.7071C4.48043 12.8946 4.73478 13 5 13H11V19C11 19.2652 11.1054 19.5196 11.2929 19.7071C11.4804 19.8946 11.7348 20 12 20C12.2652 20 12.5196 19.8946 12.7071 19.7071C12.8946 19.5196 13 19.2652 13 19V13H19C19.2652 13 19.5196 12.8946 19.7071 12.7071C19.8946 12.5196 20 12.2652 20 12C20 11.7348 19.8946 11.4804 19.7071 11.2929C19.5196 11.1054 19.2652 11 19 11Z'
									fill='#EDF8FF'
								/>
							</svg>
						</div>

						<span class='text-blueDark text-[21px] font-medium font-dmSans leading-[29.40px]'>
							Add Template
						</span>
					</button>
				</div>

				<div className='mt-[46px]  '>
					<Table
						rowSelection={true}
						size={'middle'}
						bordered={true}
						pagination={{
							position: ['bottomCenter'],
						}}
						rowKey={(record) => record?.emtId}
						columns={tableColumns}
						dataSource={dataList}
						scroll={'scroll'}
						className='custom-table'
					/>
				</div>
			</div>

			<AddEmailtempModal
				open={open}
				handleCancel={handleCancel}
				handleFetchListData={handleFetchListData}
			/>
		</>
	);
}

export default EmailTemplate;
