import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Modal, Button, Switch, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { saveEmployee } from "../features/myEmployees/myEmployeesSlice";

const { TextArea } = Input;
const LayOffConfirmationModal = ({
  isModalOpen,
  setIsModalOpen,
  handleSubmit,
  loading,
  content,
  fetchEmployeeDetails,
  prodd,
}) => {
 
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const formRef = useRef(null);
  const { user } = useSelector((state) => state.auth);

  const [data, setdata] = useState({});

  async function handleSubmit(data) {
    console.log("calling")
    const res = await dispatch(
      saveEmployee({
        emplId: prodd?.emplId,
        emplStatus: "LAYOFF",
        emplTerminationReason: data?.emplTerminationReason,
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      form.resetFields();
      await setIsModalOpen(false);
      await fetchEmployeeDetails();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  return (
    <>
      <Modal
        className="modal-btn mod-height mod-footer activity activity-header activity-close"
        title="Confirm Submission"
        open={isModalOpen}
        onCancel={handleCancel}
        width={898}
      >
        <div className="mt-[18px] text-center flex justify-center text-black1 text-[21px] font-normal font-dmSans leading-loose">
          {content}
        </div>
        <Form
          layout="vertical"
          ref={formRef}
          name="control-ref"
          onFinish={handleSubmit}
          className="px-20"
          style={{
            maxWidth: "100%",
          }}
          form={form}
        >
          <Form.Item
            label="Give Reason"
            name="emplTerminationReason"
            rules={[
              {
                required: true,
                message: "Please add reason",
              },
            ]}
          >
            <TextArea rows={4} className="input-textarea" />
          </Form.Item>

          <div className="flex flex-col items-center mt-[150px] gap-y-5 mb-[66px]">
            <button
              type="submit"
              key="submit"
              className="text-white text-[1rem] font-medium font-dmSansleading-normal !bg-blueDark
          w-[239px] h-[46px] px-[18px] py-3  rounded-[56px] justify-center items-center gap-2.5 inline-flex"
              disabled={loading} 
            >
              {loading ? <Spin /> : "Submit"}
            </button>

            <button
              className="text-blueDark text-[1rem] font-medium font-dmSansleading-normal
          w-[239px] h-[46px] px-[18px] py-3  rounded-[56px] border border-blueDark justify-center items-center gap-2.5 inline-flex"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </Form>
      </Modal>
    </>
  );
};
export default LayOffConfirmationModal;
