import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NoGrads from './graduates_files/NoGrads';
import GraduatesFolderTable from './GraduatesFolderTable';
import AddFolderModal from './graduates_files/modals/AddFolderModal';
import { fetchGradFolders, folderCleanUp } from '../../features/folder/folderSlice';
import InsideHeader from '../../components/InsideHeader';

export default function Graduates() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { user } = useSelector((state) => state.auth);
	const { gradFolders } = useSelector((state) => state.folder);

	const [open, setopen] = useState(false);

	function handleCancel() {
		setopen(false);
	}

	function handleAddFolder() {
		setopen(true);
	}

	function handleGoBack() {
		navigate(-1);
	}

	async function handleFetchData() {
		await dispatch(fetchGradFolders(user?.usrInstId));
	}

	useEffect(() => {}, [gradFolders]);

	useEffect(() => {
		handleFetchData();
        dispatch(folderCleanUp())
	}, []);

	return (
		<>
			<InsideHeader title="Graduates" subtitle="Manage graduates within your institution" back={false}/>

			<div className='mt-[51px]  px-10 h-full'>
				{gradFolders?.length  ? (
					<GraduatesFolderTable handleFetchData={handleFetchData} handleAddFolder={handleAddFolder} />
				) : (
					<NoGrads handleAddFolder={handleAddFolder} />
				)}
			</div>
			<AddFolderModal handleFetchData={handleFetchData} open={open} handleCancel={handleCancel} />
		</>
	);
}
