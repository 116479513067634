import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	emailTempLoading: false,
	emailTemplates: [],
};

export const saveEmailTemplate = createAsyncThunk(
	'emailTempSlice/saveEmailTemplate',
	async (data) => {
		const res = await axiosInstance.post(`${url}/emp/saveEmailTemplate.action`, data);
		return res.data;
	},
);

export const fetchEmailTemplates = createAsyncThunk(
	'emailTempSlice/fetchEmailTemplates',
	async (emtInstId) => {
		const res = await axiosInstance.get(
			`${url}/emp/fetchEmailTemplates.action?emtInstId=${emtInstId}`,
		);
		return res.data.data.result;
	},
);

export const emailTempSlice = createSlice({
	name: 'emailTemp',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(saveEmailTemplate.pending, (state) => {
				state.emailTempLoading = true;
			})
			.addCase(saveEmailTemplate.fulfilled, (state, action) => {
				state.emailTempLoading = false;
			})
			.addCase(saveEmailTemplate.rejected, (state) => {
				state.emailTempLoading = false;
			})

			.addCase(fetchEmailTemplates.pending, (state) => {
				state.emailTempLoading = true;
			})
			.addCase(fetchEmailTemplates.fulfilled, (state, action) => {
				state.emailTempLoading = false;
				state.emailTemplates = action.payload;
			})
			.addCase(fetchEmailTemplates.rejected, (state) => {
				state.emailTempLoading = false;
				state.emailTemplates = [];
			});
	},
});

export default emailTempSlice.reducer;
export const {} = emailTempSlice.actions;
