import { message } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import { useState } from 'react';

const FileUploader = ({ name, url, onSuccess, onError, fileName, blueBtn }) => {
	const [fileUploaded, setfileUploaded] = useState('');

	const props = {
		name: 'file',
		multiple: false,
		accept: 'image/png, image/jpeg, .pdf',
		action: `${url}/nea/saveFile.action`,
		onChange(info) {
			const { status } = info.file;
			if (status !== 'uploading') {
			}
			if (status === 'done') {
				message.success(`${info.file.name} file uploaded successfully.`);
				if (info?.file?.response?.success) {
					const data = { ...info?.file?.response, fileName: name };
					onSuccess(data);
					setfileUploaded(info.file.name);
				} else {
					onError(info?.file?.response);
				}
			} else if (status === 'error') {
				message.error(`${info.file.name} file upload failed.`);
				onError(info?.file?.response);
			}
		},
		onDrop(e) {
			// console.log('Dropped files', e.dataTransfer.files);
		},
	};

	return (
		<>
			{blueBtn ? (
				<Dragger
					{...props}
					className='mt-1 blueBtn w-[211px] h-[56px] rounded-[56px]'
				>
					<div className='flex justify-center mt-[-7px] items-center w-full ml-[17%]'>
						<div className='mr-5'>
							<CloudUploadOutlined
								style={{
									fontSize: 24,
									color: 'white',
								}}
							/>
						</div>
						<span className='w-[150px] truncate !text-left text-white text-[20px] font-medium'>
							Upload
						</span>
					</div>
				</Dragger>
			) : (
				<Dragger {...props} className='mt-1'>
					<div className='flex justify-between mt-[-5px] items-center'>
						{fileUploaded || fileName ? (
							<span className='w-[150px] truncate !text-left'>
								{fileUploaded || fileName}
							</span>
						) : null}
						{/* <p className="ant-upload-text !ml-[35%] flex justify-center items-center ">Upload File</p> */}
						<div className='ml-auto mr-5'>
							<CloudUploadOutlined
								style={{
									fontSize: 20,
									color: 'var(--blueDark)',
								}}
							/>
						</div>
					</div>
				</Dragger>
			)}
		</>
	);
};

export default FileUploader;
