import { Dropdown, Input, Table } from "antd";
import React, { useEffect, useState } from "react";
import MaterialIcon from "material-icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { 
  fetchReadvertisedJobs,
  saveJob,
  setEditJobDescription,
  setEditJobDetails,
  setEditSkills,
  setEditTermsEmployment,
  setJobEditId,
  setRefetch,
  setViewJobDescription,
  setViewJobDetails,
  setViewModeTable,
  setViewSkills,
  setViewTermsEmployment,
} from "../../../../features/jobs/jobSlice";
import { cashConverter, dateForHumans } from "../../../../utils";  
import { Skeleton } from "antd";
import { setTabsActiveKey } from "../../../../features/applicant/applicantSlice";
import { setRefetchKey } from "../../../../features/cv/cvSlice"; 
import EndRecruitmentConfirmationModal from "../../../../components/EndRecruitmentConfirmationModal";
import ConfirmationModal from "../../../../components/ConfirmationModal";
import toast from "react-hot-toast";
import NoDataTableJob from "../../../../components/NoDataTableJob";
import morevert from "../../../../assets/morevert.svg";

function ReadvertisedJobTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { jobData, jobLoading, refetchKeyJobTables,jobsCount,readvertisedJobs} = useSelector(
    (state) => state.jobs
  );
  const { user } = useSelector((state) => state.auth);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const [prodd, setProdd] = useState("");

  const showDrawer = async () => {
    await dispatch(setJobEditId(prodd?.jobId));
    await dispatch(setViewJobDetails(true));
    await dispatch(setEditJobDetails(false));
    await dispatch(setViewTermsEmployment(true));
    await dispatch(setEditTermsEmployment(false));
    await dispatch(setViewJobDescription(true));
    await dispatch(setEditJobDescription(false));
    await dispatch(setViewModeTable(true));
    await dispatch(setViewSkills(true));
    await dispatch(setEditSkills(false));
    await dispatch(setRefetch());
    await dispatch(setTabsActiveKey("1"));
    await dispatch(setRefetchKey("1"));
    await navigate("/job-view");
  };

  const columns = [
    {
      title: "Position name",
      render: (item) => (
        <>
          <div className="flex items-center justify-between"> 
              <span className="table-name mx-3">{item?.jobPosition}</span>
              {/* <button className="p-0 mx-4 flex items-end" type="button">
                <MaterialIcon
                  color="#000"
                  icon="visibility"
                  onClick={() => showDrawer(item?.jobId)}
                />
              </button> */}
              <button
                className="flex items-end"
                type="button"
                onClick={() => setProdd(item)}
              >
                <Dropdown
                  overlayStyle={{
                    width: "350px",
                  }}
                  trigger={"click"}
                  menu={{ items: settingItems }}
                  placement="bottom"
                >
                <img src={morevert} alt="morevert" />
                </Dropdown>
              </button> 
          </div>
        </>
      ), 
    }, 
    {
      title: "Advert Status",
      render: (item) => (
        <div>
          {item?.jobAdvertStatus === "APPROVED" ? (
            <span className="profile-status py-2 px-4 mt-2">
              {item?.jobAdvertStatus}
            </span>
          ) : item?.jobAdvertStatus == "REJECTED" ? (
            <span className="profile-status-rejected p-1 mt-2">
              {item?.jobAdvertStatus}
            </span>
          ) : item?.jobAdvertStatus == "END_RECRUITMENT" ? (
            <span className="profile-status-rejected p-1 mt-2">
              End Recruitment
            </span>
          ) : item?.jobAdvertStatus == "APPROVE_CANCEL" ? (
            <span className="profile-status-rejected p-1 mt-2">Cancelled</span>
          ) : item?.jobAdvertStatus == "PENDING_APPROVE_CANCEL" ? (
            <span className="profile-status-pending py-2 px-4 mt-2">
              Pending Cancel Approval
            </span>
          ) : (
            <span className="">{item?.jobAdvertStatus}</span>
          )}
        </div>
      ), 
    },
    {
      title: "Job Client",
      dataIndex: "jobCntName", 
    },
    {
      title: "Job Location",
      dataIndex: "jobCountry", 
    },
    {
      title: "Vacancy Count	",
      dataIndex: "jobVacancyCount", 
    },
    {
      title: "Salary",
      render: (item) => <div>{cashConverter(item?.jobGrossPay)}</div>, 
    },
    {
      title: "Job Owner",
      render: (item) => {
        return <div>{user?.usrFullNames}</div>;
      }, 
    },
    {
      title: "Date Created",
      render: (item) => <div>{dateForHumans(item?.jobCreatedDate)}</div>, 
    },
    {
      title: "End Date",
      render: (item) => <div>{dateForHumans(item?.jobAdvertEndDate)}</div>, 
    },
  ];

  const [isModalOpenConfirmation, setIsModalOpenConfirmation] = useState(false);
  const showModalConfirmation = async () => {
    setIsModalOpenConfirmation(true);
  };

  const [isModalOpenEndConfirmation, setIsModalOpenEndConfirmation] =
    useState(false);
  const showModalEndConfirmation = async () => {
    setIsModalOpenEndConfirmation(true);
  };

  const handleArchive = async () => {
    const res = await dispatch(
      saveJob({
        jobId: prodd?.jobId,
        jobStatus: "ARCHIVE",
        jobInstId: user?.usrInstId,
      })
    );
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
      fetchJobsDetails();
      setIsModalOpenConfirmation(false);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const handleEndRecruitment = async () => {
    const res = await dispatch(
      saveJob({
        jobId: prodd?.jobId,
        jobAdvertStatus: "END_RECRUITMENT",
        jobInstId: user?.usrInstId,
      })
    );
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
      fetchJobsDetails();
      setIsModalOpenEndConfirmation(false);
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const settingItems = [
    {
      key: "0",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={showDrawer}
        >
          View
        </div>
      ),
      icon: (
        <button className="" type="button">
        <MaterialIcon
          color="#000"
          icon="visibility" 
        />
      </button>
      ),
    },
    {
      key: "1",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={showModalConfirmation}
        >
          Archive
        </div>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M8.29 13.29C8.19627 13.383 8.12188 13.4936 8.07111 13.6154C8.02034 13.7373 7.9942 13.868 7.9942 14C7.9942 14.132 8.02034 14.2627 8.07111 14.3846C8.12188 14.5064 8.19627 14.617 8.29 14.71L11.29 17.71C11.383 17.8037 11.4936 17.8781 11.6154 17.9289C11.7373 17.9797 11.868 18.0058 12 18.0058C12.132 18.0058 12.2627 17.9797 12.3846 17.9289C12.5064 17.8781 12.617 17.8037 12.71 17.71L15.71 14.71C15.8983 14.5217 16.0041 14.2663 16.0041 14C16.0041 13.7337 15.8983 13.4783 15.71 13.29C15.5217 13.1017 15.2663 12.9959 15 12.9959C14.7337 12.9959 14.4783 13.1017 14.29 13.29L13 14.59V3C13 2.73478 12.8946 2.48043 12.7071 2.29289C12.5196 2.10536 12.2652 2 12 2C11.7348 2 11.4804 2.10536 11.2929 2.29289C11.1054 2.48043 11 2.73478 11 3V14.59L9.71 13.29C9.61704 13.1963 9.50644 13.1219 9.38458 13.0711C9.26272 13.0203 9.13201 12.9942 9 12.9942C8.86799 12.9942 8.73728 13.0203 8.61542 13.0711C8.49356 13.1219 8.38296 13.1963 8.29 13.29ZM18 9H16C15.7348 9 15.4804 9.10536 15.2929 9.29289C15.1054 9.48043 15 9.73478 15 10C15 10.2652 15.1054 10.5196 15.2929 10.7071C15.4804 10.8946 15.7348 11 16 11H18C18.2652 11 18.5196 11.1054 18.7071 11.2929C18.8946 11.4804 19 11.7348 19 12V19C19 19.2652 18.8946 19.5196 18.7071 19.7071C18.5196 19.8946 18.2652 20 18 20H6C5.73478 20 5.48043 19.8946 5.29289 19.7071C5.10536 19.5196 5 19.2652 5 19V12C5 11.7348 5.10536 11.4804 5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11H8C8.26522 11 8.51957 10.8946 8.70711 10.7071C8.89464 10.5196 9 10.2652 9 10C9 9.73478 8.89464 9.48043 8.70711 9.29289C8.51957 9.10536 8.26522 9 8 9H6C5.20435 9 4.44129 9.31607 3.87868 9.87868C3.31607 10.4413 3 11.2044 3 12V19C3 19.7956 3.31607 20.5587 3.87868 21.1213C4.44129 21.6839 5.20435 22 6 22H18C18.7956 22 19.5587 21.6839 20.1213 21.1213C20.6839 20.5587 21 19.7956 21 19V12C21 11.2044 20.6839 10.4413 20.1213 9.87868C19.5587 9.31607 18.7956 9 18 9Z"
            fill="black"
          />
        </svg>
      ),
    },

    {
      key: "2",
      label: (
        <div
          className={`font-dmSans text-black font-[400] text-[19px] ${
            prodd?.jobAdvertStatus != "APPROVED"
              ? "cursor-not-allowed"
              : "cursor-pointer"
          }`}
          onClick={
            prodd?.jobAdvertStatus != "APPROVED"
              ? null
              : showModalEndConfirmation
          }
          disabled={prodd?.jobAdvertStatus != "APPROVED"}
        >
          End Recruitment Process
        </div>
      ),
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M8 12H16M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3Z"
            stroke="#101828"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
    },
  ];

  // const readvertisedJob = jobData?.filter(
  //   (item) => item?.jobIsReadvertised === true && item.jobStatus != "ARCHIVE"
  // );

  const filteredData = searchText
    ? readvertisedJobs?.filter((item) =>
        item?.jobPosition?.toLowerCase().includes(searchText.toLowerCase())
      )
    : readvertisedJobs;

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  const { applicantEditId, applicants, singleCreateApplicant } = useSelector(
    (state) => state.applicant
  );

  const [applicantsList, setapplicantsList] = useState(applicants);
  const [singleClient, setSingleClient] = useState("");
  const [receivedId, setReceivedId] = useState(
    applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
  );

  useEffect(() => {
    setapplicantsList(applicants);
  }, [applicants]);

  useEffect(() => {
    setReceivedId(
      applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
    );
  }, [applicantEditId, singleCreateApplicant?.usrId]);

  useEffect(() => {
    const singleItem =
      applicantsList?.length > 0 &&
      applicantsList?.find((item) => item?.usrId == receivedId);
    setSingleClient(singleItem);
  }, [receivedId, applicantsList]);

  useEffect(() => {}, [singleClient]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [rowId, setRowId] = useState([]);

  const handleAddJobToApplicant = async (selectedRows) => {
    setRowId(selectedRows);
  };

  const onSelectChange = (keys, rows) => {
    setSelectedRowKeys(keys);
    handleAddJobToApplicant(rows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchJobsDetails(page,size) {
    const res = await dispatch(
      fetchReadvertisedJobs({
        jobInstId: user?.usrInstId, 
        readvertised:true, 
        limit: size ?? pageSize,
        start: page ?? pageIndex,
      })
    );
  }

  useEffect(() => {
    if (refetchKeyJobTables == 4) {
      fetchJobsDetails();
    }
  }, [refetchKeyJobTables]);

  return (
    <>
      {jobLoading ? (
        <>
          <Skeleton />
        </>
      ) : (
        <>
          {readvertisedJobs && readvertisedJobs?.length > 0 ? (
            <div className="min-h-[895px] bg-white border border-gray-200  mt-[1%]">
              <div className=" p-[50px]">
              <div
                  className={`text-black text-[1.5rem] dash-title font-bold font-dmSans leading-[33px] mb-[31px]`}
                >
               Re-advertised jobs
                </div>
                <div className="flex justify-between items-center w-full mt-[31px]">
                  <div className="flex items-center w-full ">
                    <Input
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder="Search job name or keyword"
                      className="text-[16px] font-[400] flex-row-reverse"
                      prefix={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.2508 3.75C7.50609 3.75 4.47041 6.93997 4.47041 10.875C4.47041 14.81 7.50609 18 11.2508 18C14.9955 18 18.0312 14.81 18.0312 10.875C18.0312 6.93997 14.9955 3.75 11.2508 3.75ZM3.04297 10.875C3.04297 6.11154 6.71773 2.25 11.2508 2.25C15.7838 2.25 19.4586 6.11154 19.4586 10.875C19.4586 15.6385 15.7838 19.5 11.2508 19.5C6.71773 19.5 3.04297 15.6385 3.04297 10.875Z"
                            fill="#333333"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.045 15.913C16.3237 15.6201 16.7756 15.6201 17.0543 15.913L21.3902 20.4693C21.6689 20.7622 21.6689 21.237 21.3902 21.5299C21.1115 21.8228 20.6596 21.8228 20.3809 21.5299L16.045 16.9737C15.7663 16.6808 15.7663 16.2059 16.045 15.913Z"
                            fill="#333333"
                          />
                        </svg>
                      }
                    />
                  </div> 
                </div> 
              </div>

              <div className="mt-[6px] ml-[45px] mr-[10px]">
                <Table
                  rowSelection={rowSelection}
                  size={"middle"}
                  bordered={true}
                  pagination={{
                    position: ["bottomCenter"],
                    current: pageIndex + 1,
                    total: jobsCount,
                    pageSize: pageSize,
                    onChange: (page, size) => {
                      setPageIndex(page - 1);
                      setPageSize(size);
                      fetchJobsDetails(page - 1, size);
                    },
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                  }}
                  rowKey={(record) => record?.jobId}
                  columns={tableColumns}
                  dataSource={filteredData}
                  scroll={{
                    x: 1500,
                  }}
                  loading={jobLoading}
                />
              </div>
            </div>
          ) : (
            <NoDataTableJob content="Re-advertised jobs will be displayed here" />
          )}
        </>
      )}

      <ConfirmationModal
        isModalOpen={isModalOpenConfirmation}
        setIsModalOpen={setIsModalOpenConfirmation}
        handleSubmit={handleArchive}
        loading={jobLoading}
        content={`Are you sure you want to archive this job`}
      />
      <EndRecruitmentConfirmationModal
        isModalOpen={isModalOpenEndConfirmation}
        setIsModalOpen={setIsModalOpenEndConfirmation}
        handleSubmit={handleEndRecruitment}
        loading={jobLoading}
        content={`Are you sure you want to end recruitment process for ${prodd?.jobPosition}`}
      />
    </>
  );
}

export default ReadvertisedJobTable;
