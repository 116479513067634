import React, { useRef, useState } from 'react';
import { Button, Form, Input, Modal, Spin } from 'antd';
import '../../../clients/client.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import uplodSvg from '../../../../assets/uploadPic.png';
import toast from 'react-hot-toast';
import { saveFile } from '../../../../features/application/applicationSlice';
import { saveEmployee } from '../../../../features/myEmployees/myEmployeesSlice';
import { formatImgPath } from '../../../../utils';
import { saveFolder } from '../../../../features/folder/folderSlice';

const { TextArea } = Input;

const AddFolderModal = ({ open, handleCancel, handleFetchListData }) => {
	const [form] = Form.useForm();
	const formRef = useRef(null);
	const { user } = useSelector((state) => state.auth);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { fldLoading } = useSelector((state) => state.folder);

	const onFinish = async (data) => {
        data.fldInstId = user?.usrInstId
		await dispatch(saveFolder(data))
		form.resetFields();
        await handleFetchListData()
        await handleCancel()
	};

	return (
		<>
			<Modal
				className='modal-btn mod-height mod-footer'
				title='Add group'
				open={open}
				onOk={onFinish}
				onCancel={handleCancel}
				width={520}
			> 
				<Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					onFinish={onFinish}
					className=''
					style={{
						maxWidth: '100%',
					}}
					form={form}
				> 
 
						<Form.Item
							name='fldName'
							label='Group name'
							rules={[
								{
									required: true,
									message: 'Enter name of group',
								},
							]}
						>
							<Input className='input' />
						</Form.Item>
					 
					<div className='w-full'>
						<Form.Item name='fldDesc' label='Description'
						rules={[
							{
								required: true,
								message: 'Enter description of this group',
							},
						]}
						extra={"Write a brief description of this group"}>
							<TextArea
							required
								rows={4}
								placeholder='Enter description'
								className='input-textarea'
							/>
						</Form.Item>
					</div>
					<div className='flex justify-between mt-[156px] mb-[48px]'>
						<div className='justify-start'></div>
						<div className='justify-end flex items-center gap-x-2'>
							<button
								onClick={handleCancel}
								className='w-[149px] h-[50px] p-2 justify-center items-center rounded-[36px] border-2 border-none flex text-blueDark text-[18px]'
							>
								Cancel
							</button>

							

							<button
								key='submit'
								type='submit'
								className='w-[132px] h-[50px] px-10 py-3 bg-blueDark rounded-[28px] justify-center items-center text-white text-[18px]'
								disabled={fldLoading}
							>
								{fldLoading ? <Spin /> : 'Save'}
							</button>
						</div>
					</div>
				</Form>
			</Modal>
		</>
	);
};
export default AddFolderModal;
