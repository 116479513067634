import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  cvsData: {},
  cvLoading: false,
  cvEditMode: false,
  cvViewMode: false,
  cvEditId:'',
  refetch:false,
  refetchKey:'',
  applicantJobViewTable: false,
};

export const saveApplicantCv = createAsyncThunk(
  "cv/saveApplicantCv",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveApplicantCv.action`, data);
    return res.data?.jsonData;
  }
);

export const fetchApplicantCvs = createAsyncThunk(
  "cv/fetchApplicantCvs",
  async (data) => {
    const res = await axiosInstance.get(
      `${url}/nea/fetchApplicantCvs.action?cvUsrId=${data?.cvUsrId}`
    );
    return res.data?.data?.result;
  }
);

export const cvSlice = createSlice({
  name: "letter",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    },
    setcvEditMode: (state, action) => {
      state.cvEditMode = action.payload;
    },
    setcvViewMode: (state, action) => {
      state.cvViewMode = action.payload;
    },
    setApplicantJobViewTable: (state, action) => {
      state.applicantJobViewTable = action.payload;
    },
    setRefetchKey: (state, action) => {
      state.refetchKey = action.payload;
    },
    
    
    cleanupcv: (state) => {
      state.cvEditMode = initialState.cvEditMode;
      state.cvEditId = initialState.cvEditId;
      state.applicantJobViewTable = initialState.applicantJobViewTable;
      state.refetchKey = initialState.refetchKey;
    },
    setCvEditId:(state,action)=>{
      state.cvEditId = action.payload;
    },
    setRefetch:(state)=>{
      state.refetch = !state.refetch;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(saveApplicantCv.pending, (state) => {
        state.cvLoading = true;
      })
      .addCase(saveApplicantCv.fulfilled, (state, action) => {
        state.cvLoading = false;
      })
      .addCase(saveApplicantCv.rejected, (state) => {
        state.cvLoading = false;
      })

      .addCase(fetchApplicantCvs.pending, (state) => {
        state.cvLoading = true;
      })
      .addCase(fetchApplicantCvs.fulfilled, (state, action) => {
        state.cvLoading = false;
        state.cvsData = action.payload[0];
      })
      .addCase(fetchApplicantCvs.rejected, (state) => {
        state.cvLoading = false;
      });
  },
});

export default cvSlice.reducer;
export const {
  handleSidenavFullWidth,
  setcvEditMode,
  cleanupcv,
  setCvEditId,
  setRefetch,
  setcvViewMode,
  setApplicantJobViewTable,
  setRefetchKey
} = cvSlice.actions;
