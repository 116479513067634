import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Breadcrumb,
  Collapse,
  DatePicker,
  Form,
  Input,
  Select,
  Spin,
  theme,
} from "antd";
import dayjs from "dayjs";
import { saveGraduate } from "../../../features/graduates/GraduateSlice";
import {
  dropDownCleanUp,
  fetchAwards,
  fetchCourses,
  fetchGrades,
  fetchInstitutionTypes,
  fetchStudyAreas,
} from "../../../features/dropDowns/dropDownSlice";
import { customToast } from "../../../notifications";
import PhoneInput from "react-phone-input-2";
import InsideHeader from "../../../components/InsideHeader";

function EditGraduate() {
  const { token } = theme.useToken();
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { dropDownLoading, instTypes, studyAreas, awards, courses, grades } =
    useSelector((state) => state.dropDown);

  const { gradLoading, gradListObj, graduateObj } = useSelector(
    (state) => state.graduate
  );

  const { folderObj } = useSelector((state) => state.folder);

  const { user } = useSelector((state) => state.auth);

  const [mminDate, setmminDate] = useState();

  function handleDateCHnage(date) {
    setmminDate(date);
  }

  async function handleFetchCourses(name) {
    let ardObj = awards?.find(
      (item) =>
        String(item?.awdName).toUpperCase() === String(name).toUpperCase()
    );
    await dispatch(fetchCourses(ardObj?.awdId));
  }

  function handleGoBack() {
    navigate(-1);
  }

  function handleCancel() {
    navigate(`/graduates/folders/list/${gradListObj?.gdcTitle}`);
  }

  const panelStyle = {
    marginBottom: 24,
    background: "#fff",
    borderRadius: token.borderRadiusLG,
    border: "1px solid #147CBC",
  };

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <div className="flex flex-row w-[100%] gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <circle cx="12.5" cy="12.5" r="12" fill="white" stroke="#147CBC" />
            <circle cx="12.7904" cy="12.7943" r="2.32558" fill="#147CBC" />
          </svg>

          <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
            Graduate Details
          </div>
        </div>
      ),
      children: (
        <div className="p-10">
          <span className="accordion-sub-title">Basic Info</span>
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            style={{
              maxWidth: "100%",
              marginTop: "30px",
            }}
            initialValues={{
              ...graduateObj,
              grdStartDate: graduateObj?.grdStartDate
                ? dayjs(graduateObj?.grdStartDate)
                : null,
              grdGradDate: graduateObj?.grdGradDate
                ? dayjs(graduateObj?.grdGradDate)
                : null,
            }}
            form={form}
          >
            <div className="grid grid-cols-2 gap-x-10 w-full mb-5">
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="grdFirstName"
                label="First Name"
              >
                <Input className="input" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="grdLastName"
                label="Last Name"
              >
                <Input className="input" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="grdNationalId"
                label="National ID"
              >
                <Input className="input" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="grdAdmNo"
                label="Admission No."
              >
                <Input className="input" />
              </Form.Item>
            </div>

            <span className="accordion-sub-title">Academic Info</span>

            <div className="grid grid-cols-2 gap-x-10 mb-5 w-full mt-5">
              {/* <Form.Item
								label='Institution Type'
								name='grdInstType'
								className='flex flex-col w-[100%]'>
								<Select
									showSearch
									loading={dropDownLoading}
									style={{
										width: '100%',
										height: '3.25rem',
									}}
									className='mt-2'
									placeholder='Search to Select'
									optionFilterProp='children'
									filterOption={(input, option) =>
										(option?.label?.toLocaleLowerCase() ?? '').includes(
											input?.toLocaleLowerCase()
										)
									}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '')
											.toLowerCase()
											.localeCompare((optionB?.label ?? '').toLowerCase())
									}
									options={
										instTypes?.length > 0 &&
										instTypes?.map((item) => ({
											value: item.instpName,
											label: item.instpName,
										}))
									}
								/>
							</Form.Item> */}

              <Form.Item
                label="Institution Name"
                name="grdDesc"
                className="flex flex-col w-[100%]"
              >
                <Input readOnly className="input" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                label="Area of Study"
                name="grdAreaOfStudy"
                className="flex flex-col w-[100%]"
              >
                <Select
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "3.25rem",
                  }}
                  className="mt-2"
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    studyAreas?.length > 0 &&
                    studyAreas?.map((item) => ({
                      value: item.stdyName,
                      label: item.stdyName,
                    }))
                  }
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                label="Award"
                name="grdAward"
                className="flex flex-col w-[100%]"
              >
                <Select
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "3.25rem",
                  }}
                  className="mt-2"
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  onChange={(val) => {
                    handleFetchCourses(val);
                  }}
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    awards?.length > 0 &&
                    awards?.map((item) => ({
                      value: item.awdName,
                      label: item.awdName,
                    }))
                  }
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                label="Course"
                name="grdCourse"
                className="flex flex-col w-[100%]"
              >
                <Select
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "3.25rem",
                  }}
                  className="mt-2"
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    courses?.length > 0 &&
                    courses?.map((item) => ({
                      value: item.corsName,
                      label: item.corsName,
                    }))
                  }
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                label="Grade"
                name="grdGrade"
                className="flex flex-col w-[100%]"
              >
                <Select
                  showSearch
                  loading={dropDownLoading}
                  style={{
                    width: "100%",
                    height: "3.25rem",
                  }}
                  className=""
                  placeholder="Search to Select"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLocaleLowerCase() ?? "").includes(
                      input?.toLocaleLowerCase()
                    )
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={
                    grades?.length > 0 &&
                    grades?.map((item) => ({
                      value: item.grdeName,
                      label: item.grdeName,
                    }))
                  }
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="grdCertNo"
                label="Certificate No."
              >
                <Input className="input" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="grdStartDate"
                label="Start Date"
              >
                <DatePicker
                  onChange={handleDateCHnage}
                  maxDate={dayjs(new Date())}
                  className="input"
                />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="grdGradDate"
                label="Graduation Date"
              >
                <DatePicker minDate={dayjs(mminDate)} className="input" />
              </Form.Item>
            </div>

            <span className="accordion-sub-title mt-10">Contact Info</span>

            <div className="grid grid-cols-2 gap-x-10 w-full mt-5">
              <Form.Item
                rules={[
                  {
                    type: "email",
                    required: false,
                    message:
                      "Enter correct email format (i.e example@gmail.com)",
                  },
                ]}
                name="grdEmail"
                label="Email"
              >
                <Input className="input" />
              </Form.Item>
              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Field is required",
                  },
                ]}
                name="grdMobileNumber"
                label="Mobile No."
              >
                <PhoneInput
                  country="ke"
                  onlyCountries={["ke"]}
                  countryCodeEditable={false}
                  className="input"
                />
              </Form.Item>
            </div>

            <div className="flex items-center justify-end mt-14 mb-14 w-full">
              <button
                onClick={handleCancel}
                disabled={gradLoading}
                className="w-[149px] h-[50px] p-2 justify-center items-center rounded-[36px] border-2 border-none flex !text-blueDark text-[18px] "
                type="button"
              >
                Cancel
              </button>
              <button
                disabled={gradLoading}
                className="w-[132px] h-[50px] px-10 py-3 bg-blueDark rounded-[28px] justify-center items-center text-white text-[18px]"
                type="submit"
              >
                {gradLoading ? <Spin /> : "Save"}
              </button>
            </div>
          </Form>
        </div>
      ),
      showArrow: true,
      style: panelStyle,
    },
  ];

  const onFinish = async (values) => {
    values.grdId = graduateObj?.grdId;
    values.grdStartDate = values.grdStartDate?.$d;
    values.grdGradDate = values.grdGradDate?.$d;
    values.grdFldId = folderObj?.fldId;
    values.grdCreatedBy = user?.usrId;
    const res = await dispatch(
      saveGraduate({
        ...values,
      })
    );
    if (res?.payload?.success) {
      await customToast({
        content: "Graduate details updated",
        bdColor: "success",
        header: "Success",
      });
      await form.resetFields();
      await navigate(`/graduates/folder/${folderObj?.fldName}`);
    } else {
      await customToast({
        content: res?.payload?.messages?.message,
        bdColor: "error",
        header: "Error",
      });
    }
  };

  function handleCleanUp() {
    dispatch(dropDownCleanUp());
  }

  async function handleDropdownOptions() {
    await dispatch(fetchInstitutionTypes());
    await dispatch(fetchAwards());
    await dispatch(fetchStudyAreas());
    await dispatch(fetchGrades());
  }

  useEffect(() => {}, [
    instTypes,
    studyAreas,
    awards,
    courses,
    graduateObj,
    grades,
  ]);

  useEffect(() => {
    handleCleanUp();
    handleDropdownOptions();
  }, []);

  return (
    <>
      <InsideHeader
        title="Graduates"
        subtitle="Manage graduates within your institution"
        back={false}
      />

      <div className="mt-[2.5rem] px-10 font-dmSans text-[18px]">
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer"
                  onClick={() => navigate("/graduates")}
                >
                  Graduates
                </span>
              ),
            },
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer"
                  onClick={() =>
                    navigate(`/graduates/folder/${folderObj?.fldName}`)
                  }
                >
                  {folderObj?.fldName}
                </span>
              ),
            },
            {
              title: (
                <span className="text-blueDark font-dmSans">Edit graduate</span>
              ),
            },
          ]}
        />
      </div>

      <div className="bg-white mr-3 mb-[10px] flex items-center justify-between h-auto px-[39px]">
        <div className="flex flex-col w-[100%]">
          <div className="text-black text-[22px] font-bold font-dmSans leading-[33px] py-7">
            View/update details of graduate
          </div>
          <Collapse
            bordered={false}
            expandIconPosition="end"
            defaultActiveKey={["1", "2", "3", "4", "5", "6"]}
            style={{
              background: "#fff",
            }}
            items={getItems(panelStyle)}
          />
        </div>
      </div>
    </>
  );
}

export default EditGraduate;
