import { Tabs } from 'antd';
import EmployeeTable from './EmployeeTable';
import FolderTable from '../folder_files/FolderTable';

export default function EmployeesSection() {
	const items = [
		{
			key: '1',
			label: 'Employees',
			children: <EmployeeTable />, 
		},
		{
			key: '2',
			label: 'Groups',
			children: <FolderTable />, 
		},
	];

	return (
		<>
			<Tabs className='employee-tab employee-bar' defaultActiveKey='1' items={items} />
		</>
	);
}
