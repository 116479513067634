import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import backArrow from "../../../assets/Back.svg"; 
import Candidates from "./overview/Candidates";
import JobTable from "./jobs/JobTable";
import { fetchSingleAdminUser } from "../../../features/admin/adminSlice";

function UsersView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {userEditId,singleAdminUser} =  useSelector((state)=>state.admin)

  const handleBack = async () => {
    await navigate("/users-list");
  };

  const { TabPane } = Tabs;

  const tabConfigurations = [
    {
      label: (
        <span className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 5.50002H12.72L12.4 4.50002C12.1926 3.91325 11.8077 3.40553 11.2989 3.04718C10.7901 2.68884 10.1824 2.49762 9.56 2.50002H5C4.20435 2.50002 3.44129 2.81609 2.87868 3.3787C2.31607 3.94131 2 4.70437 2 5.50002V18.5C2 19.2957 2.31607 20.0587 2.87868 20.6213C3.44129 21.184 4.20435 21.5 5 21.5H19C19.7956 21.5 20.5587 21.184 21.1213 20.6213C21.6839 20.0587 22 19.2957 22 18.5V8.50002C22 7.70437 21.6839 6.94131 21.1213 6.3787C20.5587 5.81609 19.7956 5.50002 19 5.50002ZM20 18.5C20 18.7652 19.8946 19.0196 19.7071 19.2071C19.5196 19.3947 19.2652 19.5 19 19.5H5C4.73478 19.5 4.48043 19.3947 4.29289 19.2071C4.10536 19.0196 4 18.7652 4 18.5V5.50002C4 5.23481 4.10536 4.98045 4.29289 4.79292C4.48043 4.60538 4.73478 4.50002 5 4.50002H9.56C9.76964 4.49948 9.97416 4.56484 10.1446 4.68686C10.3151 4.80889 10.4429 4.9814 10.51 5.18002L11.05 6.82002C11.1171 7.01864 11.2449 7.19116 11.4154 7.31318C11.5858 7.4352 11.7904 7.50056 12 7.50002H19C19.2652 7.50002 19.5196 7.60538 19.7071 7.79292C19.8946 7.98045 20 8.23481 20 8.50002V18.5Z"
              fill=""
            />
          </svg>
          <span className="ml-2 ">Overview</span>
        </span>
      ),
      key: "1",
      children: <Candidates />, 
    },

    

    {
      label: (
        <span className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M19 5.50002H12.72L12.4 4.50002C12.1926 3.91325 11.8077 3.40553 11.2989 3.04718C10.7901 2.68884 10.1824 2.49762 9.56 2.50002H5C4.20435 2.50002 3.44129 2.81609 2.87868 3.3787C2.31607 3.94131 2 4.70437 2 5.50002V18.5C2 19.2957 2.31607 20.0587 2.87868 20.6213C3.44129 21.184 4.20435 21.5 5 21.5H19C19.7956 21.5 20.5587 21.184 21.1213 20.6213C21.6839 20.0587 22 19.2957 22 18.5V8.50002C22 7.70437 21.6839 6.94131 21.1213 6.3787C20.5587 5.81609 19.7956 5.50002 19 5.50002ZM20 18.5C20 18.7652 19.8946 19.0196 19.7071 19.2071C19.5196 19.3947 19.2652 19.5 19 19.5H5C4.73478 19.5 4.48043 19.3947 4.29289 19.2071C4.10536 19.0196 4 18.7652 4 18.5V5.50002C4 5.23481 4.10536 4.98045 4.29289 4.79292C4.48043 4.60538 4.73478 4.50002 5 4.50002H9.56C9.76964 4.49948 9.97416 4.56484 10.1446 4.68686C10.3151 4.80889 10.4429 4.9814 10.51 5.18002L11.05 6.82002C11.1171 7.01864 11.2449 7.19116 11.4154 7.31318C11.5858 7.4352 11.7904 7.50056 12 7.50002H19C19.2652 7.50002 19.5196 7.60538 19.7071 7.79292C19.8946 7.98045 20 8.23481 20 8.50002V18.5Z"
              fill=""
            />
          </svg>
          <span className="ml-2">Jobs</span>
        </span>
      ),
      key: "3",
      children: <JobTable/>,
    },
  ];

  async function fetchSingleAdminUserDetails(){
    await dispatch(fetchSingleAdminUser({
      usrId:userEditId
    }))
  }

  useEffect(()=>{
    fetchSingleAdminUserDetails()
  },[])

  return (
    <>
      <div className="bg-white mr-3 mb-[10px] flex items-center justify-between h-[115px] px-[39px]">
        <div className="items-center flex text-center gap-x-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="73"
            height="73"
            viewBox="0 0 73 73"
            fill="none"
          >
            <g clip-path="url(#clip0_3241_15554)">
              <path
                d="M36.4987 6.08203C19.7087 6.08203 6.08203 19.7087 6.08203 36.4987C6.08203 53.2887 19.7087 66.9154 36.4987 66.9154C53.2887 66.9154 66.9154 53.2887 66.9154 36.4987C66.9154 19.7087 53.2887 6.08203 36.4987 6.08203ZM36.4987 15.207C41.5479 15.207 45.6237 19.2829 45.6237 24.332C45.6237 29.3812 41.5479 33.457 36.4987 33.457C31.4495 33.457 27.3737 29.3812 27.3737 24.332C27.3737 19.2829 31.4495 15.207 36.4987 15.207ZM36.4987 58.3987C28.8945 58.3987 22.1724 54.5054 18.2487 48.6045C18.3399 42.5516 30.4154 39.2362 36.4987 39.2362C42.5516 39.2362 54.6574 42.5516 54.7487 48.6045C50.8249 54.5054 44.1029 58.3987 36.4987 58.3987Z"
                fill="#0873B9"
              />
            </g>
            <defs>
              <clipPath id="clip0_3241_15554">
                <rect width="73" height="73" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <span className="text-black1 text-2xl font-bold font-dmSans leading-[54px]">
           {singleAdminUser?.usrFirstName}  {singleAdminUser?.usrLastName}
          </span>
        </div>

        <div className="flex-end items-center flex gap-[5rem]">
          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={handleBack}
          >
            <div
              style={{
                height: "35px",
                width: "35px",
                background: "#EDF8FF",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={backArrow}
                alt="back-arrow"
                style={{ height: "40px", width: "40px" }}
              />
            </div>

            <span className="text-black2 text-[22px] font-bold font-dmSans leading-relaxed">
              Go back
            </span>
          </div>
        </div>
      </div>

      <div className="h-[111px] bg-white border border-gray-200 mr-3 mt-[1%]">
        <Tabs
          items={tabConfigurations}
          defaultActiveKey="1"
          className="users-tab"
        />
      </div>
 
    </>
  );
}

export default UsersView;
