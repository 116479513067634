import { Tabs } from "antd";
import React, { useState } from "react";
import {
  setRefetch,
  setRefetchKey,
} from "../../features/myEmployees/myEmployeesSlice";
import { useDispatch } from "react-redux";
import MyEmployeesTable from "./my-employees/MyEmployeesTable";
import RedundancyTable from "./RedundancyTable";
import Certificate from "./Certificate";
import InsideHeader from "../../components/InsideHeader";
import LayOffTable from "./LayOffTable";

function EmploymentReturnsView() {
  const { TabPane } = Tabs;

  const [active, setactive] = useState("1");
  const dispatch = useDispatch();

  function refetching(activeKey) {
    dispatch(setRefetch());
    dispatch(setRefetchKey(activeKey));
    setactive(activeKey);
  }

  const tabConfigurations = [
    {
      label: (
        <span className="flex items-center">
          <span className="">My Employees</span>
        </span>
      ),
      key: "1",
      children: <MyEmployeesTable />,
    },
    {
      label: (
        <span className="flex items-center">
          <span className="">Redundancies</span>
        </span>
      ),
      key: "2",
      children: <RedundancyTable />,
    },
    {
      label: (
        <span className="flex items-center">
          <span className="">Laid Off</span>
        </span>
      ),
      key: "3",
      children: <LayOffTable />,
    },
    
    {
      label: (
        <span className="flex items-center">
          <span className="">Certificate</span>
        </span>
      ),
      key: "4",
      children: <Certificate />,
    },
  ];
  return (
    <>
     <InsideHeader
        title="Employment returns"
        subtitle="Manage your employee details and returns."
        back={false}
      /> 
      <div className="h-[111px] mr-3 px-10">
        <Tabs
          activeKey={active}
          onChange={refetching}
          items={tabConfigurations}
          defaultActiveKey="1"
          className="returns-tab returns-bar returns-tab-wrap"
        />
      </div>
    </>
  );
}

export default EmploymentReturnsView;
