import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	licenseLoading: false,
	agentLicence: [],
	licenseStep: 0,
};

export const fetchAgencyLicense = createAsyncThunk(
	'licenseSlice/fetchAgencyLicense',
	async () => {
		const res = await axiosInstance.get(`${url}/nea/fetchLicenses.action`);
		return res.data;
	},
);

export const licenseSlice = createSlice({
	name: 'license',
	initialState,
	reducers: {
		handleLicenseStep: (state, action) => {
			state.licenseStep = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(fetchAgencyLicense.pending, (state) => {
				state.licenseLoading = true;
			})
			.addCase(fetchAgencyLicense.fulfilled, (state, action) => {
				state.licenseLoading = false;
				state.agentLicence = action?.payload?.data?.result;
			})
			.addCase(fetchAgencyLicense.rejected, (state) => {
				state.licenseLoading = false;
				state.agentLicence = [];
			});
	},
});

export default licenseSlice.reducer;
export const { handleLicenseStep } = licenseSlice.actions;
