import React, { useEffect, useRef, useState } from "react";
import backArrow from "../../../assets/Back.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Breadcrumb,
  Collapse,
  DatePicker,
  Form,
  Input,
  Select,
  Spin,
  theme,
} from "antd";
import toast from "react-hot-toast";
import {
  fetchEmployees,
  saveEmployee,
} from "../../../features/myEmployees/myEmployeesSlice";
import dayjs from "dayjs";
import EmployeeTable from "../employee_files/EmployeeTable";
import AddEmployeeToFolderModal from "./modals/AddEmployeeToFolderModal";
import InsideHeader from "../../../components/InsideHeader";
import container from "../../../assets/Container-employees.png";

const { TextArea } = Input;

function FolderDetails() {
  const { token } = theme.useToken();
  const formRef = useRef(null);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { employeeLoading, employeeFolderFilterLength } = useSelector(
    (state) => state.employee
  );
  const { folderObj, fldLoading } = useSelector((state) => state.folder);
  const { user } = useSelector((state) => state.auth);

  const [data, setdata] = useState(folderObj);
  const [open, setopen] = useState(false);

  function handleCancel() {
    setopen(false);
  }

  function handleOpen() {
    navigate("/folder-employee-add");
    // setopen(true);
  }

  function handleGoBack() {
    navigate(-1);
  }

  const panelStyle = {
    marginBottom: 24,
    background: "#fff",
    borderRadius: token.borderRadiusLG,
    border: "1px solid #147CBC",
  };

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <div className="flex flex-row w-[100%] gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
          >
            <circle cx="12.5" cy="12.5" r="12" fill="white" stroke="#147CBC" />
            <circle cx="12.7904" cy="12.7943" r="2.32558" fill="#147CBC" />
          </svg>

          <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-tight">
            Folder Info
          </div>
        </div>
      ),
      children: (
        <div className="p-10">
          <span className="accordion-sub-title">Update Folder Details</span>
          <Form
            layout="vertical"
            ref={formRef}
            name="control-ref"
            onFinish={onFinish}
            style={{
              maxWidth: "100%",
              marginTop: "30px",
            }}
            form={form}
            initialValues={{
              ...data,
              fldCreatedTime: dayjs(data?.fldCreatedTime),
            }}
          >
            <div className="grid grid-cols-2 gap-x-10 w-full mb-5">
              <Form.Item name="fldName" label="Folder Name">
                <Input className="input" />
              </Form.Item>

              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Field is required",
                  },
                ]}
                name="fldCreatedTime"
                label="Created Date"
              >
                <DatePicker disabled className="input" />
              </Form.Item>
            </div>

            <div className="w-full">
              <Form.Item name="fldDesc" label="Description">
                <TextArea
                  rows={4}
                  placeholder="Brief description about this folder"
                  className="input-textarea"
                />
              </Form.Item>
            </div>

            <div className="flex items-center justify-end mt-14 mb-14 w-full">
              <button
                disabled={fldLoading}
                className="w-[132px] h-[50px] px-10 py-3 bg-blueDark rounded-[28px] justify-center items-center text-white text-[18px]"
                type="submit"
              >
                {fldLoading ? <Spin /> : "Save"}
              </button>
            </div>
          </Form>
        </div>
      ),
      showArrow: true,
      style: panelStyle,
    },
    // {
    // 	key: '2',
    // 	label: (
    // 		<div className='flex flex-row w-[100%] gap-2'>
    // 			<svg
    // 				xmlns='http://www.w3.org/2000/svg'
    // 				width='25'
    // 				height='25'
    // 				viewBox='0 0 25 25'
    // 				fill='none'
    // 			>
    // 				<circle cx='12.5' cy='12.5' r='12' fill='white' stroke='#147CBC' />
    // 				<circle cx='12.7904' cy='12.7943' r='2.32558' fill='#147CBC' />
    // 			</svg>

    // 			<div className='text-blueDark text-[1rem] font-medium font-dmSans leading-tight'>
    // 				Employees
    // 			</div>
    // 		</div>
    // 	),
    // 	children: (
    // 		<div className=''>
    // 			<EmployeeTable hide={true} fldId={data?.fldId} />
    // 		</div>
    // 	),
    // 	showArrow: true,
    // 	style: panelStyle,
    // },
  ];

  const onFinish = async (values) => {
    values.emplContractCommencement = values.emplContractCommencement.$d;
    const res = await dispatch(
      saveEmployee({
        ...data,
        ...values,
      })
    );
    if (res?.payload?.emplId) {
      toast.success("Employee details saved successful");
      form.resetFields();
      navigate("/employees");
    } else {
      toast.error(
        "We cannot process your request at the moment please try again later"
      );
    }
  };

  async function handleFetchListData() {
    await dispatch(
      fetchEmployees({
        emplInstId: user?.usrInstId,
      })
    );
  }

  useEffect(() => {}, [data]);

  useEffect(() => {
    setdata(folderObj);
  }, [folderObj]);

  useEffect(() => {
    handleFetchListData();
  }, []);

  return (
    <>
      <InsideHeader
        title="Employees"
        subtitle="Manage employees within your organization"
        back={false}
      />

      <div className="mt-[2.5rem] px-10 font-dmSans text-[18px]">
        <Breadcrumb
          items={[
            {
              title: (
                <span
                  className="font-dmSans cursor-pointer"
                  onClick={() => navigate("/employees")}
                >
                  Employees
                </span>
              ),
            },
            {
              title: (
                <span
                  className="text-blueDark font-dmSans"
                  onClick={() => navigate(`/employees/folder/${data?.fldName}`)}
                >
                  {folderObj?.fldName}
                </span>
              ),
            },
          ]}
        />
      </div>

      <div className="px-10 mt-[51px]">
        <h3 className="dash-title font-[700] text-[24px] text-black1 leading-[32.4px]">
          {folderObj?.fldName}
        </h3>

        <div className="flex justify-between mb-[29px]">
          <div className="mt-[15px] text-[#616161] text-[16px] font-normal  leading-[24px]">
            Add employees to this group
          </div>
          {employeeFolderFilterLength > 0 && (
            <button
              className="w-[250px] h-[50px] px-[24px] py-3 bg-blueDark rounded-[28px] justify-center items-center gap-1 inline-flex
			          text-white text-[18px] leading-normal"
              onClick={handleOpen}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
              >
                <path
                  d="M19.5 11H13.5V5C13.5 4.73478 13.3946 4.48043 13.2071 4.29289C13.0196 4.10536 12.7652 4 12.5 4C12.2348 4 11.9804 4.10536 11.7929 4.29289C11.6054 4.48043 11.5 4.73478 11.5 5V11H5.5C5.23478 11 4.98043 11.1054 4.79289 11.2929C4.60536 11.4804 4.5 11.7348 4.5 12C4.5 12.2652 4.60536 12.5196 4.79289 12.7071C4.98043 12.8946 5.23478 13 5.5 13H11.5V19C11.5 19.2652 11.6054 19.5196 11.7929 19.7071C11.9804 19.8946 12.2348 20 12.5 20C12.7652 20 13.0196 19.8946 13.2071 19.7071C13.3946 19.5196 13.5 19.2652 13.5 19V13H19.5C19.7652 13 20.0196 12.8946 20.2071 12.7071C20.3946 12.5196 20.5 12.2652 20.5 12C20.5 11.7348 20.3946 11.4804 20.2071 11.2929C20.0196 11.1054 19.7652 11 19.5 11Z"
                  fill="#EDF8FF"
                />
              </svg>
              Add employee
            </button>
          )}
        </div>

        {employeeFolderFilterLength === 0 && (
          <div
            className=" bg-white w-full h-[471.33px] rounded-[15px] border border-solid
       border-[#F5F7FF] py-[60px] flex flex-col justify-center items-center cursor-pointer"
            onClick={handleOpen}
          >
            <div>
              <img
                src={container}
                alt="group-pic"
                className="object-contain h-[291.33px] w-[292px]"
              />
              <div className="empty-desc flex items-center justify-center">
                Add employees to this group
              </div>
            </div>

            <div className="mt-[2.63rem]">
              <button
                className="w-[250px] h-[50px] px-[24px] py-3 bg-blueDark rounded-[28px] justify-center items-center gap-1 inline-flex
        text-white text-[18px] leading-normal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M19.5 11H13.5V5C13.5 4.73478 13.3946 4.48043 13.2071 4.29289C13.0196 4.10536 12.7652 4 12.5 4C12.2348 4 11.9804 4.10536 11.7929 4.29289C11.6054 4.48043 11.5 4.73478 11.5 5V11H5.5C5.23478 11 4.98043 11.1054 4.79289 11.2929C4.60536 11.4804 4.5 11.7348 4.5 12C4.5 12.2652 4.60536 12.5196 4.79289 12.7071C4.98043 12.8946 5.23478 13 5.5 13H11.5V19C11.5 19.2652 11.6054 19.5196 11.7929 19.7071C11.9804 19.8946 12.2348 20 12.5 20C12.7652 20 13.0196 19.8946 13.2071 19.7071C13.3946 19.5196 13.5 19.2652 13.5 19V13H19.5C19.7652 13 20.0196 12.8946 20.2071 12.7071C20.3946 12.5196 20.5 12.2652 20.5 12C20.5 11.7348 20.3946 11.4804 20.2071 11.2929C20.0196 11.1054 19.7652 11 19.5 11Z"
                    fill="#EDF8FF"
                  />
                </svg>
                Add employee
              </button>
            </div>
          </div>
        )}
      </div>

      {employeeFolderFilterLength > 0 && (
        <div className="bg-white mr-3 mb-[10px] h-auto">
          <EmployeeTable hide={true} fldId={data?.fldId} />
        </div>
      )}

      <AddEmployeeToFolderModal
        handleCancel={handleCancel}
        open={open}
        data={data}
        handleFetchListData={handleFetchListData}
      />
    </>
  );
}

export default FolderDetails;
