import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, DatePicker, Form, Input, Spin, theme } from 'antd';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import { saveBranchAgency } from '../../../../features/agency/agencySlice';

const { TextArea } = Input;

function EditBranch() {
	const { token } = theme.useToken();
	const formRef = useRef(null);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { branchObj, agnLoading } = useSelector((state) => state.agency);

	const [data, setdata] = useState(branchObj);

	function handleGoBack() {
		navigate(-1);
	}

	const panelStyle = {
		marginBottom: 24,
		background: '#fff',
		borderRadius: token.borderRadiusLG,
		border: '1px solid #147CBC',
	};

	const getItems = (panelStyle) => [
		{
			key: '1',
			label: (
				<div className='flex flex-row w-[100%] gap-2'>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='25'
						height='25'
						viewBox='0 0 25 25'
						fill='none'
					>
						<circle cx='12.5' cy='12.5' r='12' fill='white' stroke='#147CBC' />
						<circle cx='12.7904' cy='12.7943' r='2.32558' fill='#147CBC' />
					</svg>

					<div className='text-blueDark text-[1rem] font-medium font-dmSans leading-tight'>
						Branch Info
					</div>
				</div>
			),
			children: (
				<div className='p-10'>
					<span className='accordion-sub-title'>Update Branch Details</span>
					<Form
						layout='vertical'
						ref={formRef}
						name='control-ref'
						onFinish={onFinish}
						style={{
							maxWidth: '100%',
							marginTop: '30px',
						}}
						form={form}
						initialValues={{
							...data,
							instCreatedTime: dayjs(data?.fldCreatedTime),
						}}
					>
						<div className='grid grid-cols-2 gap-x-10 w-full mb-5'>
							<Form.Item name='instName' label='Branch Name'>
								<Input className='input' />
							</Form.Item>

                            <Form.Item name='instEmail' label='Email'>
								<Input className='input' />
							</Form.Item>

                            <Form.Item name='instWorkTel' label='Phone No.'>
								<Input className='input' />
							</Form.Item>

                            <Form.Item name='instKraPin' label='KRA PIN'>
								<Input readOnly className='input' />
							</Form.Item>

							<Form.Item
								name='instCreatedTime'
								label='Created Date'
							>
								<DatePicker
									disabled
									className='input'
								/>
							</Form.Item>
						</div>

						<div className='flex items-center justify-end mt-14 mb-14 w-full'>
							<button
								disabled={agnLoading}
								className='bg-blueDark rounded-[8px] text-white w-[168px] p-3'
								type='submit'
							>
								{agnLoading ? <Spin /> : 'Save'}
							</button>
						</div>
					</Form>
				</div>
			),
			showArrow: true,
			style: panelStyle,
		},
	];

	const onFinish = async (values) => {
		const res = await dispatch(
			saveBranchAgency({
				...data,
				...values,
			}),
		);
		if (res?.payload?.success) {
			toast.success('Branch details successfully updated');
			form.resetFields();
			navigate('/branches');
		} else {
			toast.error(
				'We cannot process your request at the moment please try again later',
			);
		}
	};

	useEffect(() => {}, [data]);

	useEffect(() => {
		setdata(branchObj);
	}, [branchObj]);

	return (
		<>
			<div className='bg-white mr-3 mb-[10px] flex items-center justify-between h-[115px] px-[39px]'>
				<div className='items-center flex text-center gap-x-3'>
					<span className='text-black1 text-2xl font-bold font-dmSans leading-[54px]'>
						{data?.instName}
					</span>
				</div>

				<div className='flex-end items-center flex gap-[5rem]'>
					<div className='flex items-center'>
						<button
							type='button'
							onClick={handleGoBack}
							className='bg-transparent flex items-center'
						>
							<div className='flex justify-center items-center rounded-full w-[40px] h-[40px] p-2 bg-[#EDF8FF]'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='28'
									height='24'
									viewBox='0 0 28 24'
									fill='none'
								>
									<path
										d='M17.7692 18L11.0469 12L17.7692 6'
										stroke='#147CBC'
										stroke-width='2'
										stroke-linecap='round'
										stroke-linejoin='round'
									/>
								</svg>
							</div>
							<span className='ml-5 font-bold text-[22px] text-[#222222]'>
								Go Back
							</span>
						</button>
					</div>
				</div>
			</div>

			<div className='bg-white mr-3 mb-[10px] flex items-center justify-between h-auto px-[39px]'>
				<div className='flex flex-col w-[100%]'>
					<div className='text-black text-[18px] leading-[33px] py-[41px]'></div>
					<Collapse
						bordered={false}
						expandIconPosition='end'
						defaultActiveKey={['1']}
						style={{
							background: '#fff',
						}}
						items={getItems(panelStyle)}
					/>
				</div>
			</div>
		</>
	);
}

export default EditBranch;
