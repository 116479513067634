import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";

export default function Start() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { object } = useSelector((state) => state.redux);

  const [state, set] = useState();

  useEffect(() => {}, [state]);

  useEffect(() => {
    set(object);
  }, [object]);

  return (
    <>
      <div>
     
      </div>
    </>
  );
}
