import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
  usersData: [],
  usersLoading: false, 
  refetchKey:'', 
  userEditId:'',
  clientAdminView:false,
  singleAdminUser:{},
};
export const createAdminUsers = createAsyncThunk(
  "applicantSlice/createApplicantDetails",
  async (data) => {
    const res = await axiosInstance.post(`${url}/nea/saveUser.action`, data);
    return res.data;
  }
);

export const fetchAdminUsers = createAsyncThunk(
  "applicantSlice/fetchApplicantsDetails",
  async (data) => {
    const res = await axiosInstance.get(`${url}/nea/fetchUsers.action?usrInstId=${data?.usrInstId}&usrRoleId=6`);
    return res?.data?.data?.result;
  }
);

export const fetchSingleAdminUser = createAsyncThunk(
  "applicantSlice/fetchSingleAdminUser",
  async (data) => {
    const res = await axiosInstance.get(`${url}/nea/fetchUsers.action?usrId=${data?.usrId}`);
    return res?.data?.data?.result;
  }
);

export const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    handleSidenavFullWidth: (state) => {
      state.fullWidth = !state.fullWidth;
    }, 
    setRefetch:(state)=>{
      state.refetch = !state.refetch;
    },
    setUserEditId:(state,action)=>{
      state.userEditId = action.payload;
    },
    setClientAdminView:(state,action)=>{
      state.clientAdminView = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(createAdminUsers.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(createAdminUsers.fulfilled, (state, action) => {
        state.usersLoading = false;
      })
      .addCase(createAdminUsers.rejected, (state) => {
        state.usersLoading = false;
      })

      .addCase(fetchAdminUsers.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(fetchAdminUsers.fulfilled, (state, action) => {
        state.usersLoading = false;
        state.usersData = action.payload;
      })
      .addCase(fetchAdminUsers.rejected, (state) => {
        state.usersLoading = false;
      })
      
      .addCase(fetchSingleAdminUser.pending, (state) => {
        state.usersLoading = true;
      })
      .addCase(fetchSingleAdminUser.fulfilled, (state, action) => {
        state.usersLoading = false;
        state.singleAdminUser = action.payload[0];
      })
      .addCase(fetchSingleAdminUser.rejected, (state) => {
        state.usersLoading = false;
        state.singleAdminUser = {};
      })
  },
});

export default adminSlice.reducer;
export const {
  handleSidenavFullWidth, 
  setRefetchKey,
  setUserEditId,
  setClientAdminView
} = adminSlice.actions;
