import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from "../../instance";

const url = process.env.REACT_APP_API_BASE_URL;

const initialState = {
	gradLoading: false,
	graduates: [],
	graduatesHeaders: [],
	gradHeaderObj: {},
	gradListObj: {},
	graduateObj: {},
	gradUpload: false,
};

export const saveGraduate = createAsyncThunk(
	'graduate/saveGraduate',
	async (data) => {
		const res = await axiosInstance.post(`${url}/emp/saveGraduate.action`, data);
		return res.data;
	}
);

export const saveGraduateHeader = createAsyncThunk(
	'graduate/saveGraduateHeader',
	async (data) => {
		const res = await axiosInstance.post(`${url}/emp/saveGraduateFolder.action`, data);
		return res.data;
	}
);

export const fetchGraduates = createAsyncThunk(
	'graduate/fetchGraduates',
	async (fldId) => {
		const res = await axiosInstance.get(
			`${url}/emp/fetchGraduates.action?grdFldId=${fldId}`
		);
		return res.data?.data?.result;
	}
);

export const fetchGraduateFolders = createAsyncThunk(
	'graduate/fetchGraduateFolders',
	async (folderId) => {
		const res = await axiosInstance.get(
			`${url}/nea/fetchFolders.action?fldId=${folderId}`
		);
		return res.data?.data?.result;
	}
);

export const deleteGraduate = createAsyncThunk(
	'graduate/deleteGraduate',
	async (grdId) => {
		const res = await axiosInstance.get(
			`${url}/emp/deleteGraduate.action?grdId=${grdId}`
		);
		return res.data;
	}
);

export const graduateSlice = createSlice({
	name: 'graduate',
	initialState,
	reducers: {
		setGraduateHeaderObj: (state, action) => {
			state.gradHeaderObj = action.payload;
		},

		setGraduateListObj: (state, action) => {
			state.gradListObj = action.payload;
		},

		setGraduateObj: (state, action) => {
			state.graduateObj = action.payload;
		},

		setGradUpload: (state, action) => {
			state.gradUpload = action.payload;
		},

		graduateCleanUp: (state) => {
			state.gradHeaderObj = {};
			state.gradListObj = {};
			state.graduateObj = {};
			state.gradUpload = false;
		},
	},
	extraReducers: (builder) => {
		builder

			.addCase(deleteGraduate.pending, (state) => {
				state.gradLoading = true;
			})
			.addCase(deleteGraduate.fulfilled, (state) => {
				state.gradLoading = false;
			})
			.addCase(deleteGraduate.rejected, (state) => {
				state.gradLoading = false;
			})

			.addCase(saveGraduateHeader.pending, (state) => {
				state.gradLoading = true;
			})
			.addCase(saveGraduateHeader.fulfilled, (state) => {
				state.gradLoading = false;
			})
			.addCase(saveGraduateHeader.rejected, (state) => {
				state.gradLoading = false;
			})

			.addCase(saveGraduate.pending, (state) => {
				state.gradLoading = true;
			})
			.addCase(saveGraduate.fulfilled, (state) => {
				state.gradLoading = false;
			})
			.addCase(saveGraduate.rejected, (state) => {
				state.gradLoading = false;
			})

			.addCase(fetchGraduateFolders.pending, (state) => {
				state.gradLoading = true;
			})
			.addCase(fetchGraduateFolders.fulfilled, (state, action) => {
				state.gradLoading = false;
				state.graduatesHeaders = action.payload;
			})
			.addCase(fetchGraduateFolders.rejected, (state) => {
				state.gradLoading = false;
				state.graduatesHeaders = {};
			})

			.addCase(fetchGraduates.pending, (state) => {
				state.gradLoading = true;
			})
			.addCase(fetchGraduates.fulfilled, (state, action) => {
				state.gradLoading = false;
				state.graduates = action.payload;
			})
			.addCase(fetchGraduates.rejected, (state) => {
				state.gradLoading = false;
				state.graduates = {};
			});
	},
});

export default graduateSlice.reducer;
export const {
	setGraduateHeaderObj,
	graduateCleanUp,
	setGraduateListObj,
	setGraduateObj,
	setGradUpload,
} = graduateSlice.actions;
