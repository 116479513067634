import { Table, Skeleton, Button, Dropdown, Input } from "antd";
import React, { useEffect, useState } from "react";
import MaterialIcon from "material-icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dateForHumans, formatImgPath } from "../../utils";
import {
  cleanMyEmployees,
  fetchRedundantEmployees,
  saveEmployee,
  setEmployeeEditObj,
  setEmployeeReturnsPage,
} from "../../features/myEmployees/myEmployeesSlice";
import toast from "react-hot-toast";
import ConfirmationModal from "../../components/ConfirmationModal";
import emptyContainer from "../../assets/Container.png";

function RedundancyTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const {
    employeesData,
    employeeLoading,
    employeeEditId,
    redundantEmployees,
    refetchKey,
    employeeCount,
  } = useSelector((state) => state.employee);

  const { user } = useSelector((state) => state.auth);

  const [searchText, setSearchText] = useState("");

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [editObj, seteditObj] = useState({});
  const [prodd, setProdd] = useState();

  async function handleEdit(item) {
    await dispatch(setEmployeeEditObj(item));
    await navigate(`/employees/edit/${item?.emplFullNames}`);
  }
  async function handleEditDropDown() {
    await dispatch(setEmployeeEditObj(prodd));
    await navigate(`/employees/edit/${prodd?.emplFullNames}`);
  }

  function handleSetEditObj(obj) {
    seteditObj(obj);
  }

  const [isModalOpenConfirmation, setIsModalOpenConfirmation] = useState(false);
  const showModalConfirmation = async () => {
    setIsModalOpenConfirmation(true);
  };

  async function handleActive() {
    const res = await dispatch(
      saveEmployee({
        emplId: prodd?.emplId,
        emplStatus: "ACTIVE",
      })
    );
    if (res?.payload?.success) {
      await toast.success(res?.payload?.messages?.message);
      await setIsModalOpenConfirmation(false);
      await fetchEmployeeDetails();
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  }

  const columns = [
    {
      title: "Employee Name",
      render: (item) => (
        <>
          <div className="flex items-center">
            <div className="flex items-center w-[300px]">
              <img
                className="w-12 h-12 rounded-full object-contain border-2 border-black2"
                src={
                  item?.emplIcon
                    ? formatImgPath(item?.emplIcon)
                    : "https://kazi254.ke/myimages/OTP_IMAGES/ACCOUNT_OPENING/avatar.png"
                }
                alt="Avatar"
              />
              <span className="table-name mx-3 truncate">
                {item?.emplFirstName} {item?.emplMiddleName}{" "}
                {item?.emplLastName}
              </span>
            </div>
            <div
              className="flex items-center col-end-0"
              // onClick={() => setProdd(item)}
            >
              <button
                onClick={() => handleEdit(item)}
                className="p-0 mx-4 flex items-end"
                type="button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="27"
                  height="24"
                  viewBox="0 0 27 24"
                  fill="none"
                >
                  <path
                    d="M23.9436 11.6C21.8099 6.91 17.7959 4 13.4651 4C9.13428 4 5.12035 6.91 2.98662 11.6C2.92846 11.7262 2.89844 11.8623 2.89844 12C2.89844 12.1377 2.92846 12.2738 2.98662 12.4C5.12035 17.09 9.13428 20 13.4651 20C17.7959 20 21.8099 17.09 23.9436 12.4C24.0018 12.2738 24.0318 12.1377 24.0318 12C24.0318 11.8623 24.0018 11.7262 23.9436 11.6ZM13.4651 18C10.1061 18 6.94774 15.71 5.12035 12C6.94774 8.29 10.1061 6 13.4651 6C16.8241 6 19.9825 8.29 21.8099 12C19.9825 15.71 16.8241 18 13.4651 18ZM13.4651 8C12.6294 8 11.8125 8.2346 11.1177 8.67412C10.4229 9.11365 9.88133 9.73836 9.56153 10.4693C9.24174 11.2002 9.15807 12.0044 9.3211 12.7804C9.48413 13.5563 9.88654 14.269 10.4774 14.8284C11.0683 15.3878 11.8212 15.7688 12.6408 15.9231C13.4604 16.0775 14.31 15.9983 15.082 15.6955C15.8541 15.3928 16.514 14.8801 16.9782 14.2223C17.4425 13.5645 17.6903 12.7911 17.6903 12C17.6903 10.9391 17.2451 9.92172 16.4528 9.17157C15.6604 8.42143 14.5857 8 13.4651 8ZM13.4651 14C13.0473 14 12.6388 13.8827 12.2914 13.6629C11.944 13.4432 11.6732 13.1308 11.5133 12.7654C11.3534 12.3999 11.3116 11.9978 11.3931 11.6098C11.4746 11.2219 11.6758 10.8655 11.9713 10.5858C12.2667 10.3061 12.6432 10.1156 13.053 10.0384C13.4628 9.96126 13.8875 10.0009 14.2736 10.1522C14.6596 10.3036 14.9895 10.56 15.2217 10.8889C15.4538 11.2178 15.5777 11.6044 15.5777 12C15.5777 12.5304 15.3551 13.0391 14.9589 13.4142C14.5627 13.7893 14.0254 14 13.4651 14Z"
                    fill="black"
                  />
                </svg>
              </button>
              <button
                className="p-0 m-0 flex items-end"
                type="button"
                onClick={() => setProdd(item)}
              >
                <Dropdown
                  overlayStyle={{
                    width: "auto",
                  }}
                  onOpenChange={() => handleSetEditObj(item)}
                  trigger={"click"}
                  menu={{ items: settingItems }}
                  placement="bottom"
                >
                  <div className="flex flex-col gap-y-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="5"
                      viewBox="0 0 5 5"
                      fill="none"
                    >
                      <circle
                        cx="2.5"
                        cy="2.5"
                        r="2.5"
                        transform="rotate(-180 2.5 2.5)"
                        fill="#333333"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="5"
                      viewBox="0 0 5 5"
                      fill="none"
                    >
                      <circle
                        cx="2.5"
                        cy="2.5"
                        r="2.5"
                        transform="rotate(-180 2.5 2.5)"
                        fill="#333333"
                      />
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5"
                      height="5"
                      viewBox="0 0 5 5"
                      fill="none"
                    >
                      <circle
                        cx="2.5"
                        cy="2.5"
                        r="2.5"
                        transform="rotate(-180 2.5 2.5)"
                        fill="#333333"
                      />
                    </svg>
                  </div>
                </Dropdown>
              </button>
            </div>
          </div>
        </>
      ),

      width: "300px",
    },
    {
      title: "Job Title",
      dataIndex: "emplTitle",
      sorter: {
        compare: (a, b) => a.emplJobTitle - b.emplJobTitle,
        multiple: 3,
      },
    },
    {
      title: "Employee No.",
      dataIndex: "emplEmploymentNo",
      sorter: {
        compare: (a, b) => a.emplEmploymentNo - b.emplEmploymentNo,
        multiple: 2,
      },
    },
    {
      title: "National ID No.",
      dataIndex: "emplNationalId",
      sorter: {
        compare: (a, b) => a.emplNationalId - b.emplNationalId,
        multiple: 2,
      },
    },
    {
      title: "Email",
      dataIndex: "emplEmail",
      sorter: {
        compare: (a, b) => a.emplEmail - b.emplEmail,
        multiple: 1,
      },
    },
    {
      title: "Termination Reason",
      dataIndex: "emplTerminationReason",
      sorter: {
        compare: (a, b) => a.emplTerminationReason - b.emplTerminationReason,
        multiple: 3,
      },
    },
  ];

  const settingItems = [
    {
      key: "0",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={handleEditDropDown}
        >
          Edit
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          className="font-dmSans text-black font-[400] text-[19px]"
          onClick={showModalConfirmation}
        >
          Declare active
        </div>
      ),
    },
  ];

  const filteredData = searchText
    ? redundantEmployees?.filter(
        (item) =>
          item?.emplFirstName
            ?.toLowerCase()
            .includes(searchText.toLowerCase()) ||
          item?.emplLastName
            ?.toUpperCase()
            .includes(searchText.toUpperCase()) ||
          item?.emplMiddleName
            ?.toUpperCase()
            .includes(searchText.toUpperCase()) ||
          item?.emplDepartment
            ?.toUpperCase()
            .includes(searchText.toUpperCase()) ||
          item?.emplKraPin?.toUpperCase().includes(searchText.toUpperCase()) ||
          item?.emplNationalId?.toUpperCase().includes(searchText.toUpperCase())
      )
    : redundantEmployees;

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  async function setEmployeePage() {
    await dispatch(setEmployeeReturnsPage(true));
  }

  async function cleanEmployeeDetails() {
    dispatch(cleanMyEmployees());
  }

  async function fetchEmployeeDetails(page, size) {
    await dispatch(
      fetchRedundantEmployees({
        emplInstId: user?.usrInstId,
        limit: size ?? pageSize,
        start: page ?? pageIndex,
        emplStatus: "REDUNDANT",
      })
    );
  }

  useEffect(() => {
    setEmployeePage();
  }, []);

  useEffect(() => {
    if (refetchKey == 2) {
      fetchEmployeeDetails();
    }
  }, [refetchKey]);

  useEffect(() => {
    cleanEmployeeDetails();
  }, []);

  return (
    <>
      <div className="h-auto">
        <div className="">
          {redundantEmployees && redundantEmployees?.length > 0 ? (
            <>
              <h3 className="font-[700] text-[24px] text-black1 dash-title mt-[11px]">
                Redundant Employees
              </h3>

              <div className="flex justify-between items-center w-full mt-[31px]">
                <div className="flex items-center w-full ">
                  <Input
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    placeholder="Search employee name or keyword"
                    className="text-[16px] font-[400] flex-row-reverse"
                    prefix={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.2508 3.75C7.50609 3.75 4.47041 6.93997 4.47041 10.875C4.47041 14.81 7.50609 18 11.2508 18C14.9955 18 18.0312 14.81 18.0312 10.875C18.0312 6.93997 14.9955 3.75 11.2508 3.75ZM3.04297 10.875C3.04297 6.11154 6.71773 2.25 11.2508 2.25C15.7838 2.25 19.4586 6.11154 19.4586 10.875C19.4586 15.6385 15.7838 19.5 11.2508 19.5C6.71773 19.5 3.04297 15.6385 3.04297 10.875Z"
                          fill="#333333"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M16.045 15.913C16.3237 15.6201 16.7756 15.6201 17.0543 15.913L21.3902 20.4693C21.6689 20.7622 21.6689 21.237 21.3902 21.5299C21.1115 21.8228 20.6596 21.8228 20.3809 21.5299L16.045 16.9737C15.7663 16.6808 15.7663 16.2059 16.045 15.913Z"
                          fill="#333333"
                        />
                      </svg>
                    }
                  />
                </div>
              </div>

              <div className="mt-[23px]">
                {employeeLoading ? (
                  <Skeleton />
                ) : (
                  <Table
                    rowSelection={true}
                    size={"middle"}
                    bordered={true}
                    pagination={{
                      position: ["bottomCenter"],
                      current: pageIndex + 1,
                      total: employeeCount,
                      pageSize: pageSize,
                      onChange: (page, size) => {
                        setPageIndex(page - 1);
                        setPageSize(size);
                        fetchEmployeeDetails(page - 1, size);
                      },
                      showSizeChanger: false,
                      hideOnSinglePage: true,
                    }}
                    rowKey={(record) => record?.emplId}
                    columns={tableColumns}
                    dataSource={filteredData}
                    scroll={{
                      x: 800,
                    }}
                    loading={employeeLoading}
                  />
                )}
              </div>
            </>
          ) : (
            <div className=" h-[885px] bg-white flex   flex-col">
              <div
                className="  bg-white full h-[471.33px] rounded-[15px] border border-solid
             border-[#F5F7FF] py-[60px] flex flex-col justify-center items-center cursor-pointer"
              >
                <div>
                  <img
                    src={emptyContainer}
                    alt="group-pic"
                    className="object-contain h-[291.33px] w-[292px]"
                  />
                  <div className="empty-desc flex items-center justify-center">
                    No redundant employees
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ConfirmationModal
        isModalOpen={isModalOpenConfirmation}
        setIsModalOpen={setIsModalOpenConfirmation}
        handleSubmit={handleActive}
        loading={employeeLoading}
        content={
          "Are you ready to declare active" +
          " " +
          prodd?.emplFirstName +
          " " +
          prodd?.emplMiddleName +
          " " +
          prodd?.emplLastName
        }
      />
    </>
  );
}

export default RedundancyTable;
