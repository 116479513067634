import React, { useState } from "react";
import { Button, Card, Modal } from "antd";
import "../../clients/client.css";
import group from "../../../assets/Group.svg";
import upload from "../../../assets/upload csv.svg";
import groupCv from "../../../assets/GroupCv.svg";
import multipleCv from "../../../assets/multiplecv.svg";

import ApplicantNameModal from "./ApplicantNameModal";
 
const ApplicantAddModal = ({ isModalOpen, setIsModalOpen, showModal }) => {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [isModalOpenName, setIsModalOpenName] = useState(false);
  const showModalName = () => {
    setIsModalOpenName(true);
    handleCancel();
  };
  return (
    <>
      <Modal
        className="mod mod-height-applicants"
        title="Create applicant"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={850}
      >
        <div className="grid grid-cols-2 gap-5 p-[41px]">
          <Card className="p-[70px cursor-pointer" onClick={showModalName}>
            <div className="text-center justify-center flex flex-col ">
              <img
                src={group}
                alt="group"
                className="mx-auto w-[169.002px] h-[161.471px]"
              />

              <span className="text-black text-[20px] font-[500] font-dmSans leading-loose mt-[32px]">
                {" "}
                Complete a Form{" "}
              </span>
            </div>
          </Card>
          <Card className="p-[70px cursor-pointer">
            <div className="text-center justify-center flex flex-col ">
              <img
                src={groupCv}
                alt="groupCv"
                className="mx-auto w-[169.002px] h-[161.471px]"
              />

              <span className="text-black text-[20px] font-[500] font-dmSans leading-loose mt-[32px]">
                {" "}
                Upload a CV{" "}
              </span>
            </div>
          </Card>

          <Card className="p-[70px cursor-pointer">
            <div className="text-center justify-center flex flex-col ">
              <img
                src={multipleCv}
                alt="multipleCv"
                className="mx-auto w-[169.002px] h-[161.471px]"
              />

              <span className="text-black text-[21px] font-bold font-dmSans leading-loose mt-[44px]">
                {" "}
                Upload multiple CVs{" "}
              </span>
            </div>
          </Card>

          <Card className="p-[70px cursor-pointer">
            <div className="text-center justify-center flex flex-col ">
              <img
                src={upload}
                alt="upload"
                className="mx-auto w-[169.002px] h-[161.471px]"
              />

              <span className="text-black text-[21px] font-bold font-dmSans leading-loose mt-[44px]">
                {" "}
                Import a CSV file{" "}
              </span>
            </div>
          </Card>
         
        </div>
      </Modal>
      <ApplicantNameModal
        isModalOpenName={isModalOpenName}
        setIsModalOpenName={setIsModalOpenName}
        showModalName={showModalName}
        showModal={showModal}
      />
    </>
  );
};
export default ApplicantAddModal;
