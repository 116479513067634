import React, { useEffect, useRef, useState } from 'react';
import Header from './Header';
import { Breadcrumb, Form, Input, InputNumber, Spin, Tabs } from 'antd';
import { saveFile } from '../../features/application/applicationSlice';
import toast from 'react-hot-toast';
import uplodSvg from '../../assets/image 194.png';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatImgPath } from '../../utils';
import { fetchAgencyData, saveAgency } from '../../features/agency/agencySlice';
import { createInstitution } from '../../features/auth/authSlice';

export default function AdminProfile() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [form] = Form.useForm();
	const formRef = useRef(null);

	const { user, authLoading } = useSelector((state) => state.auth);

	const { agencyObj } = useSelector((state) => state.agency);

	const [profile, setProfile] = useState(
		agencyObj?.instIcon ? agencyObj?.instIcon : null,
	);

	const [loadingAttach, setLoadingAttach] = useState(false);

	const handleImageSelect = async (event) => {
		setLoadingAttach(true);
		const formData = new FormData();
		formData.append('file', event.target.files[0]);

		const res = await dispatch(saveFile(formData));

		if (res?.payload?.success) {
			setLoadingAttach(false);
			setProfile(res?.payload?.jsonData ?? '');
		} else {
			setLoadingAttach(false);
			toast.error('Please try uploading your image again');
			return;
		}

		const createAdminUsersRes = await dispatch(
			saveAgency({
				instId: user?.usrInstId,
				instIcon: res?.payload?.jsonData ?? '',
			}),
		);

		if (createAdminUsersRes?.payload?.success) {
			toast.success('Profile updated successfully');
			fetchAgencyDetails();
		} else {
			toast.error('Error updating profile, please try again');
		}
	};

	async function fetchAgencyDetails() {
		await dispatch(fetchAgencyData(user?.usrInstId));
	}

	const onFinish = async (data) => {
		data.instId = agencyObj?.instId;
		data.instStatus = agencyObj?.instStatus;

		const res = await dispatch(
			createInstitution({
				...data,
			}),
		);

		if (res?.payload?.success) {
			await toast.success('Details updated successfully');
			await fetchAgencyDetails();
		} else {
			toast.error('Errror occured while saving, please try again');
		}
	};

	useEffect(() => {
		fetchAgencyDetails();
	}, []);

	return (
		<>
			<div className='flex justify-center items-center flex-col w-full'>
				<div className='ml-[54px] flex flex-col gap-y-[23px] text-center h-auto'>
					<div className='mt-[20px] flex flex-col items-center gap-x-1'>
						{profile === null ? (
							<label className='flex flex-row items-center justify-start '>
								<input
									name='secIcon'
									type='file'
									accept='image/*'
									style={{ display: 'none' }}
									onChange={(e) => handleImageSelect(e)}
								/>
								<img
									loading='lazy'
									className='z-10 '
									alt='tab1'
									src={uplodSvg}
									style={{ cursor: 'pointer' }}
								/>
							</label>
						) : loadingAttach ? (
							<Spin className='spin-modal' />
						) : (
							<label className='flex flex-row items-center justify-start gap-2'>
								<input
									name='secIcon'
									type='file'
									accept='image/*'
									style={{ display: 'none' }}
									onChange={handleImageSelect}
								/>
								<div className='rounded-full  overflow-hidden border-4 border-blueDark'>
									<img
										loading='lazy'
										src={formatImgPath(profile)}
										alt='director'
										className='w-28 h-28 rounded-full cursor-pointer object-cover'
									/>
								</div>
							</label>
						)}
						<div className='flex flex-col items-center justify-center'>
							<div className='text-blueDark text-[22px] font-medium font-dmSans leading-relaxed flex justify-start'>
								Upload logo
							</div>
						</div>
					</div>
				</div>

				<Form
					layout='vertical'
					ref={formRef}
					name='control-ref'
					onFinish={onFinish}
					style={{
						maxWidth: '100%',
						width: '800px',
						padding: '30px',
					}}
					form={form}
					initialValues={{
						...agencyObj,
					}}
				>
					<div className='grid grid-cols-1'>
						<Form.Item
							name='instName'
							label='Employer Name'
							rules={[
								{
									required: true,
									message: 'Please add agency name',
								},
							]}
						>
							<Input
								readOnly
								className='input'
							/>
						</Form.Item>
					</div>

					<div className='grid grid-cols-1 lg:grid-cols-2 lg:gap-x-4'>
						<Form.Item
							name='instRegCertNo'
							label='Business Reg No'
							rules={[
								{
									required: true,
									message: 'Please add business reg no',
								},
							]}
						>
							<Input
								readOnly
								className='input'
							/>
						</Form.Item>
						<Form.Item
							name='instKraPin'
							label='Kra Pin'
							rules={[
								{
									required: true,
									message: 'Please add kra pin',
								},
								{
									pattern: /^[A-Z0-9]{11}$/,
									message:
										'Invalid Kra Pin format. A valid Kra Pin should be 11 alphanumeric characters.',
								},
							]}
						>
							<Input
								readOnly
								className='input'
							/>
						</Form.Item>

						<Form.Item
							name='instNationalId'
							label='National ID number'
							rules={[
								{
									required: true,
									message: 'Please add ID number',
								},
							]}
						>
							<Input
								readOnly
								className='input'
							/>
						</Form.Item>

						<Form.Item
							name='instPostalAddress'
							label='Postal Address'
							rules={[
								{
									required: true,
									message: 'Please add postal address',
								},
							]}
						>
							<Input className='input' />
						</Form.Item>

						<Form.Item
							name='instPostalCode'
							label='Postal Code'
							rules={[
								{
									required: true,
									message: 'Please add postal code',
								},
							]}
						>
							<Input className='input' />
						</Form.Item>

						<Form.Item
							name='instPhysicalAddress'
							label='Office Location'
							rules={[
								{
									required: true,
									message: 'Please add office location',
								},
							]}
						>
							<Input className='input' />
						</Form.Item>

						<Form.Item
							name='instCellPhone'
							label='Office Office No'
							rules={[
								{
									required: true,
									message: 'Please add office office no',
								},
							]}
						>
							<Input className='input' />
						</Form.Item>
						<Form.Item
							name='instWorkTel'
							label='Office Phone No'
							rules={[
								{
									required: true,
									message: 'Please add office phone no',
								},
							]}
						>
							<InputNumber
								readOnly
								maxLength={10}
								minLength={9}
								className='input'
							/>
						</Form.Item>

						<Form.Item
							name='instEmail'
							label='Official Email Address'
							rules={[
								{
									required: true,
									message: 'Please add official email address',
								},
								{
									type: 'email',
									message: 'Please enter a valid email address',
								},
							]}
						>
							<Input
								readOnly
								type='email'
								className='input'
							/>
						</Form.Item>
						<Form.Item
							name='instWebsite'
							label='Official Website'
							rules={[
								{
									required: true,
									message: 'Please add official website',
								},
								{
									type: 'url',
									message: 'Please add a valid URL',
								},
							]}
						>
							<Input className='input' />
						</Form.Item>
					</div>

					<div className='grid grid-cols-1'>
						<Form.Item
							name='instNearNeaOffice'
							label='Nearest NEA Office'
							rules={[
								{
									required: true,
									message: 'Please select nearest NEA office',
								},
							]}
						>
							<Input
								readOnly
								className='input'
							/>
						</Form.Item>
					</div>

					<div className='flex justify-center mt-5'>
						<button
							className='w-[200px] bg-blueDark text-white rounded-[36px] p-3'
							type='submit'
							disabled={authLoading ? true : false}
						>
							{authLoading ? <Spin /> : 'Update Account'}
						</button>
					</div>
				</Form>
			</div>
		</>
	);
}
