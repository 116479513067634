import { Button, DatePicker, Form, Input, Select, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { removeCommas } from "../../../../utils";
import { 
  fetchSingleJob,
  saveJob,
  setEditTermsEmployment, 
  setViewTermsEmployment,
} from "../../../../features/jobs/jobSlice";
import { fetchJobExperienceLevels, fetchJobTypes, fetchJobWorkStyles } from "../../../../features/jobFilters/jobFilterSlice";
import { fetchWorkCategorys } from "../../../../features/dropDowns/dropDownSlice";
 
function TermsEmployment() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { jobTypes,jobStyles,jobExperienceLevels } = useSelector((state) => state.jobFilter);
  
  const { singleCreateJob, jobEditId, jobData, jobLoading,editTermsEmployment,singleJob } = useSelector(
    (state) => state.jobs
  );

  const {workTypeCategory} =  useSelector((state)=>state.dropDown)

 
  const [formData, setFormData] = useState(singleJob); 

  useEffect(()=>{
    setFormData(singleJob)
  },[singleJob])

  const [data, setdata] = useState({});

  function handleSelectChange(value) {
    setdata((prevData) => ({
      ...prevData,
      objectName: value,
    }));
  }

  const onChange = async (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };
 
  const onChangeComma = async (e) => {
    const rawValue = e.target.value.replace(/,/g, ''); 
    const numericValue = parseFloat(rawValue);
  
    const formattedValue = isNaN(numericValue) ? '' : numericValue.toLocaleString('en-US');
  
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: formattedValue,
    }));
  };
  
  
  function handleSelectPayment(value) {
    setFormData((prevData) => ({
      ...prevData,
      jobPayFrequency: value,
    }));
  }
  function handleSelectJobType(value) {
    setFormData((prevData) => ({
      ...prevData,
      jobEmploymentTerms: value,
    }));
  }

  
  
  function handleSelectWork(value) {
    setFormData((prevData) => ({
      ...prevData,
      jobWorkStyle: value,
    }));
  }
  function handleSelectExperience(value) {
    setFormData((prevData) => ({
      ...prevData,
      jobExperience: value,
    }));
  } 

  function handleSelectPeriod(value) {
    setFormData((prevData) => ({
      ...prevData,
      jobStartPeriod: value,
    }));
  }

  const [catId,setCatId] = useState(null)
  const [catMainId,setCatMainId] = useState(null)
  function handleSelectWorkType(value) {
    setFormData((prevData) => ({
      ...prevData,
      jobCategory: value,
    }));
    const cat =  workTypeCategory && workTypeCategory?.find(item=>item?.wcatName === value); 
    setCatId(cat?.wcatId)
    setCatMainId(cat?.wcatWktId)
  }

   
  const handleCancel =async () => {
    await dispatch(setEditTermsEmployment(false));
    await dispatch(setViewTermsEmployment(true)); 
  };

  function closeModal() {
    toast.dismiss();
  }
  const onFinish = async (data) => {
    if (!formData?.jobExperience || !formData?.jobPayFrequency || !formData?.jobEmploymentTerms || !formData?.jobWorkStyle) {
      return toast("Please fill all the job details")
    }
    const res = await dispatch(
      saveJob({
        jobId: formData?.jobId,
        jobCategory:formData?.jobCategory,
        jobCategoryId:catId,
        jobMainCategoryId:catMainId,
        jobExperience: formData?.jobExperience, 
        jobGrossPay: removeCommas(formData?.jobGrossPay),
        jobPayFrequency: formData?.jobPayFrequency,
        jobEmploymentTerms: formData?.jobEmploymentTerms,
      //  jobType: formData?.jobType,
        jobWorkStyle: formData?.jobWorkStyle,
        jobDuration: formData?.jobEmploymentTerms != "Full time" ? formData?.jobDuration : "",
        jobStartPeriod: formData?.jobStartPeriod,
        jobInstId: user?.usrInstId, 
      })
    );
    if (res?.payload?.success) {
      toast.success("Job details saved successfull");
      form.resetFields();
      await dispatch(fetchSingleJob({
        jobId:jobEditId? jobEditId: singleCreateJob?.jobId
      }))
      await dispatch(setEditTermsEmployment(false));
      await dispatch(setViewTermsEmployment(true)); 
    } else {
      toast.error(res?.payload?.messages?.message);
    }
  };

  const options = [
    {
      label: "Daily",
      value: "Daily",
    },
    {
      label: "Weekly",
      value: "Weekly",
    },
    {
      label: "Monthly",
      value: "Monthly",
    },
  ];
 

  

  const startPeriodOptions = [
    {
      label: "Immediately",
      value: "Immediately",
    },
    {
      label: "Two-weeks",
      value: "Two-weeks",
    },
    {
      label: "One-month",
      value: "One-month",
    },
  ];

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSearch = (value) => {};

  async function fetchJobTypesData() {
    await dispatch(fetchJobTypes());
  }
  async function fetchJobWorkStylesData() {
    await dispatch(fetchJobWorkStyles());
  }
  async function fetchJobExperienceLevelsData() {
    await dispatch(fetchJobExperienceLevels());
  }
  async function fetchWorkCategorysData() {
    await dispatch(fetchWorkCategorys());
  }
  
  

  useEffect(()=>{
    fetchJobTypesData();
    fetchJobWorkStylesData();
    fetchJobExperienceLevelsData(); 
    fetchWorkCategorysData();
  },[])

 

  return (
    <div className="mt-[28px]">
      <div className="text-blueDark text-[1rem] font-medium font-dmSans leading-[30px] mb-[28px]">
        Basic Information
      </div>
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
        }}
        form={form}
      >
        <div className="grid grid-cols-2 lg:gap-4"> 
          <Form.Item
             label="Experienced required"
            rules={[
              {
                required: true,
                message: "Please add experienced required",
              },
            ]}
          >
            <Select
              required
              name="jobExperience"
              value={formData?.jobExperience}
              style={{
                width: "100%",
                height: "44px",
              }}
              options={
                jobExperienceLevels?.length > 0 &&
                jobExperienceLevels?.map((item) => ({
                  value: item?.jelName,
                  label: item?.jelName,
                }))
              }
              onChange={(value) => {
                handleSelectExperience(value);
              }}
              onDropdownVisibleChange={() => {
                fetchJobExperienceLevelsData()
              }}
            />
          </Form.Item>



          <Form.Item
            label="Gross pay"
            rules={[
              {
                required: true,
                message: "Please add gross pay",
              },
            ]}
          >
            <Input
              required
              name="jobGrossPay"
              value={formData?.jobGrossPay}
              onChange={onChangeComma}
              className="input"
            />
          </Form.Item>

      

          <Form.Item
            label="Payment Frequency"
            rules={[
              {
                required: true,
                message: "Please select payment frequency",
              },
            ]}
          >
            <Select
              required
              name="jobPayFrequency"
              value={formData?.jobPayFrequency}
              style={{
                width: "100%",
                height: "44px",
              }}
              options={options}
              onChange={(value) => {
                handleSelectPayment(value);
              }}
              onDropdownVisibleChange={() => {
                //trigger something
              }}
            />
          </Form.Item>

          {/* <Form.Item
            label="Terms of Employment"
            rules={[
              {
                required: true,
                message: "Please add terms of employment",
              },
            ]}
          >
            <Input
              required
              name="jobEmploymentTerms"
              value={formData?.jobEmploymentTerms}
              onChange={onChange}
              className="input"
            />
          </Form.Item> */}

          <Form.Item
            label="Terms of Employment"
            rules={[
              {
                required: true,
                message: "Please select terms of employment",
              },
            ]}
          >
            <Select
              required
              name="jobEmploymentTerms"
              value={formData?.jobEmploymentTerms}
              style={{
                width: "100%",
                height: "44px",
              }}
              options={
                jobTypes?.length > 0 &&
                jobTypes?.map((item) => ({
                  value: item?.jtpName,
                  label: item?.jtpName,
                }))
              }
              onChange={(value) => {
                handleSelectJobType(value);
              }}
              onDropdownVisibleChange={() => {
                fetchJobTypesData();
              }}
            />
          </Form.Item>

          <Form.Item
            label="Work Style"
            rules={[
              {
                required: true,
                message: "Please select work style",
              },
            ]}
          >
            <Select
              required
              name="jobWorkStyle"
              value={formData?.jobWorkStyle}
              style={{
                width: "100%",
                height: "44px",
              }}
              options={
                jobStyles?.length > 0 &&
                jobStyles?.map((item) => ({
                  value: item?.jwsName,
                  label: item?.jwsName,
                }))
              }
              onChange={(value) => {
                handleSelectWork(value);
              }}
              onDropdownVisibleChange={() => {
                fetchJobWorkStylesData()
              }}
            />
          </Form.Item>
          
          {
            formData?.jobEmploymentTerms != "Full time" && (
              <Form.Item
              label={
                <span>
                  Length of employment <span className="text-sm">(months)</span>
                </span>
              }
              rules={[
                {
                  required: false,
                  message: "Please add length of employment",
                },
              ]}
            >
              <Input 
                name="jobDuration"
                value={formData?.jobDuration}
                onChange={onChangeComma}
                className="input"
              />
            </Form.Item>
            )
          }
         

          <Form.Item
            label="Start period"
            rules={[
              {
                required: true,
                message: "Please select start period",
              },
            ]}
          >
            <Select
              required
              name="jobStartPeriod"
              value={formData?.jobStartPeriod}
              style={{
                width: "100%",
                height: "44px",
              }}
              options={
                startPeriodOptions?.length > 0 &&
                startPeriodOptions?.map((item) => ({
                  value: item?.value,
                  label: item?.label,
                }))
              }
              onChange={(value) => {
                handleSelectPeriod(value);
              }}
              onDropdownVisibleChange={() => {
                //trigger something
              }}
            />
          </Form.Item>

          <Form.Item
            label="Work Type"
            rules={[
              {
                required: true,
                message: "Please select work type",
              },
            ]}
          >
            <Select
              required
              name="jobCategory"
              value={formData?.jobCategory}
              style={{
                width: "100%",
                height: "44px",
              }}
              options={
                workTypeCategory?.length > 0 &&
                workTypeCategory?.map((item) => ({
                  key:item?.wcatId,
                  value: item?.wcatName,
                  label: item?.wcatName, 
                }))
              }  
              onChange={(value) => {
                handleSelectWorkType(value);
              }}
              onDropdownVisibleChange={() => {
                fetchWorkCategorysData();
              }}
              showSearch
              optionFilterProp="children"
              onSearch={onSearch}
              filterOption={filterOption}
            />
          </Form.Item>

          
        </div>

        <div className="flex items-center justify-between mt-[35px]">
          <div className="flex items-start"></div>
          <div className="flex gap-5 items-center">
            {
              editTermsEmployment && (
                <button
                className="text-blueDark text-[1rem] font-medium font-dmSans leading-normal border-none
                w-[168px] h-12 px-[18px] py-3    justify-center items-center gap-2 inline-flex"
                onClick={handleCancel}  
              >
                Cancel
              </button>
              )
            }
           

            <button
              className="text-white text-[1rem] font-medium font-dmSans leading-normal  bg-blueDark
            w-[168px] h-[46px] px-[18px] py-3 rounded-[46px] border justify-center items-center gap-2 inline-flex"
              type="submit"
              disabled={jobLoading}
            >
              {jobLoading ? <Spin /> : "Save"}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
}

export default TermsEmployment;
