import { Button, Table, Spin, Dropdown, Input } from "antd";
import React, { useEffect, useState } from "react";
import MaterialIcon from "material-icons-react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanUpJobEditId,
  cleanupJob,
  fetchCancelledJobs,
  setEditJobDescription,
  setEditJobDetails,
  setEditSkills,
  setEditTermsEmployment,
  setJobCountRefetch,
  setJobEditId,
  setRefetch,
  setViewJobDescription,
  setViewJobDetails,
  setViewModeTable,
  setViewSkills,
  setViewTermsEmployment,
} from "../../../../features/jobs/jobSlice";
import { cashConverter, dateForHumans } from "../../../../utils"; 
import { cleanUpEditId } from "../../../../features/client/clientSlice"; 
import { Skeleton } from "antd";
import backArrow from "../../../../assets/Back.svg";
import {
  fetchApplicantsDetails,
  setTabsActiveKey,
} from "../../../../features/applicant/applicantSlice";
import { setRefetchKey } from "../../../../features/cv/cvSlice"; 
import NoDataTableJob from "../../../../components/NoDataTableJob";

function CancelledJobTable() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = async () => {
    await dispatch(cleanupJob());
    dispatch(cleanUpJobEditId());
    await dispatch(cleanUpEditId());
    setIsModalOpen(true);
  };

  const { cancelledJobs, jobLoading, refetchKeyJobTables, jobsCount } =
    useSelector((state) => state.jobs);

  const { applicantJobViewTable } = useSelector((state) => state.cv);

  const { user } = useSelector((state) => state.auth);
  const { clientEditId } = useSelector((state) => state.client);

  const [searchText, setSearchText] = useState("");

  const [open, setOpen] = useState(false);
  const [prodd, setProdd] = useState("");

  const showDrawer = async (id) => {
    await dispatch(setJobEditId(id));
    await dispatch(setViewJobDetails(true));
    await dispatch(setEditJobDetails(false));
    await dispatch(setViewTermsEmployment(true));
    await dispatch(setEditTermsEmployment(false));
    await dispatch(setViewJobDescription(true));
    await dispatch(setEditJobDescription(false));
    await dispatch(setViewModeTable(true));
    await dispatch(setViewSkills(true));
    await dispatch(setEditSkills(false));
    await dispatch(setRefetch());
    await dispatch(setTabsActiveKey("1"));
    await dispatch(setRefetchKey("1"));
    await navigate("/job-view");
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  async function fetchJobsDetails(page, size) {
    const res = await dispatch(
      fetchCancelledJobs({
        jobInstId: user?.usrInstId,
        jobAdvertStatus: "CANCEL",
        limit: size ?? pageSize,
        start: page ?? pageIndex,
      })
    );
  }

  async function fetchJobsDetailsClient() {
    const res = await dispatch(
      fetchCancelledJobs({
        jobInstId: user?.usrInstId,
        jobCntId: clientEditId,
        jobAdvertStatus: "APPROVE_CANCEL",
      })
    );
  }

  useEffect(() => {
    if (refetchKeyJobTables == 5) {
      fetchJobsDetails();
    }
  }, [refetchKeyJobTables]);

  const columns = [
    {
      title: "Position name",
      render: (item) => (
        <>
          <div className="flex justify-between">
            <div className="flex items-center">
              <span className="table-name mx-3">{item?.jobPosition}</span>
            </div>
            <div className="flex items-center">
              <button className="p-0 mx-4 flex items-end" type="button">
                <MaterialIcon
                  color="#000"
                  icon="visibility"
                  onClick={() => showDrawer(item?.jobId)}
                />
              </button>
            </div>
          </div>
        </>
      ), 
    },
    {
      title: "Advert Status",
      render: (item) => (
        <div>
          {item?.jobAdvertStatus === "APPROVED" ? (
            <span className="profile-status py-2 px-4 mt-2">
              {item?.jobAdvertStatus}
            </span>
          ) : item?.jobAdvertStatus == "REJECTED" ? (
            <span className="profile-status-rejected p-1 mt-2">
              {item?.jobAdvertStatus}
            </span>
          ) :  item?.jobAdvertStatus == "END_RECRUITMENT" ? (
            <span className="profile-status-rejected p-1 mt-2">
            End Recruitment
            </span>
          )  :  item?.jobAdvertStatus == "APPROVE_CANCEL" ? (
            <span className="profile-status-rejected p-1 mt-2">
            Cancelled
            </span>
          )  :  item?.jobAdvertStatus == "PENDING_APPROVE_CANCEL" ? (
            <span className="profile-status-pending py-2 px-4 mt-2">
            Pending Cancel Approval
            </span>
          ) : 
          
          (
            <span className="">{item?.jobAdvertStatus}</span>
          )}
        </div>
      ), 
    },
    {
      title: "Job Client",
      dataIndex: "jobCntName", 
    },
    {
      title: "Job Location",
      dataIndex: "jobCountry", 
    },
    {
      title: "Vacancy Count	",
      dataIndex: "jobVacancyCount", 
    },
    {
      title: "Salary",
      render: (item) => <div>{cashConverter(item?.jobGrossPay)}</div>, 
    },
    {
      title: "Job Owner",
      render: (item) => {
        return <div>{user?.usrFullNames}</div>;
      }, 
    },
    {
      title: "Date Created",
      render: (item) => <div>{dateForHumans(item?.jobCreatedDate)}</div>, 
    },
    {
      title: "End Date",
      render: (item) => <div>{dateForHumans(item?.jobAdvertEndDate)}</div>, 
    },
  ];

  const filteredData = searchText
    ? cancelledJobs?.filter((item) =>
        item?.jobPosition?.toLowerCase().includes(searchText.toLowerCase())
      )
    : cancelledJobs;

  const tableColumns = columns?.map((item) => ({
    ...item,
  }));

  const { applicantEditId, applicants, singleCreateApplicant } = useSelector(
    (state) => state.applicant
  );

  const [applicantsList, setapplicantsList] = useState(applicants);
  const [singleClient, setSingleClient] = useState("");
  const [receivedId, setReceivedId] = useState(
    applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
  );

  useEffect(() => {
    setapplicantsList(applicants);
  }, [applicants]);

  useEffect(() => {
    setReceivedId(
      applicantEditId ? applicantEditId : singleCreateApplicant?.usrId
    );
  }, [applicantEditId, singleCreateApplicant?.usrId]);

  useEffect(() => {
    const singleItem =
      applicantsList?.length > 0 &&
      applicantsList?.find((item) => item?.usrId == receivedId);
    setSingleClient(singleItem);
  }, [receivedId, applicantsList]);

  useEffect(() => {}, [singleClient]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [rowId, setRowId] = useState([]);

  const handleAddJobToApplicant = async (selectedRows) => {
    setRowId(selectedRows);
  };

  const onSelectChange = (keys, rows) => {
    setSelectedRowKeys(keys);
    handleAddJobToApplicant(rows);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleBack = async () => {
    await navigate(-1);
  };

  async function fetchApplicants() {
    const res = await dispatch(
      fetchApplicantsDetails({
        usrInstId: user?.usrInstId,
      })
    );
  }

  useEffect(() => {
    fetchApplicants();
  }, []);

  async function cleanJobEditId() {
    await dispatch(cleanUpJobEditId());
  }
  useEffect(() => {
    cleanJobEditId();
  }, []);

  async function onClose() {
    await dispatch(setJobCountRefetch(false));
    await dispatch(cleanupJob());
  }
  useEffect(() => {
    onClose();
  }, []);

  return (
    <>
      {jobLoading ? (
        <>
          <Skeleton />
        </>
      ) : (
        <>
          {applicantJobViewTable && (
            <div className="bg-white mr-3 mb-[10px]">
              <div className="flex justify-between">
                <div className="items-center ml-[54px] flex text-center h-[115px] ">
                  <span className="text-black1 text-2xl font-bold font-dmSans leading-[54px]">
                    Jobs
                  </span>
                </div>
                <div className="flex-end mr-[26px] items-center flex gap-[5rem]">
                  <div
                    className="flex items-center gap-2 cursor-pointer"
                    onClick={handleBack}
                  >
                    <div
                      style={{
                        height: "35px",
                        width: "35px",
                        background: "#EDF8FF",
                        borderRadius: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={backArrow}
                        alt="back-arrow"
                        style={{ height: "40px", width: "40px" }}
                      />
                    </div>

                    <span className="text-black2 text-[22px] font-bold font-dmSans leading-relaxed">
                      Go back
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}

          {cancelledJobs && cancelledJobs?.length > 0 ? (
            <div className="min-h-[895px] bg-white border border-gray-200  mt-[1%]">
              <div className=" p-[50px]">
                <div
                  className={`text-black text-[1.5rem] dash-title font-bold font-dmSans leading-[33px] mb-[31px]`}
                >
                  Cancelled jobs
                </div>
                <div className="flex justify-between items-center w-full mt-[31px]">
                  <div className="flex items-center w-full ">
                    <Input
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                      placeholder="Search job name or keyword"
                      className="text-[16px] font-[400] flex-row-reverse"
                      prefix={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.2508 3.75C7.50609 3.75 4.47041 6.93997 4.47041 10.875C4.47041 14.81 7.50609 18 11.2508 18C14.9955 18 18.0312 14.81 18.0312 10.875C18.0312 6.93997 14.9955 3.75 11.2508 3.75ZM3.04297 10.875C3.04297 6.11154 6.71773 2.25 11.2508 2.25C15.7838 2.25 19.4586 6.11154 19.4586 10.875C19.4586 15.6385 15.7838 19.5 11.2508 19.5C6.71773 19.5 3.04297 15.6385 3.04297 10.875Z"
                            fill="#333333"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.045 15.913C16.3237 15.6201 16.7756 15.6201 17.0543 15.913L21.3902 20.4693C21.6689 20.7622 21.6689 21.237 21.3902 21.5299C21.1115 21.8228 20.6596 21.8228 20.3809 21.5299L16.045 16.9737C15.7663 16.6808 15.7663 16.2059 16.045 15.913Z"
                            fill="#333333"
                          />
                        </svg>
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="mt-[6px] ml-[45px] mr-[10px]">
                <Table
                  rowSelection={rowSelection}
                  size={"middle"}
                  bordered={true}
                  pagination={{
                    position: ["bottomCenter"],
                    current: pageIndex + 1,
                    total: jobsCount,
                    pageSize: pageSize,
                    onChange: (page, size) => {
                      setPageIndex(page - 1);
                      setPageSize(size);
                      fetchJobsDetails(page - 1, size);
                    },
                    showSizeChanger: false,
                    hideOnSinglePage: true,
                  }}
                  rowKey={(record) => record?.jobId}
                  columns={tableColumns}
                  dataSource={filteredData}
                  scroll={{
                    x: 1500,
                  }}
                  loading={jobLoading}
                />
              </div>
            </div>
          ) : (
            <NoDataTableJob
              content="Jobs you cancel will be displayed here"
              archive={true}
            />
          )}
        </>
      )}
    </>
  );
}

export default CancelledJobTable;
