import React, { useEffect, useState } from 'react';
import {
	Navigate,
	Route,
	Routes,
	useNavigate,
	useSearchParams,
	useLocation,
} from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import './App.css';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import {
	fetchLoggedInUser,
	handleLoginSession,
	handleToken,
	logout,
} from './features/auth/authSlice';
import Home from './pages/home/Home';
import Dashboard from './pages/dashboard/Dashboard';
import Application from './pages/application/Application';
import JobList from './pages/jobs/job-adverts/job-list/JobList';
import TextEditor from './pages/jobs/job-adverts/job-description/TextEditor';
import ApplicantList from './pages/applicants/ApplicantList';
import ApplicantTabs from './pages/applicants/applicant-tabs/ApplicantTabs';
import JobTable from './pages/jobs/job-adverts/job-list/JobTable';
import ActivityList from './pages/activities/ActivityList';
import UsersList from './pages/admin/UsersList';
import AdminLanding from './pages/admin/AdminLanding';
import AdminLogo from './pages/admin/AdminLogo';
import UsersView from './pages/admin/user-view/UsersView';
import MainLicensePage from './pages/license/MainLicensePage';
import EmploymentReturnsView from './pages/employment-returns/EmploymentReturnsView';
import { fetchAgencyData } from './features/agency/agencySlice';
import Profile from './pages/profile/Profile';
import Employees from './pages/employees/Employees';
import AddEmployee from './pages/employees/employee_files/AddEmployee';
import EditEmployee from './pages/employees/employee_files/EditEmployee';
import FolderDetails from './pages/employees/folder_files/FolderDetails';
import Branches from './pages/admin/branches/Branches';
import EditBranch from './pages/admin/branches/branch_files/EditBranchMoal';
import Inbox from './pages/inbox/Inbox';
import InboxMessages from './pages/inbox/inbox_files/InboxMessages';
import SentMessages from './pages/inbox/inbox_files/SentMessages'; 
import SettingsLanding from './pages/setting/SettingsLanding';
import Recruitment from './pages/setting/Recruitment';
import EmailTemplate from './pages/setting/EmailTemplate';
import AccountClosedModal from './AccountClosedModal';
import DrawerModal from './pages/jobs/job-adverts/view/DrawerModal';
import PanelList from './pages/panel/PanelList';
import ActivityInvites from './pages/activities/ActivityInvites';
import ActivityAdd from './pages/activities/ActivityAdd';
import Graduates from './pages/graduates/Graduates';
import AddGraduate from './pages/graduates/graduates_files/AddGraduate';
import GraduatesFolderView from './pages/graduates/graduates_files/GraduateFolderView';
import GraduatesListView from './pages/graduates/graduates_files/GraduatesListView';
import EditGraduate from './pages/graduates/graduates_files/EditGraduate';
import GraduatesUploadList from './pages/graduates/graduates_files/GraduateUploadList';
import { jwtDecode } from 'jwt-decode';
import AuthVerify from './AuthVerify';
import axiosInstance from './instance';
import Loader from './Loader';
import EmployeesUploadList from './pages/employees/employee_files/EmployeesUpload';
import { fetchNotifications } from './features/global/globalSlice';
import Test from './pages/Test';
import FolderEmployeesAdd from './pages/employees/folder_files/FolderEmployeesAdd';
import ActivityStep2 from './pages/activities/ActivityStep2';
import ActivityStep3 from './pages/activities/ActivityStep3';
import JapTable from './pages/activities/JapTable';
import ActivityView from './pages/activities/ActivityView';

function App() {
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const { isLoggedIn, user, token } = useSelector((state) => state.auth);

	const { agencyObj } = useSelector((state) => state.agency);

	const [isUserLoggedIn, setisUserLoggedIn] = useState(true);
	const [usrToken, setusrToken] = useState(searchParams.get('usrId') ?? token);
	const [load, setload] = useState(false);

	message.config({
		duration: 10,
		maxCount: 3,
	});

	let location = window.location.href;

	axiosInstance.defaults.headers.common['Authorization'] = usrToken;

	async function handleInit() {
		if (usrToken?.length) {
			setload(true);
			const decoded = jwtDecode(usrToken);
			let decodeObj = JSON.parse(decoded.payload);

			await dispatch(handleToken(usrToken));
			await dispatch(fetchLoggedInUser());
			await dispatch(fetchAgencyData(decodeObj?.instId));
			await dispatch(handleLoginSession(true));
			await setload(false);
		} else {
			return;
		}
	}

	async function handleLogout() {
		await dispatch(logout());
		await localStorage.clear();
		window.location.href = await 'https://kazi254.ke/#/';
	}

	async function handleFetchInstitutionObj() {
		if (user?.usrInstId) {
			await dispatch(fetchAgencyData(user?.usrInstId));
		}
	}

	async function handleFetchNotifications() {
		if (user?.usrInstId && user?.usrId) {
			await dispatch(fetchNotifications(user));
		}
	}

	useEffect(() => {
		handleInit();
	}, []);

	useEffect(() => {
		handleFetchNotifications();
		handleFetchInstitutionObj();
	}, [pathname, user]);

	useEffect(() => {
		if (isUserLoggedIn) {
			// handleFetchInstitutionObj();
		}
	}, [location, isUserLoggedIn]);

	useEffect(() => {
		if (usrToken?.length === 0) {
			axiosInstance.defaults.headers.common['Authorization'] = token;
		}
		if (token) {
			setusrToken('');
		}
		handleFetchInstitutionObj();
	}, [token]);

	useEffect(() => {
		if (agencyObj?.instStatus === 'APPROVE_ACCOUNT_CREATION' || agencyObj?.instStatus === 'DEREGISTRATION_REQUEST') {
			return
		}else{
			navigate('/dashboard');
		}
	}, [agencyObj, location]);

	useEffect(() => {
		setusrToken(token);
	}, [agencyObj, user, token]);

	useEffect(() => {
		setisUserLoggedIn(isLoggedIn);
	}, [isLoggedIn]);

	if (load) {
		return <Loader />;
	}

	if (isUserLoggedIn && agencyObj?.instStatus === 'CLOSE') {
		return (
			<DashboardLayout isSideBarEnabled={isLoggedIn}>
				<AccountClosedModal />
			</DashboardLayout>
		);
	}

	return (
		<div className='wrapper'>
			<DashboardLayout isSideBarEnabled={isLoggedIn}>
				<Routes>
					<Route exact path='/' element={<Home />} />

					<Route exact path='/home' element={<Home />} />
					<Route exact path='/dashboard' element={<Dashboard />} />
					<Route exact path='/application' element={<Application />} /> 
					<Route exact path='/job-list' element={<JobList />} />
					<Route exact path='/job-view' element={<DrawerModal />} />
					<Route exact path='/applicants-list' element={<ApplicantList />} />
					<Route exact path='/text-editor' element={<TextEditor />} />
					<Route exact path='/applicant-tabs' element={<ApplicantTabs />} />
					<Route exact path='/job-table' element={<JobTable />} />
					<Route exact path='/activity-list' element={<ActivityList />} />
					<Route exact path='/users-list' element={<UsersList />} />
					<Route exact path='/admin-landing' element={<AdminLanding />} />
					<Route exact path='/admin-logo' element={<AdminLogo />} />
					<Route exact path='/users-view' element={<UsersView />} />
					<Route exact path='/license' element={<MainLicensePage />} />
					<Route exact path='/test' element={<Test />} />
					<Route
						exact
						path='/employment-returns'
						element={<EmploymentReturnsView />}
					/>
					<Route exact path='/profile' element={<Profile />} />
					<Route exact path='/employees' element={<Employees />} />
					<Route exact path='/employees/add' element={<AddEmployee />} />
					<Route
						exact
						path='/employees/edit/:name'
						element={<EditEmployee />}
					/>
					<Route
						exact
						path='/employees/folder/:name'
						element={<FolderDetails />}
					/>
					<Route
						exact
						path='/folder-employee-add'
						element={<FolderEmployeesAdd />}
					/>

					<Route exact path='/branches' element={<Branches />} />
					<Route exact path='/branches/edit/:name' element={<EditBranch />} />
					<Route exact path='/inbox' element={<Inbox />} />
					<Route exact path='/inbox/:subject' element={<InboxMessages />} />
					<Route exact path='/sent/:subject' element={<SentMessages />} />
					<Route exact path='/settings' element={<SettingsLanding />} />
					<Route exact path='/recruitment' element={<Recruitment />} />
					<Route exact path='/email-template' element={<EmailTemplate />} />
					<Route exact path='/panel-list' element={<PanelList />} />
					<Route exact path='/activity-invites' element={<ActivityInvites />} />
					<Route exact path='/activity-add' element={<ActivityAdd />} />
					<Route exact path='/activity-step2' element={<ActivityStep2 />} />
					<Route exact path='/activity-step3' element={<ActivityStep3 />} />
					<Route exact path='/jap-table' element={<JapTable />} />
					<Route exact path='/activity-view' element={<ActivityView />} />

					<Route exact path='/license' element={<MainLicensePage />} />
					<Route
						exact
						path='/employment-returns'
						element={<EmploymentReturnsView />}
					/>
					<Route exact path='/profile' element={<Profile />} />
					<Route exact path='/employees' element={<Employees />} />
					<Route exact path='/employees/add' element={<AddEmployee />} />
					<Route
						exact
						path='/employees/add/upload'
						element={<EmployeesUploadList />}
					/>
					<Route
						exact
						path='/employees/edit/:name'
						element={<EditEmployee />}
					/>
					<Route
						exact
						path='/employees/folder/:name'
						element={<FolderDetails />}
					/>
					<Route exact path='/branches' element={<Branches />} />
					<Route exact path='/branches/edit/:name' element={<EditBranch />} />
					<Route exact path='/inbox' element={<Inbox />} />
					<Route exact path='/inbox/:subject' element={<InboxMessages />} />
					<Route exact path='/sent/:subject' element={<SentMessages />} />
					<Route exact path='/settings' element={<SettingsLanding />} />
					<Route exact path='/recruitment' element={<Recruitment />} />
					<Route exact path='/email-template' element={<EmailTemplate />} />

					<Route exact path='/graduates' element={<Graduates />} />
					<Route exact path='/graduates/add' element={<AddGraduate />} />
					<Route
						exact
						path='/graduates/add/file-upload'
						element={<GraduatesUploadList />}
					/>
					<Route exact path='/graduates/edit' element={<EditGraduate />} />
					<Route
						exact
						path='/graduates/folder/:name'
						element={<GraduatesFolderView />}
					/>
					<Route
						exact
						path='/graduates/folders/list/:name'
						element={<GraduatesListView />}
					/>
					<Route
						exact
						path='/graduates/folder/:name'
						element={<FolderDetails />}
					/>
				</Routes>
			</DashboardLayout>
			<AuthVerify logOut={handleLogout} usrToken={usrToken} />
		</div>
	);
}

export default App;
