import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MaterialIcon from "material-icons-react";
import { Link, useNavigate } from "react-router-dom";
import { handleStepCurrent } from "../../../../../features/profile/profileSlice";
import { Spin, Switch } from "antd";
import moment from "moment";
import { fetchProfessionalQualifications } from "../../../../../features/edu/eduSlice";

export default function ProfQualificationTab() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);
  const { professionalQualifications, eduLoading } = useSelector(
    (state) => state.edu
  );

  const [educationList, seteducationList] = useState(
    professionalQualifications
  );

  const { applicantEditId } = useSelector((state) => state.applicant);
  const usrObj = {
    usrId: applicantEditId,
  };

  async function handleProfileBuilder() {
    await dispatch(handleStepCurrent(1));
    await navigate("/profile-builder");
  }

  async function handleFetchEducations() {
    const resp = await dispatch(fetchProfessionalQualifications(usrObj));

    if (resp?.payload?.success) {
      await seteducationList(resp?.payload?.data?.result);
    }
  }

  useEffect(() => {}, [educationList]);

  useEffect(() => {
    seteducationList(professionalQualifications);
  }, [professionalQualifications]);

  useEffect(() => {
    handleFetchEducations();
  }, []);

  return (
    <>
      {eduLoading && <Spin size="large" />}

      {!eduLoading && educationList?.length ? (
        educationList.map((item) => {
          return (
            <div key={item?.pqlId}>
              <div className="bg-white p-3 flex items-center justify-between w-full">
                <div className="w-full flex flex-col">
                  <h3 className="text-blueDark text-[21px] font-bold">
                    {item?.pqlInstName === "N/A"
                      ? "Institution (N/A)"
                      : item?.pqlInstName}{" "}
                    -{" "}
                    {item?.pqlAreaOfStudy === "N/A"
                      ? "Area (N.A)"
                      : item?.pqlAreaOfStudy}
                  </h3>
                  <p className="text-black333 font-medium text-[18px] my-1">
                    {item?.pqlCourse} - {item?.pqlAward}
                  </p>
                  <span className="text-black333 font-normal text-[19px]">
                    {item?.pqlStartDate
                      ? moment(item?.pqlStartDate).format("MMMM YYYY")
                      : "Start Date"}{" "}
                    -{" "}
                    {item?.endEndDate
                      ? moment(item?.endEndDate).format("MMMM YYYY")
                      : "End Date"}
                  </span>

                  {item?.pqlDesc && item?.pqlDesc !== "N/A" ? (
                    <>
                      <span className="text-black2 font-normal text-[18px] text-justify mt-5">
                        {item?.pqlDesc}
                      </span>
                    </>
                  ) : null}
                </div>
              </div>

              <div
                style={{
                  background: "#D8D8D88A",
                  height: "1px",
                  width: "100%",
                }}
                className="my-5"
              ></div>
            </div>
          );
        })
      ) : (
        <div className="flex w-full h-full mb-14">
          <h2 className="text-center text-black3 text-[18px]">
            Your professional qualifications will appear here
          </h2>
        </div>
      )}
    </>
  );
}
