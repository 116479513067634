import { Dropdown, Input, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFolderObj } from "../../features/folder/folderSlice";
import { useNavigate } from "react-router-dom";

export default function GraduatesFolderTable({
  handleFetchData,
  handleAddFolder,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fldLoading, gradFolders } = useSelector((state) => state.folder);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasSelected, sethasSelected] = useState(false);
  const [activeFolder, setactiveFolder] = useState({});
  const [search, setsearch] = useState("");

  const filterDataList = gradFolders?.filter((item) => {
    if (search.length) {
      return item?.fldName?.toUpperCase().includes(search.toUpperCase());
    }
    return item;
  });

  async function handleFolderChange(item) {
    await dispatch(setFolderObj(item));
    await setactiveFolder(item);
    await navigate(`/graduates/folder/${item?.fldName}`);
  }

  const columns = [
    {
      title: "Group name",
      sorter: (a, b) => a.fldName.localeCompare(b.fldName),
      render: (item) => (
        <div
          className="cursor-pointer"
          onClick={() => handleFolderChange(item)}
        >
          {item?.fldName}
        </div>
      ),
    },
    {
      title: "Group description",
      dataIndex: "fldDesc",
      sorter: (a, b) => a.fldDesc.localeCompare(b.fldDesc),
    },
    {
      title: "Creator",
      dataIndex: "fldCreatedByName",
      sorter: (a, b) => a.fldCreatedByName.localeCompare(b.fldCreatedByName),
    },
    {
      title: "Date Created",
      dataIndex: "fldCreatedDate",
      sorter: (a, b) => a.fldCreatedDate.localeCompare(b.fldCreatedDate),
      render: (item) => <span>{moment(item).format("Do MMMM YYYY")}</span>,
    },
    {
      title: "",
      render: (item) => (
        <>
          {/* <Dropdown
						onOpenChange={() => handleFolderChange(item)}
						overlayStyle={{
							width: '250px',
						}}
						trigger={'click'}
						menu={{ items: settingItems }}
						placement='bottom'>
						<button>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='24'
								height='24'
								viewBox='0 0 24 24'
								fill='none'>
								<path
									d='M12 7C12.3956 7 12.7822 6.8827 13.1111 6.66294C13.44 6.44318 13.6964 6.13082 13.8478 5.76537C13.9991 5.39992 14.0387 4.99778 13.9616 4.60982C13.8844 4.22186 13.6939 3.86549 13.4142 3.58579C13.1345 3.30608 12.7781 3.1156 12.3902 3.03843C12.0022 2.96126 11.6001 3.00087 11.2346 3.15224C10.8692 3.30362 10.5568 3.55996 10.3371 3.88886C10.1173 4.21776 10 4.60444 10 5C10 5.53043 10.2107 6.03914 10.5858 6.41421C10.9609 6.78929 11.4696 7 12 7ZM12 17C11.6044 17 11.2178 17.1173 10.8889 17.3371C10.56 17.5568 10.3036 17.8692 10.1522 18.2346C10.0009 18.6001 9.96126 19.0022 10.0384 19.3902C10.1156 19.7781 10.3061 20.1345 10.5858 20.4142C10.8655 20.6939 11.2219 20.8844 11.6098 20.9616C11.9978 21.0387 12.3999 20.9991 12.7654 20.8478C13.1308 20.6964 13.4432 20.44 13.6629 20.1111C13.8827 19.7822 14 19.3956 14 19C14 18.4696 13.7893 17.9609 13.4142 17.5858C13.0391 17.2107 12.5304 17 12 17ZM12 10C11.6044 10 11.2178 10.1173 10.8889 10.3371C10.56 10.5568 10.3036 10.8692 10.1522 11.2346C10.0009 11.6001 9.96126 12.0022 10.0384 12.3902C10.1156 12.7781 10.3061 13.1345 10.5858 13.4142C10.8655 13.6939 11.2219 13.8844 11.6098 13.9616C11.9978 14.0387 12.3999 13.9991 12.7654 13.8478C13.1308 13.6964 13.4432 13.44 13.6629 13.1111C13.8827 12.7822 14 12.3956 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10Z'
									fill='black'
								/>
							</svg>
						</button>
					</Dropdown> */}
          <button
            className="text-blueDark"
            onClick={() => handleFolderChange(item)}
          >
            view
          </button>
        </>
      ),
    },
  ];

  // const onSelectChange = (newSelectedRowKeys) => {
  // 	console.log('selectedRowKeys changed: ', newSelectedRowKeys);
  // 	// setSelectedRowKeys(newSelectedRowKeys);
  // 	newSelectedRowKeys.length > 0
  // 		? sethasSelected(true)
  // 		: sethasSelected(false);
  // };

  // const tableColumns = columns.map((item) => ({
  // 	...item,
  // }));

  async function handleFetch() {
    await handleFetchData();
  }

  useEffect(() => {}, [gradFolders, activeFolder]);

  useEffect(() => {
    handleFetch();
  }, []);

  return (
    <>
      <div className="flex flex-col  w-full">
        <h3 className="font-[700] text-[24px] text-black1 dash-title ">
          Graduate groups   
        </h3>
        <div className="mt-5 flex justify-between items-center w-full">
          <div className="flex items-center w-full">
            <Input
              value={search}
              onChange={(e) => setsearch(e.target.value)}
              placeholder="Search groups name or keyword"
              className="text-[16px] font-[400] flex-row-reverse"
              prefix={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.2508 3.75C7.50609 3.75 4.47041 6.93997 4.47041 10.875C4.47041 14.81 7.50609 18 11.2508 18C14.9955 18 18.0312 14.81 18.0312 10.875C18.0312 6.93997 14.9955 3.75 11.2508 3.75ZM3.04297 10.875C3.04297 6.11154 6.71773 2.25 11.2508 2.25C15.7838 2.25 19.4586 6.11154 19.4586 10.875C19.4586 15.6385 15.7838 19.5 11.2508 19.5C6.71773 19.5 3.04297 15.6385 3.04297 10.875Z"
                    fill="#333333"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.045 15.913C16.3237 15.6201 16.7756 15.6201 17.0543 15.913L21.3902 20.4693C21.6689 20.7622 21.6689 21.237 21.3902 21.5299C21.1115 21.8228 20.6596 21.8228 20.3809 21.5299L16.045 16.9737C15.7663 16.6808 15.7663 16.2059 16.045 15.913Z"
                    fill="#333333"
                  />
                </svg>
              }
            />
          </div>
          {hasSelected ? (
            <>
              <button className="bg-blueDark w-[211px] p-2 text-white font-medium text-[18px] rounded-[56px]">
                Submit to NEA
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => handleAddFolder()}
                className="w-[329px] h-[50px] px-2 py-3  bg-blueDark rounded-[28px] justify-center items-center gap-1 flex
							 text-white text-[18px] leading-normal"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M19.5 11H13.5V5C13.5 4.73478 13.3946 4.48043 13.2071 4.29289C13.0196 4.10536 12.7652 4 12.5 4C12.2348 4 11.9804 4.10536 11.7929 4.29289C11.6054 4.48043 11.5 4.73478 11.5 5V11H5.5C5.23478 11 4.98043 11.1054 4.79289 11.2929C4.60536 11.4804 4.5 11.7348 4.5 12C4.5 12.2652 4.60536 12.5196 4.79289 12.7071C4.98043 12.8946 5.23478 13 5.5 13H11.5V19C11.5 19.2652 11.6054 19.5196 11.7929 19.7071C11.9804 19.8946 12.2348 20 12.5 20C12.7652 20 13.0196 19.8946 13.2071 19.7071C13.3946 19.5196 13.5 19.2652 13.5 19V13H19.5C19.7652 13 20.0196 12.8946 20.2071 12.7071C20.3946 12.5196 20.5 12.2652 20.5 12C20.5 11.7348 20.3946 11.4804 20.2071 11.2929C20.0196 11.1054 19.7652 11 19.5 11Z"
                    fill="#EDF8FF"
                  />
                </svg>
                Add graduate group
              </button>
            </>
          )}
        </div>
      </div>

      <div className="dash-inner-page mt-[24px] max-w-full w-full overflow-x-auto">
        <section>
          <Table
            // rowKey={'fldId'}
            // rowSelection={{
            // 	onChange: onSelectChange,
            // }}
            rowSelection={true}
            loading={fldLoading}
            className="no-header-bg-table"
            size={"middle"}
            bordered={false}
            columns={columns}
            dataSource={filterDataList}
            scroll={{
              x: 700,
            }}
            pagination={{
              defaultPageSize: 5,
              hideOnSinglePage: true,
              pageSizeOptions: [10, 20, 50, 100],
            }}
          />
        </section>
      </div>
    </>
  );
}
