import emblem from "../../assets/emblem.png";
import flagDivider from "../../assets/flag_divider.svg";
import neaLogo from "../../assets/NEA-logo.png";
import { Link } from "react-router-dom";

function Header() {
  return (
    <Link to="https://kazi254.ke">
      <div
        className="flex items-center z-[1000] bg-white lg:bg-transparent w-full"
        style={{ position: "fixed" }}
      >
        <div className="flex items-center bg-white lg:bg-transparent w-full">
          <img
            src={emblem}
            className="ml-[20px] mr-[14px] object-contain w-[97px] h-[86px]"
            alt="kenya Emplem"
          />
          <img src={flagDivider} alt="kenya flag colors" />
          <img
            src={neaLogo}
            className="ml-[25.84px] object-contain w-[196px] h-[70px]"
            alt="NEAIMIS Logo"
          />
        </div>
      </div>
    </Link>
  );
}

export default Header;
