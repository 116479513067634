import { Dropdown, Input, Modal, Table } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteGraduate,
  fetchGraduateFolders,
  fetchGraduates,
  saveGraduateHeader,
  setGradUpload,
  setGraduateObj,
} from "../../../features/graduates/GraduateSlice";
import { Link, useNavigate } from "react-router-dom";
import { customToast } from "../../../notifications";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ConfirmationModal from "../../../components/ConfirmationModal";
import toast from "react-hot-toast";
import { saveFolder } from "../../../features/folder/folderSlice";
import DeleteModal from "../../../components/DeleteModal";

export default function GraduatesListView({ handleGraduateOption }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { gradLoading, gradListObj, graduates } = useSelector(
    (state) => state.graduate
  );

  const [activeGraduate, setactiveGraduate] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [hasSelected, sethasSelected] = useState(false);
  const { folderObj } = useSelector((state) => state.folder);

  const [modal, contextHolder] = Modal.useModal();
  const [search, setsearch] = useState("");

  const filteredGraduates = search
    ? graduates?.filter(
        (item) =>
          item?.grdFirstName?.toLowerCase().includes(search.toLowerCase()) ||
          item?.grdLastName?.toLowerCase().includes(search.toLowerCase()) ||
          item?.grdEmail?.toLowerCase().includes(search.toLowerCase()) ||
          item?.grdCourse?.toLowerCase().includes(search.toLowerCase()) ||
          item?.emplKraPin?.toLowerCase().includes(search.toLowerCase()) ||
          item?.grdNationalId?.toLowerCase().includes(search.toLowerCase())
      )
    : graduates;

  const confirm = () => {
    modal.confirm({
      title: "Confirm ",
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <div className="flex flex-col">
            <span className="text-[18px] font-medium text-black3 mb-3">
              Do you confirm to deleting the following graduate?
            </span>
            <div className="flex">
              <span className="text-[18px]">Name: </span>
              <span className="text-[18px] font-bold ml-3">
                {activeGraduate?.grdFirstName} {activeGraduate?.grdLastName}
              </span>
            </div>
            <div className="flex">
              <span className="text-[18px]">Admission No.: </span>
              <span className="text-[18px] font-bold ml-3">
                {activeGraduate?.grdAdmNo}
              </span>
            </div>
          </div>
        </>
      ),
      okText: "Delete",
      onOk: handleDeleteGraduate,
      cancelText: "Cancel",
      cancelButtonProps: {
        style: {
          background: "#fff",
          border: "1px solid #147CBC",
          color: "#212143",
          fontWeight: 500,
          fontSize: "14px",
        },
      },
      okButtonProps: {
        style: {
          background: "#10101E",
          color: "#fff",
          fontWeight: 500,
          fontSize: "14px",
        },
      },
    });
  };

  function handleAddNew() {
    navigate("/graduates/add");
  }

  function handleGoBack() {
    navigate(-1);
  }
  const [isModalOpenConfirmation, setIsModalOpenConfirmation] = useState(false);

  const handleOpenConfirmation = async () => {
    setIsModalOpenConfirmation(true);
  };

  const [isModalOpenDelete, setIsModalOpenDelete] = useState(false);
  const handleOpenDelete = async () => {
    setIsModalOpenDelete(true);
  };

  async function handleDeleteGraduate() {
    const res = await dispatch(deleteGraduate(activeGraduate?.grdId));
    if (res?.payload?.success) {
      await customToast({
        content: "Graduate deleted",
        bdColor: "success",
        header: "Success",
      });
      await setactiveGraduate({});
      await setIsModalOpenDelete(false);
      handleFetchGraduatesData();
    } else {
      await customToast({
        content: res?.payload?.messages?.message,
        bdColor: "error",
        header: "Error",
      });
    }
  }

  const content = `Are you sure you want to submit graduates in folder ${folderObj.fldName} to NEA`;
  const contentDelete = `Are you sure you want to remove graduant ${activeGraduate.grdFirstName}`;

  async function handleActiveGraduate(item) {
    await dispatch(setGraduateObj(item));
    await setactiveGraduate(item);
  }

  async function handleUploadFile() {
    await dispatch(setGradUpload(true));
    await navigate("/graduates/add/file-upload");
  }

  async function handleSubmitToNea() {
    // await selectedRowKeys?.forEach((item) => {
    //   let updObj = {
    //     gdcId: item,
    //     gdcStatus: "SUBMITTED",
    //   };
    //   dispatch(saveGraduateHeader(updObj));
    // });
    let updObj = {
      fldId: folderObj?.fldId,
      fldStatus: "SUBMITTED",
    };
    const res = await dispatch(saveFolder(updObj));
    if (res?.payload?.success) {
      toast.success(res?.payload?.messages?.message);
      await setIsModalOpenConfirmation(false);
      await navigate('/graduates')
    } else {
      toast.error(res?.payload?.messages?.message);
    }
    await handleFetchFolderData();
  }

  async function handleFetchFolderData() {
    await dispatch(fetchGraduateFolders(folderObj?.fldId));
  }

  async function handleFetchGraduatesData() {
    await dispatch(fetchGraduates(folderObj?.fldId));
  }

  const settingItems = [
    {
      key: "0",
      label: (
        <Link
          to="/graduates/edit"
          className={`font-dmSans text-black font-[400] text-[19px] !mt-[5%]`}
        >
          Edit
        </Link>
      ),
    },

    {
      key: "1",
      label: (
        <div
          onClick={handleOpenDelete}
          className={`font-dmSans text-black font-[400] text-[19px] !mt-[5%]  `}
        >
          Remove
        </div>
      ),
    },
  ];

  const settingItemsQuickActions = [
    {
      key: "0",
      label: (
        <div
          onClick={folderObj?.fldStatus === "SUBMITTED" ? null : handleGraduateOption}
          className={`font-dmSans text-[#344054] font-[500] text-[18px] !mt-[5%]  ${folderObj?.fldStatus === "SUBMITTED"
            ? "cursor-not-allowed"
            : "cursor-pointer"}`}
        >
          Add graduates
        </div>
      ),
    },

    {
      key: "1",
      label: (
        <div
          onClick={
            folderObj?.fldStatus === "SUBMITTED" ? null : handleOpenConfirmation
          }
          className={`font-dmSans text-[#344054] font-[500] text-[18px] !mt-[5%] ${folderObj?.fldStatus === "SUBMITTED"
            ? "cursor-not-allowed"
            : "cursor-pointer"}`}
        >
          Submit to NEA 
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: "Name of graduate",
      render: (item) => (
        <span>
          {item?.grdFirstName} {item?.grdLastName}
        </span>
      ),
      sorter: (a, b) => a.grdFirstName.localeCompare(b.grdFirstName),
    },
    {
      title: "ID No.",
      dataIndex: "grdNationalId",
      sorter: (a, b) => a.grdNationalId.localeCompare(b.grdNationalId),
    },
    {
      title: "Admission No.",
      dataIndex: "grdAdmNo",
      sorter: (a, b) => a.grdAdmNo.localeCompare(b.grdAdmNo),
    }, 
    {
      title: "Email",
      dataIndex: "grdEmail",
      sorter: (a, b) => a.grdEmail.localeCompare(b.grdEmail),
    },
    {
      title: "Phone No.",
      dataIndex: "grdMobileNumber",
      sorter: (a, b) => a.grdMobileNumber.localeCompare(b.grdMobileNumber),
    },
    {
      title: "Award",
      dataIndex: "grdAward",
      sorter: (a, b) => a.grdAward.localeCompare(b.grdAward),
    },
    {
      title: "Course",
      dataIndex: "grdCourse",
      sorter: (a, b) => a.grdCourse.localeCompare(b.grdCourse),
    },
    {
      title: "Graduation Date",
      dataIndex: "grdGradDate",
      render: (item) => <span>{moment(item).format("Do MMMM YYYY")}</span>,
      sorter: (a, b) => a.grdGradDate.localeCompare(b.grdGradDate),
    },
    {
      title: "",
      render: (item) => (
        <>
          {item?.grdStatus != "SENT" && (
            <Dropdown
              onOpenChange={() => handleActiveGraduate(item)}
              overlayStyle={{
                width: "250px",
              }}
              trigger={"click"}
              menu={{ items: settingItems }}
              placement="bottom"
            >
              <button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M12 7C12.3956 7 12.7822 6.8827 13.1111 6.66294C13.44 6.44318 13.6964 6.13082 13.8478 5.76537C13.9991 5.39992 14.0387 4.99778 13.9616 4.60982C13.8844 4.22186 13.6939 3.86549 13.4142 3.58579C13.1345 3.30608 12.7781 3.1156 12.3902 3.03843C12.0022 2.96126 11.6001 3.00087 11.2346 3.15224C10.8692 3.30362 10.5568 3.55996 10.3371 3.88886C10.1173 4.21776 10 4.60444 10 5C10 5.53043 10.2107 6.03914 10.5858 6.41421C10.9609 6.78929 11.4696 7 12 7ZM12 17C11.6044 17 11.2178 17.1173 10.8889 17.3371C10.56 17.5568 10.3036 17.8692 10.1522 18.2346C10.0009 18.6001 9.96126 19.0022 10.0384 19.3902C10.1156 19.7781 10.3061 20.1345 10.5858 20.4142C10.8655 20.6939 11.2219 20.8844 11.6098 20.9616C11.9978 21.0387 12.3999 20.9991 12.7654 20.8478C13.1308 20.6964 13.4432 20.44 13.6629 20.1111C13.8827 19.7822 14 19.3956 14 19C14 18.4696 13.7893 17.9609 13.4142 17.5858C13.0391 17.2107 12.5304 17 12 17ZM12 10C11.6044 10 11.2178 10.1173 10.8889 10.3371C10.56 10.5568 10.3036 10.8692 10.1522 11.2346C10.0009 11.6001 9.96126 12.0022 10.0384 12.3902C10.1156 12.7781 10.3061 13.1345 10.5858 13.4142C10.8655 13.6939 11.2219 13.8844 11.6098 13.9616C11.9978 14.0387 12.3999 13.9991 12.7654 13.8478C13.1308 13.6964 13.4432 13.44 13.6629 13.1111C13.8827 12.7822 14 12.3956 14 12C14 11.4696 13.7893 10.9609 13.4142 10.5858C13.0391 10.2107 12.5304 10 12 10Z"
                    fill="black"
                  />
                </svg>
              </button>
            </Dropdown>
          )}
        </>
      ),
    },
  ];

  useEffect(() => {
    handleFetchGraduatesData();
  }, []);

  return (
    <>
      <div className="mt-[35px]">
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center w-full">
            <Input
              value={search}
              onChange={(e) => setsearch(e.target.value)}
              placeholder="Search graduate name or keyword"
              className="text-[16px] font-[400] flex-row-reverse"
              prefix={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.2508 3.75C7.50609 3.75 4.47041 6.93997 4.47041 10.875C4.47041 14.81 7.50609 18 11.2508 18C14.9955 18 18.0312 14.81 18.0312 10.875C18.0312 6.93997 14.9955 3.75 11.2508 3.75ZM3.04297 10.875C3.04297 6.11154 6.71773 2.25 11.2508 2.25C15.7838 2.25 19.4586 6.11154 19.4586 10.875C19.4586 15.6385 15.7838 19.5 11.2508 19.5C6.71773 19.5 3.04297 15.6385 3.04297 10.875Z"
                    fill="#333333"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.045 15.913C16.3237 15.6201 16.7756 15.6201 17.0543 15.913L21.3902 20.4693C21.6689 20.7622 21.6689 21.237 21.3902 21.5299C21.1115 21.8228 20.6596 21.8228 20.3809 21.5299L16.045 16.9737C15.7663 16.6808 15.7663 16.2059 16.045 15.913Z"
                    fill="#333333"
                  />
                </svg>
              }
            />
          </div>

          <Dropdown
            overlayStyle={{
              width: "250px",
            }}
            trigger={"click"}
            menu={{ items: settingItemsQuickActions }}
            placement="bottom"
          >
            <button
              className="w-[250px] h-[50px] px-[24px] py-3 bg-blueDark rounded-[28px] justify-center items-center gap-1 inline-flex
				   text-white text-[18px] leading-normal"
              type="submit"
            >
              Quick actions
            </button>
          </Dropdown>
        </div>

        <div className="text-black1 mt-[45px] mb-[21px] text-[20px] font-medium leading-[24px]">
          List of graduates
        </div>

        <section>
          <Table
            rowSelection={true}
            loading={gradLoading}
            className="no-header-bg-table"
            size={"middle"}
            bordered={false}
            columns={columns}
            dataSource={filteredGraduates}
            scroll={{
              x: 700,
            }}
            pagination={{
              defaultPageSize: 5,
              hideOnSinglePage: true,
              pageSizeOptions: [10, 20, 50, 100],
            }}
          />
        </section>
      </div>

      <ConfirmationModal
        isModalOpen={isModalOpenConfirmation}
        setIsModalOpen={setIsModalOpenConfirmation}
        handleSubmit={handleSubmitToNea}
        loading={gradLoading}
        content={content}
      />

      <DeleteModal
        isModalOpen={isModalOpenDelete}
        setIsModalOpen={setIsModalOpenDelete}
        handleDelete={handleDeleteGraduate}
        content={contentDelete}
        title="Remove Graduate"
        loading={gradLoading}
      />
    </>
  );
}
