import { Button, Form, Input, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchContactPersons,
  setContactId,
  setEditContact,
} from "../../../../../features/client/clientSlice";

function ContactInfoView() {
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const formRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.auth);
  const { refetch, refetchKey } = useSelector((state) => state.cv);
  const {
    contactDetails,
    clients,
    clientEditId,
    contactAdding,
    contactPersons,
  } = useSelector((state) => state.client);
  const { singleCreateApplicant, applicantEditId } = useSelector(
    (state) => state.applicant
  );

  const [data, setdata] = useState({});

  const handleEditContact = () => {
    dispatch(setEditContact(true));
  };
  const handleSetContactId = (id) => {
    dispatch(setContactId(id));
  };

  const onFinish = async (data) => {};

  useEffect(() => {
    if (refetchKey == 1) {
      dispatch(
        fetchContactPersons({
          conUsrId: singleCreateApplicant?.usrId
            ? singleCreateApplicant?.usrId
            : applicantEditId,
        })
      );
    }
    // dispatch(
    //   fetchContactPersons({
    //     conUsrId: singleCreateApplicant?.usrId ? singleCreateApplicant?.usrId : applicantEditId,
    //   })
    // );
  }, [refetch, refetchKey, applicantEditId]);

  return (
    <div className="mt-[38px]">
      <Form
        layout="vertical"
        ref={formRef}
        name="control-ref"
        onFinish={onFinish}
        style={{
          maxWidth: "100%",
        }}
        form={form}
      >
        {contactPersons?.length > 0 &&
          contactPersons?.map((item) => (
            <>
              <div className="w-full">
                <div className="text-blueDark text-[21px] font-bold font-dmSans flex text-center items-center gap-2 cursor-pointer">
                  <span className="flex items-center">{item?.conName}</span>
                </div>
              </div>
              <div className="grid grid-cols-6 mb-[0px]"></div>
              <div className="grid grid-cols-12 mb-[10px]">
                <div className=" w-full">
                  <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose">
                    {item?.conDesc}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 mb-[0px]">
                <div className="w-full">
                  <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose">
                    Email
                  </div>
                </div>
                <div className="w-full">
                  <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose">
                    {item?.conEmail}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-12 mb-[30px]">
                <div className="w-full">
                  <div className="text-blackest text-[19px] font-medium font-dmSans leading-loose">
                    Phone
                  </div>
                </div>
                <div className="w-full">
                  <div className="text-blackest text-[19px] font-normal font-dmSans leading-loose">
                    {item?.conMobileNumber}
                  </div>
                </div>
              </div>{" "}
            </>
          ))}
      </Form>
    </div>
  );
}

export default ContactInfoView;
