import React, { useEffect, useState } from "react";  
import { Dropdown, Tabs } from "antd"; 
import RecommedationTable from "./RecommedationTable";
import ReferralsTable from "./ReferralsTable";

function RecommedationsTabs() { 
  const { TabPane } = Tabs;

  const tabConfigurations = [
    {
      label: (
        <span className="flex items-center"> 
          <span className="ml-2 ">Recommendations</span>
        </span>
      ),
      key: "1",
      children: <RecommedationTable/>,
    },

    // {
    //   label: (
    //     <span className="flex items-center"> 
    //       <span className="ml-2">Referrals</span>
    //     </span>
    //   ),
    //   key: "2",
    //   children: <ReferralsTable/>,
    //   // children: <ReferralsTable/>,
    // }, 
  ];
 
  return (
    <> 
      <div className="mt-[1%]">
        <Tabs
          items={tabConfigurations}
          defaultActiveKey="1"
          className="jobs-tab recommedation-tab-wrap recommedation-bar"
        />
      </div>
 
    </>
  );
}

export default RecommedationsTabs;
